import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { RICH_BLACK } from '../../colors/colors';

export interface SpeechBubbleProps {
  isMirror?: boolean;
  text?: string;
  backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    marginLeft: theme.typography.pxToRem(5),
  },
  content: {
    display: 'inline-block',
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
    borderRadius: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(
      30
    )} ${theme.typography.pxToRem(30)} 0`,
    minHeight: 39,
    backgroundColor: ({ backgroundColor }: any) => backgroundColor,
  },
  text: {
    color: `${RICH_BLACK} !important`,
    wordBreak: 'break-word',
  },
  mirror: {
    transform: 'scale(-1, 1)',
  },
}));

export const SpeechBubble = ({
  isMirror,
  text,
  backgroundColor,
}: SpeechBubbleProps) => {
  const classes = useStyles({ backgroundColor });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography
          className={clsx(classes.text, isMirror && classes.mirror)}
          align="left"
          variant="body2"
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};
