import {
  InputLabel,
  withStyles,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FieldError } from '../FieldError/FieldError';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export interface ComboBoxprops {
  handleOnClick?: (event: any) => void;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  options: any;
  value?: any;
  inputLabelClassName?: any;
  defaultValue?: any;
  classes?: any;
  className?: any;
  clearIndicatorClass?: any;
  rest?: any;
  errormessage?: any;
  required?: boolean;
  optionClass?: any;
  inputValue?: any;
  IconClassName?: any;
  popupIndicatorClass?: any;
  inputLabel?: any;
  inputClass?: any;
  borderCondition?: boolean;
  borderColor?: any;
  backgroundColorCondition?: boolean;
  backgroundColor?: any;
  rootClass?: any;
  inputRootClass?: any;
  noOptionsText?: string | any;
  onKeyDown?: (event: any) => void;
}
const StyledInputLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    position: 'relative',
    '& span': {
      color: 'red !important',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))((props) => <InputLabel {...props} />) as typeof InputLabel;
export function ComboBox({
  handleOnClick,
  isOptionEqualToValue,
  onChange,
  onBlur,
  className,
  inputLabel,
  options,
  value,
  defaultValue,
  optionClass,
  classes,
  inputLabelClassName,
  IconClassName,
  errormessage,
  rootClass,
  inputClass,
  inputValue,
  popupIndicatorClass,
  borderCondition,
  backgroundColorCondition,
  backgroundColor,
  borderColor,
  required,
  clearIndicatorClass,
  noOptionsText,
  onKeyDown,
  inputRootClass,
  ...rest
}: ComboBoxprops) {
  const useStyles = makeStyles(() => ({
    root: {
      maxWidth: '100%',
      color: 'rgb(46, 49, 190)',
      border: borderCondition ? borderColor : '1px solid rgb(227, 227, 227)',
      borderRadius: '6px',
      alignItems: 'center',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'OpenSans-semibold !important',
      justifyContent: 'space-between',
      minHeight: '38px',
      position: 'relative',
      transition: 'all 100ms ease 0s',
      boxSizing: 'border-box',
      backgroundColor: backgroundColorCondition
        ? backgroundColor
        : 'rgb(242, 242, 255) !important',
      outline: '0px !important',
    },
    popupIndicator: {
      color: '#0f0fbf !important',
      fontFamily: 'OpenSans-semibold !important',
      fill: ' #0f0fbf !important',
    },

    inputRoot: {
      paddingRight: '7px !important',
    },
  }));
  const ownClass = useStyles();
  return (
    <React.Fragment>
      {inputLabel && (
        <StyledInputLabel
          className={clsx(classes?.inputLabel, inputLabelClassName)}
          disableAnimation
          required={required}
        >
          {inputLabel}
        </StyledInputLabel>
      )}
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        //   sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={clsx(ownClass.popupIndicator, IconClassName)}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
        value={value}
        defaultValue={value}
        onChange={onChange}
        inputValue={inputValue}
        onClick={handleOnClick}
        onKeyDown={onKeyDown}
        clearIcon={<React.Fragment />}
        popupIcon={<React.Fragment />}
        onOpen={() => {
          return null;
        }}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={isOptionEqualToValue}
        className={className}
        classes={{
          root: clsx(ownClass.root, rootClass),
          popupIndicator: clsx(ownClass.popupIndicator, popupIndicatorClass),
          input: clsx(ownClass.popupIndicator, inputClass),
          option: clsx(ownClass.popupIndicator, optionClass),
          //   clearIndicator: clsx(ownClass.popupIndicator, clearIndicatorClass),
          inputRoot: clsx(ownClass.inputRoot, inputRootClass),
        }}
        onBlur={onBlur}
        {...rest}
      />
      {errormessage && (
        <FieldError classes={classes} errorMessage={errormessage} />
      )}
    </React.Fragment>
  );
}
