import React, { PropsWithChildren } from 'react';
import {
  TableCell,
  TableCellProps,
  TableRowProps as MuiTableRowProps,
  Typography,
  withStyles,
  TableHead as MuiTableHead,
  TableRow,
  TableHeadProps,
  Table as MuiTable,
  TableBody,
  TableProps,
  TypographyProps,
} from '@material-ui/core';
import { pixelToRem } from '../../utils/utils';
import { StyleClasses } from '../StyleClasses';
import clsx from 'clsx';

export interface RowTipProps extends TypographyProps {
  tipColor: string;
}

export const StyledRowTip = withStyles(() => ({
  root: {
    padding: `${pixelToRem(14)}rem ${pixelToRem(5)}rem`,
    borderRadius: `${pixelToRem(3)}rem`,
    borderLeft: (props: RowTipProps) =>
      `${pixelToRem(3)}rem solid ${props.tipColor}`,
  },
}))((props: RowTipProps) => (
  <Typography color="secondary" component="span" {...props} />
));

export interface CellProps extends TableCellProps {
  noLeftPadding?: boolean;
}

export const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    lineHeight: `${pixelToRem(21)}rem`,
    padding: `${pixelToRem(15)}rem ${pixelToRem(8)}rem`,
  },
  head: {
    fontWeight: 600,
  },
  paddingCheckbox: {
    paddingLeft: 0,
  },
}))((props: CellProps) => <TableCell {...props} />);

export interface TableRowProps extends MuiTableRowProps {
  backgroundColor?: string;
}

export const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: (props: TableRowProps) =>
      props.backgroundColor ? props.backgroundColor : 'currentColor',
  },
}))((props: TableRowProps) => <TableRow {...props} />);

type ClassesKeys = 'root';

export interface MuiTableProps extends TableProps {
  'data-testid'?: string;
  renderTableHead?: () => JSX.Element;
  renderTableContent?: () => JSX.Element;
  headClasses?: StyleClasses<ClassesKeys>;
  bodyClasses?: StyleClasses<ClassesKeys>;
}

export interface HeadProps extends TableHeadProps {
  'data-testid'?: string;
}

const TableHead = ({ children, ...rest }: PropsWithChildren<HeadProps>) => {
  return (
    <MuiTableHead {...rest}>
      <TableRow>{children}</TableRow>
    </MuiTableHead>
  );
};

export const Table = ({
  renderTableHead,
  renderTableContent,
  headClasses,
  bodyClasses,
  ...rest
}: MuiTableProps) => {
  return (
    <MuiTable {...rest}>
      {renderTableHead && (
        <TableHead classes={{ root: clsx(headClasses?.root) }}>
          {renderTableHead()}
        </TableHead>
      )}
      {renderTableContent && (
        <TableBody classes={{ root: clsx(bodyClasses?.root) }}>
          {renderTableContent()}
        </TableBody>
      )}
    </MuiTable>
  );
};
