/* eslint-disable */
import {
    RACSelect,
    Typography,
    RACButton,
    Grid,
    CardContent,
    Card,
    RACCheckBox,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACTextbox,
    RACModalCard,
    RACDatePicker,
    CircularProgress,
  } from "@rentacenter/racstrap";
  import React, { useContext, useEffect, useState } from "react";
import {packageContext} from "./PackageContext";
import { Context } from "vm";
import { postFilterValue } from "../../../api/user";
import {PackageFilterPayloadObjInt} from "../../shared/interface/Request"
import { MultiSelect } from "react-multi-select-component";
import {PricingReportStyle} from "../Styles/PricingReport"
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as DownArrow } from "../../../assets/images/down-arrow-filter.svg";

export default function PackageFilter(){
    const {loader, setLoader, enableGrid, setEnableGrid, pginitial,
         setpginitial, tableRecord, setTableRecord, pageCount, setPageCount, 
         totRecord, setTotRecord, noRecordsFound, setNoRecordsFound, filterPopup, setFilterPopup,
         errorMsgPackage, setErrorMsgPackage, gridErrorPopup, setGridErrorPopup, applyFilter, filterPackagePayload, setFilterPackagePayload, 
        zoneDd, setZoneDd, bracketDd, setBracketDd, departmentDd, setDepartmentDd, subDepartmentDd, setSubDepartmentDd,
         packageNameDd, setPackageNameDd, dropdownLoader, updatePackagePopup, setUpdatePackagePopup,isPackageFormOpen, setIsPackageFormOpen,
        originalSubDeptArr, setOriginalSubDeptArr, zoneSelected, setZoneSelected, discountType, originalBracketArr, setOriginalBracketArr, filterPackagePayloadObj, 
        emailId,setEmailId, employeeId, setEmployeeId, isApprover, setIsApprover, authOpen, setAuthOpen, isSLACompleted, setIsSLACompleted, isSLACancel,setIsSLACancel, sucessPopup, setSuccessPopup, masterLoader, setMasterLoader,
        isSubmitButtonClicked, setIsSubmitButtonClicked, submitClick, submitButtonStatus, setSubmitButtonStatus, handleSubmitButtonValidation, handleCancelClick, ApprovedBy, setApprovedBy, selectQueueType, setSelectQueueType, editRecordsArray, setEditRecordsArray, companyCodeZone, setCompanyCodeZone, franchisee, setFranchisee, startDateCheck, setStartDateCheck} = useContext(packageContext)
         
  
         const [zoneOptions, setZoneOptions] = useState([
          { value: "2000", label: "2000" },
    { value: "9899", label: "9999" },
    { value: "1188", label: "1144" },
    { value: "64666", label: "64666" },
    { value: "8", label: "8" },
    { value: "898", label: "898" }
  ])
    //Apply click
    const names = [
        { value: "", label: "Select" },
        { value: "9899", label: "9999" },
        { value: "1188", label: "1144" },
        { value: "64666", label: "64666" },
        { value: "85687", label: "85687" },
        { value: "2000", label: "2000" }
      ];
    
      //handle the onChange of the filter fields
      const handleFilterOnChange = (e: any, field: any) => {

        if(field == "rmsItemNumber"){
          e.target.value = e.target.value.replace(/[^0-9]/g, "");
        }
            setFilterPackagePayload((prevFilter: PackageFilterPayloadObjInt) => ({...prevFilter, [field]: e.target.value}))
              console.log("e.target.value", e.target.value)
            console.log("befor the function ends handle package paylod filterPackagePayload", filterPackagePayload)
      }
    console.log("after the handle function ends handle package paylod filterPackagePayload", filterPackagePayload)
    console.log("this is also working")
    console.log("filterPackagePayload", filterPackagePayload)
    const pricing = PricingReportStyle();

    
    const validateApplyButton = () => {
      if(((filterPackagePayload.zoneNumber?.length != 0) || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0" && filterPackagePayload.subdepartmentNumber != "0") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0" && filterPackagePayload.brandName != "") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0" && filterPackagePayload.rmsItemNumber != "")
      || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0" && filterPackagePayload.bracketNumber != "0") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0" && filterPackagePayload.packageName != "0") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.departmentNumber != "0" && filterPackagePayload.modelNumber != "") ||
      (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.subdepartmentNumber != "0") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.subdepartmentNumber != "0" && filterPackagePayload.brandName != "") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.rmsItemNumber != "") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.rmsItemNumber != "" && filterPackagePayload.discountType != "") || (filterPackagePayload.zoneNumber?.length != 0 && filterPackagePayload.packageName != "0" && filterPackagePayload.discountType != "") ) && !loader){
        return false
      }else{
        return true
      }
    }

    const handleClearClick = () => {
      setZoneSelected([])
      setBracketDd(originalBracketArr)
    setSubDepartmentDd(originalSubDeptArr);
      setFilterPackagePayload({
        zoneNumber: [],
        departmentNumber: '0',
  subdepartmentNumber: '0',
  bracketNumber: '0',
  brandName: '',
  modelNumber: '',
  rmsItemNumber: '',
  packageName: '0',
  discountType: '',
      });
      setEnableGrid(false);
    };

const filterCard = () => {
    return(
        <>
        <Grid container
        md={12}
        lg={12}
        sm={12}
        xs={12}
        style={{ paddingTop: "1%", paddingBottom:"0%" }}>
          <Grid item
          md={9}
          lg={9}
          sm={4}
          xs={4}
          style={{ padding: "0.5% 0%", display: "flex" }}>
          <Typography
                style={{ float: "left", position: "relative", top: "12px" }}
                variant="h4"
              >
                Package Details
              </Typography>
          </Grid>
              <Grid item md={3} lg={3} sm={3} xs={3} style={{ float: "right" }}>
              <RACButton
                variant="contained"
                color="primary"
                size="small"
            style={{ cursor: "pointer", float: "right" }}

                onClick={() =>
                 { filterPopup == true ? setFilterPopup(false) : setFilterPopup(true)}
                }
                
              >
                <RacFilter></RacFilter>
                <span style={{ margin: "0px 8px" }}>Filter</span>
                {filterPopup == true ? (
                  <UpArrow style={{ marginLeft: "10px" }} />
                ) : (
                  <DownArrow style={{ marginLeft: "10px" }} />
                )}
              </RACButton>
              </Grid>
              
              </Grid>
              
              {filterPopup == true ? (
              <Grid style={{ margin: "0.5% 0% 1.5% 0%", overflow: "visible" }}>

<Card style={{ overflow: "visible", height: "230px", borderRadius: "12px" }}>
        <CardContent style={{ overflow: "visible" }}>
          <Grid container spacing={4}>
            <Grid item md={2} >
                  <Typography>
                    Zone<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <MultiSelect
                  isLoading={zoneDd?.length > 0 || (franchisee && companyCodeZone?.length == 0)? false : true}
                    options={zoneDd}//zoneDd
                    value={zoneSelected}//new array to store all the selected value and pushing it to the array.
                    className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                    onChange={(e: any) => {
                      setZoneSelected(e);
                      let arr = e.map((item: any) => {
                        if (item.value == item.value) return item.value;
                      });
                      console.log(arr);

                      setFilterPackagePayload({
                        ...filterPackagePayload,
                        ["zoneNumber"]: arr,
                      });
                    }}
                    labelledBy={"Select"}
                  />
                </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                inputLabel="Department"
                options={departmentDd}//departmentDd
                defaultValue={filterPackagePayload?.departmentNumber ? filterPackagePayload?.departmentNumber : '0'}//filterPackagePayload.departmentNumber ? filterPackagePayload.departmentNumber : '0'
                onChange={(e) => {
                  setFilterPackagePayload({...filterPackagePayload, "subdepartmentNumber": "0", "bracketNumber": "0"})
                  handleFilterOnChange(e, "departmentNumber");
                  console.log("originalSubDeptArr array", originalSubDeptArr)
                  let restrictSubDept = originalSubDeptArr.filter(
                  (item: any) => {
                    console.log("item for the department", item)
                    if (item.deptId == e.target.value) {
                      return item;
                    }
                  }
                );
                restrictSubDept.unshift({
                  label: "Select",
                  value: "0",
                  deptId: "",
                });
                if(e.target.value !='0')
                setSubDepartmentDd(restrictSubDept);
              else {
                setBracketDd(originalBracketArr)
              setSubDepartmentDd(originalSubDeptArr);
              }
              
              }}
                loading= {dropdownLoader}
              ></RACSelect>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                inputLabel="Sub Department"
                options={subDepartmentDd}//subDepartmentDd
                defaultValue={filterPackagePayload?.subdepartmentNumber != "0" ? filterPackagePayload?.subdepartmentNumber : '0'}//filterPackagePayload.subdepartmentNumber ? filterPackagePayload.subdepartmentNumber : '0'
                onChange={(e) => {
                  setFilterPackagePayload({...filterPackagePayload, "bracketNumber": "0"})
                  handleFilterOnChange(e, "subdepartmentNumber");
                  console.log("originalSubDeptArr array", originalSubDeptArr)
                  let restrictBracket = originalBracketArr.filter(
                  (item: any) => {
                    console.log("item for the department", item)
                    if (item.subdeptId == e.target.value) {
                      return item;
                    }
                  }
                );
                restrictBracket.unshift({
                  label: "Select",
                  value: "0",
                  subdeptId: "",
                });
                if(e.target.value !='0')
                setBracketDd(restrictBracket);
              else 
              setBracketDd(originalBracketArr);
              }}
                loading= {dropdownLoader}
              ></RACSelect>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                inputLabel="Bracket"
                options={bracketDd}//bracketDd
                defaultValue={filterPackagePayload?.bracketNumber != "0" ? String(filterPackagePayload?.bracketNumber) : "0"}//filterPackagePayload.bracketName ? filterPackagePayload.bracketName : '0'
                onChange={(e) => handleFilterOnChange(e, "bracketNumber")}
                loading= {dropdownLoader}
              ></RACSelect>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACTextbox
                value={filterPackagePayload?.brandName}
                inputlabel="Brand"
                maxlength={50}
                OnChange={(e) => handleFilterOnChange(e, "brandName")} 
              ></RACTextbox>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACTextbox
                value={filterPackagePayload?.modelNumber}
                inputlabel="Model #"
                maxlength={30}
                OnChange={(e) => handleFilterOnChange(e, "modelNumber")} 
              ></RACTextbox>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACTextbox
                value={filterPackagePayload?.rmsItemNumber}
                inputlabel="RMS #"
                maxlength={13}
                OnChange={(e) => handleFilterOnChange(e, "rmsItemNumber")} 
              ></RACTextbox>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                inputLabel="Package Name"
                options={packageNameDd}// packageNameDd
                defaultValue={filterPackagePayload?.packageName ? filterPackagePayload?.packageName : '0'}// filterPackagePayload.packageName ? filterPackagePayload.packageName : '0'
                onChange={(e) => handleFilterOnChange(e, "packageName")} 
                loading= {dropdownLoader}
              ></RACSelect>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                inputLabel="Discount Type"
                options={discountType}
                defaultValue={filterPackagePayload?.discountType ? filterPackagePayload?.discountType : ""}
                onChange={(e) => handleFilterOnChange(e, "discountType")}
              ></RACSelect>
            </Grid>
        
          </Grid>
          <Grid container>
            <Grid
              md={12} style={{ float: "right" }} spacing={3}
            >
              
              <RACButton
                disabled={validateApplyButton()}
                variant="contained"
                color="primary"
                onClick={() => applyFilter("A")}
                size="small"
                    style={{
                      float: "right",
                      cursor: "pointer",
                      marginLeft: "8px",
                    }}
              >
                Apply
              </RACButton>
              <RACButton
                disabled={validateApplyButton()}
                variant="text"
                style={{backgroundColor: "white", float: "right", color: "blue", marginBottom: "10px"}}
                onClick={() => handleClearClick()}
              >
                Clear
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
              </Grid>
              
              ) : null}
        
        </>
    )
}

// last return
    return(
        <>
        {!isPackageFormOpen ? filterCard() : null}
        </>
    )
    
}




