/* eslint-disable */
import {
    RACSelect,
    Typography,
    RACButton,
    Grid,
    CardContent,
    Card,
    RACCheckBox,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACTextbox,
    RACModalCard,
    RACDatePicker,
    CircularProgress,
    List,
    ListItem,
    RACRadio,
  } from "@rentacenter/racstrap";
  import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as DownArrow } from "../../../assets/images/down-arrow-filter.svg";
import { ReactComponent as SortdownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../../assets/images/sort-icon-up.svg";
import { ReactComponent as SomethingWrong } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";
import { ReactComponent as Danger } from "../../../assets/images/danger.svg";
import { ReactComponent as NorecordsIcon } from '../../../assets/images/No-records.svg';
import { ReactComponent as EditIcon } from "../../../assets/images/Edit Icon.svg";
import { ReactComponent as DeleteDanger } from "../../../assets/images/delete-danger.svg"; 
import { ReactComponent as AddRow } from "../../../assets/images/action-add-row.svg"; 
import { ReactComponent as EditCheck } from "../../../assets/images/edit-update-check.svg"; 
import { ReactComponent as EditCross } from "../../../assets/images/edit-notupdate-cross.svg"; 
import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up-icon.svg";
import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down-icon.svg";
import {packageContext} from "./PackageContext";
import {globalStyles} from "../Styles/globalstyles";
import {ProgressBar} from "../Styles/ProgressStyles";
import ReactPaginate from "react-paginate";
import {PricingReportStyle} from "../Styles/PricingReport"
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { handleInputChange } from "react-select/src/utils";
import { fetchDropDownAPI, insertZoneValue, packageDetails} from "../../../api/user";
import updatePricingQueueStatusAPI from "../../../api/user";
import TwoFactorAuthentication from "../../shared/TwoStepAuthentication";

export const PackageGrid = () => {
    const {loader, setLoader, enableGrid, setEnableGrid, pginitial,
        setpginitial, tableRecord, setTableRecord, pageCount, setPageCount,criteriaFirstRecord, setCriteriaFirstRecord, criteriaFirstRecordObj,
        totRecord, setTotRecord, noRecordsFound, setNoRecordsFound, filterPopup, setFilterPopup,
        errorMsgPackage, setErrorMsgPackage, gridErrorPopup, setGridErrorPopup, applyFilter, filterPackagePayload, setFilterPackagePayload, 
       zoneDd, setZoneDd, bracketDd, setBracketDd, departmentDd, setDepartmentDd, subDepartmentDd, setSubDepartmentDd,
        packageNameDd, setPackageNameDd, tableHeader, setTableHeader, sortDirection, updatePackageData, setUpdatePackageData, updatePackagePopup, setUpdatePackagePopup,
        manageNewPackagePayload, setManageNewPackagePayload,
        manageUpdatePackagePayload, setManageUpdatePackagePayload, 
        formRecords, setFormRecords, 
        backupRecords, setBackupRecords, 
        newPackagePopup, setNewPackagePopup, formatData, isPackageFormOpen, setIsPackageFormOpen, count, setCount,
        originalSubDeptArr, setOriginalSubDeptArr, originalBracketArr, setOriginalBracketArr, zoneSelected, setZoneSelected,
        zoneSelectedForm, setZoneSelectedForm, discountType, discountTypeNew, companyCode, setCompanyCode, submittedBy, setSubmittedBy, duplicateDepartmentDropdown, setDuplicateDepartmentDropdown, 
        duplicateSubdepartmentDropdown, setDuplicateSubdepartmentDropdown, setSubmitPopup, submitPopup, pricingQueueId, setPricingQueueId, 
        setRmsAffected, emailId, setEmailId, employeeId, setEmployeeId, isApprover, setIsApprover, authOpen, setAuthOpen, isSLACompleted, setIsSLACompleted, isSLACancel,setIsSLACancel,
        isApprovalOpen, setIsApprovalOpen, sucessPopup, setSuccessPopup, masterLoader, setMasterLoader, isSubmitButtonClicked, setIsSubmitButtonClicked, dropdownLoader, 
        submitButtonStatus, setSubmitButtonStatus, handleSubmitButtonValidation, handleCancelClick, ApprovedBy, setApprovedBy, selectQueueType, setSelectQueueType, editRecordsArray, setEditRecordsArray, companyCodeZone, setCompanyCodeZone, franchisee, setFranchisee, startDateCheck, setStartDateCheck} = useContext(packageContext)

        const [wantToDelete, setWantToDelete] = useState({
          isOpen: false,
          index: undefined,
        });
        //For inserting the values in the first row 


        const classes = globalStyles();
        const Classes = globalStyles();
        const progressclasses = ProgressBar();
        const pricing = PricingReportStyle();
        
        const names = [
            { value: "0", label: "select" },
            { value: "9899", label: "9999" },
            { value: "1188", label: "1144" },
            { value: "64666", label: "64666" },
            { value: "85687", label: "85687" },
            { value: "Zone1", label: "85687" },
            { value: "Percentage", label: "percentage" }
          ];

            //grid head
        const bindGridHead = () => {
            return (
              <>
                {tableHeader.map((value: any, index: any) => {
                  return value.columnTitle == "Action" ? (
                    <RACTableCell className={`${classes.textCenter}`}>
                      Action
                    </RACTableCell>
                  ) : (
                    <RACTableCell key={index} >
                      {value.IsSortable ? (
                        <a
                          style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          id={value.columnId}
                          onClick={() =>
                            sortArray(value.columnId, index, tableHeader, tableRecord)
                          }
                        >
                          {value.columnTitle + "  "}
                          {value.IsSorted === true ? (
                            value.sortOrder == sortDirection.DESC ? (
                              <SortupIcon className={classes.ml0} />
                            ) : (
                              <SortdownIcon className={classes.ml0} />
                            )
                          ) : null}
                        </a>
                      ) : (
                        <a
                          style={{
                            color: "black",
                            textDecoration: "none",
                            textAlign: value.columnTitle == "Package Name" ? "center" : "center"//////////////////////////////
                          }}
                          id={value.columnId}
                        >
                          { value.columnTitle + "  "}
                        </a>
                      )}
                    </RACTableCell>
                  );
                })}
              </>
            );
          };
    //Grid body
    const buildTableContent = () => {
        return (
          <>{loader === true ? (
            <Grid style={{position: 'relative',
    top: '50%',
    transform: 'translate(500%, 100%)',
    left: '50%'}}>
           <Grid className={pricing.textSpaceNorecords}>
             <Grid className={pricing.textcenterImage}>
             <CircularProgress />
             </Grid>
           </Grid>
         </Grid>) : (<>
            {tableRecord?.map((value:any,index:any) => {
              let sDate = formatDate(value.startDate)
              let eDate = formatDate(value.endDate)
              return (
                <>
                  <RACTableRow
                    key={index}
                    className={classes.bgTransparent}
                  >
                    <RACTableCell  style={{ fontSize: "0.85rem" }}>
                      {value.zoneNumber}
                    </RACTableCell>
                    <RACTableCell  style={{ fontSize: "0.85rem"}} title={value?.packageName?.length > 35 ? value?.packageName : ''}>
                  {value?.packageName?.length > 35 ? value?.packageName?.slice(0,35) + '...' : value?.packageName}
                  </RACTableCell>
                    <RACTableCell  style={{ fontSize: "0.85rem" }} >
                      {value.discountType == "P" ? "%": "$"}
                    </RACTableCell>
                    <RACTableCell style={{ fontSize: "0.85rem" }} >
                    {value.discountPercent ? value.discountPercent+"%" : "-"}
                    </RACTableCell>
                    <RACTableCell style={{ fontSize: "0.85rem", textAlign: "center" }}>
                      {value.discountAmount ? "$"+value.discountAmount : "-"}
                    </RACTableCell>
                    <RACTableCell style={{ fontSize: "0.85rem" }}>
                      {sDate}
                    </RACTableCell>
                    <RACTableCell style={{ fontSize: "0.85rem" }}>
                      {eDate}
                    </RACTableCell>
                    <RACTableCell className={`${classes.textCenter}`}>
                      <RACButton
                        onClick={() => {
                          editData(value);
                        }}
                      >
                        <EditIcon />
                      </RACButton>
                    </RACTableCell>
                  </RACTableRow>
                </>
              );
            })}
          </>)}
          </>
          
        );
      };
      //Record data
      const renderRecordData = () => {
        return (
          <>
            <Grid className={`${classes.mb0} ${classes.textRight}`} style={{marginTop: "32px"}}>
              <Grid className={`${progressclasses.totrec}`} >
              { !totRecord ? null : <Typography >
                  {/* # of Records: {pageCount - pginitial == 0 ? (totRecord) : pginitial *25  
                  /*  totRecord - (pginitial* 25) <  25 ? (pginitial *25) : totRecord - (pginitial* 25)  }/{totRecord}{" "} */}
                  # of Records:&nbsp;
                    {pginitial == 0
                      ? totRecord < 25 ? totRecord :25 * 1
                      : (pginitial+1) == pageCount
                      ? totRecord
                      : (pginitial + 1) * 25}
                    /{totRecord}
                </Typography>}
              </Grid>
              {totRecord ?<ReactPaginate
                nextLabel=">"
                onPageChange={(num: any) => {
                  handlePageClick(num)
                }
                }
                  initialPage= {pginitial}
                pageCount={pageCount} // pageCount needs to be set here.
                pageRangeDisplayed={2}
                marginPagesDisplayed={0}
                previousLabel="<"
                pageClassName={progressclasses.pagination}
                pageLinkClassName={progressclasses.pagelink}
                previousLinkClassName={
                  pginitial === 0
                    ? progressclasses.paginationprevnextdisable
                    : progressclasses.paginationprevnext
                }
                disableInitialCallback={true}
                nextLinkClassName={
                   pginitial === pageCount - 1
                    ? progressclasses.paginationprevnextdisable
                    : progressclasses.paginationprevnext
                }
                breakLabel=""
                breakClassName={progressclasses.pagination}
                breakLinkClassName={progressclasses.pagination}
                containerClassName={progressclasses.pagination}
                activeClassName={progressclasses.pageactive}
                forcePage={pginitial === 0 ? 0 : undefined}
              /> : null}
            </Grid>
          </>
        )
      }
      //render no records found
      const rendernorecordsheader = () => {
        return (
          <React.Fragment>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Zone</RACTableCell>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Package Name</RACTableCell>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Discount Type</RACTableCell>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Discount Percent</RACTableCell>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Discount Amount</RACTableCell>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Start Date</RACTableCell>
            <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>End Date</RACTableCell>
            <RACTableCell className={`${classes.p2} ${classes.pt1} ${classes.pb1} ${classes.textCenter}`}> Action</RACTableCell>
            </React.Fragment>
        )
      }
//handle page click function
const handlePageClick = (event: any) => {
    
    setpginitial(event.selected );
    console.log("event.selected", event.selected)
    scrollTop;
    console.log(event.selected + 1,"Tje value dfawfnol");
    console.log("page initial checking if the state is setted", pginitial)
    filterPackagePayload.offset = (event.selected + 1).toString();
    console.log("event", event)
    console.log("page initial", pginitial);
    applyFilter()
  };

  //sort functionality
  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any
  ) => {
    try {
      const sortedData =
        datacolumn[index].sortOrder == sortDirection.DESC
          ? dataValues.sort((a: any, b: any) =>
              b[columnId] < a[columnId] ? -1 : 1
            )
          : dataValues.sort((a: any, b: any) =>
              a[columnId] < b[columnId] ? -1 : 1
            );
      const reorderColumns = datacolumn;
      reorderColumns[index].sortOrder =
        reorderColumns[index].sortOrder == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : true;
      });
      setTableRecord([...sortedData]);
      setTableHeader([...reorderColumns]);
    } catch (error) {
      console.log(error);
    }
  };
  //scroll top functionality
  const scrollTop = (ref: any) => {
    if (ref) {
      const element = ref;
      console.log("elementsss", element);
      const clientWidth = element.getBoundingClientRect().height;
      console.log("clientWidth refAssignCallback", clientWidth);
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };
  let pricingQueueObj = {
    pricingQueueId: pricingQueueId,
    status: "A",
    actionBy: ApprovedBy
  }

  useEffect(() => {
  
    if (isSLACancel== false && isSLACompleted == true){
      console.log('The price queue Completed');
      updatePackageFun();
  

      // updatePricingQueueStatus(alterPopUp?.value);} approved popup
    }
  }, [isSLACancel, isSLACompleted]);

  const updatePackageFun = async () => {
    setMasterLoader(true);
    
    console.log("pricingQueueObj.actionBy", pricingQueueObj.actionBy)
    console.log("pricingQueueId", pricingQueueId)
    let responseFromUpdatePricing = await updatePricingQueueStatusAPI(pricingQueueObj);
    if(responseFromUpdatePricing.status == 200){
      setMasterLoader(false);
      setAuthOpen(false);
      setSuccessPopup(true)
      /* newStoreZoneClear(); */
      /* await applyFilter("A"); */
      console.log("response for the if", responseFromUpdatePricing)
    }else if(responseFromUpdatePricing.status == 400){
      console.log("responseFromUpdatePricing", responseFromUpdatePricing)
      setErrorMsgPackage(responseFromUpdatePricing.data.errors[0].error);
    setGridErrorPopup(true);
    }else{
      setErrorMsgPackage("Something Went Wrong");
    setGridErrorPopup(true);
    }
    setMasterLoader(false);
  }

  
  //Submit click functionality
  
  
  
    /* {isSubmitButtonClicked == true? submitClick() : null} */ 


  // format date function
  function formatDate(date: any) { 
    console.log("date", date)
    var d = new Date(date), 
  month = '' + (d.getUTCMonth() + 1), 
  day = '' + d.getUTCDate(),
  year = d.getUTCFullYear()
  if (month.length < 2) {
  month = '0' + month;
  } 
  if (day.length < 2) {
  day = '0' + day; 
  }
  return [month, day, year].join('/');
  }
//handleUpdate function
function handleUpdateOnChangePackageDetails(e: any, key: any) {
  

    setManageUpdatePackagePayload({...manageUpdatePackagePayload, queueData: {...manageUpdatePackagePayload.queueData, 
        packageDetails: {...manageUpdatePackagePayload.queueData.packageDetails, 
            [key]: e.target.value}}})
   /*  if (key == "startDate") {
      setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, [key]: e}} });
      console.log("updtPopupData.startDate", updtPopupData.startDate);
      console.log("UpdtZoneObj.queueData.zoneStore.startDate", UpdtZoneObj.queueData.zoneStore.startDate);
    }
    if(key == "zoneNumber"){
      const filteredItems:any = zoneDropDown.filter((item: any) => item.zoneNumber === e.target.value)
      console.log("filteredItems", filteredItems[0].zoneName);
      zoneNameFun(filteredItems[0].zoneName, e.target.value);  
      setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, [key]: e.target.value}} });
    }
    console.log("updtPopupData", updtPopupData)
    setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, [key]: e.target.value}} }); */
    }
  console.log("UpdtZoneObj console", manageUpdatePackagePayload);

  //edit data
  function editData(rowData: any) {
    
    let formatFormData =JSON.parse(JSON.stringify(rowData));
    console.log("formatFormData this is for finding the object", formatFormData)
    formatFormData['packageName'] =formatFormData.packageName,
    formatFormData['startDate'] = formatFormData.startDate,
    formatFormData['endDate'] = formatFormData.endDate,
    formatFormData['zone']= [formatFormData.zoneNumber]
    formatFormData['discountType']= formatFormData.discountType
    formatFormData['discountPct']= formatFormData.discountType == "P" ? formatFormData.discountPercent : undefined
    formatFormData['discountAmt']= formatFormData.discountType == "D" ? formatFormData.discountAmount : undefined    
    let dupZoneInfo = JSON.parse(JSON.stringify(rowData))
    setStartDateCheck(dupZoneInfo.startDate)
    formatFormData['criteria'].duplicateDepartmentDd =  formatFormData.criteria.map((item: any, index: any) => {
      return(
        item.duplicateDepartmentDd =  JSON.parse(JSON.stringify(departmentDd))    
        )                  
    })
    formatFormData['criteria'].duplicateSubdepartmentDd =  formatFormData.criteria.map((item: any, index: any) => {
      let arrSub = subDepartmentDd.filter((it: any)=>{
        return it.deptId  === item.rmsDepartmentNumber
      })
      arrSub.unshift({
        label:'Select',
        value:'0'
      })
  
      return(
        item.duplicateSubdepartmentDd =  JSON.parse(JSON.stringify(arrSub.length ? arrSub : subDepartmentDd))    
        )                  
    })
    formatFormData['criteria'].duplicateBracketDd =  formatFormData.criteria.map((item: any, index: any) => {
      let arrBracket = bracketDd.filter((it: any)=>{
        return it.subdeptId  === item.rmsSubdepartmentNumber
      })
      arrBracket.unshift({
        label:'Select',
        value:'0'
      })
      return(
        item.duplicateBracketDd =  JSON.parse(JSON.stringify(arrBracket.length ? arrBracket : bracketDd))    
        )                  
    })
  
    criteriaFirstRecordObj['duplicateSubdepartmentDd'] = subDepartmentDd
    criteriaFirstRecordObj['duplicateDepartmentDd'] =  departmentDd
    criteriaFirstRecordObj['duplicateBracketDd'] =  bracketDd
    console.log("ghcgvjbkl;", criteriaFirstRecordObj);
    
    setCriteriaFirstRecord(criteriaFirstRecordObj);
    setFormRecords(JSON.parse(JSON.stringify(formatFormData)));
    setBackupRecords(JSON.parse(JSON.stringify(formatFormData)));
    setNewPackagePopup(false);// should be true if the new package button is clicked but it is false since update icon is clicked
    setIsPackageFormOpen(true);// need to change this to setIsPackageFormOpen
  }

  function handleOnChangeCriteriaFirstRecord(e: any, key: any) {
  
    let criteriaOnFirst = {...criteriaFirstRecord}
    debugger;
    if(key == "minQuantity" || key == "maxQuantity" || key == "rmsItemNumber"){
      e.target.value = e.target.value.replace(/\D/g, "");
    }
    criteriaOnFirst[key] = e.target.value;
    if(!newPackagePopup){
      if(key == "rmsDepartmentNumber"){
        criteriaOnFirst["departmentDesc"] = (criteriaFirstRecord?.duplicateDepartmentDd?.find((item: any) => item.value == e.target.value)).label
      }
      else if(key == "rmsSubdepartmentNumber"){
        criteriaOnFirst["subdepartmentDesc"] = (criteriaFirstRecord?.duplicateSubdepartmentDd?.find((item: any) => item.value == e.target.value)).label
      }else if(key == "bracketNumber"){
        criteriaOnFirst["bracketName"] = (criteriaFirstRecord?.duplicateBracketDd?.find((item: any) => item.value == e.target.value)).label
      }
      
    }
    
    setCriteriaFirstRecord(criteriaOnFirst)

  }

  
  const handleUpdateOnChange = (value: any, key: any) => {
    debugger;
/*     const validatedValue = validateInput(key, value); */
if (key == "discountPct") {
  value = value.replace(
    /[^0-9.]|[0-9]+\.[0-9]+\.[0-9]+|(\.\d{2})\d+?$/g,
    ""
  );
}
    setFormRecords({
      ...formRecords,
      [key]: value,
    });
  };
  console.log("formatFormData", formRecords);
  //validating the row if all the fields were filled with any values.
  const addRowValidation = () => {
    if(criteriaFirstRecord.rmsDepartmentNumber != "0" && criteriaFirstRecord.minQuantity != "" && criteriaFirstRecord.maxQuantity != ""){
      handleAddRow()
    } 
  } 

  const saveRowValidation = (index1: any) => {
    let arr = JSON.parse(JSON.stringify(formRecords.criteria));
    if(arr[index1].rmsDepartmentNumber != "0" && arr[index1].minQuantity != "" && arr[index1].maxQuantity != ""){
      handleSaveRow(index1)
    } 
  }
// Function to handle adding a new row
const handleAddRow = () => {
    debugger
    
    setCount(1)
    if( newPackagePopup && count == 0){
      let arr: any =[];
      let temp =JSON.parse(JSON.stringify(criteriaFirstRecord));
      temp.rmsSubdepartmentNumber== '0' ?  temp.subdepartmentDesc = '' : null
      temp.bracketNumber== '0' ?  temp.bracketName = '' : null
    arr.unshift(temp);
    console.log("arr for printing the existing array", arr)
    setFormRecords({ ...formRecords, ["criteria"]: arr });
    setBackupRecords({ ...formRecords, ["criteria"]: arr });
    criteriaFirstRecordObj['duplicateSubdepartmentDd'] = subDepartmentDd
    criteriaFirstRecordObj['duplicateDepartmentDd'] =  departmentDd
    criteriaFirstRecordObj['duplicateBracketDd'] =  bracketDd
    setCriteriaFirstRecord(criteriaFirstRecordObj);
    }
    else{
      let arr = JSON.parse(JSON.stringify(formRecords.criteria)) 
      let temp =JSON.parse(JSON.stringify(criteriaFirstRecord));
      temp.rmsSubdepartmentNumber== '0' ?  temp.subdepartmentDesc = '' : null
      temp.bracketNumber== '0' ?  temp.bracketName = '' : null
    arr.unshift(temp);
    console.log("arr for printing the existing array", arr)
    setFormRecords({ ...formRecords, ["criteria"]:  arr});
    setBackupRecords({ ...formRecords, ["criteria"]: arr });
    criteriaFirstRecordObj['duplicateSubdepartmentDd'] = subDepartmentDd
    criteriaFirstRecordObj['duplicateDepartmentDd'] =  departmentDd
    criteriaFirstRecordObj['duplicateBracketDd'] =  bracketDd
    setCriteriaFirstRecord(criteriaFirstRecordObj);
    }
    console.log("count after", count)
    /* setBackupRecords({ ...formRecords, ["range"]: arr }); */
    /* let editArr = editRecordsArray;
    console.log("editArr of the matrix", editArr)
    editArr.forEach((item: any, index: any) => {
      console.log(item, "edit the issue");
      editArr[index] = editArr[index] + 1;
    });
    console.log(editArr, "edit the for editArr issue");
    editArr.push(0);
    console.log(editArr, "edit2 the for editArr issue");
    setEditRecordsArray([...editArr]); */
  };
{
  console.log("manageUpdatePackagePayload dfjsie", manageUpdatePackagePayload);
  
}
  // Function to handle editing a row(using an array to add the index of the clicked icon so if the user is clicking 1, 2, 3 records then all the records index will be stored in that)
  const handleEditRow = (rowIndex: any) => {
    debugger;
    console.log(editRecordsArray, "the records to edit");
  
      setEditRecordsArray([...editRecordsArray, rowIndex]);
  } 
//Function to save the data in the criteria
const handleSaveRow = (rowIndex: any) => {
  debugger;
  const updatedRow =JSON.parse(JSON.stringify(formRecords["criteria"][rowIndex])) ;
  setBackupRecords(() => {
    const newBackupRecords = JSON.parse(JSON.stringify(backupRecords));
    newBackupRecords["criteria"][rowIndex] = updatedRow;
    return newBackupRecords;
  });
  setEditRecordsArray(() =>
    editRecordsArray.filter((index: any) => index !== rowIndex)
  );
};

//function to handle the criteria onChange
const handleCriteriaOnChange = (
  key: string,
  currentEvent: any,
  rangeObject: any,
  index: number,
  /* desc :string ='' */ 
) => {
  debugger;
  const updatedFormRecords = { ...formRecords };

  // Create a copy of the criteria array
  const updatedRangeArray = [...updatedFormRecords.criteria];
console.log("updatedRangeArray", updatedRangeArray)
  // Assuming you have the updated values
  const updatedValues: any = rangeObject;
  console.log((updatedRangeArray[index]?.duplicateSubdepartmentDd?.find((item: any) => item.value == currentEvent)),"updatedValues", updatedValues)
  /* if (key == "minQuantity") {
    currentEvent = currentEvent.replace(
      /[^0-9.]|[0-9]+\.[0-9]+\.[0-9]+|(\.\d{2})\d+?$/g,
      ""
    );
  } */ 
  if (
    key == "minQuantity" ||
    key == "maxQuantity" || key ==  "rmsItemNumber"
  ) {
    currentEvent = currentEvent.replace(/\D/g, "");
  }

  // Update the object at the specified index with the new values
  updatedValues[key] = currentEvent;
  if(key == "rmsDepartmentNumber"){
    updatedValues["departmentDesc"] = (updatedRangeArray[index]?.duplicateDepartmentDd?.find((item: any) => item.value == currentEvent))?.label
    updatedValues["rmsSubdepartmentNumber"] = "0"
  updatedValues["bracketNumber"] = "0"
  updatedValues['subdepartmentDesc']=''
  updatedValues['bracketName']=''
  }
  else if(key == "rmsSubdepartmentNumber"){
    updatedValues["subdepartmentDesc"] = (updatedRangeArray[index]?.duplicateSubdepartmentDd?.find((item: any) => item.value == currentEvent))?.label
    updatedValues['bracketName']=''
    updatedValues["bracketNumber"] = "0"
  currentEvent == '0'?  updatedValues['subdepartmentDesc'] = '' : null
  }else if(key == "bracketNumber"){
    updatedValues["bracketName"] = (updatedRangeArray[index]?.duplicateBracketDd?.find((item: any) => item.value == currentEvent))?.label
    currentEvent == '0'?  updatedValues['bracketName'] = '' : null
  }
  

  // Update the formRecords with the updated range array
  updatedRangeArray[index] = updatedValues;
  updatedFormRecords.criteria = updatedRangeArray;
  console.log(updatedFormRecords, "The updated form records", formRecords);
  setFormRecords({ ...updatedFormRecords });
  handleSubmitButtonValidation()
};

//function to handle cancelling the editing row
const handleCancelEdit = (rowIndex: any) => {
  debugger;
  const originalRow = JSON.parse(JSON.stringify(backupRecords["criteria"][rowIndex]));
  console.log("originalRow", originalRow)
  setFormRecords(() => {
    const newFormRecords = formRecords;
    newFormRecords["criteria"][rowIndex] = originalRow;
    return newFormRecords;
  });

  setEditRecordsArray(() => editRecordsArray.filter((index: any) => index !== rowIndex));
};
//function to handle delete row 
const handleDeleteRow = (rowIndex: any) => {
  setFormRecords({
    ...formRecords,
    ["criteria"]: formRecords.criteria.filter(
      (_: any, index: any) => index !== rowIndex
    ),
  });
  setBackupRecords({
    ...backupRecords,
    criteria: backupRecords?.criteria?.filter(
      (_: any, index: any) => index !== rowIndex
    ),
  });
  let editArr = editRecordsArray;
  editArr.forEach((item: any, index: any) => {
    console.log(item, "edit the issue");

    editArr[index] = item < rowIndex ? editArr[index] : editArr[index] - 1;
  });
  setEditRecordsArray([...editArr]);
  setWantToDelete({
    isOpen: false,
    index: undefined,
  });
};


//Delete Popup
const deletePopup = () => {
  return (
    <>
      <Grid>
        <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
        <Danger/>
        </Grid>
        <Grid>
          <Typography
            variant="h5"
            style={{ textAlign: "center", marginBottom: "2%" }}
          >
            Are you sure you want to delete?
          </Typography>
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "center", margin: "5%" }}
        >
          <RACButton
            style={{ marginRight: "10%" }}
            variant="outlined"
            onClick={() => setWantToDelete({
              isOpen: false,
              index: undefined,
            })}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              // On getting something went wrong to pricing page refreshed to the initial page
              handleDeleteRow(wantToDelete.index); setMasterLoader(false)
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
};

 
  /* console.log("updtPopupData", updtPopupData); */
   // this is for updating the storezone.
   {console.log("formRecords?.pacakgeCriteri", formRecords?.pacakgeCriteri);
   }
   //for rendering the head of the update popup
   const CriteriaHead = () => (
    <>
      <RACTableCell>
        Department<span style={{color: "red"}}>*</span>{" "}
      </RACTableCell>
      <RACTableCell>
        Sub Department{" "}
      </RACTableCell>
      <RACTableCell>
        Bracket{" "}
      </RACTableCell>
      <RACTableCell>
        RMS #{" "}
      </RACTableCell>
      <RACTableCell>Min Quantity<span style={{color: "red"}}>*</span></RACTableCell>
      <RACTableCell>Max Quantity<span style={{color: "red"}}>*</span></RACTableCell>
      <RACTableCell className={`${Classes.textCenter}`}>Actions</RACTableCell>
    </>
  );
  //for rendering the body of the update popup\
  
  const CriteriaContent = () => (
    <React.Fragment>
        <RACTableRow style={{ backgroundColor: "transparent" }}>
        <RACTableCell>
        <RACSelect
               options={criteriaFirstRecord.duplicateDepartmentDd}
               defaultValue={criteriaFirstRecord.rmsDepartmentNumber != "0" ? criteriaFirstRecord.rmsDepartmentNumber: "0"}
               loading= {dropdownLoader}
               onChange={(e) => {handleOnChangeCriteriaFirstRecord(e, "rmsDepartmentNumber"); 
               let departmentDesc = (criteriaFirstRecord?.duplicateDepartmentDd?.find((item: any) => item.value == e.target.value)).label
               let restrictSubDept = originalSubDeptArr.filter(
                (item: any) => {
                  console.log("item for the department", item)
                  if (item.deptId == e.target.value) {
                    return item;
                  }
                }
              );
              restrictSubDept.unshift({
                label: "Select",
                value: "0",
                deptId: "",
              });
              /* if(e.target.value !='0') */
              setCriteriaFirstRecord({...criteriaFirstRecord,['duplicateSubdepartmentDd']:restrictSubDept,'rmsDepartmentNumber':e.target.value, "rmsSubdepartmentNumber": "0", "departmentDesc":departmentDesc, "bracketNumber": "0"})
            /* else 
            setCriteriaFirstRecord({...criteriaFirstRecord,['duplicateSubdepartmentDd']:originalSubDeptArr,'rmsDepartmentNumber':e.target.value,"departmentDesc":departmentDesc})
 */
            /* setSubDepartmentDd(restrictSubDept) */}}
             ></RACSelect>
        </RACTableCell>
        <RACTableCell>
        <RACSelect
        // inputLabel=""
        isDisabled={criteriaFirstRecord.rmsDepartmentNumber != "0" ? false : true}
        options={criteriaFirstRecord.duplicateSubdepartmentDd}
        defaultValue={criteriaFirstRecord.rmsSubdepartmentNumber != "0" ? criteriaFirstRecord.rmsSubdepartmentNumber: "0"}
        loading= {dropdownLoader}
         onChange={(e) => {handleOnChangeCriteriaFirstRecord(e, "rmsSubdepartmentNumber");
         let subdepartmentDesc = (criteriaFirstRecord?.duplicateSubdepartmentDd?.find((item: any) => item.value == e.target.value)).label
         console.log("originalSubDeptArr array", originalSubDeptArr)
         let restrictBracket = originalBracketArr.filter(
         (item: any) => {
           console.log("item for the department", item)
           if (item.subdeptId == e.target.value) {
             return item;
           }
         }
       );
       restrictBracket.unshift({
         label: "Select",
         value: "0",
         subdeptId: "",
       });
       setCriteriaFirstRecord({...criteriaFirstRecord,['duplicateBracketDd']:restrictBracket, "rmsSubdepartmentNumber": e.target.value, "bracketNumber": "0", "subdepartmentDesc":subdepartmentDesc})
       /* if(e.target.value !='0') */
       /* setBracketDd(restrictBracket) */;
     /* else 
     setBracketDd(originalBracketArr) */}}></RACSelect>
        </RACTableCell>
        <RACTableCell>
        <RACSelect
        // inputLabel=""
        isDisabled={criteriaFirstRecord.rmsSubdepartmentNumber != "0" ? false : true}
        options={criteriaFirstRecord.duplicateBracketDd}
        defaultValue={criteriaFirstRecord.bracketNumber != "0" ? criteriaFirstRecord.bracketNumber: "0"}
        loading= {dropdownLoader}
         onChange={(e) => handleOnChangeCriteriaFirstRecord(e, "bracketNumber")}></RACSelect>
        </RACTableCell>
        <RACTableCell>
        <RACTextbox value={criteriaFirstRecord.rmsItemNumber?criteriaFirstRecord.rmsItemNumber : '' } OnChange={(e) => handleOnChangeCriteriaFirstRecord(e, "rmsItemNumber")}/>
        </RACTableCell>
        <RACTableCell>
        <RACTextbox 
      value={criteriaFirstRecord.minQuantity}
      OnChange={(e) => handleOnChangeCriteriaFirstRecord(e, "minQuantity")}
       />
        </RACTableCell>
        <RACTableCell>
        <RACTextbox value={criteriaFirstRecord.maxQuantity} OnChange={(e) => handleOnChangeCriteriaFirstRecord(e, "maxQuantity")}/>
        </RACTableCell>
        <RACTableCell className={`${Classes.textCenter}`}>
          <AddRow
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={() => {
            addRowValidation() 
          }}
          ></AddRow>
        </RACTableCell>
      </RACTableRow>
      { formRecords?.criteria?.map((value: any, index1: any) => {
        return(
           <>
           {newPackagePopup ? count == 1  ?(<>
                  <RACTableRow style={{ backgroundColor: "transparent" }}>
                  <RACTableCell >
    <RACSelect
        options={value.duplicateDepartmentDd}
        defaultValue={value.rmsDepartmentNumber? value.rmsDepartmentNumber: "0"}
        loading= {dropdownLoader}
       onChange={(e) => {
        debugger
        console.log(e.target,"Tnklrbgtjgvtbgkjvrkjvgu");
        
        handleCriteriaOnChange(
          "rmsDepartmentNumber",
          e.target.value,
          value
            ? value
            : {
              rmsDepartmentNumber: "",
              rmsSubdepartmentNumber: "",
              minQuantity: "",
              bracketNumber: "", 
              maxQuantity: "", 
              rmsItemNumber: null},
          index1, 
        
        );
        /* setFormRecords('departemtdesc',) */
        
        console.log("originalSubDeptArr array", originalSubDeptArr)
        let restrictSubDept = originalSubDeptArr.filter(
        (item: any) => {
          console.log("item for the department", item)
          if (item.deptId == e.target.value) {
            return item;
          }
        }
      );
      restrictSubDept.unshift({
        label: "Select",
        value: "0",
        deptId: "",
      });
      /* if(e.target.value !='0') */
      value.duplicateSubdepartmentDd = restrictSubDept
    /* else 
    setSubDepartmentDd(originalSubDeptArr); */ 
    }}
      ></RACSelect>
    </RACTableCell>
    <RACTableCell>
    <RACSelect
        // inputLabel=""
        isDisabled={value.rmsDepartmentNumber != "0" ? false : true}
        options={value.duplicateSubdepartmentDd}
         defaultValue={value.rmsSubdepartmentNumber ? value.rmsSubdepartmentNumber : "0"}
        onChange={(e) => {
         handleCriteriaOnChange(
           "rmsSubdepartmentNumber",
           e.target.value,
           value
             ? value
             : {
               rmsDepartmentNumber: "",
               rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1, 
         );

         console.log("originalSubDeptArr array", originalSubDeptArr)
        let restrictBracket = originalBracketArr.filter(
        (item: any) => {
          console.log("item for the department", item)
          if (item.subdeptId == e.target.value) {
            return item;
          }
        }
      );
      restrictBracket.unshift({
        label: "Select",
        value: "0",
        subdeptId: "",
      });
      /* if(e.target.value !='0') */
      value.duplicateBracketDd = restrictBracket;
    /* else 
    setBracketDd(originalBracketArr); */
       }}

      ></RACSelect>
   
    </RACTableCell>
    <RACTableCell>
    <RACSelect
        // inputLabel=""
        isDisabled={value.rmsSubdepartmentNumber != "0" ? false : true}
        options={value.duplicateBracketDd}
         defaultValue={value.bracketNumber ? value.bracketNumber : "0"}
        onChange={(e) => {
         handleCriteriaOnChange(
           "bracketNumber",
           e.target.value,
           value
             ? value
             : {
               rmsDepartmentNumber: "",
               rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1
         );
       }}
      ></RACSelect>
    </RACTableCell>
    <RACTableCell>
      <RACTextbox value={value?.rmsItemNumber ? value?.rmsItemNumber: ""} OnChange={(e) => {
         handleCriteriaOnChange(
           "rmsItemNumber",
           e.target.value,
           value
             ? value
             : {
               rmsDepartmentNumber: "",
               rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1
         );
       }}/>
    </RACTableCell>
    <RACTableCell>
      <RACTextbox 
      value={value.minQuantity}
      OnChange={(e) => {
         handleCriteriaOnChange(
           "minQuantity",
           e.target.value,
           value
             ? value
             : {
               rmsDepartmentNumber: "",
               rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null
               },
           index1
         );
       }}  />
    </RACTableCell>
    <RACTableCell>
      <RACTextbox value={value.maxQuantity} OnChange={(e) => {
         handleCriteriaOnChange(
           "maxQuantity",
           e.target.value,
           value
             ? value
             : {
               rmsDepartmentNumber: "",
               rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1
         );
       }}/>
    </RACTableCell>
    <RACTableCell
                      className={`${Classes.textCenter}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <DeleteDanger
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // handleDeleteRow(index);
                          setWantToDelete({
                            isOpen: true,
                            index: index1,
                          });
                        }}
                      ></DeleteDanger>
                    </RACTableCell>
                  </RACTableRow>
                </>) : (null): (<>  <RACTableRow style={{ backgroundColor: "transparent" }}>
           {editRecordsArray?.includes(index1) ? (
      <>
      <RACTableCell >
    <RACSelect
        options={value.duplicateDepartmentDd}
        defaultValue={value.rmsDepartmentNumber ? value.rmsDepartmentNumber : "0"}
        onChange={(e) => {
          handleCriteriaOnChange(
            "rmsDepartmentNumber",
            e.target.value,
            value
              ? value
              : {
                rmsDepartmentNumber: "",
                rmsSubdepartmentNumber: "",
                minQuantity: "",
                bracketNumber: "", 
                maxQuantity: "", 
                rmsItemNumber: null},
            index1,
          );
          /* debugger
          let updateRec = {...formRecords};
          let updateArr = updateRec.criteria
          updateArr[index1]['rmsDepartmentNumber'] = e.target.value
          updateArr[index1]['departmentDesc'] = (value.duplicateDepartmentDd.find((item: any) => item.value == e.target.value)).label
          updateRec.criteria = updateArr;
          setFormRecords({...updateRec}) */
           console.log("originalSubDeptArr array", originalSubDeptArr)
          let restrictSubDept = originalSubDeptArr.filter(
          (item: any, index2: any) => {
            console.log("item for the department criteria", item)
            if (item.deptId == e.target.value) {
              return item;
            }
          }
        );
        restrictSubDept.unshift({
          label: "Select",
          value: "0",
          deptId: "",
        });
        /* if(e.target.value !='0') */
       value.duplicateSubdepartmentDd = restrictSubDept
      /* else 
      setSubDepartmentDd(originalSubDeptArr); */ 
      }}
        
      ></RACSelect>
    </RACTableCell>
    <RACTableCell>
    <RACSelect
        // inputLabel=""
        isDisabled={(value.rmsDepartmentNumber != "0" && value.rmsDepartmentNumber != null)? false : true}
        options={value.duplicateSubdepartmentDd}
         defaultValue={value.rmsSubdepartmentNumber? value.rmsSubdepartmentNumber: "0"}
         onChange={(e) => {
          handleCriteriaOnChange(
            "rmsSubdepartmentNumber",
            e.target.value,
            value
              ? value
              : {
                rmsDepartmentNumber: "",
                rmsSubdepartmentNumber: "",
                minQuantity: "",
                bracketNumber: "", 
                maxQuantity: "", 
                rmsItemNumber: null},
            index1
          );
 
          console.log("originalSubDeptArr array", originalSubDeptArr)
         let restrictBracket = originalBracketArr.filter(
         (item: any) => {
           console.log("item for the department", item)
           if (item.subdeptId == e.target.value) {
             return item;
           }
         }
       );
       restrictBracket.unshift({
         label: "Select",
         value: "0",
         subdeptId: "",
       });
       /* if(e.target.value !='0') */
       value.duplicateBracketDd = restrictBracket
     /* else 
     setBracketDd(originalBracketArr); */
        }}
      ></RACSelect>
    </RACTableCell>
    <RACTableCell>
    <RACSelect
        // inputLabel=""
        isDisabled={(value.rmsSubdepartmentNumber != "0"  && value.rmsSubdepartmentNumber != null)? false : true}
        options={value.duplicateBracketDd}
         defaultValue={value.bracketNumber? value.bracketNumber: "0"}
        onChange={(e) => {
         handleCriteriaOnChange(
           "bracketNumber",
           e.target.value,
           value
             ? value
             : {
              rmsDepartmentNumber: "",
              rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1
         );
       }}
      ></RACSelect>
    </RACTableCell>
    <RACTableCell>
      <RACTextbox value={value?.rmsItemNumber ? value?.rmsItemNumber: "" } OnChange={(e) => {
         handleCriteriaOnChange(
           "rmsItemNumber",
           e.target.value,
           value
             ? value
             : {
              rmsDepartmentNumber: "",
              rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1
         );
       }}/>
    </RACTableCell>
    <RACTableCell>
      <RACTextbox 
      value={value.minQuantity}
      OnChange={(e) => {
         handleCriteriaOnChange(
           "minQuantity",
           e.target.value,
           value
             ? value
             : {
              rmsDepartmentNumber: "",
              rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null
               },
           index1
         );
       }}  />
    </RACTableCell>
    <RACTableCell>
      <RACTextbox value={value.maxQuantity} OnChange={(e) => {
         handleCriteriaOnChange(
           "maxQuantity",
           e.target.value,
           value
             ? value
             : {
              rmsDepartmentNumber: "",
              rmsSubdepartmentNumber: "",
               minQuantity: "",
               bracketNumber: "", 
               maxQuantity: "", 
               rmsItemNumber: null},
           index1
         );
       }}/>
    </RACTableCell>
    <RACTableCell
      className={`${Classes.textCenter}`}
      style={{ whiteSpace: "nowrap" }}
    >
      <EditCheck
        className={`${Classes.mr3}`}
        style={{ width: "20px", height: "20px", cursor: "pointer" }}
        onClick={() => {
          saveRowValidation(index1);
       }}
      ></EditCheck>
      <EditCross
        style={{ width: "16px", height: "16px", cursor: "pointer" }}
        onClick={() => {
         handleCancelEdit(index1);
       }}
      ></EditCross>
    </RACTableCell>
    </>   
      ) : (
             <>
        <RACTableCell title={value?.departmentDesc?.length > 13 ? value?.departmentDesc : ''}>
        <Typography
          variant="caption"
          className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
        >
          {value?.departmentDesc ? value?.departmentDesc?.length > 13 ? value?.departmentDesc?.slice(0,13) + '...' : value?.departmentDesc: "-"}
        </Typography>
      </RACTableCell>
      <RACTableCell title={value?.subdepartmentDesc?.length > 13 ? value?.subdepartmentDesc : ''}>
        <Typography
          variant="caption"
          className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey} ${Classes.textCenter}`}
        >
          {value?.subdepartmentDesc ? value?.subdepartmentDesc?.length > 13 ? value?.subdepartmentDesc?.slice(0,13) + '...' : value?.subdepartmentDesc: "-"}
        </Typography>
      </RACTableCell>
      <RACTableCell title={value?.bracketName?.length > 13 ? value?.bracketName : ''}>
        <Typography
          variant="caption"
          className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey} ${Classes.textCenter}`}
        >
          {value?.bracketName ? value?.bracketName?.length > 13 ? value?.bracketName?.slice(0,13) + '...' : value?.bracketName: "-"}
        </Typography>
      </RACTableCell>
        <RACTableCell>
          <Typography
            variant="caption"
            style={{textAlign: "center"}}
            className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey} ${Classes.textCenter}`}
          >
            {value.rmsItemNumber ? value.rmsItemNumber: "-"}
          </Typography>
        </RACTableCell>
        <RACTableCell>
          <Typography
            variant="caption"
            className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey} ${Classes.textCenter}`}
          >
            {value.minQuantity}
          </Typography>
        </RACTableCell>
        <RACTableCell>
          <Typography
            variant="caption"
            className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
          >
            {value.maxQuantity}
          </Typography>
        </RACTableCell>
        <RACTableCell
          className={`${Classes.textCenter}`}
          style={{ whiteSpace: "nowrap" }}
        >
            
          <EditIcon
            className={`${Classes.mr3}`}
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={() => {
            handleEditRow(index1);
            }}
          ></EditIcon>
          <DeleteDanger
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => {
              // handleDeleteRow(index);
              setWantToDelete({
                isOpen: true,
                index: index1,
              });
            }}
          ></DeleteDanger>
        </RACTableCell>
        </>
      )  
      }
           </RACTableRow></>)}
         
           </>

        )
      })}
    </React.Fragment>
  );

  function somethingWentWrongShow() {
    return (
      <>
        <Grid>
          {true ? (
            <Grid style={{ position: "relative", top: "-24px" }}>
              <Grid className={progressclasses.successPopup}>
                <SomethingWrong style={{ position: "relative", top: "13px" }} />
              </Grid>
              <Grid
                className={`${progressclasses.textcenterImage} ${classes.fontBold}`}
              >
                {errorMsgPackage}
              </Grid>
              <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                <RACButton
                  style={{ position: "relative", top: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setGridErrorPopup(false); setMasterLoader(false); setErrorMsgPackage("")
                  }}
                >
                  OK
                </RACButton>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  }

  const sortDropDown = (value: any) => {
    return value.slice().sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  
  const refObj2: any={
    type:["PACKAGE"]
  }
  
  const fetchDropDownAPIFun = async() => {
    try{
      const responseForPackageDd = await fetchDropDownAPI(refObj2)
  
    if(responseForPackageDd.status == 200){
      let packageNameArr = responseForPackageDd.data.package.map((item : any)=> {
        return ({
          label : item.packageName,
          value : item.packageName
      })
      })
      console.log("packageNameArr hihihe", packageNameArr)
      const sortedPackageArray = sortDropDown(packageNameArr)
      let tempPackageNameArr : any = [];
        tempPackageNameArr = sortedPackageArray;
        tempPackageNameArr.unshift({
          label:'Select',
          value:'0',
        })
  
      setPackageNameDd(tempPackageNameArr);
    }else if(responseForPackageDd.status == 400){
      setGridErrorPopup(true);
       setMasterLoader(false);
        setErrorMsgPackage("Something Went Wrong")
    }
    }catch(error){
      setGridErrorPopup(true);
       setMasterLoader(false);
        setErrorMsgPackage("Something Went Wrong")
    }
    
  }
  const handleClearFields = () => {
    let formatData2 = {...formatData}
      formatData2.criteria = []
      console.log("formatData2 for finding the object is setted to the correct value", formatData2)
      setFormRecords(formatData2) 
  }
  
//approval popup
  const approvalPopup = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ textAlign: "center", marginBottom: "2%" }}
            >
              {" "}
              Request has been submitted
            </Typography>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Do you want to continue for approval?
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setSubmitPopup(false); setMasterLoader(false) /* newStoreZoneClear(), */ 
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsApprovalOpen(false);
                setLoader(false);
                setSubmitPopup(false);
                setAuthOpen(true);
                setMasterLoader(false); 
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderOkPopup= ()=>{
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <SuccessIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Request has been approved.
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {setMasterLoader(false);
                setSuccessPopup(false);
                setIsPackageFormOpen(false);
                setIsSLACompleted(false);
                setIsSLACancel(false);
                handleCancelClick();
                handleClearFields();
                {newPackagePopup ? fetchDropDownAPIFun(): null}}}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
          
        </Grid>
      </>
    );
  }

  const updatePopup = () => {
    return (
        <>
          <Grid style={{ width: "100%", paddingTop: "0.75rem" }}>
            {/* braedcrumbs starts  */}
            <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
              <ListItem
                style={{ paddingLeft: "0" }}
                className={`${Classes.breadcrumbItemActive} `}
              >
                <a onClick={() => handleCancelClick()} style={{ cursor: "pointer", color: "black" }}>Package Details</a>
              </ListItem>
              <ListItem
                className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
              >
                <a >{newPackagePopup ? "New Package" : "Update Package"}</a>
              </ListItem>
            </List>
            {/* braedcrumbs ends  */}
    {(<Grid container spacing={2} style={{height: "90vh"}}>
              {/* Update Package starts  */}
              <Grid item xs={12} md={12} lg={4}>
                <Typography variant="h4">{newPackagePopup ? "New Package": "Update Package"}</Typography>
                <Card className={`${Classes.p3} `} style={{ height: "70vh", borderRadius: "10px"}}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                    {!newPackagePopup ? <><Typography>Zone</Typography>
                      <RACTextbox disabled={true} value={formRecords.zone}/></>: <><Typography>
                    Zone<span style={{ color: "red" }}>*</span>
                  </Typography>
                 <MultiSelect
                  isLoading={zoneDd.length > 1 || (franchisee && companyCodeZone.length ==0)? false : true}
                    options={zoneDd}
                    value={zoneSelectedForm}
                    className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                    onChange={(e: any) => {
                      setZoneSelectedForm(e);
                      let arr = e.map((item: any) => {
                        if (item.value == item.value) return item.value;
                      });
                      console.log(arr);
                      handleUpdateOnChange(arr, "zone");
                    }}
                    labelledBy={"Select"}
                  /> </> }
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} `} >
                       <Typography >Package Name{newPackagePopup && <span style={{ color: "red" }}>*</span>}</Typography>
                      <RACTextbox disabled={newPackagePopup ? false: true} value={formRecords.packageName} OnChange={(e) => handleUpdateOnChange(e.target.value, "packageName")}/>
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                      {!newPackagePopup ? <><Typography>Discount Type</Typography><RACTextbox disabled={true} value={formRecords.discountType == "P" ? "%": "$"}/></>: <><RACSelect
                inputLabel="Discount Type"
                required
                options={discountTypeNew}
                defaultValue={formRecords?.discountType != "" ? formRecords?.discountType : ""}
                onChange={(e) => {
                
                  // if (key == "discountPct") {
                  //   value = value.replace(
                  //     /[^0-9.]|[0-9]+\.[0-9]+\.[0-9]+|(\.\d{2})\d+?$/g,
                  //     ""
                  //   );
                  //   }
                    debugger;
                    if(e.target.value == 'P')
                      setFormRecords({
                        ...formRecords,
                        ['discountType']: e.target.value,['discountAmount']:''
                      });
                      else if(e.target.value=="")
                      setFormRecords({
                        ...formRecords,
                        ['discountType']: e.target.value,['discountAmount']:'',['discountPct']:''
                      });
                      else
                      setFormRecords({
                        ...formRecords,
                        ['discountType']: e.target.value,['discountPct']:''
                      });
                
                /* handleUpdateOnChange(e.target.value, "discountType") */}}
              ></RACSelect></>} 
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3}`}>
                    <Typography>Discount Percent{formRecords?.discountType == "P" ? <span style={{ color: "red" }}>*</span> : null}</Typography>
                    <RACTextbox value={(formRecords?.discountType == "D" || formRecords?.discountType == "") ? "" : formRecords.discountPct} disabled = {(formRecords?.discountType == "D" || formRecords?.discountType == "" || formRecords?.discountType == undefined) ? true : false}  OnChange={(e) => handleUpdateOnChange(e.target.value, "discountPct")} />
                  </Grid>
                  <Grid item xs={6}  className={`${Classes.mt3} ${progressclasses.currencyInputStylePackage}`}>
                  <Typography>Discount Amount{(formRecords?.discountType == "D") ? <span style={{ color: "red" }}>*</span> : null}</Typography>
                    <RACTextbox className={progressclasses.packageTextBox} isCurrency={true} value={(formRecords?.discountType == "P" || formRecords?.discountType == "") ? "" : formRecords.discountAmount} disabled = {(formRecords?.discountType == "P" || formRecords?.discountType == "" || formRecords?.discountType == undefined) ? true : false} OnChange={(e) => handleUpdateOnChange(e.target.value, "discountAmount")}/>
                  </Grid>
                  <Grid item xs={6} className={`${Classes.mt3}`}>
                    <RACDatePicker  label="Start Date" required inputProps={{min: moment().format("YYYY-MM-DD")}} value={moment(formRecords.startDate).format('YYYY-MM-DD')} onChange={(e) => handleUpdateOnChange(e, "startDate")}/>
                  </Grid>
                  <Grid item xs={6} className={`${Classes.mt3} ${Classes.mb3}`}>
                    <RACDatePicker required={newPackagePopup ? true: false} label="End Date"  disabled = {true} inputProps={{min: moment(formRecords.startDate).format("YYYY-MM-DD")}}  value={moment(formRecords.endDate).format('YYYY-MM-DD')} onChange={(e) => handleUpdateOnChange(e, "endDate")}/>
                  </Grid>
                    <Grid
                  spacing={3}
                  item
                  xs={12}
                  md={12}
                  style={{ marginTop: "0.5%", marginLeft: "2% !important" }}
                >
                  <Typography>
                    {" "}
                    How do you want to update the package?
                    <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <Grid
                    style={{
                      margin: "1%",
                    }}
                  >
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "IMMEDIATE" ? true : false}
                      label="Immediately"
                      name="updateType"
                      onClick={() => setSelectQueueType("IMMEDIATE")}
                    ></RACRadio>
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "OVERNIGHT" ? true : false}
                      label="Overnight"
                      name="updateType"
                      onClick={() => setSelectQueueType("OVERNIGHT")}
                    ></RACRadio>
                  </Grid>
                </Grid>
                  </Grid>
                </Card>
              </Grid>
              {/* Update Autoterm ends  */}
    
              {/* Range starts  */}
              <Grid item xs={12} md={12} lg={8}>
                <Typography variant="h4">Criteria</Typography>
                <Card
                  className={`${Classes.p3}`}
                  style={{ overflow: "auto", height: "70vh", borderRadius: "10px" }}
                >
                  {/* <Grid > */}
                  
                  <Grid className={Classes.criteriaGridStyles} >
                <RACTable
                  style={{ whiteSpace: "nowrap", tableLayout:"fixed" }}
                  renderTableHead={CriteriaHead}
                  renderTableContent={CriteriaContent}
                />
                </Grid>
                  {/* </Grid> */}
                </Card>
              </Grid>
              {/* Range starts  */}
            </Grid>
) }
          </Grid>
        </>
      )
  }
// for the grid data
const gridData = () => {
    return (
        <>
            <Grid
              style={{ /* backgroundColor: "red",  */width: "100%", height: "auto", marginBottom: "60px" }}
            >
              {enableGrid == true ? (
                <Card
                  style={{ width: "100%", borderRadius: "12px", marginTop: filterPopup ? "5px" : "20px",  marginBottom: "5%"}}
                >
                  <CardContent style={{paddingBottom: "0px"}}>
                    {!noRecordsFound ? (
                    <>
                    <Grid className={classes.storeZoneDetailGrid}>
                          <Grid className={classes.tableCell}>
                            <RACTable
                              renderTableHead={bindGridHead}
                              renderTableContent={buildTableContent}
                            ></RACTable>
                          </Grid>
                          
                        </Grid>
                        {renderRecordData()}
                        </>
                        ) : (
                        
                    <Grid>
                    <RACTable renderTableHead={rendernorecordsheader} />
                      <Grid item className={progressclasses.textSpaceNorecords} style={{height: "25vh"}}>
                        <Grid className={progressclasses.textcenterNorecords} >
                            <NorecordsIcon className={progressclasses.racErrorIcon} />
                        </Grid>
                        <Grid className={progressclasses.textcenterImage}>
                                        No Records Found !
                        </Grid>
                      </Grid>
                    </Grid>)
                    }
                        
                  </CardContent>
                </Card>
              ) : null}  
            </Grid>  
        </>
     
        
      );
}
      //last return
    return (
        <>
        {isPackageFormOpen ? updatePopup(): gridData()}
        <RACModalCard
        isOpen={wantToDelete.isOpen}
        maxWidth="xs"
        borderRadius={10}
        // closeIcon={true}
        onClose={() => {
          wantToDelete.isOpen = false;
          wantToDelete.index = undefined;
        }}
        children={deletePopup()}
      />
      <RACModalCard
        isOpen={gridErrorPopup}
        maxWidth="xs"
        borderRadius={10}
        // closeIcon={true}
        onClose={() => {
          setGridErrorPopup(false)
        }}
        children={somethingWentWrongShow()}
      />

<RACModalCard
                isOpen={submitPopup}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() =>{setSubmitPopup(false); setMasterLoader(false)}}
                children={approvalPopup()}
                title=""
              />

<RACModalCard
            isOpen={sucessPopup}
            maxWidth="xs"
            closeIcon={false}
            borderRadius="10px"
            onClose={() => setSuccessPopup(false)}
            children={renderOkPopup()}
          />
          
<TwoFactorAuthentication
                  IsHigherAuthority={isApprover} isVisible={authOpen} setIsVisible={setAuthOpen} loader={masterLoader}setloader={setMasterLoader} setTwoFactorCompleted={setIsSLACompleted} setTwoFactorCancelClick={setIsSLACancel} twoFactorCompleted={isSLACompleted} twoFactorCancelClick={isSLACancel} setSomethingWentWrong={setGridErrorPopup} mailId={emailId} employeeId={employeeId} submitedBy ={sessionStorage.getItem('userName')} ApprovedBy={setApprovedBy}/>  
        
        </>
      );
}
