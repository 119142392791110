/* eslint-disable */
import {
  Typography,
  RACButton,
  Grid,
  CardContent,
  Card,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { formatDate } from "../shared/interface/commonFuntions";
import { PromoSchedulerContext } from "./PromoSchedulerContext";
import PromoSchedulerFilter from "./PromoSchedulerFilter";
import PromoSchedulerForm from "./PromoSchedulerForm";
import PromoSchedulerFooter from "./PromoSchedulerFooter";
import { globalStyles } from "../Pricing/Styles/globalstyles";
import { ProgressBar } from "../Pricing/Styles/ProgressStyles";
import { ReactComponent as SortdownIcon } from "../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../assets/images/sort-icon-up.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit Icon.svg";
import { ReactComponent as NorecordsIcon } from "../../assets/images/No-records.svg";
import moment from "moment";
export default function PromoSchedulerGrid() {
  const {
    noDataFound,
    applyFilter,
    psFormLoader,
    setPsFormLoader,
    getPromoFormData,
    filterData,
    TRTOD,
    MAXWEEKS,
    USEFREETIME,
    NUMPAYMENT,
    MAXDAYS,
    PERCENTOFF,
    INITD,
    MAXDAYSMONTHLY,
    MAXDAYSOTHER,
    CLUBAMOUNT,
    gridLoaderFlag,
    setGridLoaderFlag,
    associatedStores,
    postObj,
    psActionPage,
    landingPage,
    totRecord,
    tableHeader,
    gridData,
    setGridData,
    setTableHeader,
    sortDirection,
    assStoreObj,
    pageCount, 
  } = useContext(PromoSchedulerContext);
  const [pginitial, setpginitial] = useState(0);
  const classes = globalStyles();
  const progressclasses = ProgressBar();
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  useEffect(() => {
    applyFilter();
  }, []);

  const iframes = document.querySelector("iframe");
  if (iframes) {
    iframes?.remove();
  }

  console.log("gridData", gridData, "tableHeader", tableHeader);
  console.log("totRecord", totRecord);

  const editData = (promotionId: any) => {
    const response: any = getPromoFormData(promotionId);
  };

  console.log("postObj", postObj);
  console.log(
    INITD,
    TRTOD,
    MAXDAYS,
    MAXDAYSMONTHLY,
    MAXDAYSOTHER,
    MAXWEEKS,
    PERCENTOFF,
    USEFREETIME,
    NUMPAYMENT,
    CLUBAMOUNT
  );
  console.log("associatedStores", associatedStores);
  console.log("assStoreObj", assStoreObj);

  const sortArray = (
    columnId: any,
    index: any,
    gridData: any,
    tableHeader: any
  ) => {
    console.log("columnId :",columnId,"index :",index,"gridData :",gridData,"tableHeader :",tableHeader)
    const sortedArray =
      tableHeader[index].sortOrder == sortDirection.DESC
        ? gridData.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
        : gridData.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
          console.log("sortedArray",sortedArray)
    const tempHeader = tableHeader;
    tempHeader[index].sortOrder =
      tempHeader[index].sortOrder === sortDirection.ASC ? sortDirection.DESC: sortDirection.ASC;
    setGridData([...sortedArray]);
    setTableHeader(tempHeader);
  };

  const bindGridHead = () => {
    return (
      <>
        {tableHeader.map((value: any, index: any) => {
          return value.columnTitle == "Action" ? (
            <RACTableCell className={`${classes.textCenter}`}>
              Action
            </RACTableCell>
          ) : (
            <RACTableCell key={index}>
              {value.IsSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() =>
                    sortArray(value.columnId, index, gridData, tableHeader)
                  }
                >
                  {value.columnTitle + "  "}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ml0} />
                    ) : (
                      <SortdownIcon className={classes.ml0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + "  "}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  };

  const buildTableContent = () => {
    return (
      <>
        {gridData?.map((value: any, index: any) => {
          let sDate = moment(value.startDate).format('MM/DD/YYYY');
          let eDate = moment(value.endDate).format('MM/DD/YYYY');
          return (
            <>
              <RACTableRow key={index} className={classes.bgTransparent}>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.promoGroupName}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.promoType ? value.promoType : "-"}
                </RACTableCell>
                <RACTableCell
                  style={{ fontSize: "0.85rem" }}
                  title={value.promoName > 20 ? value.promoName : ""}
                >
                  {value.promoName?.lenght > 20
                    ? value.promoName.slice(0, 20) + "..."
                    : value.promoName
                    ? value.promoName
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  style={{ fontSize: "0.85rem" }}
                  title={value.promoCode.length > 15 ? value.promoCode : ""}
                >
                  {value.promoCode?.length > 20
                    ? value.promoCode.slice(0, 20) + "..."
                    : value.promoCode
                    ? value.promoCode
                    : "-"}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.customerOrder=="1"?"Yes":"No"}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.ecomm=="1"?"Yes":"No"}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.startDate ? sDate : "-"}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.endDate ? eDate : "-"}
                </RACTableCell>
                <RACTableCell className={`${classes.textCenter}`}>
                  <RACButton
                    onClick={() => {
                      // setFilterData({...filterData,promotionId:value.promotionId})
                      setPsFormLoader(true);
                      editData(value.promotionId);
                      // getPromoFormData()
                    }}
                  >
                    <EditIcon />
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            </>
          );
        })}
      </>
    );
  };

  const rendernorecordsheader = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Promo Group
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Promo Type
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Promo Name
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Promo Code
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Customer Order
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Ecomm
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          Start Date
        </RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>
          End Date
        </RACTableCell>
        <RACTableCell
          className={`${classes.p2} ${classes.pt1} ${classes.pb1} ${classes.textCenter}`}
        >
          {" "}
          Action
        </RACTableCell>
      </React.Fragment>
    );
  };

  const handlePageClick = (event: any) => {
    setpginitial(event.selected);
    console.log("event.selected", event.selected);
    // scrollTop;
    console.log(event.selected + 1, "Tje value dfawfnol");
    console.log("page initial checking if the state is setted", pginitial);
    filterData.offset = (event.selected + 1).toString();
    console.log("event", event);
    console.log("page initial", pginitial);
    // filterData.storeLimit=""
    // filterData.storeOffset=""
    filterData.promotionId=""
    applyFilter();
    setGridLoaderFlag(false);
  };

  const renderRecordData = () => {
    return (
      <>
        <Grid
          item
          md={12}
          className={`${classes.mb0} ${classes.textRight}`}
          style={{ marginTop: "32px" }}
        >
          <Grid className={`${progressclasses.totrec}`}>
            {!totRecord ? null : (
              <Typography>
                # of Records:{" "}
                {(pginitial + 1) * 25 > totRecord
                    ? totRecord
                    : (pginitial + 1) * 25
                  }
                /{totRecord}{" "}
              </Typography>
            )}
          </Grid>
          {totRecord ? (
            <ReactPaginate
              disableInitialCallback={true}
              nextLabel=">"
              onPageChange={(num: any) => {
                setGridLoaderFlag(false);
                handlePageClick(num);
              }}
              initialPage={pginitial}
              pageCount={pageCount} 
              pageRangeDisplayed={2}
              marginPagesDisplayed={0}
              previousLabel="<"
              pageClassName={progressclasses.pagination}
              pageLinkClassName={progressclasses.pagelink}
              previousLinkClassName={
                pginitial === 0
                  ? progressclasses.paginationprevnextdisable
                  : progressclasses.paginationprevnext
              }
              nextLinkClassName={
                pginitial+1 === pageCount
                  ? progressclasses.paginationprevnextdisable
                  : progressclasses.paginationprevnext
              }
              breakLabel=""
              breakClassName={progressclasses.pagination}
              breakLinkClassName={progressclasses.pagination}
              containerClassName={progressclasses.pagination}
              activeClassName={progressclasses.pageactive}
              forcePage={pginitial === 0 ? 0 : undefined}
            />
          ) : null}
        </Grid>
      </>
    );
  };

  return (
    <>
      {psFormLoader == true ? (
        <Grid container className={progressclasses.masterLoader}>
          <Grid className={progressclasses.Loader}>
           <CircularProgress/>
          </Grid>
        </Grid>):null}
      {landingPage == true ? (
        <Grid
          style={{
            width: "100%",
            height: "auto",
            paddingTop: "0.75rem",
            marginBottom: "60px",
          }}
        >
          <Typography
            style={{ float: "left", position: "relative", top: "12px" }}
            variant="h5"
          >
            Promo Scheduler
          </Typography>
          <PromoSchedulerFilter />
          <Card
            style={{
              width: "100%",
              borderRadius: "12px",
              marginTop: "25px",
              marginBottom: "5%",
            }}
          >
            <CardContent style={{ paddingBottom: "0px" }}>
              {gridLoaderFlag == true ? (
                <>
                  {" "}
                  {noDataFound == false  ? (
                    <>
                      <Grid className={classes.promoGrid}>
                        <Grid className={classes.tableCell}>
                          <RACTable
                            renderTableHead={bindGridHead}
                            renderTableContent={buildTableContent}
                          ></RACTable>
                        </Grid>
                      </Grid>
                      {renderRecordData()}
                    </>
                  ) : (
                    <Grid className={classes.promoGridLoader}>
                      <RACTable renderTableHead={rendernorecordsheader} />
                      <Grid
                        item
                        className={progressclasses.textSpaceNorecords}
                        style={{ height: "25vh" }}
                      >
                        <Grid className={progressclasses.textcenterLoader}>
                          <NorecordsIcon
                            className={progressclasses.racErrorIcon}
                          />
                        </Grid>
                        <Grid className={progressclasses.textcenterImage}>
                          No Records Found 
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <Grid  className={classes.promoGridLoader}>
                    <RACTable renderTableHead={rendernorecordsheader} />
                    <Grid
                      item
                      className={progressclasses.textSpaceNorecords}
                      style={{ height: "25vh" }}
                    >
                      <Grid className={progressclasses.textcenterLoader}>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      ) : null}
      {psActionPage == true ? <PromoSchedulerForm /> : null}
      <PromoSchedulerFooter />
    </>
  );
}
