import { useState } from 'react';
import React from 'react';
import clsx from 'clsx';
import {
  Grid,
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  btnGrp: {
    position: 'relative',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  '&:label': {
    display: 'inline !important' as any,
    paddingLeft: theme.typography.pxToRem(5),
    paddingRight: theme.typography.pxToRem(5),
  },

  btnPrimary: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(22)} `,
    backgroundColor: '#2179FE',
    fontFamily: 'OpenSans-semibold',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0b5ed7',
      borderColor: '#0a58ca',
    },
    '&:focus': {
      color: '#fff',
      backgroundColor: '#0b5ed7',
      borderColor: '#0a58ca',
      boxShadow: '0 0 0 0.25rem rgba(49, 132, 253, 0.5)',
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#0d6efd',
      borderColor: '#0d6efd',
    },
  },
  dropdownItem: {
    display: 'block',

    width: '100%',
    padding: '0.25rem 1rem',
    clear: 'both',
    fontWeight: 400,
    color: '#212529',
    textAlign: 'inherit',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover &:focus': {
      color: '#1e2125',
      backgroundColor: '#e9ecef',
    },
    '&:disabled': {
      color: '#adb5bd',
      pointerEvents: 'none',
      backgroundColor: 'transparent',
    },
  },
  mx2: {
    marginRight: '0.5rem !important' as any,
    marginLeft: '0.5rem !important' as any,
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    zIndex: 1000,
    display: 'none',
    minWidth: '10rem',
    padding: '0.5rem 0',
    margin: 0,
    fontSize: '1rem',
    color: '#212529',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: `${theme.typography.pxToRem(1)} solid rgba(0, 0, 0, 0.15)`,
    borderRadius: '0.25rem',
    '&:hover': {
      color: '#1e2125',
    },
  },
  button: {
    display: 'inline-block',
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#212529',
    textAlign: 'center',
    textDecoration: 'none',
    verticalAlign: 'middle',
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: 'transparent',
    border: `${theme.typography.pxToRem(1)} solid transparent`,
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    borderRadius: '0.375rem 0.75rem',
    transition: 'color 0.15s ease-in-out',
    borderColor: '0.15s ease-in-out',
    boxShadow: '0.15s ease-in-out',
    '&:hover': {
      color: '#212529',
    },
  },
  customerMenuContainer: {
    border: `${theme.typography.pxToRem(1)} solid #2468FF`,
  },
  show: {
    display: 'block',
  },
}));
export interface Dropdownprops {
  disabled?: boolean;
  handleOnClick: (event: any) => void;
  children?: any;
  BindAry: string[];
  buttonText: string;
}

export function RacDropdown({
  handleOnClick,
  disabled,
  BindAry,
  buttonText,
  children,
}: Dropdownprops) {
  const ownClasses = useStyles();

  const cssval = clsx(
    ownClasses.dropdownMenu,
    ownClasses.customerMenuContainer,
    ownClasses.show
  );
  const cssval1 = clsx(
    ownClasses.dropdownMenu,
    ownClasses.customerMenuContainer
  );
  const [dropdownpop, setdropdownpop] = useState(false);

  const BindValue = () => {
    return BindAry.map((value: any, index: any) => {
      return (
        <ListItem
          key={index}
          className={clsx(ownClasses.dropdownItem)}
          style={{ color: 'blue' }}
          onClick={(e) => {
            handleOnClick(e);
            setdropdownpop(false);
          }}
        >
          <ListItemText id={index} primary={value}>
            {value}
          </ListItemText>
        </ListItem>
      );
    });
  };

  return (
    <Grid
      className={clsx(ownClasses.btnGrp)}
      role="group"
      style={{ marginTop: 200 }}
    >
      <Button
        id="btnGroupDrop1"
        type="button"
        className={clsx(ownClasses.btnPrimary, ownClasses.mx2)}
        style={{ background: 'blue', padding: 1, color: 'white' }}
        data-bs-toggle="dropdown"
        aria-expanded="true"
        disabled={disabled}
        onClick={() => setdropdownpop(!dropdownpop)}
      >
        {buttonText}
        {children}
      </Button>

      <List
        className={dropdownpop ? cssval : cssval1}
        aria-labelledby="btnGroupDrop1"
        data-popper-placement="top-start"
        style={{
          position: 'absolute',
          inset: 'auto auto 0rem 0rem',
          margin: '0rem',
          transform: 'translate(0.5rem, -2.813rem)',
          border: '0.063rem solid #2468FF',
        }}
      >
        {BindValue()}
      </List>
    </Grid>
  );
}
