import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Grid, makeStyles, Typography, createTheme } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => ({
  dialogTitle: {
    flex: '0 0 auto',

    width: '50%',
  },
  badge: {
    '& .card': {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      color: '#fff',
      wordWrap: 'break-word',
      backgroundColor: '#2179FE',
      backgroundClip: 'border-box',
      border: `${theme.typography.pxToRem(5)} solid rgba(0, 0, 0, 0.125);`,
      borderRadius: '0.25rem',
    },
    '& .MuiTypography-colorTextPrimary': {
      color: 'inherit ! important' as any,
    },
  },
}));
export const theme = createTheme({
  overrides: {
    // For label
    MuiCard: {
      root: {
        '& .hidden-button': {
          display: 'none',
          borderRadius: '50%',
        },
        '&:hover .hidden-button': {
          display: 'flex',
        },
      },
    },
  },
});

export interface BadgeCardprops {
  header?: string;
  OnClick?: (event: any) => void;
  backgroundColor: string;
  fullscreenIcon: boolean;
  className?: any;
  children?: any;
  borderRadius: string;
  color: string;
  headercolor?: string;
}

export function RacBadgeCard({
  header,
  headercolor,
  OnClick,
  backgroundColor,
  color,
  borderRadius,
  fullscreenIcon,
  className,
  children,
}: BadgeCardprops) {
  const ownClasses = useStyles();
  return (
    <Grid>
      {header !== '' ? (
        <Typography variant="h4" style={{ color: headercolor }}>
          {header}
        </Typography>
      ) : null}
      <Grid
        className={clsx(ownClasses.badge, className)}
        style={{
          backgroundColor: backgroundColor,
          padding: 5,
          color: color,
          borderRadius: parseInt(borderRadius),
        }}
      >
        {fullscreenIcon === true ? (
          <FontAwesomeIcon
            style={{
              color: 'white',
              backgroundColor: '#2a75f5',
              width: '18',
              height: '18',
              padding: 4,
              marginRight: '-6',
              marginTop: '-11',
              borderRadius: 12,
              float: 'right',
            }}
            className={'hidden-button'}
            data-bs-toggle="modal"
            icon={faExpandAlt}
            data-bs-target="#salesleads"
            onClick={OnClick ? (event) => OnClick(event) : OnClick}
          />
        ) : null}
        {children}
      </Grid>
    </Grid>
  );
}
