/* eslint-disable */
import {
    RACSelect,
    Typography,
    RACButton,
    Grid,
    CardContent,
    Card,
    RACCheckBox,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACTextbox,
    RACModalCard,
    RACDatePicker,
    CircularProgress,
    List,
    ListItem,
  } from "@rentacenter/racstrap";
  import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/Up-arrow-white.svg";
import { ReactComponent as DownArrow } from "../../../assets/images/down-arrow-white.svg";
import { ReactComponent as SortdownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../../assets/images/sort-icon-up.svg";
import { ReactComponent as SomethingWrong } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/success-icon.svg";
import { ReactComponent as Danger } from "../../assets/images/danger.svg";
import { ReactComponent as NorecordsIcon } from '../../../assets/images/No-records.svg';
import { ReactComponent as EditIcon } from "../../../assets/images/Edit Icon.svg";
import { ReactComponent as DeleteDanger } from "../../../assets/images/delete-danger.svg"; 
import { ReactComponent as AddRow } from "../../../assets/images/action-add-row.svg"; 
import { ReactComponent as EditCheck } from "../../../assets/images/edit-update-check.svg"; 
import { ReactComponent as EditCross } from "../../../assets/images/edit-notupdate-cross.svg"; 
import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up-icon.svg";
import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down-icon.svg";
import {globalStyles} from "../Styles/globalstyles";
import {ProgressBar} from "../Styles/ProgressStyles";
import ReactPaginate from "react-paginate";
import {PricingReportStyle} from "../Styles/PricingReport"
import moment from "moment";
import { PricingQueueContext } from "./PricingQueueContext";

export const PackageApproval = () => {
    const {indexValue,setIsPackage, setPackageCriteria,setIndexValue,packageCriteria,setHideMainPage,comments, setComments} = useContext(PricingQueueContext);


        const Classes = globalStyles();

  //scroll top functionality

  const updatePopup = () => {
    return (
        <>
          <Grid style={{ width: "100%", paddingTop: "0.75rem" }}>
            {/* braedcrumbs starts  */}
            <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
              <ListItem
                style={{ paddingLeft: "0" }}
                className={`${Classes.breadcrumbItemActive} `}
              >
                <a style={{ cursor: "pointer" }} onClick={()=>{
                    setIsPackage(false);
                    setHideMainPage(false);
                    setPackageCriteria();
                    setIndexValue("");
                }}>Package Details</a>
              </ListItem>
              <ListItem
                className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
              >
                <a>Package Approval</a>
              </ListItem>
            </List>
            {/* braedcrumbs ends  */}
    
            <Grid container spacing={2}>
              {/* Update Autoterm starts  */}
              <Grid item xs={12} md={12} lg={4}>
                <Typography variant="h4">Update Package</Typography>
                <Card className={`${Classes.p3} `} style={{ height: "70vh" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                    <Typography className={`${Classes.fontBold} `}>Zone</Typography>
                    <Typography title={indexValue?.package.zone? (indexValue?.package.zone.join(', ')).length > 17 ? (indexValue?.package.zone.join(', ')) : (indexValue?.package.zone.join(', ')):''}>{indexValue?.package.zone ?(indexValue?.package.zone.join(', ')).length > 17 ? (indexValue?.package.zone.join(', ')).slice(0,17) + '...' : (indexValue?.package.zone.join(', ')) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                       <Typography className={`${Classes.fontBold} `}>Package Name</Typography>
                       <Typography title={indexValue?.package.packageName?(indexValue?.package.packageName).length > 17 ? (indexValue?.package.packageName): (indexValue?.package.packageName) :''}>{ indexValue?.package.packageName? indexValue?.package.packageName.length> 17 ? (indexValue?.package.packageName).slice(0,17) + '...': indexValue?.package.packageName :'-' }</Typography>
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                    <Typography className={`${Classes.fontBold} `}>Discount Type</Typography>
                    <Typography >{indexValue?.package?.discountType? indexValue?.package?.discountType:'-' }</Typography>
                    </Grid>
    
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                      <Typography className={`${Classes.fontBold} `}>Discount Percent</Typography>
                      <Typography>{indexValue?.package.discountPercent ? indexValue?.package.discountPercent :'-'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                      <Typography className={`${Classes.fontBold} `}>Discount Amount</Typography>
                      <Typography title={indexValue?.package.discountAmount ?(indexValue?.package.discountAmount).length > 17 ? ( indexValue?.package.discountAmount): (indexValue?.package.discountAmount) : ''}>{indexValue?.package.discountAmount ?(indexValue?.package.discountAmount).length > 17 ? ( indexValue?.package.discountAmount).slice(0,17) + '...' : (indexValue?.package.discountAmount) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} `}>
                    <Typography className={`${Classes.fontBold} `}>Start Date</Typography>
                    <Typography>{moment(indexValue?.package.startDate).format('MM/DD/YYYY')}</Typography>
                    </Grid>
                    <Grid item xs={6} className={`${Classes.mt3} ${Classes.mb3} `}>
                    <Typography className={`${Classes.fontBold} `}>End Date</Typography>
                    <Typography>{moment(indexValue?.package.endDate).format('MM/DD/YYYY')}</Typography>
                    </Grid>
                    <Grid md={12}>
                    <Typography variant="h4" style={{ marginTop: "2%" }}>
            Comments
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <textarea
              rows={6}
              style={{ resize: "none", width: "100%", border:'1px solid lightgray !important' }}
              disabled={
                indexValue?.status?.toUpperCase() == "QUEUED" ||
                  indexValue?.status?.toUpperCase() == "WAITING"
                  ? false
                  : true
              }
              value={comments}
              onChange={(e: any) => {
                setComments(e.target.value);
              }}
            ></textarea>
          </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              {/* Update Autoterm ends  */}
    
              {/* Range starts  */}
              <Grid item xs={12} md={12} lg={8}>
                <Typography variant="h4">Criteria</Typography>
                <Card
                  className={`${Classes.p3}`}
                  style={{ overflow: "auto", height: "70vh" }}
                >
                  {/* <Grid > */}
                  <RACTable
                    style={{ whiteSpace: "nowrap" }}
                    renderTableHead={CriteriaHead}
                    renderTableContent={CriteriaContent}
                  />
                  {/* </Grid> */}
                </Card>
              </Grid>
              {/* Range starts  */}
            </Grid>
          </Grid>
        </>
      )
  }
  // format date function
  function formatDate(date: any) { 
    console.log("date", date)
    var d = new Date(date), 
  month = '' + (d.getUTCMonth() + 1), 
  day = '' + d.getUTCDate(),
  year = d.getUTCFullYear()
  if (month.length < 2) {
  month = '0' + month;
  } 
  if (day.length < 2) {
  day = '0' + day; 
  }
  return [month, day, year].join('/');
  }
   //for rendering the head of the update popup
   const CriteriaHead = () => (
    <>
      <RACTableCell>
        Department{" "}
      </RACTableCell>
      <RACTableCell>
        Sub Department{" "}
      </RACTableCell>
      <RACTableCell>
        Bracket{" "}
      </RACTableCell>
      <RACTableCell>
        RMS #{" "}
      </RACTableCell>
      <RACTableCell>Minimum Quantity</RACTableCell>
      <RACTableCell>Maximum Quantity</RACTableCell>
    </>
  );
  //for rendering the body of the update popup\
  {
    console.log(packageCriteria, "the appsddbjg j");
    
  }
  const CriteriaContent = () => (
    <React.Fragment>
        
      { packageCriteria?.map((value: any, index1: any) => {
        return(
           <>
          <RACTableRow style={{ backgroundColor: "transparent" }}>
        <RACTableCell>
            <Typography title={value.departmentDesc? value.departmentDesc.length> 17 ? value.departmentDesc.slice(0,17) +'...': value.departmentDesc:''}>{value.departmentDesc ? value.departmentDesc.length> 17 ? value.departmentDesc.slice(0,17) +'...': value.departmentDesc : '-'}</Typography>
        </RACTableCell>
        <RACTableCell>
            <Typography title={value.subdepartmentDesc? value.subdepartmentDesc.length> 17 ? value.subdepartmentDesc.slice(0,17) +'...': value.subdepartmentDesc:''}>{value.subdepartmentDesc? value.subdepartmentDesc.length> 17 ? value.subdepartmentDesc.slice(0,17) +'...': value.subdepartmentDesc : '-'}</Typography>
        </RACTableCell>
        <RACTableCell>
            <Typography title={value.bracketName ?value.bracketName.length> 17 ? value.bracketName.slice(0,17) +'...': value.bracketName:''}>{value.bracketName ?value.bracketName.length> 17 ? value.bracketName.slice(0,17) +'...': value.bracketName  : '-'}</Typography>
        </RACTableCell>
        <RACTableCell>
            <Typography title={value.rmsItemNumber?value.rmsItemNumber.length> 17 ? value.rmsItemNumber.slice(0,17) +'...': value.rmsItemNumber:''}>{value.rmsItemNumber? value.rmsItemNumber : '-'}</Typography>
        </RACTableCell>
        <RACTableCell>
            <Typography >{value.minQuantity}</Typography>
        </RACTableCell>
        <RACTableCell>
            <Typography>{value.maxQuantity}</Typography>
        </RACTableCell>
      </RACTableRow>
         
           </>

        )
      })}
    </React.Fragment>
  );


      //last return
    return ( 
        <>
        {updatePopup()}
        </>
      );
}