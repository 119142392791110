import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
  className?: string;
  title?: string;
}

const useStyles = makeStyles(() => ({
  cardRoot: {
    width: '100%',
    boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%)',
    backgroundColor: '#FFFFFF',
    borderRadius: '1rem',
  },
  inputRoot: {},
}));

export function Card({
  children,
  className,
  ...rest
}: PropsWithChildren<CardProps>) {
  const ownClasses = useStyles();
  return (
    <div className={clsx([ownClasses.cardRoot, className])} {...rest}>
      {children}
    </div>
  );
}
