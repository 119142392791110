/* eslint-disable prettier/prettier */
import React, { PropsWithChildren } from 'react';
import {
  makeStyles,
  createStyles
 
} from '@material-ui/core';
import { Tooltip } from '../Tooltip/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import clsx from 'clsx';
export interface InfoIconprops {

    infoTitle?:any;
    placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
    className?:any;
    
    iconclasses?:any;
    iconClassname?:any;
    classes?:any;

}

const useStyles = makeStyles(() =>
  createStyles({
    InfoIcon: {
      width: '15px !important',
      color: '#007EFF',
    },
  })
);


export function RACInfoIcon({
  infoTitle,
  placement,
  className,
  iconclasses,
  iconClassname,
  classes
 }: PropsWithChildren<InfoIconprops>) {
  const ownStyles = useStyles();

  return (
    <Tooltip placement={placement} title={infoTitle}  className={className} classes={classes}>
      <InfoIcon className={clsx(ownStyles.InfoIcon,iconClassname)} classes={iconclasses}></InfoIcon>
    </Tooltip>
  );
}
