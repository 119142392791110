import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { SpeechBubble } from '../SpeechBubble/SpeechBubble';
import { LetterAvatar } from '../LetterAvatar/LetterAvatar';
import {
  CULTURED,
  DARK_MAGENTA,
  INDEPENDENCE,
  LIGHT_BLUE_BACKGROUND,
  LOTION,
  OLD_SILVER,
  PALE_SPRING_BUD,
  PALE_SPRING_BUD_DARKER,
} from '../../colors/colors';
import clsx from 'clsx';
import { LightTooltip } from '../LightTooltip/LightTooltip';

export interface TextConversationMessageProps {
  isOwnMessage?: boolean;
  message?: string;
  avatarText?: string;
  tooltipSubtitle?: any[];
  tooltipTitle?: JSX.Element | string;
}
const useStyles = makeStyles(() => ({
  textConversationMessageRoot: {
    display: 'flex',
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  mirror: {
    transform: 'scale(-1, 1)',
  },
  tooltipText: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  tooltipTitle: {
    color: `${INDEPENDENCE} !important`,
  },
  tooltipSubtitle: {
    color: `${OLD_SILVER} !important`,
  },
}));

export const TextConversationMessage = ({
  isOwnMessage,
  message,
  avatarText,
  tooltipTitle,
  tooltipSubtitle,
}: TextConversationMessageProps) => {
  const ownClasses = useStyles();
  let avatarTextColor = PALE_SPRING_BUD_DARKER;
  let avatarBackgroundColor = PALE_SPRING_BUD;
  let speechBubbleBackground = CULTURED;
  if (isOwnMessage) {
    avatarTextColor = DARK_MAGENTA;
    avatarBackgroundColor = LOTION;
    speechBubbleBackground = LIGHT_BLUE_BACKGROUND;
  }
  return (
    <div
      className={clsx(
        ownClasses.textConversationMessageRoot,
        isOwnMessage && ownClasses.mirror
      )}
    >
      <LightTooltip
        text={
          <>
            <Typography
              className={clsx(ownClasses.tooltipText, ownClasses.tooltipTitle)}
              variant="body1"
              align="center"
            >
              {tooltipTitle}
            </Typography>
            {tooltipSubtitle?.map((subTitle, index) => (
              <Typography
                key={index}
                className={clsx(
                  ownClasses.tooltipText,
                  ownClasses.tooltipSubtitle
                )}
                variant="body1"
                align="center"
              >
                {subTitle}
              </Typography>
            ))}
          </>
        }
      >
        <span>
          <LetterAvatar
            text={avatarText}
            backgroundColor={avatarBackgroundColor}
            textColor={avatarTextColor}
            isMirror={isOwnMessage}
          />
        </span>
      </LightTooltip>
      <SpeechBubble
        text={message}
        backgroundColor={speechBubbleBackground}
        isMirror={isOwnMessage}
      />
    </div>
  );
};
