import { Grid, InputLabel, withStyles } from '@material-ui/core';
import React from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import { FieldError } from '../FieldError/FieldError';
import { StyleClasses } from '../StyleClasses';

export type ClassKey = 'inputLabel' | 'errorMessage';

const StyledInputLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    position: 'relative',
    '& span': {
      color: 'red !important',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))((props) => <InputLabel {...props} />) as typeof InputLabel;

export interface SearchableDropdownprops {
  OnChange: (event: any) => void;
  value?: unknown;
  id?: string;
  disabled?: boolean;
  name?: string;
  options: any;
  errormessage?: string;
  inputLabelClassName?: any;
  noOptionsMessage?:
    | ((obj: { inputValue: string }) => string | null)
    | undefined;
  inputLabel?: string;
  classes?: StyleClasses<ClassKey>;
  labelFocused?: boolean;
  required?: boolean;
  borderCondition?: boolean;
  borderColor?: any;
  backgroundColorCondition?: boolean;
  backgroundColor?: any;
  fontColor?: any;
  AccordionColor?: any;
  defaultInputValue?: any;
  defaultValue?: any;
  styles?: any;
  className?: any;
  defaultMenuIsOpen?: any;
  onBlur?: any;
  onKeyDown?: any;
  inputValue?: any;
  onInputChange?: any;
  onFocus?: any;
}

export function RacSearchableDropdown({
  OnChange,
  value,
  id,
  disabled,
  name,
  errormessage,
  options,
  inputLabel,
  classes,
  labelFocused,
  required,
  borderCondition,
  borderColor,
  backgroundColorCondition,
  backgroundColor,
  fontColor,
  AccordionColor,
  defaultInputValue,
  defaultValue,
  inputLabelClassName,
  onBlur,
  onKeyDown,
  inputValue,
  onInputChange,
  onFocus,
  styles,
  className,
  defaultMenuIsOpen,
  ...props
}: SearchableDropdownprops) {
  const boxSize = 'border-box';
  const customStyles = {
    control: () => ({
      maxWidth: '100%',
      color: '#2E31BE',
      backgroundColor: backgroundColorCondition
        ? backgroundColor
        : '#FAFAFE !important',
      border: borderCondition ? borderColor : '1px solid #EEEEF9 !important',
      borderRadius: '6px',
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '38px',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: boxSize,
    }),
    indicatorSeparator: () => ({
      display: 'none !important',
    }),
    singleValue: () => ({
      color: fontColor !== undefined ? fontColor : '#212529 !important',
      gridArea: '1/1/2/3',
      marginLeft: '2px',
      marginRight: '2px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: boxSize,
      fontFamily: 'semi-bold',
    }),
    //build triggered
    dropdownIndicator: () => ({
      color: AccordionColor !== undefined ? AccordionColor : '#212529',
      display: 'flex',
      transition: 'color 150ms',
      boxSizing: boxSize,
      padding: '8px',
    }),
  };
  return (
    <Grid>
      {inputLabel && (
        <StyledInputLabel
          className={clsx(classes?.inputLabel, inputLabelClassName)}
          disableAnimation
          focused={labelFocused}
          required={required}
        >
          {inputLabel}
        </StyledInputLabel>
      )}
      <Select
        className={className}
        defaultInputValue={defaultInputValue}
        defaultValue={defaultValue}
        defaultMenuIsOpen={defaultMenuIsOpen}
        id={id}
        name={name}
        inputValue={inputValue}
        onInputChange={onInputChange}
        placeholder=""
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        value={value}
        options={options}
        isDisabled={disabled}
        styles={styles !== undefined ? styles : customStyles}
        onChange={(e) => OnChange(e)}
        maxMenuHeight={200}
        {...props}
      />
      {errormessage && (
        <FieldError classes={classes} errorMessage={errormessage} />
      )}
    </Grid>
  );
}
