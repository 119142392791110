import React, { HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import { MVS_RED } from '../../colors/colors';
import clsx from 'clsx';
import { StyleClasses } from '../StyleClasses';

export type ClassKey = 'errorMessage';

const useStyles = makeStyles((theme: any) => ({
  errorMessage: {
    color: MVS_RED,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(19.5),
    margin: 0,
  },
}));

interface Props extends HTMLProps<HTMLParagraphElement> {
  errorMessage?: any;
  classes?: StyleClasses<ClassKey>;
}

export const fieldErrorTestId = 'fieldErrorTestId';

export const FieldError = ({ errorMessage, classes, ...rest }: Props) => {
  const ownClasses = useStyles();
  return (
    <p
      className={clsx(ownClasses.errorMessage, classes?.errorMessage)}
      data-testid={fieldErrorTestId}
      {...rest}
    >
      {errorMessage}
    </p>
  );
};
