import React, { PropsWithChildren } from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  withStyles,
} from '@material-ui/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pixelToRem } from '../../utils/utils';

export interface RacButtonProps extends MuiButtonProps {
  'data-testid'?: string;
  className?: string;
  isRounded?: boolean;
  loading?: boolean;
}
const StyledButton = withStyles({
  root: {
    borderRadius: (props: RacButtonProps) =>
      props?.isRounded ? pixelToRem(30) : pixelToRem(4),
    textTransform: 'none',
  },
  iconSizeLarge: {
    '& svg': {
      fontSize: `${pixelToRem(18)}rem !important`,
    },
  },
})((props: MuiButtonProps) => (
  <MuiButton disableRipple {...props} />
)) as typeof MuiButton;
export function RacButton(props: PropsWithChildren<RacButtonProps>) {
  const { children, loading, ...rest } = props;
  return (
    <StyledButton
      endIcon={
        loading && (
          <FontAwesomeIcon
            size="lg"
            icon={faCircleNotch}
            className={'fa-w-14 fa-spin'}
          />
        )
      }
      {...rest}
    >
      {children}
    </StyledButton>
  );
}
