import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import { RacTextbox } from '../TextboxMain/TextboxMain';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldError } from '../FieldError/FieldError';

export interface SsnTextBoxProps {
  name?: string;
  id?: string;
  inputlabel?: string;
  value?: any;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  inputLabelClassname?: any;
  OnChange?: (e: any) => void;
  OnClick?: (e: any) => void;
  Onblur?: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      width: '100% !important',
    },
    inputfirst: {
      flex: '0 0 auto',
      marginRight: '4%',
    },
    inputsecond: {
      flex: '0 0 auto',
      marginRight: '4%',
    },
    inputthird: {
      flex: '0 0 auto',
    },
    GridFirst: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    FieldAlign: {
      '& .MuiInputBase-input': {
        textAlign: 'center',
      },
    },
  })
);

const StyledInputLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(14),

    position: 'relative',
    '& .MuiFormLabel-asterisk': {
      color: 'red !important',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))((props) => <InputLabel {...props} />) as typeof InputLabel;

export function RACSsnTextBox({
  OnClick,
  Onblur,
  OnChange,
  value,

  inputLabelClassname,
  inputlabel,
  disabled,
  required,
  errorMessage,
}: SsnTextBoxProps) {
  const [value1, setValues1] = useState<any>('');
  const [value2, setValues2] = useState<any>('');
  const [value3, setValues3] = useState<any>('');
  const ownstyles = useStyles();

  useEffect(() => {
    if (value.length > 0) {
      setValues1(value.slice(0, 3));
      setValues2(value.slice(3, 5));
      setValues3(value.slice(5));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ssn2Handler = (e: any) => {
    if (e.target.value.length === 2) {
      (document.getElementById('a11y_ssnthree') as HTMLInputElement).focus();
    }

    if (e.target.value.length === 0) {
      (document.getElementById('a11y_ssnone') as HTMLInputElement).focus();
    }
  };

  const ssn1Handler = (e: any) => {
    if (e.target.value.length === 3) {
      (document.getElementById('a11y_ssntwo') as HTMLInputElement).focus();
    }
  };

  const ssn3Handler = (e: any) => {
    if (e.target.value.length === 0) {
      (document.getElementById('a11y_ssntwo') as HTMLInputElement).focus();
    }
  };

  const handleChange = (e: any) => {
    if (e.target.name == 'ssn1') {
      const SSNData1: any = e.target.value.replace(/\D/g, '');
      setValues1(SSNData1);
      //OnChange(e);
      ssn1Handler(e);
    } else if (e.target.name == 'ssn2') {
      const SSNData2: any = e.target.value.replace(/\D/g, '');

      setValues2(SSNData2);
      ssn2Handler(e);
    } else if (e.target.name == 'ssn3') {
      const SSNData3: any = e.target.value.replace(/\D/g, '');
      setValues3(SSNData3);
      ssn3Handler(e);
    }
    const value1Obj = e.target.name == 'ssn1' ? e.target.value : value1;
    const value2Obj = e.target.name == 'ssn2' ? e.target.value : value2;
    const value3Obj = e.target.name == 'ssn3' ? e.target.value : value3;
    e.target.value = value1Obj + value2Obj + value3Obj;

    OnChange(e);
  };

  return (
    <div className={clsx(ownstyles.GridFirst)}>
      <Grid container md={12}>
        <StyledInputLabel
          className={clsx(inputLabelClassname)}
          disableAnimation
          required={required}
        >
          {inputlabel}
        </StyledInputLabel>
      </Grid>
      <Grid container md={12}>
        <Grid item md={4} className={clsx(ownstyles.inputfirst)}>
          <RacTextbox
            type="password"
            id={'a11y_ssnone'}
            className={clsx(ownstyles.FieldAlign)}
            name={'ssn1'}
            OnChange={(e) => handleChange(e)}
            OnClick={OnClick}
            Onblur={Onblur}
            value={value1}
            disabled={disabled}
            maxlength={3}
          />
        </Grid>
        <Grid item md={3} className={clsx(ownstyles.inputsecond)}>
          <RacTextbox
            type="password"
            id={'a11y_ssntwo'}
            className={clsx(ownstyles.FieldAlign)}
            name={'ssn2'}
            OnChange={(e) => handleChange(e)}
            OnClick={OnClick}
            Onblur={Onblur}
            value={value2}
            disabled={disabled}
            maxlength={2}
          />
        </Grid>
        <Grid item md={4} className={clsx(ownstyles.inputthird)}>
          <RacTextbox
            type="text"
            id={'a11y_ssnthree'}
            className={clsx(ownstyles.FieldAlign)}
            name={'ssn3'}
            OnChange={(e) => handleChange(e)}
            OnClick={OnClick}
            Onblur={Onblur}
            value={value3}
            disabled={disabled}
            maxlength={4}
          />
        </Grid>
        <Grid container md={12}>
          {errorMessage && <FieldError errorMessage={errorMessage} />}
        </Grid>
      </Grid>
    </div>
  );
}
