import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { GRAY } from '../../colors/colors';
import { BreadcrumbSeparator } from './BreadcrumbSeparator';

export interface BreadcrumbLinkType {
  href?: string;
  label: string;
}

export interface BreadcrumbProps {
  links?: BreadcrumbLinkType[];
  onClick?: (event: any, href: string) => void;
}

const useStyles = makeStyles(() => ({
  grayText: {
    color: `${GRAY} !important`,
  },
}));

export const Breadcrumb = ({ links, onClick }: BreadcrumbProps) => {
  const ownClasses = useStyles();
  if (onClick && typeof onClick === 'function') {
    return (
      <MuiBreadcrumbs separator={<BreadcrumbSeparator />}>
        {links.map(({ href, label }, index) => (
          <Link
            key={`${index}-${href}`}
            color="inherit"
            component="button"
            onClick={(event) => onClick(event, href)}
          >
            <Typography
              className={
                (index !== links.length - 1 || !href) &&
                clsx(ownClasses.grayText)
              }
              color="primary"
              variant="body1"
            >
              {label}
            </Typography>
          </Link>
        ))}
      </MuiBreadcrumbs>
    );
  }
  return (
    <MuiBreadcrumbs separator={<BreadcrumbSeparator />}>
      {links.map(({ href, label }, index) => (
        <Link key={`${index}-${href}`} color="inherit" href={href}>
          <Typography
            className={
              (index !== links.length - 1 || !href) && clsx(ownClasses.grayText)
            }
            color="primary"
            variant="body1"
          >
            {label}
          </Typography>
        </Link>
      ))}
    </MuiBreadcrumbs>
  );
};
