/* eslint-disable */
import React, {useState, createContext, useEffect, useContext} from 'react'
import { getEmployeeInfoAndReferenceData, validateApprover } from '../../shared/getEmployeeInfoAndReferenceData';
import { insertZoneValue, packageDetails, postFilterValue } from '../../../api/user';
import {PackageFilterPayloadObjInt, ManagePackagePayloadObjInt} from '../../shared/interface/Request'
import { ContainerContext } from "../../../app/App";
/* import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons'; */
import {
  RACSelect,
  Typography,
  RACButton,
  Grid,
  CardContent,
  Card,
  RACCheckBox,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTextbox,
  RACModalCard,
  RACDatePicker,
  CircularProgress,
} from "@rentacenter/racstrap";
import {ProgressBar} from "../Styles/ProgressStyles";

/* import { packageNameApi, packageFilterDetail } from '../../../api/user'; */

export const packageContext = createContext<any>('');


export default function PackageContext(props : any){
    const containerData = useContext(ContainerContext);
    const progressclasses = ProgressBar();
// State variables and setters
const [pageCount, setPageCount] = useState<number>(1);
const [totRecord, setTotRecord] = useState<any>();
const [pginitial, setpginitial] = useState<any>(0);
const [noRecordsFound, setNoRecordsFound] = useState(false);
const [filterPopup, setFilterPopup] = useState(true);
const [errorMsgPackage, setErrorMsgPackage] = useState("");
const [gridErrorPopup, setGridErrorPopup] = useState(false);
const [submitPopup, setSubmitPopup] = useState(false);
const [updatePackagePopup, setUpdatePackagePopup] = useState(false);
const [newPackagePopup, setNewPackagePopup] = useState(false);
const [pricingQueueId, setPricingQueueId] = useState('');
const [disableFlag, setDisableFlag] = useState(false);// For disabling the field in the form
const [enableGrid, setEnableGrid] = useState(true);// to enable the grid after the apply click.// set to true but should be false by defaul
const [loader, setLoader] = useState(false);
const [franchisee, setFranchisee] = useState();
const [zoneDd, setZoneDd] = useState<any>([]);
const [zoneSelected, setZoneSelected] = useState([]);
const [zoneSelectedForm, setZoneSelectedForm] = useState([])
const [departmentDd, setDepartmentDd] = useState([{label:'Select',
value:'0'}
]);
const [subDepartmentDd, setSubDepartmentDd] = useState([{
  label:'Select',
  value:'0',
}]);
const [bracketDd, setBracketDd] = useState<any>([{
  label:'Select',
  value:'0',
}]);
const [packageNameDd, setPackageNameDd] = useState<any>([{
  label:'Select',
  value:'0',
}]);
const [zoneDropdown, setZoneDropdown] = useState();
const [rMSObj, setRMSObj] = useState();
const [dropdownLoader, setDropDownLoader] = useState(true);
const [masterLoader, setMasterLoader] = useState(true)
const [formRecords, setFormRecords] = useState<any>({});  //for storing the single edit records after clicking the edit icon in the grid.
const [backupRecords, setBackupRecords] = useState<any>({}); //for retain the value if the edited value in the criteria is revoked by clicking the close icon. 
const [isPackageFormOpen, setIsPackageFormOpen] = useState(false)
const [count, setCount] = useState(0)
const [originalSubDeptArr,setOriginalSubDeptArr] = useState<any[]>([])
const [originalBracketArr, setOriginalBracketArr] = useState<any[]>([])
const [companyCode, setCompanyCode] = useState<any>("")
const [submittedBy, setSubmittedBy] = useState<any>("")
const [submitState, setSubmitState] = useState("")
const [duplicateDepartmentDropdown, setDuplicateDepartmentDropdown] = useState<[]>([])
const [duplicateSubdepartmentDropdown, setDuplicateSubdepartmentDropdown] = useState<[]>([])
const [emailId,setEmailId]= useState();
  const [employeeId, setEmployeeId]= useState();
  const [isApprover, setIsApprover]= useState(false);
  const[authOpen, setAuthOpen] = useState(false);
  const [isSLACompleted, setIsSLACompleted]= useState(false);
  const [isSLACancel,setIsSLACancel] = useState(false);
  const [isApprovalOpen, setIsApprovalOpen] = useState(false);
  const [sucessPopup, setSuccessPopup] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)
  const [submitButtonStatus, setSubmitButtonStatus] = useState(true);
  const [ApprovedBy, setApprovedBy] = useState();
  const [selectQueueType, setSelectQueueType] = useState<string>("");
  const [editRecordsArray, setEditRecordsArray] = useState<any[]>([])
  const [companyCodeZone, setCompanyCodeZone] = useState<any>()
  const [startDateCheck, setStartDateCheck] = useState<any>("")
  //Cancel click
const handleCancelClick = async () => {
  debugger
  setStartDateCheck("")
  setSelectQueueType("")
  setIsPackageFormOpen(false)
  setCount(0)
  setZoneSelectedForm([])
  console.log("checking cancel click formatData", formatData)
  setFormRecords({...formatData}) 
  setSelectQueueType("")
  setEditRecordsArray([])
}


 //submit click
  const submitClick = async () => {
    try {
        setMasterLoader(true);
        console.log(formRecords,"The keys");
        
        const allowedKeys = ['zone','packageName','discountType','discountPct','discountAmt','startDate','endDate'];
        let packageReqPayload = JSON.parse(JSON.stringify(formRecords));
        Object.keys(packageReqPayload).forEach((item: any)=>{
          if(!(allowedKeys.includes(item)) || ((allowedKeys.includes(item)) && !(packageReqPayload[item]))){
            delete packageReqPayload[item]
          }
        })
        console.log("packageReqPayload for the package to check if the discountAmt is coming or not", packageReqPayload)
        packageReqPayload['zone'] = formRecords?.zone?.length != 0 ? formRecords?.zone : undefined;
        packageReqPayload['packageName'] = (formRecords?.packageName != "0" || formRecords?.packageName != "") ? formRecords?.packageName: undefined;
        packageReqPayload['discountType'] = (formRecords?.discountType != "" ? formRecords?.discountType : undefined);
        packageReqPayload["discountPct"] = (formRecords?.discountType == "P" ? formRecords?.discountPct : undefined);
        packageReqPayload["discountAmt"] = (formRecords?.discountType == "D" ? formRecords?.discountAmount: undefined);
        packageReqPayload["startDate"] = (formRecords?.startDate != "" ? formRecords?.startDate : undefined);
        packageReqPayload["endDate"] = (formRecords?.endDate != "" ? formRecords?.endDate : undefined);
        packageReqPayload['packageCriteria'] = formRecords?.criteria?.map((item : any ) => {
          // Create a new object with the modified key
          return {
            departmentNumber: item.rmsDepartmentNumber != "0" ? item.rmsDepartmentNumber: undefined,
            subdepartmentNumber: item.rmsSubdepartmentNumber !="0" ? item.rmsSubdepartmentNumber : undefined,
            bracketNumber: item.bracketNumber != "0" ? item.bracketNumber: undefined,
            rmsItemNumber: item.rmsItemNumber ? item.rmsItemNumber : undefined,
            minQuantity: item.minQuantity ? item.minQuantity : undefined,
            maxQuantity: item.maxQuantity ? item.maxQuantity: undefined 
          };

      });
        let req = {
          schedule: selectQueueType,
          status: "WAITING",
          submittedBy: submittedBy,
          type: "PACKAGE",
          companyCode: companyCode ? companyCode: undefined,
          queueData: {
            packageDetails: packageReqPayload
          }
        }
        const response = await insertZoneValue(req);
        console.log("response for addPricingQueue", response)
        if(response.status==200){
          console.log("responseFromInsertZoneValue", response)
          
          setPricingQueueId(response?.data?.pricingQueueId)
          setSubmitPopup(true);
          setUpdatePackagePopup(false);
          /* setIsSubmitButtonClicked(false); */
          /* newStoreZoneClear(); */
        
          /* if(req.queueData.packageDetails.zoneNumber){
            const rms = RMSObj.filter(
              (value: any, index: any) => {
                RMSObj.include(updtPopupData.zoneNumber);
              }
            );
            console.log("checking if the rms value is assigned", rms)
            setRmsAffected(rms); 
          }*/
        } 
        else if(response.status==400){
          setErrorMsgPackage(response.data.errors[0].error);
          setGridErrorPopup(true);
          /* setIsSubmitButtonClicked(false); */
        }  
        else {
          setErrorMsgPackage("Something Went Wrong");
          setGridErrorPopup(true);
          /* setIsSubmitButtonClicked(false); */
        }
        /* setIsSubmitButtonClicked(false); */
    } catch (error: any) {
      setErrorMsgPackage("Something Went Wrong");
          setGridErrorPopup(true);
          /* setIsSubmitButtonClicked(false); */
    }
  };
  const handleEditSubmitValidation = (formRecordsArr: any) => {
    if(newPackagePopup == true){
      if (formRecordsArr.hasOwnProperty('discountAmount')) {
        if(formRecordsArr.discountPct != "" || formRecordsArr.discountAmount != ""){
          return true
        }else{
          return false
        }
    } else {
      if(formRecordsArr.discountPct != "" || formRecordsArr.discountAmt != ""){
        return true
      }else{
        return false
      }
    }
    }else{
      if(formRecordsArr.discountPercent == null){
        if(formRecordsArr.discountAmount != ""){
          return true
        }
      }else if(formRecordsArr.discountAmount == null){
        if(formRecordsArr.discountPct != ""){
          return true
        }
      }
    }
  return false
  }



  const handleSubmitButtonValidation = () => {
    debugger
    let criteriaArr = JSON.parse(JSON.stringify(formRecords?.criteria))
    let buttonStatus2 = true
    let formRecordsArr = JSON.parse(JSON.stringify(formRecords))
    console.log("formRecordsArr for checking the validation for the criteria", criteriaArr)
    console.log("formRecordsArr for checking the validation", formRecordsArr)
    let buttonStatus = true
    
    console.log("onclicked formRecords.zone for the validation" , formRecords.zone)
    console.log("onclicked formRecords.zone for the validation", formRecords.packageName)
    console.log("formRecords.discountPct zone for the validation", formRecords.discountPct)
    console.log("formRecords.discountAmt zone for the validation", formRecords.discountAmt)
    let buttonStatus3
    if(newPackagePopup == true ){
      if(newPackagePopup && count == 0){
        if(criteriaArr?.length > 1 ){
          buttonStatus3 = true
        }else{
          buttonStatus3 = false
        }
      }
      else{
        if(criteriaArr?.length > 0){
          buttonStatus3 = true
        }else{
          buttonStatus3 = false
        }
      }
    }else{
      if(criteriaArr?.length > 0){
        buttonStatus3 = true
      }else{
        buttonStatus3 = false
      }
    }

    let resultSubmitEdit = handleEditSubmitValidation(formRecordsArr)
    if(formRecordsArr.zone.length != 0 && formRecordsArr.packageName != "" && formRecordsArr.discountType != ""  && resultSubmitEdit  && formRecordsArr.startDate != "" && formRecordsArr.endDate != "" && selectQueueType != "" && buttonStatus3){
      buttonStatus = false
    }else{
      buttonStatus = true
    }
    return buttonStatus 
    console.log("criteriaArr for the", criteriaArr)
     

    /* for(let i= 0; i < criteriaArr.length ; i++){
      console.log("checking if the values how many times the for loop is running")
      if(criteriaArr[i].rmsDepartmentNumber != "0" && criteriaArr[i].minQuantity != "" && criteriaArr[i].maxQuantity != ""){
        setSubmitButtonStatus(false)
      }else{
        setSubmitButtonStatus(true)
      }
      
    if(buttonStatus == false && buttonStatus2 == false){
      return true  
    }else{
      return false
    } 
  } */
}
// function for sorting the array
const sortDropDown = (value: any) => {
  return value.slice().sort((a: any, b: any) => a.label.localeCompare(b.label));
}
//sort direction
const sortDirection = {
    ASC: "asc",
    DESC: "desc",
  };
  const criteriaFirstRecordObj : any = {
    rmsDepartmentNumber: "0",
    rmsSubdepartmentNumber: "0",
    departmentDesc: "", 
    subdepartmentDesc: "", 
    bracketName: "",
    minQuantity: "",
    bracketNumber: "0", 
    maxQuantity: "",
    duplicateSubdepartmentDd: [],
    duplicateDepartmentDd:[],
    duplicateBracketDd:[],
    rmsItemNumber: null}
  const [criteriaFirstRecord, setCriteriaFirstRecord] = useState(criteriaFirstRecordObj)

  const formatData = {
    
      zone: [],
      packageName: '',
      discountType: '',
      discountPct: '',
      discountAmt: '',
      startDate: '',
      endDate: '2099-12-31',
      criteria: [
        {
          rmsDepartmentNumber: '0',
          rmsSubdepartmentNumber: '0',
          bracketNumber: '0',
          rmsItemNumber: null,
          minQuantity: '',
          maxQuantity: '',
          duplicateDepartmentDd: [],
          duplicateSubdepartmentDd: [],
          duplicateBracketDd: []
        }
      ]
    
  };
  
  
//table head
let tableColumns = [
    {
      columnTitle: "Zone",
      sortOrder: sortDirection.ASC,
      columnId: "zoneNumber",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Package Name",
      sortOrder: sortDirection.DESC,
      columnId: "packageName",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Discount Type",
      sortOrder: sortDirection.DESC,
      columnId: "discountType",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Discount Percent",
      sortOrder: sortDirection.ASC,
      columnId: "discountPercent",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Discount Amount",
      sortOrder: sortDirection.ASC,
      columnId: "discountAmount",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Start Date",
      sortOrder: sortDirection.ASC,
      columnId: "startDate",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "End Date",
      sortOrder: sortDirection.ASC,
      columnId: "endDate",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Action",
      sortOrder: sortDirection.ASC,
      columnId: "action",
      IsSorted: false,
      IsSortable: false,
    },
  ];
//table head and table data:
const [tableHeader, setTableHeader] = useState(tableColumns);
const [tableRecord, setTableRecord] = useState<any>([]);

//For static discount type dropdown
const discountType = [
  { value: "", label: "All" },// need to ask this "sai" what should be the value for the discount type. if u want to change the payload to all then assign the initial state of the filterPackagePaylodObjobject to all 
  { value: "D", label: "$" },
  { value: "P", label: "%" }
];

const discountTypeNew = [
  { value: "" , label: "Select" },  
{ value: "D" , label: "$" },
{ value: "P", label: "%" }] 

// Object definition and state variable
const updateObject = {
  zoneNumber: '',
  packageName: '',
  discountType: '',
  discountPercent: '',
  discountAmount: '',
  startDate: '',
  endDate: ''
};
const [updatePackageData, setUpdatePackageData] = useState(updateObject);

// Payload object definition and state variables
const managePackagePayloadObj: ManagePackagePayloadObjInt = {
  schedule: '',
  submittedBy: '',
  status: '',
  companyCode: '',
  type: '',
  queueData: {
    packageDetails: {
      zone: [],
      packageName: '',
      discountType: '',
      discountPct: '',
      discountAmt: '',
      startDate: '',
      endDate: '',
      packageCriteria: [{
        departmentNumber: '',
        subdepartmentNumber: '',
        bracketNumber: '',
        rmsItemNumber: '',
        minQuantity: '',
        maxQuantity: ''
      }]
    }
  }
};
const [manageNewPackagePayload, setManageNewPackagePayload] = useState(managePackagePayloadObj);
const [manageUpdatePackagePayload, setManageUpdatePackagePayload] = useState(managePackagePayloadObj);

// Filter payload object definition and state variable
const packageFilterPayloadObj: PackageFilterPayloadObjInt= {
  zoneNumber: [],
  companyCode: [],
  departmentNumber: '0',
  subdepartmentNumber: '0',
  bracketNumber: '0',
  brandName: '',
  modelNumber: '',
  rmsItemNumber: '',
  packageName: '0',
  discountType: '',
  limit: '25',
  offset: '1'
};
const [filterPackagePayload, setFilterPackagePayload] = useState(packageFilterPayloadObj);
// payload for getting the dropdown
const refObj={
    type:["ZONE","RMSITEM_COUNT", "DEPARTMENT", "SUBDEPARTMENT", "PACKAGE", "BRACKET"]
  }

//Fetching the grid data.

const applyFilter= async (params: any = '') =>{
  setTotRecord(0);
    setEnableGrid(true);
    setLoader(true);
    setNoRecordsFound(false);
    
    /* console.log("filter value for the orphan storezone", filterValueObj) */
  try{
  let copyFilterValue = JSON.parse(JSON.stringify(filterPackagePayload));
  copyFilterValue.zoneNumber = filterPackagePayload.zoneNumber;
  copyFilterValue.departmentNumber = filterPackagePayload.departmentNumber != '0' ? filterPackagePayload.departmentNumber : undefined; 
  copyFilterValue.subdepartmentNumber = filterPackagePayload.subdepartmentNumber != '0' ? filterPackagePayload.subdepartmentNumber : undefined;
  copyFilterValue.bracketNumber = filterPackagePayload.bracketNumber != '0' ? filterPackagePayload.bracketNumber : undefined;
  copyFilterValue.brandName = filterPackagePayload.brandName != '' ? filterPackagePayload.brandName : undefined;
  copyFilterValue.companyCode = franchisee ? filterPackagePayload.companyCode?.length ? filterPackagePayload.companyCode : undefined : undefined ; //NEED TO REMOVE THE COMMENTS
  copyFilterValue.modelNumber = filterPackagePayload.modelNumber != '' ? filterPackagePayload.modelNumber : undefined;
  copyFilterValue.rmsItemNumber = filterPackagePayload.rmsItemNumber != '' ? filterPackagePayload.rmsItemNumber : undefined;
  copyFilterValue.packageName = filterPackagePayload.packageName != '0' ? filterPackagePayload.packageName : undefined;
  copyFilterValue.discountType = filterPackagePayload.discountType != '' ? filterPackagePayload.discountType : undefined;// need to ask how to send the payload.
/* copyFilterValue = {zoneNumber: ["3000"], orphanStore: "N", offset: "1", limit: "25"} */
  
  if(params == "A"){
    copyFilterValue.offset = "1";
    setpginitial(0)
  }

  console.log("the value of copy", copyFilterValue)
  const response:any = await packageDetails(copyFilterValue);
  console.log(response, "The rep");
  if (response.status == 200) {
    debugger
    setEnableGrid(true) 
      setTableRecord(response.data.packageDetails);
      setPageCount(Math.ceil(response.data.totalRecordsCount / 25));
      setTotRecord(response.data.totalRecordsCount);
      setNoRecordsFound(false);
      setFilterPopup(false)

    if(response.data == ""){
      setTableRecord([]);
      setEnableGrid(true);
      setNoRecordsFound(true);
    }
  } 
  else if(response.status == 400){
    setErrorMsgPackage('Something Went Wrong');
    setGridErrorPopup(true);
  }
  else {
    setErrorMsgPackage("Something Went Wrong");
    setGridErrorPopup(true);
    setNoRecordsFound(false);
  }
  setLoader(false)
} 
catch(err){
  setErrorMsgPackage("Something Went Wrong");
  setGridErrorPopup(true);
}
}

 
useEffect(() => {

    bindFilterValue();
    setMasterLoader(false);
    setEnableGrid(false)
    // setErrorPopup(true);
  }, []);

  //calling the useEffect function and getEmployeeInfoAndReferenceData for getting all the dropdowns 
  async function bindFilterValue() {
    setLoader(true)
    try {
      const response : any  = await getEmployeeInfoAndReferenceData(containerData, refObj);
      // const response: any = await Promise.all([getEmployeeInfoAndReferenceData(containerData, refObj)/* ,getFilterData(refObj)] */]) ;
      console.log("response for getEmployeeInfoAndReferenceData", response)
      console.log(response.dropdownData, "response getEmployeeInfoAndReferenceData");
      console.log(response.dropdownData.zone[0].zoneName, "response getEmployeeInfoAndReferenceData");
      if (response.isFranchiseeUser) {
        setFranchisee(response?.isFranchiseeUser);

          if(response.companyCode?.length>0)
          setCompanyCodeZone(response.companyCode);
        else{
          setErrorMsgPackage('Something Went Wrong');
          setGridErrorPopup(true);
          setCompanyCodeZone(response.companyCode);
      }
      }
      setFranchisee(response?.isFranchiseeUser);
      console.log("response.dropdownData.zone", response.dropdownData.zone);
      setZoneDropdown(response.dropdownData.zone);// dont know where to use this zoneDropdown.
       
      console.log("zoneDropDown", zoneDd)
     
      
      if (response.status == 200) {
        setDropDownLoader(false)
        
        console.log(response,'validateApprover',validateApprover(response.menuConfigArray));
        setEmployeeId(response.employeeId);
        setEmailId(response.emailId);
        
        setIsApprover(validateApprover(response.menuConfigArray))
        setSubmittedBy(response.userName)
        console.log("insertNewZoneObj for checking the userName", managePackagePayloadObj)
        let arr : any = [];
      arr = response?.zone;
      arr.unshift({
        label:'Select',
        value:'0',
      })
      
      let sortedZone = response?.zone.sort((a: any, b: any) =>
        a["label"].localeCompare(b["label"], undefined, { numeric: 'true' }));
        let removedSelectFromZone = sortedZone.filter((item: any) => {return item.label != "Select"})
        setZoneDd(removedSelectFromZone)
      //For Department dropdown  
      let departmentDdArr = response.dropdownData.department.map((item : any)=> {
          return ({
            label : item.descEn,
            value : item.departmentNumber
        })
        })
        console.log("departmentDdArr", departmentDdArr)
        const sortedDepartmentArray = sortDropDown(departmentDdArr)
        let tempdepartmentDdArr : any = [];
        tempdepartmentDdArr = sortedDepartmentArray
        tempdepartmentDdArr.unshift({
          label:'Select',
          value:'0',
        })
        setDepartmentDd(tempdepartmentDdArr);
        //For subDepartment
        let subDepartmentArr = response.dropdownData.subdepartment.map((item : any)=> {
          return ({
            label: item.descEn,
            deptId: item.departmentNumber,
            value: item.subdepartmentNumber,
          })
        })
        console.log("subDepartmentArr", subDepartmentArr);
        
        const sortedSubDepartmentArray = sortDropDown(subDepartmentArr)
        let tempsubDepartmentArr : any = [];
          tempsubDepartmentArr = sortedSubDepartmentArray;
          tempsubDepartmentArr.unshift({
            label:'Select',
            value:'0',
          })
        setSubDepartmentDd(tempsubDepartmentArr);
        setOriginalSubDeptArr(JSON.parse(JSON.stringify(tempsubDepartmentArr)))
        
        //For Bracket
        let bracketArr = response.dropdownData.bracket.map((item : any, index: any)=> {
            return ({ 
              label: item.descEn,
            subdeptId: item.subdepartmentNumber,
            value: item.bracketNumber,
          })
          })
          console.log("bracketArr", bracketArr);
          
          const sortedBracketArray = sortDropDown(bracketArr) 
          let tempBracketArr : any = [];
            tempBracketArr = sortedBracketArray;
         tempBracketArr.unshift({
          label:'Select',
          value:'0',
        })
          setBracketDd(tempBracketArr);
          setOriginalBracketArr(JSON.parse(JSON.stringify(tempBracketArr)))

          //For packageName
        let packageNameArr = response.dropdownData.package.map((item : any)=> {
            return ({
              label : item.packageName,
              value : item.packageName
          })
          })
          console.log("packageNameArr hihihe", packageNameArr)
          const sortedPackageArray = sortDropDown(packageNameArr)
          let tempPackageNameArr : any = [];
            tempPackageNameArr = sortedPackageArray;
            tempPackageNameArr.unshift({
              label:'Select',
              value:'0',
            })

          setPackageNameDd(tempPackageNameArr);

        setRMSObj(response.dropdownData.rmsItemCount);

        setCompanyCode(response.companyCode[0]);
        console.log("response.companyCode.length", response.companyCode.length)
       setFilterPackagePayload({...filterPackagePayload, companyCode: response.companyCode.length >0 ? response.companyCode: undefined})
        setManageNewPackagePayload({...manageNewPackagePayload, companyCode: manageNewPackagePayload.companyCode})
        console.log("insertNewZoneObj.companyCode", manageNewPackagePayload.companyCode)
        console.log("response.dropdownData.rmsItemCount", response.dropdownData.rmsItemCount)
        setLoader(false);
      } 
      else if(response.status==400){
        setErrorMsgPackage(response.data.errors[0].error);
        setGridErrorPopup(true);
      }  
      else {
        setErrorMsgPackage("Something Went Wrong");
        setGridErrorPopup(true);
      }

      
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  }
{console.log("zoneDd", zoneDd)}
    return(
      <>
        <packageContext.Provider value={{noRecordsFound, setNoRecordsFound, 
            filterPopup, setFilterPopup, 
            disableFlag, setDisableFlag, 
            enableGrid, setEnableGrid,
            pageCount, setPageCount,
            totRecord, setTotRecord,
            pginitial, setpginitial,
            loader, setLoader,
            tableRecord, setTableRecord,
            errorMsgPackage, setErrorMsgPackage,
            gridErrorPopup, setGridErrorPopup,
            submitPopup, setSubmitPopup,
            updatePackagePopup, setUpdatePackagePopup,
            newPackagePopup, setNewPackagePopup,
            pricingQueueId, setPricingQueueId,
            updateObject,
            updatePackageData, setUpdatePackageData,
            managePackagePayloadObj, packageFilterPayloadObj, 
            manageNewPackagePayload, setManageNewPackagePayload,
            manageUpdatePackagePayload, setManageUpdatePackagePayload , applyFilter,
            filterPackagePayload, setFilterPackagePayload, franchisee, setFranchisee,
            zoneDd, setZoneDd,
            departmentDd, setDepartmentDd,
            subDepartmentDd, setSubDepartmentDd,
            bracketDd, setBracketDd,
            packageNameDd, setPackageNameDd,
            zoneDropdown, setZoneDropdown, tableHeader, setTableHeader, sortDirection, dropdownLoader,
            formRecords, setFormRecords, 
            backupRecords, setBackupRecords, formatData,criteriaFirstRecord, setCriteriaFirstRecord,criteriaFirstRecordObj,
            isPackageFormOpen, setIsPackageFormOpen, count, setCount, originalSubDeptArr, setOriginalSubDeptArr, originalBracketArr, setOriginalBracketArr,
            zoneSelected, setZoneSelected, zoneSelectedForm, setZoneSelectedForm, discountType, discountTypeNew, companyCode, setCompanyCode, submittedBy, setSubmittedBy, submitState, setSubmitState, duplicateDepartmentDropdown, setDuplicateDepartmentDropdown, 
            duplicateSubdepartmentDropdown, setDuplicateSubdepartmentDropdown, emailId,setEmailId, employeeId, setEmployeeId, isApprover, setIsApprover, authOpen, setAuthOpen, isSLACompleted, setIsSLACompleted, isSLACancel,setIsSLACancel, isApprovalOpen, setIsApprovalOpen, sucessPopup, setSuccessPopup, 
            masterLoader, setMasterLoader, isSubmitButtonClicked, setIsSubmitButtonClicked, submitClick, submitButtonStatus, setSubmitButtonStatus, handleSubmitButtonValidation, handleCancelClick, ApprovedBy, setApprovedBy, selectQueueType, setSelectQueueType, editRecordsArray, setEditRecordsArray, startDateCheck, setStartDateCheck
        }}>
            {props.children}
        </packageContext.Provider>

{masterLoader === true ? (
  <Grid
    className={progressclasses.masterLoader}
  >
    <Grid
     className={progressclasses.Loader}
    >
      <CircularProgress />
    </Grid>
  </Grid>
) : null }
    </>
    )
    
}

