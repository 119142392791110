/* eslint-disable */
import {
  Typography,
  Grid,
  RACButton,
  List,
  ListItem,
  Card,
  CardContent,
  RACTextbox,
  RACTable,
  RACTableRow,
  RACModalCard,
  CircularProgress,
  RACSelect,
  RACTableCell,
} from "@rentacenter/racstrap";
import React, { createContext, useContext, useState, useEffect } from "react";
import { ReactComponent as EditIcon } from "../../../assets/images/Edit Icon.svg";
import { addPricingQueueAPI } from "../../../api/user";
import { ProgressBar } from "../Styles/ProgressStyles";
import { ReactComponent as SortdownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../../assets/images/sort-icon-up.svg";
import { globalStyles } from "../Styles/globalstyles";
import { PricingReportStyle } from "../Styles/PricingReport";
import { ReactComponent as NorecordsIcon } from "../../../assets/images/No-records.svg";
import ReactPaginate from "react-paginate";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";

import { RangeObject, autoTermContext } from "../autoTerm/AutoTermContext";
import AutoTermFilter from "../autoTerm/AutoTermFilter";
import { AutoTermForm } from "./AutoTermForm";
import { ReactComponent as WarningIcon } from "../../../assets/images/no-records-found.svg";
import moment from "moment";
import TwoFactorAuthentication from "../../shared/TwoStepAuthentication";

export default function AutoTermGrid() {
  const progressclasses = ProgressBar();
  const classes = globalStyles();
  const pricing = PricingReportStyle();

  const {
    setPageNumber,setGridLoader,createNewRange, setAddNewRange,
    fetchAutoTermDetails,gridLoader,filterReqAtApply,
    editRecordsArray,isApprover, setIsApprover,
    loader,setSelectQueueType,
    errorPopup,selectQueueType,setZoneSelected,
    isNewAutoTerm,
    setErrorPopup,employeeId,setEmployeeId,
    emailId,setEmailId,isApproved, setIsApproved,
    tableHeader,
    gridData,approvedBy,setApprovedBy,
    setGridData,
    setTableHeader,
    sortArray,isApprovalOpen,setIsApprovalOpen,
    errorPopupStatement,
    setErrorPopupStatement,
    errorPopupOpen,
    setErrorPopupOpen,
    noRecords,isFilterOpen,
    totalRowCount,setZoneSelectedForm,
    isAutoTermFormOpen,
    setIsAutoTermFormOpen,
    validateAutoTerm,
    displayGrid,authOpen, setAuthOpen,
    pageNumber,
    setFormRecords,backupRecords,
    setBackupRecords,
    setIsNewAutoTerm,
    pgCount,updatePricingQueueStatus,
    formatData,
    formRecords,
    setLoader,
    isTrue,isSLACancel,setIsSLACancel,
    isSLACompleted, setIsSLACompleted,
    
    setIsTrue,
  } = useContext(autoTermContext);
  // Function to handle page click
  const handlePageClick = (event: any) => {
    setPageNumber(event.selected);
    fetchAutoTermDetails(event.selected, filterReqAtApply);
  };

  // Function to handle edit icon click
  const handleEditClick = (rowData: any) => {
    let formatFormData =JSON.parse(JSON.stringify(rowData));
    formatFormData.range.map((item : any)=>{
      item.percentDiscount = String(Number(item.percentDiscount).toFixed(2));
    })
    formatFormData['departmentNumber'] =formatFormData.rmsDepartmentNumber,
    formatFormData['subdepartmentNumber'] = formatFormData.rmsSubdepartmentNumber,
    formatFormData['zone']= [formatFormData.zoneNumber]
    setFormRecords(JSON.parse(JSON.stringify(formatFormData)));
    setBackupRecords(JSON.parse(JSON.stringify(formatFormData)));
    setIsNewAutoTerm(false);
    setIsAutoTermFormOpen(true);
  };

  // Function to handle new button click
  const handleNewClick = () => {
    setFormRecords(formatData);
    setBackupRecords(formatData);
    setIsNewAutoTerm(true);
    setIsAutoTermFormOpen(true);
  };

  // Function to handle cancel button click
  const handleCancelClick = () => {
    setZoneSelectedForm([]);
    setFormRecords({});
    setBackupRecords({});
    setIsNewAutoTerm(false);
    setIsAutoTermFormOpen(false);
    setSelectQueueType("");
    setAddNewRange(createNewRange);

  };
  useEffect(() => {
    debugger;
    if (isSLACancel== false && isSLACompleted == true){
      console.log('The price queue Completed');
      
      
      updatePricingQueueStatus('A')
    }
  }, [isSLACancel, isSLACompleted]);
  const approvedFn= ()=>{
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <SuccessIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Request has been approved.
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setLoader(false);
                setIsApproved(false);
                setIsSLACompleted(false);
                setIsSLACancel(false);
                // updatePricingQueueStatus('A')
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
          
        </Grid>
      </>
    );
  }
  const renderTableHeader = () => {
    return (
      <>
        {tableHeader.map((value: any, index: any) => {
          console.log(tableHeader, "The table heaad", index);

          return (
            <RACTableCell key={index} className={classes.p2}>
              {value.isSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.key}
                  onClick={() => {
                    let rep = sortArray(
                      value.key,
                      index,
                      tableHeader,
                      gridData,
                      value.key == "startDate" || value.key == "endDate"
                        ? "date"
                        : ""
                    );
                    console.log(rep, "The rep of the sortArray");

                    setGridData(rep[0]);
                    setTableHeader(rep[1]);
                  }}
                >
                  {value.heading + "  "}
                  {value.isSorted === true ? (
                    value.sortDirection == "DESC" ? (
                      <SortupIcon className={classes.ml0} />
                    ) : (
                      <SortdownIcon className={classes.ml0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.heading + "  "}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  };
  {
    console.log(isAutoTermFormOpen, "the auto term form open");
  }
  const renderTableBody = () => {
    console.log(gridData, "The grid data");

    return (
      <>
        {gridLoader?<>
          <Grid style={{position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%'}}>
           <Grid className={pricing.textSpaceNorecords}>
             <Grid className={pricing.textcenterImage}>
             <CircularProgress />
             </Grid>
           </Grid>
         </Grid>
        </> :
        noRecords ? (
          <RACTableRow className={`${classes.bgTransparent} `}>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <Grid>
              <Grid
                style={{ position: "absolute",top: '50%',
                transform: 'translate(-50%, -50%)',
                left: '50%' }}
                className={pricing.textSpaceNorecords}
              >
                <Grid className={pricing.textcenterNorecords}>
                  <NorecordsIcon className={pricing.racErrorIcon} />
                </Grid>
                <Grid className={pricing.textcenterImage}>
                  No Records Found !
                </Grid>
              </Grid>
            </Grid>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
          </RACTableRow>
        ) : (
          gridData?.map((item: any, index: any) => {
            let headersArr = [
              "zoneNumber",
              "departmentDesc",
              "subdepartmentDesc",
              "bracketName",
              "brandName",
              "modelNumber",
              "rmsItemNumber",
              "termConfigName",
              "startDate",
              "endDate" /*,'action' */,
            ];
            console.log(item, "The log in the log");

            return (
              <>
                <RACTableRow key={index}>
                  {headersArr.map((iterator: any) => {
                    return (
                      <>
                        <RACTableCell title={item[iterator] ? item[iterator].length > 20? item[iterator] : '': ''}>
                          {item[iterator] ? iterator== 'startDate' || iterator=='endDate'  ? moment(item[iterator]).format("MM/DD/YYYY") : item[iterator].length > 20? item[iterator].slice(0,17) + '...' : item[iterator] : "-"}
                        </RACTableCell>
                      </>
                    );
                  })}
                  <RACTableCell>
                    <EditIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleEditClick(item);
                      }}
                    />
                  </RACTableCell>
                </RACTableRow>
              </>
            );
          })
        )}
        {}
      </>
    );
  };
  {
    console.log(formRecords, "The form records");
  }
  const someThingWentWrongFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5% 12%",
              }}
            > 
              {errorPopup.statement}
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                // On getting something went wrong to pricing page refreshed to the initial page
                setErrorPopup({
                  isOpen: false,
                });
                setLoader(false)
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  {
   console.log( pageNumber, pgCount, "the vjkdnv", formRecords?.range?.every(
    (item: RangeObject) =>
     ( item.startDay &&
      item.endDay &&
      item.percentDiscount ? true : false )
  ),"the lon",(formRecords.zone?.length ==1 && formRecords.departmentNumber && formRecords.startDate && editRecordsArray?.length ==0),'The log og pg count and number',
   (formRecords.zone?.length ==1 && formRecords.departmentNumber && formRecords.startDate && editRecordsArray?.length ==0) &&
   formRecords.range?.every(
     (item: RangeObject) =>
      ( item.startDay &&
       item.endDay &&
       item.percentDiscount ? true : false )
   )
   )
  }
  const approvalPopup = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ textAlign: "center", marginBottom: "2%" }}
            >
              Request has been submitted.
            </Typography>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Do you want to continue for approval?
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setIsApprovalOpen(false);
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsApprovalOpen(false);
                setAuthOpen(true);
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  function setSomeThingWentWrng (){
    setErrorPopup({
      isOpen :true,
      statement:'Something went wrong'
    })
  }
  {
    console.log('new records', formRecords , 'backup records ',backupRecords);
    
  }

  return (
    <>
      <>
        {!isAutoTermFormOpen ? (
          <>
            <AutoTermFilter />
            {displayGrid ? (
              <Grid md={12} style={{marginBottom:'4%'}}>
                <Card style={{marginTop: '1%'}}>
                  <CardContent style={{ paddingBottom:'0px',position: 'relative' }}>
                    <Grid className={classes.fixTableHeadAgrTrsRec} style={{  maxHeight: "62vh", minHeight: (gridLoader || noRecords ) && isFilterOpen ?'38vh' : '60vh'}}>
                      <RACTable
                        renderTableHead={renderTableHeader}
                        renderTableContent={renderTableBody}
                        style={{
                          width: "100%",
                          whiteSpace: "nowrap",
                            
                        }}
                      ></RACTable>
                    </Grid>
                    <Grid className={`${classes.mb1} ${classes.textRight}`}>
                      {totalRowCount ? (
                        <>
                          <Grid className={`${pricing.totrec}`}>
                            <Typography>
                              {" "}
                              # of Records:&nbsp;
                              {pageNumber == 0
                                ? totalRowCount < 25
                                  ? totalRowCount
                                  : 25 * 1
                                : (pageNumber + 1) == pgCount
                                ? totalRowCount
                                : (pageNumber + 1) * 25}
                              /{totalRowCount}
                            </Typography>
                          </Grid>
                          <ReactPaginate
                            nextLabel=">"
                            onPageChange={(num: any) => {
                              handlePageClick(num);
                            }}
                            disableInitialCallback={true}
                            pageCount={pgCount}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={0}
                            previousLabel="<"
                            breakLabel=""
                            initialPage={pageNumber}//3
                            pageClassName={pricing.pagination}
                            pageLinkClassName={pricing.pagelink}
                            previousLinkClassName={
                              pageNumber === 0
                                ? pricing.paginationprevnextdisable
                                : pricing.paginationprevnext
                            }
                            nextLinkClassName={
                              pageNumber === pgCount - 1
                                ? pricing.paginationprevnextdisable
                                : pricing.paginationprevnext
                            }
                            breakClassName={pricing.pagination}
                            breakLinkClassName={pricing.pagination}
                            containerClassName={pricing.pagination}
                            activeClassName={pricing.pageactive}
                            forcePage={pageNumber === 0 ? 0 : undefined} //new logic
                          />
                        </>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            
          </>
        ) : (
          <AutoTermForm />
        )}
        {loader ? (
              <>
                <Grid className={progressclasses.masterLoader}>
                  <Grid className={progressclasses.Loader}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </>
            ) : null}
        <Card
          style={{
            position: "fixed",
            bottom: "0px",
            width: "100%",
            zIndex: "1",
            left: "0",
          }}
        >
          <CardContent style={{padding: '5px 10px 10px 0px'}}>
            <Grid container>
              {!isAutoTermFormOpen ? (
                <Grid
                  md={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  style={{ float: "right" }}
                  spacing={3}
                >
                  <RACButton
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ float: "right" }}
                    onClick={() => {
                      handleNewClick();
                    }}
                  >
                    New Autoterm
                  </RACButton>
                </Grid>
              ) : (
                <Grid
                  md={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  style={{ float: "right" }}
                  spacing={3}
                >
                  <RACButton
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ float: "right" }}
                    disabled={
                       ( (formRecords.zone?.length >0 && formRecords.departmentNumber && formRecords.termConfigName && (formRecords.startDate!= backupRecords.startDate && formRecords.startDate!='')  && editRecordsArray.length ==0) && selectQueueType &&
                      formRecords?.range?.every(
                        (item: RangeObject) =>
                         ( item.startDay &&
                          item.endDay &&
                          item.percentDiscount ? true : false )
                      ))
                        ? false
                        : true
                    }
                    onClick={() => {
                      console.log(
                        formRecords,
                        "ln 429 the submit button click ",
                        editRecordsArray
                      );
                      validateAutoTerm();
                    }}
                  >
                    Submit
                  </RACButton>
                  <RACButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ float: "right", marginRight: "1%" }}
                    onClick={() => {
                      handleCancelClick();
                    }}
                  >
                    cancel
                  </RACButton>
                </Grid>
              )}
            </Grid>
            <Grid style={{ float: "right" }}></Grid>
          </CardContent>
        </Card>
      </>

      <RACModalCard
        isOpen={errorPopup.isOpen}
        maxWidth="xs"
        borderRadius={10}
        // closeIcon={true}
        onClose={() => {
          errorPopup.isOpen = false;
          errorPopup.statement = "";
        }}
      >
        {someThingWentWrongFn()}
      </RACModalCard>
      <RACModalCard
        isOpen={isApprovalOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setIsApprovalOpen(false)}
        borderRadius={10}
      >
        {approvalPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={isApproved}
        maxWidth="xs"
        borderRadius={10}
        closeIcon={true}
        onClose={()=>{
          setLoader(false);
          setIsSLACompleted(false);
                setIsSLACancel(false);
          setIsApproved(false)}}
      >
        {approvedFn()}
      </RACModalCard>
      <TwoFactorAuthentication
              IsHigherAuthority={isApprover} isVisible={authOpen} setIsVisible={setAuthOpen} loader={loader}setloader={setLoader} setTwoFactorCompleted={setIsSLACompleted} setTwoFactorCancelClick={setIsSLACancel} twoFactorCompleted={isSLACompleted} twoFactorCancelClick={isSLACancel} setSomethingWentWrong={setSomeThingWentWrng} mailId={emailId} employeeId={employeeId} submitedBy ={sessionStorage.getItem('userName')} ApprovedBy={setApprovedBy}/>
    </>
  );
}
