import React from 'react';
import {
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  FormControlLabel,
  FormControlLabelProps,
  withStyles,
  styled,
} from '@material-ui/core';

import { StyleClasses } from '../StyleClasses';
import { POMPEII_ASH } from '../../colors/colors';
import clsx from 'clsx';

type ClassesKeys = 'root' | 'label' | 'checked' | 'indeterminate';

export interface RadioProps extends MuiRadioProps {
  name?: string;
  id?: string;
  size?: 'small' | 'medium';
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classes?: StyleClasses<ClassesKeys>;
  label?: string;
  value: any;
  IscolorVariant?: boolean;
  variantColor?: any;
  backgroundColor?: any;
  rest?: any;
}

const DISABLED_OPACITY = '0.5';
const StyledRadio = withStyles((theme) => ({
  root: {
    color: POMPEII_ASH,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  disabled: {
    opacity: DISABLED_OPACITY,
  },
}))((props: MuiRadioProps) => (
  <MuiRadio disableRipple {...props} />
)) as typeof MuiRadio;

const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    margin: 0,
  },
  label: {
    color: theme.palette.text.primary,
    minWidth: 'fit-content',
    fontSize: 14,
  },
  disabled: {
    opacity: DISABLED_OPACITY,
  },
}))((props: FormControlLabelProps) => (
  <FormControlLabel {...props} />
)) as typeof FormControlLabel;

export function Radio({
  name,
  id,
  label,
  size,
  classes,
  checked,
  onChange,
  value,
  IscolorVariant,
  variantColor,
  backgroundColor,
  ...rest
}: RadioProps) {
  const RadioIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      IscolorVariant === true
        ? 'inset 0 0 0 0px rgba(16,22,26,.2), inset 0 0px 0 rgba(16,22,26,.1)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  }));
  const RadioCheckedIcon = styled(RadioIcon)({
    backgroundColor: backgroundColor ? backgroundColor : '#0d6efd',
    borderColor: backgroundColor ? backgroundColor : '#0d6efd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        IscolorVariant === true
          ? `radial-gradient(${variantColor},${variantColor} 28%,transparent 32%)`
          : 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: backgroundColor ? backgroundColor : '#0d6efd',
    },
  });
  return (
    <StyledFormControlLabel
      className={clsx(classes?.root)}
      classes={{ label: clsx(classes?.label) }}
      control={
        <StyledRadio
          name={name}
          id={id}
          size={size || 'small'}
          className={clsx(classes?.checked)}
          checked={checked}
          onChange={onChange}
          value={value}
          icon={<RadioIcon />}
          checkedIcon={<RadioCheckedIcon />}
          {...rest}
        />
      }
      label={label}
    />
  );
}
