import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { DatePicker } from '../DatePicker/DatePicker';

export interface DateRangePickerProps {
  name?: string;
  id?: string;
  fromLabel: React.ReactElement | string;
  toLabel: React.ReactElement | string;
  fromValue?: string;
  toValue?: string;
  maxFrom?: string;
  minFrom?: string;
  maxTo?: string;
  minTo?: string;
  onFromChanged?: (value: string) => void;
  onToChanged?: (value: string) => void;
  disabled?: boolean;
  fromErrorMessage?: string;
  toErrorMessage?: string;
}

const useStyles = makeStyles(() => ({
  dateRangePickerRoot: {
    display: 'flex',
    gap: '0.5rem',
  },
}));

export function DateRangePicker({
  name,
  id,
  fromLabel,
  toLabel,
  fromValue,
  toValue,
  maxFrom,
  minFrom,
  maxTo,
  minTo,
  onFromChanged,
  onToChanged,
  disabled,
  fromErrorMessage,
  toErrorMessage,
}: DateRangePickerProps) {
  const ownClasses = useStyles();
  const [maxfrom, setMaxFrom] = useState(maxFrom);
  const [minto, setMinTo] = useState(minTo);

  const handleOnFromChanged = (e) => {
    setMinTo(e);
    onFromChanged(e);
  };

  const handleOnToChanged = (e) => {
    setMaxFrom(e);
    onToChanged(e);
  };

  return (
    <div className={ownClasses.dateRangePickerRoot}>
      <DatePicker
        onChange={handleOnFromChanged}
        value={fromValue}
        label={fromLabel}
        inputProps={{ min: minFrom, max: maxfrom }}
        name={`${id || name}From`}
        disabled={disabled}
        errorMessage={fromErrorMessage}
      />
      <DatePicker
        onChange={handleOnToChanged}
        value={toValue}
        label={toLabel}
        inputProps={{ min: minto, max: maxTo }}
        name={`${id || name}To`}
        disabled={disabled}
        errorMessage={toErrorMessage}
      />
    </div>
  );
}
