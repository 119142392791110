/* eslint-disable */
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { RangeObject, autoTermContext } from "../autoTerm/AutoTermContext";
import { ReactComponent as WarningIcon } from "../../../assets/images/no-records-found.svg";
import {
  Typography,
  RACRadio,
  Grid,
  RACButton,
  RACModalCard,
  List,
  ListItem,
  Card,
  CardContent,
  RACTextbox,
  RACTable,
  RACTableRow,
  CircularProgress,
  RACDatePicker,
  RACSelect,
  RACTableCell,
} from "@rentacenter/racstrap";
import { globalStyles } from "../Styles/globalstyles";
import { MultiSelect } from "react-multi-select-component";
import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up-icon.svg";
import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as AddRow } from "../../../assets/images/action-add-row.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/Edit Icon.svg";
import { ReactComponent as DeleteDanger } from "../../../assets/images/delete-danger.svg";
import { ReactComponent as Danger } from "../../../assets/images/danger.svg";
import { ReactComponent as EditCheck } from "../../../assets/images/edit-update-check.svg";
import { ReactComponent as EditCross } from "../../../assets/images/edit-notupdate-cross.svg";
import moment from "moment";
import { PricingReportStyle } from "../Styles/PricingReport";

export function AutoTermForm(props: any) {
  const Classes = globalStyles();
  const {
    formRecords,
    setFormRecords,addnewRange, setAddNewRange,createNewRange,
    backupRecords,bracketOptionsCopy,setBracketOptionsCopy,
    bracketOptions, setBracketOptions,
    sortArray,
    zoneOptions,isFranchiseeUser, setIsFranchiseeUser, companyCode,
    setZoneOptions,
    isAutoTermFormOpen,
    setSubDepartmentOptions,zoneSelectedForm,setZoneSelectedForm,
    originalSubDeptArr,
    isNewAutoTerm,
    validateInput,
    rangeHeader,
    setRangeHeader,zoneSelected, setZoneSelected,
    setIsNewAutoTerm,
    subDepartmentOptions,
    departmentOptions,
    setIsAutoTermFormOpen,
    setBackupRecords,
    editRecordsArray,
    selectQueueType,
    setSelectQueueType,
    setEditRecordsArray,
  } = useContext(autoTermContext);
  const pricing = PricingReportStyle();
  const [wantToDelete, setWantToDelete] = useState({
    isOpen: false,
    index: undefined,
  });
  // ... Other state variables and functions

  // Function to handle adding a new row
  const handleAddRow = () => {
    debugger;
    const newRow = { startDay: "", endDay: "", percentDiscount: "" };
    let arr = JSON.parse(JSON.stringify(formRecords.range));
    arr.unshift((JSON.stringify(addnewRange) == JSON.stringify(newRow))? newRow : JSON.parse(JSON.stringify(addnewRange)));
    setFormRecords({ ...formRecords, ["range"]: JSON.parse(JSON.stringify(arr)) });
    setBackupRecords({ ...backupRecords, ["range"]: JSON.parse(JSON.stringify(arr)) });
    setAddNewRange(newRow);

    let editArr = editRecordsArray;
    editArr.forEach((item: any, index: any) => {
      console.log(item, "edit the issue");

      editArr[index] = editArr[index] + 1;
    });
    if(!(addnewRange.startDay && addnewRange.endDay && addnewRange.percentDiscount)){
      editArr.push(0);
    }
    setEditRecordsArray([...editArr]);
  };

  // Function to handle editing a row
  const handleEditRow = (rowIndex: any) => {
    debugger;
    console.log(editRecordsArray, "the records to edit");

    setEditRecordsArray([...editRecordsArray, rowIndex]);
  };

  // Function to handle saving a row
  const handleSaveRow = (rowIndex: any) => {
    debugger;
    const updatedRow = JSON.parse(JSON.stringify(formRecords["range"][rowIndex]));
    setBackupRecords(() => {
      const newBackupRecords = backupRecords;
      newBackupRecords["range"][rowIndex] = updatedRow;
      return newBackupRecords;
    });
    setEditRecordsArray(() =>
      editRecordsArray.filter((index: any) => index !== rowIndex)
    );
  };

  // Function to handle canceling editing a row
  const handleCancelEdit = (rowIndex: any) => {
    debugger;
    const originalRow = JSON.parse(JSON.stringify(backupRecords["range"][rowIndex]));
    setFormRecords(() => {
      const newFormRecords = formRecords;
      newFormRecords["range"][rowIndex] = originalRow;
      return newFormRecords;
    });

    setEditRecordsArray(() =>
      editRecordsArray.filter((index: any) => index !== rowIndex)
    );
  };

  // Function to handle deleting a row
  const handleDeleteRow = (rowIndex: any) => {
    setFormRecords({
      ...formRecords,
      ["range"]: formRecords.range.filter(
        (_: any, index: any) => index !== rowIndex
      ),
    });
    setBackupRecords({
      ...backupRecords,
      range: backupRecords?.range?.filter(
        (_: any, index: any) => index !== rowIndex
      ),
    });
    let editArr = editRecordsArray;
    editArr.forEach((item: any, index: any) => {
      console.log(item, "edit the issue");

      editArr[index] = item < rowIndex ? editArr[index] : editArr[index] - 1;
    });
    setEditRecordsArray([...editArr]);
    setWantToDelete({
      isOpen: false,
      index: undefined,
    });
  };

  const RangeHead = () => (
    <>
      {rangeHeader.map((value: any, index: any) => {
        return (
          <>
            <RACTableCell>
              {value.isSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() => {
                    if (editRecordsArray.length == 0) {
                      let rep = sortArray(
                        value.key,
                        index,
                        rangeHeader,
                        formRecords.range,
                        "number"
                      );
                      console.log(rep, "the sort array in the form");

                      // setGridData(rep[0]);  need to set the data into formData.range()
                      setRangeHeader(rep[1]);
                      setFormRecords({ ...formRecords, ["range"]: rep[0] });
                    }
                  }}
                >
                  {value.heading + "  "}
                  {value.isSorted === true ? (
                    value.sortDirection == "DESC" ? (
                      <SortUpIcon
                        style={{ cursor: "pointer" }}
                        className={Classes.ml0}
                      />
                    ) : (
                      <SortDownIcon
                        style={{ cursor: "pointer" }}
                        className={Classes.ml0}
                      />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.heading + "  "}
                </a>
              )}
            </RACTableCell>
          </>
        );
      })}
    </>
  );
  const handleRangeInputs = (
    key: string,
    currentEvent: any,
    rangeObject: RangeObject | any ,
    index: number
  ) => {
    debugger;
    const updatedFormRecords = { ...formRecords };

    // Create a copy of the range array
    const updatedRangeArray = [...updatedFormRecords.range];

    // Assuming you have the updated values
    const updatedValues: any = rangeObject;
    if (key.toLowerCase() == "percentdiscount") {
      // const numberPattern = /^(100(\.0+)?|\d{1,2}(\.\d{0,2})?)$/;  /* %? */
      const numberPattern=  /^(?:\d{1,2}(?:\.\d{1,2})?|\.\d{1,2}|\d{1,2}(\.\d{0,2})?)?$/;
      if(!numberPattern.test(currentEvent)){
        currentEvent =rangeObject.percentDiscount.replace('%','')
      }
      // else
      // currentEvent+='%'
      // currentEvent = currentEvent.replace(
      //   /[^0-9.]|[0-9]+\.[0-9]+\.[0-9]+|(\.\d{2})\d+?$/g,
      //   ""
      // );
    } else if (
      key.toLowerCase() == "startday" ||
      key.toLowerCase() == "endday"
    ) {
      currentEvent = currentEvent.replace(/\D/g, rangeObject[key]);
    }

    // Update the object at the specified index with the new values
    updatedValues[key] = currentEvent;
    updatedRangeArray[index] = updatedValues;
    if(isNewAutoTerm && updatedValues.startDay && updatedValues.endDay && updatedValues.percentDiscount){
      editRecordsArray.splice(editRecordsArray.indexOf(0),1)
    }

    // Update the formRecords with the updated range array
    updatedFormRecords.range = updatedRangeArray;
    console.log(updatedFormRecords, "The updated form records", formRecords);
    setFormRecords({ ...updatedFormRecords });
  };

  const RangeContent = () => {
    return (
      <>
        <RACTableRow style={{ backgroundColor: "transparent" }}>
          <RACTableCell>
            <RACTextbox value={addnewRange.startDay}  OnChange={(e: any)=>{
              e.target.value = e.target.value.replace(/\D/g, "");
setAddNewRange({...addnewRange, ['startDay']: e.target.value})
            }} />
          </RACTableCell>
          <RACTableCell>
            <RACTextbox value={addnewRange.endDay}  OnChange={(e: any)=>{
              e.target.value = e.target.value.replace(/\D/g, "");
setAddNewRange({...addnewRange, ['endDay']: e.target.value})
            }}/>
          </RACTableCell>
          <RACTableCell>
            <RACTextbox value={addnewRange.percentDiscount}  OnChange={(e: any)=>{
                    const numberPattern = /^(100(\.0+)?|\d{1,2}(\.\d{0,2})?)$/;
                    if(!numberPattern.test(e.target.value)){
                      e.target.value =''
                    }
setAddNewRange({...addnewRange, ['percentDiscount']: e.target.value})
            }}/>
          </RACTableCell>
          <RACTableCell className={`${Classes.textCenter}`}>
            <AddRow
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => {
                handleAddRow();
              }}
            ></AddRow>
          </RACTableCell>
        </RACTableRow>
        {formRecords?.range?.map((item: any, index: any) => {
          return (
            <>
              {isNewAutoTerm ? (
                <>
                  <RACTableRow style={{ backgroundColor: "transparent" }}>
                    <RACTableCell>
                      <RACTextbox
                        value={item.startDay}
                        OnChange={(e) => {
                          handleRangeInputs(
                            "startDay",
                            e.target.value,
                            item
                              ? item
                              : {
                                  startDay: "",
                                  endDay: "",
                                  percentDiscount: "",
                                },
                            index
                          );
                        }}
                      />
                    </RACTableCell>
                    <RACTableCell>
                      <RACTextbox
                        value={item.endDay}
                        OnChange={(e) => {
                          handleRangeInputs(
                            "endDay",
                            e.target.value,
                            item
                              ? item
                              : {
                                  startDay: "",
                                  endDay: "",
                                  percentDiscount: "",
                                },
                            index
                          );
                        }}
                      />
                    </RACTableCell>
                    <RACTableCell>
                      <RACTextbox
                        value={item.percentDiscount}
                        Onblur={()=>{
                          let INdrange= formRecords.range
                          if(INdrange[index].percentDiscount!='' && !INdrange[index].percentDiscount.includes('%'))
                          INdrange[index].percentDiscount+='%';
                        else
                        INdrange[index].percentDiscount=item.percentDiscount
                          setFormRecords({...formRecords,range: INdrange})
                        }}
                        OnChange={(e) => {
                          handleRangeInputs(
                            "percentDiscount",
                            e.target.value,
                            item
                              ? item
                              : {
                                  startDay: "",
                                  endDay: "",
                                  percentDiscount: "",
                                },
                            index
                          );
                        }}
                      />
                    </RACTableCell>
                    <RACTableCell
                      className={`${Classes.textCenter}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <DeleteDanger
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // handleDeleteRow(index);
                          setWantToDelete({
                            isOpen: true,
                            index: index,
                          });
                        }}
                      ></DeleteDanger>
                    </RACTableCell>
                  </RACTableRow>
                </>
              ) : (
                <RACTableRow style={{ backgroundColor: "transparent" }}>
                  {editRecordsArray?.includes(index) ? (
                    <>
                      <RACTableCell>
                        <RACTextbox
                          value={item?.startDay}
                          OnChange={(e) => {
                            handleRangeInputs(
                              "startDay",
                              e.target.value,
                              item,
                              index
                            );
                          }}
                        />
                      </RACTableCell>
                      <RACTableCell>
                        <RACTextbox
                          value={item?.endDay}
                          OnChange={(e) => {
                            handleRangeInputs(
                              "endDay",
                              e.target.value,
                              item,
                              index
                            );
                          }}
                        />
                      </RACTableCell>
                      <RACTableCell>
                        <RACTextbox
                          value={item?.percentDiscount}
                          OnChange={(e) => {
                            handleRangeInputs(
                              "percentDiscount",
                              e.target.value,
                              item,
                              index
                            );
                          }}
                        />
                      </RACTableCell>
                      <RACTableCell
                        className={`${Classes.textCenter}`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <EditCheck
                          className={`${Classes.mr3}`}
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              item.startDate != "" &&
                              item.endDay != "" &&
                              item.percentDiscount != ""
                            )
                              handleSaveRow(index);
                          }}
                        ></EditCheck>
                        <EditCross
                          style={{
                            width: "16px",
                            height: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleCancelEdit(index);
                          }}
                        ></EditCross>
                      </RACTableCell>
                    </>
                  ) : (
                    <>
                      <RACTableCell>
                        <Typography
                          variant="caption"
                          className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                        >
                          {item?.startDay}
                        </Typography>
                      </RACTableCell>
                      <RACTableCell>
                        <Typography
                          variant="caption"
                          className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                        >
                          {item?.endDay}
                        </Typography>
                      </RACTableCell>
                      <RACTableCell>
                        <Typography
                          variant="caption"
                          className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                        >
                          {`${Number(item?.percentDiscount)?.toFixed(2)}%`}
                        </Typography>
                      </RACTableCell>
                      <RACTableCell
                        className={`${Classes.textCenter}`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <EditIcon
                          className={`${Classes.mr3}`}
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleEditRow(index);
                          }}
                        ></EditIcon>
                        <DeleteDanger
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // handleDeleteRow(index);
                            setWantToDelete({
                              isOpen: true,
                              index: index,
                            });
                          }}
                        ></DeleteDanger>
                      </RACTableCell>
                    </>
                  )}
                </RACTableRow>
              )}
            </>
          );
        })}
      </>
    );
  };

  const handleInputChange = (value: any, key: any) => {
    debugger;
    const validatedValue = validateInput(key, value);
    setFormRecords({
      ...formRecords,
      [key]: validatedValue,
    });
  };
  const deletePopup = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            {<Danger width={"10%"} height={"10%"} />}
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Are you sure you want to delete?
            </Typography>
          </Grid>
          <Grid
                       style={{ display: "flex", justifyContent: "center", margin: "3%" }}

          >

              <RACButton
                            style={{ marginRight: "6%" }}
                onClick={() => {
                  // On getting something went wrong to pricing page refreshed to the initial page
                  // handleDeleteRow(wantToDelete.index);
                  setWantToDelete({
                    isOpen: false,
                    index: undefined,
                  });
                }}
                color="primary"
                variant="outlined"
              >
                No
              </RACButton>
           
              <RACButton
                onClick={() => {
                  // On getting something went wrong to pricing page refreshed to the initial page
                  handleDeleteRow(wantToDelete.index);
                }}
                color="primary"
                variant="contained"
              >
                Yes
              </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid style={{ width: "100%", paddingTop: "0.75rem" }}>
        {/* breadcrumbs starts  */}
        <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
          <ListItem
            style={{ paddingLeft: "0" }}
            className={`${Classes.breadcrumbItemActive} `}
          >
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFormRecords({});
                setBackupRecords({});
                setIsNewAutoTerm(false);
                setIsAutoTermFormOpen(false);
                setEditRecordsArray([]);
                setSelectQueueType("");
                setZoneSelectedForm([])
                setAddNewRange(createNewRange);
              }}
            >
              Autoterm Details
            </a>
          </ListItem>
          <ListItem
            className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
          >
            {isNewAutoTerm ? <a>New Autoterm</a> : <a>Update Autoterm</a>}
          </ListItem>
        </List>
        {/* breadcrumbs ends  */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={5}>
            <Typography variant="h4">
              {" "}
              {isNewAutoTerm ? "New Autoterm" : "Update Autoterm"}
            </Typography>
            <Card className={`${Classes.p3} `} style={{ height: "70vh" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className={`${Classes.mt3} `}>
                { isNewAutoTerm?
                <><Typography>
                    Zone<span style={{ color: "red" }}>*</span>
                  </Typography>
                 <MultiSelect
                  isLoading={zoneOptions.length>1 || (isFranchiseeUser && companyCode.length ==0)? false: true}
                    options={zoneOptions}
                    value={zoneSelectedForm}
                    className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                    onChange={(e: any) => {
                      setZoneSelectedForm(e);
                      let arr = e.map((item: any) => {
                        if (item.value == item.value) return item.value;
                      });
                      console.log(arr);
                      handleInputChange(arr, "zone");
                    }}
                    labelledBy={"Select"}
                  /> </>:
                   <RACSelect
                    isDisabled={!isNewAutoTerm ? true : false}
                    inputLabel="Zone"
                    required
                    options={zoneOptions}
                    defaultValue={formRecords.zone}
                    onChange={(e) => {
                      handleInputChange([e.target.value], "zone");

                      console.log(e, "the log in the zone");
                    }}
                  ></RACSelect> }
                </Grid>
                <Grid item xs={4} className={`${Classes.mt3} `}>
                  <RACSelect
                    isDisabled={!isNewAutoTerm ? true : false}
                    inputLabel="Department"
                    options={departmentOptions}
                    required
                    loading={departmentOptions.length > 1 ? false : true}
                    defaultValue={formRecords.departmentNumber? formRecords.departmentNumber :''}
                    onChange={(e) => {
                      debugger;
                      // handleInputChange(e.target.value, "departmentNumber");
                      setFormRecords({
                        ...formRecords,
                        ['departmentNumber']: e.target.value,
                        ['subdepartmentNumber']: '',
                        ['bracketNumber']: '',
                      });
                      let subDept = originalSubDeptArr.filter((obj: any) => {
                        console.log(obj, e.target.value);

                        if (obj.deptId == e.target.value) {
                          return obj;
                        }
                        // return obj.departmentNumber == e.target.value;
                      });
                      console.log("subDeptOptions1", subDepartmentOptions);
                      console.log(subDept, "The sub mohan");
                      subDept.unshift({
                        label: "Select",
                        value: "",
                        deptId: "",
                      });

                      setSubDepartmentOptions(subDept);
                    }}
                  ></RACSelect>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt3} `}>
                  <RACSelect
                    isDisabled={!(isNewAutoTerm && formRecords.departmentNumber )? true : false}
                    inputLabel="Sub Department"
                    options={subDepartmentOptions}
                    loading={subDepartmentOptions.length > 1 ? false : true}

                    defaultValue={formRecords.subdepartmentNumber ? formRecords.subdepartmentNumber :''}
                    onChange={(e) => {
                      // handleInputChange(e.target.value, "subdepartmentNumber");
                      // handleInputChange(e.target.value, "subdepartmentNumber");
                      setFormRecords({
                        ...formRecords,
                        ['subdepartmentNumber']: e.target.value,
                        ['bracketNumber']: '',
                      });
                      let bracketOpts = bracketOptionsCopy.filter((obj: any) => {
                        console.log(obj, e.target.value);

                        if (obj.subdeptId == e.target.value) {
                          return obj;
                        }
                        // return obj.departmentNumber == e.target.value;
                      });
                      console.log("subDeptOptions1", subDepartmentOptions);
                      console.log(bracketOpts, "The sub mohan");
                      bracketOpts.unshift({
                        label: "Select",
                        value: "",
                        deptId: "",
                      });

                      setBracketOptions(bracketOpts);
                    }}
                  ></RACSelect>
                </Grid>

               <Grid item xs={4} className={`${Classes.mt3} `}>
                  {/* <Typography>Bracket</Typography>
                  <RACTextbox
                    disabled={!isNewAutoTerm ? true : false}
                    value={formRecords.bracketName ? formRecords.bracketName :''}
                    OnChange={(e) => {
                      handleInputChange(e.target.value, "bracketName");
                    }}
                  /> */}
                   <RACSelect
                inputLabel="Bracket"
                options={bracketOptions}//bracketDd
                isDisabled={!(isNewAutoTerm && formRecords.subdepartmentNumber )? true : false}
                defaultValue={formRecords?.bracketNumber ? formRecords?.bracketNumber : ""}//filterPackagePayload.bracketName ? filterPackagePayload.bracketName : '0'
                onChange={(e) => handleInputChange(e.target.value, "bracketNumber")}
                loading={bracketOptions.length > 1 ? false : true}
                ></RACSelect>

                </Grid>
                  <Grid item xs={4} className={`${Classes.mt3} `}>
                  <Typography>Brand</Typography>
                  <RACTextbox
                    disabled={!isNewAutoTerm ? true : false}
                    value={formRecords.brandName}
                    maxlength={50}
                    OnChange={(e) => {
                      handleInputChange(e.target.value, "brandName");
                    }}
                  />
                </Grid> 
                <Grid item xs={4} className={`${Classes.mt3} `}>
                  <Typography>Model #</Typography>
                  <RACTextbox
                    disabled={!isNewAutoTerm ? true : false}
                    value={formRecords.modelNumber}
                    maxlength={30}
                    OnChange={(e) => {
                      handleInputChange(e.target.value, "modelNumber");
                    }}
                  />
                </Grid>

                <Grid item xs={4} className={`${Classes.mt3} `}>
                  <Typography>RMS #</Typography>
                  <RACTextbox
                    disabled={!isNewAutoTerm ? true : false}
                    value={formRecords.rmsItemNumber ? formRecords.rmsItemNumber :''}
                    OnChange={(e) => {
                      handleInputChange(e.target.value, "rmsItemNumber");
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={`${Classes.mt3} `}>
                  <Typography>
                    Tier<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RACTextbox
                  disabled={!isNewAutoTerm ? true : false}
                    value={formRecords.termConfigName ? formRecords.termConfigName :''}
                    OnChange={(e) => {
                      handleInputChange(e.target.value, "termConfigName");
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={`${Classes.mt3} `}>
                  <RACDatePicker
                    required
                    label="Start Date"
                    value={formRecords.startDate}
                    inputProps={{
                      min: moment().format("YYYY-MM-DD"),
                    }}
                    onChange={(e) => {
                      handleInputChange(e, "startDate");
                    }}
                  />
                </Grid>
                {!isNewAutoTerm?<Grid item xs={4} className={`${Classes.mt3} ${Classes.mb3} `}>
                  <RACDatePicker
                    disabled={!isNewAutoTerm ? true : false}
                    label="End Date"
                    value={formRecords.endDate}
                    inputProps={{
                      min: moment().format("YYYY-MM-DD"),
                    }}
                    onChange={(e) => {
                      handleInputChange(e, "endDate");
                    }}
                  />
                </Grid>:<></>}
                <Grid
                  spacing={3}
                  item
                  xs={12}
                  md={12}
                  style={{ marginTop: "0.5%", marginLeft: "2% !important" }}
                >
                  <Typography>
                    {" "}
                    How do you want to update the autoterm?
                    <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <Grid
                    style={{
                      margin: "1%",
                    }}
                  >
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "IMMEDIATE" ? true : false}
                      label="Immediately"
                      name="updateType"
                      onClick={() => setSelectQueueType("IMMEDIATE")}
                    ></RACRadio>
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "OVERNIGHT" ? true : false}
                      label="Overnight"
                      name="updateType"
                      onClick={() => setSelectQueueType("OVERNIGHT")}
                    ></RACRadio>
                  </Grid>
                </Grid>

                
              </Grid>
            </Card>
          </Grid>
          {/* Range starts  */}
          <Grid item xs={12} md={12} lg={7}>
            <Typography variant="h4">Range</Typography>

            <Card style={{ height: "70vh" }}>
              <CardContent style={{ padding: "0px !important" }}>
                <Grid style={{ overflowY: "auto", maxHeight: "68vh" }}>
                  <RACTable
                    renderTableHead={RangeHead}
                    renderTableContent={RangeContent}
                    className={Classes.fixTableHeadAgrTrsRec}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Range starts  */}
        </Grid>
      </Grid>
      <RACModalCard
        isOpen={wantToDelete.isOpen}
        maxWidth="xs"
        borderRadius={10}
        // closeIcon={true}
        onClose={() => {
          wantToDelete.isOpen = false;
          wantToDelete.index = undefined;
        }}
      >
        {deletePopup()}
      </RACModalCard>
    </>
  );
}
