import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { DARK_SILVER } from '../../colors/colors';

const useStyles = makeStyles((theme) => ({
  separator: {
    width: 0,
    height: 0,
    borderTop: `${theme.typography.pxToRem(5)} solid transparent`,
    borderLeft: `${theme.typography.pxToRem(7)} solid ${DARK_SILVER}`,
    borderBottom: `${theme.typography.pxToRem(5)} solid transparent`,
  },
}));

export const BreadcrumbSeparator = () => {
  const ownClasses = useStyles();
  return <div className={clsx(ownClasses.separator)} />;
};
