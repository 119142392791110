//eslint-disable-next-line @typescript-eslint/no-var-requires
const { createBrowserHistory, History } = require('history');
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app/App';
import './index.css';

export interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
  GetName: () => string;
  GetEmailId: () => string;
}

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}

window.renderPricing = (
  containerId: string,
  history: typeof History,
  CustomProps?: CustomPropInterface
) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.render(<App history={history} customProps={CustomProps} />, elem);
  }
};

window.unmountPricing = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });

    window.renderPricing('root', defaultHistory);
  });
}
