/* eslint-disable */
import {
  Typography,
  RACButton,
  Grid,
  CardContent,
  Card,
  RACModalCard,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { PromoSchedulerContext } from "./PromoSchedulerContext";
import { ReactComponent as SuccessIcon } from "../../assets/images/success-icon.svg";
import { ReactComponent as WarningIcon } from "../../assets/images/no-records-found.svg";
import moment from "moment";
import { getDate } from "date-fns";
export default function PromoSchedulerFooter() {
  const {
    errorStatement,
    setErrorStatement,
    errorMessage,
    setErrorMessage,
    setOrganizationSelected,
    setHierarchyValueSelected,
    setHiererchyArrayFilter,
    setOrgArrayFilter,
    setGridLoaderFlag,
    getPromoGridData,
    setUpdateSuccessfully,
    setCreatedSuccessfully,
    applyFilter,
    setPostObj,
    customerOrderEnable,
    setCustomerOrderEnable,
    ecommEnable,
    setEcommEnable,
    onlineOnlyEnable,
    postObjCopy,
    setOnlineOnlyEnable,
    promoTextBoxEnable,
    setPromoTextBoxEnable,
    handleFormFields,
    endDate,
    setFormLoader,
    filterData,
    setFilterData,
    hierarchyArrayForm,
    newScheduleDisabled,
    noAssStoresFound,
    somethingWentWrongGrid,
    setSomethingWentWrongGrid,
    orgArray,
    updateSuccessfully,
    createdSuccessfully,
    setNoAssStoresFound,
    TRTOD,
    INITD,
    NUMPAYMENT,
    PERCENTOFF,
    USEFREETIME,
    MAXWEEKS,
    MAXDAYS,
    MAXDAYSMONTHLY,
    MAXDAYSOTHER,
    CLUBAMOUNT,
    setScheduleType,
    postObj,
    newScheduleFooter,
    setNewScheduleFooter,
    updateFooter,
    setUpdateFooter,
    submissionFooter,
    setSubmissionFooter,
    setAssStoreGrid,
    setPsActionPage,
    // setAssStoreCont,
    setLandingPage,
    postPromo,
    setEmptyPostObj,
    setClearAssStoresCollapse,
    setHiererchyArrayForm,
    headings,
    setHeadings,
    setBedcrumbs,
    manageFields,
    assStoreObj,
    assignStoreObj,
    buttonDisabler,
    setButtonDisabler,
  } = useContext(PromoSchedulerContext);
  let arr: any = [];
  let assignStoreArr: any = [];
  const updateSchdeuleCreated = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Promo schedule has been updated successfully
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                okAction();
                setGridLoaderFlag(false);
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const newSchdeuleCreated = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              New promo schedule has been created successfully
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                okAction();
                setGridLoaderFlag(false);
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const newSchedule = () => {
    manageFields("");
    setBedcrumbs("NEW_SCHEDULE");
    setHeadings("NEW_SCHEDULENE");
    setPsActionPage(true);
    setLandingPage(false);
    setNewScheduleFooter(false);
    setSubmissionFooter(true);
    setEmptyPostObj();
    setAssStoreGrid(false);
    setScheduleType("CREATE");
    setFilterData({
      ...filterData,
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
      promoGroupName: "",
      promoType: "",
      startDate: "",
      endDate: "",
    });
    setHiererchyArrayFilter([]);
    setOrgArrayFilter([]);
    setHierarchyValueSelected([]);
    setOrganizationSelected([]);
  };
  const okAction = () => {
    setCreatedSuccessfully(false);
    setUpdateSuccessfully(false);
    filterData.companyCode = undefined;
    filterData.hierarchyType = undefined;
    filterData.hierarchyValue = undefined;
    filterData.promoGroupName = undefined;
    filterData.promoType = undefined;
    filterData.startDate = undefined;
    filterData.endDate = undefined;
    filterData.limit = undefined;
    filterData.offset = undefined;
    filterData.promotionId = undefined;
    getPromoGridData();
    // setFilterData({
    //   ...filterData,
    //   companyCode: [],
    //   hierarchyType: "",
    //   hierarchyValue: [],
    //   promoGroupName: "",
    //   promoType: "",
    //   startDate: "",
    //   endDate: "",
    // });
    setNoAssStoresFound(false);
    setEmptyPostObj();
    setScheduleType("");
    setNewScheduleFooter(true);
    setUpdateFooter(false);
    setSubmissionFooter(false);
    setPsActionPage(false);
    setAssStoreGrid(false);
    setLandingPage(true);
  };
  const cloneSchedule = () => {
    postObj.clonedPromotionId = postObj.promotionId;
    postObj.startDate = "";
    postObj.endDate = "";
    postObj.promoConfig.dueDate = "";
    setScheduleType("CREATE");
    setBedcrumbs("CLONE");
    handleFormFields("CLONE");
    manageFields(postObj.promoType);
    setHeadings("CLONE");
    setClearAssStoresCollapse(true);
    setUpdateFooter(false);
    setSubmissionFooter(true);
    // setAssStoreCont(true);
    setAssStoreGrid(true);
  };

  const updateAction = () => {
    postObj.promotionId =
      postObj.promotionId != "" ? postObj.promotionId : undefined;
    postObj.clonedPromotionId = undefined;
    postObj.promoGroup = undefined;
    postObj.promoType = undefined;
    postObj.promoName = undefined;
    postObj.promoCode = undefined;
    postObj.startDate = undefined;
    postObj.promoConfig = undefined;
    postObj.promoDetails = undefined;
    postObj.promoReason = undefined;
    postObj.promoDesc = undefined;
    postObj.promoDetailsText = undefined;
    if (postObj.promoEnabled != undefined) {
      postObj.promoEnabled.customerOrderEnabled =
        postObj.promoEnabled.customerOrderEnabled  != postObjCopy.promoEnabled.customerOrderEnabled
          ? postObj.promoEnabled.customerOrderEnabled
          : undefined;
      postObj.promoEnabled.ecommEnabled =
        postObj.promoEnabled.ecommEnabled != postObjCopy.promoEnabled.ecommEnabled 
          ? postObj.promoEnabled.ecommEnabled 
          : undefined;
      postObj.promoEnabled.onlineOnlyEnabled =
        postObj.promoEnabled.onlineOnlyEnabled != postObjCopy.promoEnabled.onlineOnlyEnabled 
          ? postObj.promoEnabled.onlineOnlyEnabled
          : undefined;
      postObj.promoEnabled.promoTextBoxEnabled =
        postObj.promoEnabled.promoTextBoxEnabled != postObjCopy.promoEnabled.promoTextBoxEnabled 
          ? postObj.promoEnabled.promoTextBoxEnabled
          : undefined;
    }
    postObj.assignStores =
      orgArray.length != 0 ? postObj.assignStores : undefined;
    postObj.assignStores = ( postObj.assignStores &&postObj.assignStores.companyCode
        && postObj.assignStores.hierarchyType 
        && postObj.assignStores.hierarchyValue )  ? postObj.assignStores : undefined

    postPromo();
  };
  const submitAction = () => {

    if (postObj.promoConfig.dueDate != "") {
      const dDate = moment(postObj.promoConfig.dueDate, "YYYY-MM-DD");
      const sDate = moment(postObj.startDate, "YYYY-MM-DD");
      const eDate = moment(postObj.endDate, "YYYY-MM-DD");
      if (dDate.isAfter(eDate) || dDate.isBefore(sDate)) {
        setFormLoader(false);
        setErrorMessage(true);
        setErrorStatement(
          "Due date should be within the start date and end date"
        );
      } else {
        if (TRTOD.promoValue != "") {
          arr.push(TRTOD);
        }
        if (CLUBAMOUNT.promoValue != "") {
          arr.push(CLUBAMOUNT);
        }
        if (INITD.promoValue != "") {
          arr.push(INITD);
        }
        if (NUMPAYMENT.promoValue != "") {
          arr.push(NUMPAYMENT);
        }
        if (PERCENTOFF.promoValue != "") {
          arr.push(PERCENTOFF);
        }
        if (USEFREETIME.promoValue != "") {
          arr.push(USEFREETIME);
        }
        if (MAXWEEKS.promoValue != "") {
          arr.push(MAXWEEKS);
        }
        if (MAXDAYS.promoValue != "") {
          arr.push(MAXDAYS);
        }
        if (MAXDAYSOTHER.promoValue != "") {
          arr.push(MAXDAYSOTHER);
        }
        if (MAXDAYSMONTHLY.promoValue != "") {
          arr.push(MAXDAYSMONTHLY);
        }
        if (
          postObj.promoConfig?.dueDate == "" &&
          postObj.promoConfig?.promoRate == "" &&
          postObj.promoConfig?.maxDaysAllowed == "" &&
          postObj.promoConfig?.freeTimeDaysAllowed == "" &&
          postObj.promoConfig?.numberOfFreeDays == "" &&
          postObj.promoConfig?.referralPromoFreeDays == ""
        ) {
          postObj.promoConfig = undefined;
        }
        if (
          postObj.promoEnabled?.customerOrderEnabled == "N" &&
          postObj.promoEnabled?.ecommEnabled == "N" &&
          postObj.promoEnabled?.onlineOnlyEnabled == "N" &&
          postObj.promoEnabled?.promoTextBoxEnabled == "N"
        ) {
          postObj.promoEnabled = undefined;
        }
        postObj.promoDetails = arr;
        if (headings == "NEW_SCHEDULENE" || headings == "CLONE") {
          postObj.promotionId = undefined;
        }
        postObj.promotionId =
          postObj.promotionId != "" ? postObj.promotionId : undefined;
        postObj.clonedPromotionId =
          postObj.clonedPromotionId != ""
            ? postObj.clonedPromotionId
            : undefined;
        postObj.promoGroup =
          postObj.promoGroup != "" ? postObj.promoGroup : undefined;
        postObj.promoType =
          postObj.promoType != "" ? postObj.promoType : undefined;
        postObj.promoName =
          postObj.promoName != "" ? postObj.promoName : undefined;
        postObj.startDate =
          postObj.startDate != "" ? postObj.startDate : undefined;
        postObj.endDate = postObj.endDate != "" ? postObj.endDate : undefined;
        postObj.promoReason =
          postObj.promoReason != "" ? postObj.promoReason : undefined;
        postObj.promoDesc =
          postObj.promoDesc != "" ? postObj.promoDesc : undefined;
        postObj.promoDetailsText =
          postObj.promoDetailsText != "" ? postObj.promoDetailsText : undefined;
        postObj.promoDetails =
          postObj.promoDetails.length > 0 ? postObj.promoDetails : undefined;
        if (postObj.promoConfig != undefined) {
          postObj.promoConfig.dueDate =
            postObj.promoConfig.dueDate != ""
              ? postObj.promoConfig.dueDate
              : undefined;
          postObj.promoConfig.promoRate =
            postObj.promoConfig.promoRate != ""
              ? postObj.promoConfig.promoRate
              : undefined;
          postObj.promoConfig.maxDaysAllowed =
            postObj.promoConfig.maxDaysAllowed != ""
              ? postObj.promoConfig.maxDaysAllowed
              : undefined;
          postObj.promoConfig.freeTimeDaysAllowed =
            postObj.promoConfig.freeTimeDaysAllowed != ""
              ? postObj.promoConfig.freeTimeDaysAllowed
              : undefined;
          postObj.promoConfig.numberOfFreeDays =
            postObj.promoConfig.numberOfFreeDays != ""
              ? postObj.promoConfig.numberOfFreeDays
              : undefined;
          postObj.promoConfig.referralPromoFreeDays =
            postObj.promoConfig.referralPromoFreeDays != ""
              ? postObj.promoConfig.referralPromoFreeDays
              : undefined;
        }
        if (postObj.promoEnabled != undefined) {
          postObj.promoEnabled.customerOrderEnabled =
            postObj.promoEnabled.customerOrderEnabled != "N"
              ? postObj.promoEnabled.customerOrderEnabled
              : undefined;
          postObj.promoEnabled.ecommEnabled =
            postObj.promoEnabled.ecommEnabled != "N"
              ? postObj.promoEnabled.ecommEnabled
              : undefined;
          postObj.promoEnabled.onlineOnlyEnabled =
            postObj.promoEnabled.onlineOnlyEnabled != "N"
              ? postObj.promoEnabled.onlineOnlyEnabled
              : undefined;
          postObj.promoEnabled.promoTextBoxEnabled =
            postObj.promoEnabled.promoTextBoxEnabled != "N"
              ? postObj.promoEnabled.promoTextBoxEnabled
              : undefined;
        }
        postObj.assignStores =
          orgArray.length != 0 ? postObj.assignStores : undefined;

        postPromo();
      }
    }
    else{
      if (TRTOD.promoValue != "") {
        arr.push(TRTOD);
      }
      if (CLUBAMOUNT.promoValue != "") {
        arr.push(CLUBAMOUNT);
      }
      if (INITD.promoValue != "") {
        arr.push(INITD);
      }
      if (NUMPAYMENT.promoValue != "") {
        arr.push(NUMPAYMENT);
      }
      if (PERCENTOFF.promoValue != "") {
        arr.push(PERCENTOFF);
      }
      if (USEFREETIME.promoValue != "") {
        arr.push(USEFREETIME);
      }
      if (MAXWEEKS.promoValue != "") {
        arr.push(MAXWEEKS);
      }
      if (MAXDAYS.promoValue != "") {
        arr.push(MAXDAYS);
      }
      if (MAXDAYSOTHER.promoValue != "") {
        arr.push(MAXDAYSOTHER);
      }
      if (MAXDAYSMONTHLY.promoValue != "") {
        arr.push(MAXDAYSMONTHLY);
      }
      if (
        postObj.promoConfig?.dueDate == "" &&
        postObj.promoConfig?.promoRate == "" &&
        postObj.promoConfig?.maxDaysAllowed == "" &&
        postObj.promoConfig?.freeTimeDaysAllowed == "" &&
        postObj.promoConfig?.numberOfFreeDays == "" &&
        postObj.promoConfig?.referralPromoFreeDays == ""
      ) {
        postObj.promoConfig = undefined;
      }
      if (
        postObj.promoEnabled?.customerOrderEnabled == "N" &&
        postObj.promoEnabled?.ecommEnabled == "N" &&
        postObj.promoEnabled?.onlineOnlyEnabled == "N" &&
        postObj.promoEnabled?.promoTextBoxEnabled == "N"
      ) {
        postObj.promoEnabled = undefined;
      }
      postObj.promoDetails = arr;
      if (headings == "NEW_SCHEDULENE" || headings == "CLONE") {
        postObj.promotionId = undefined;
      }
      postObj.promotionId =
        postObj.promotionId != "" ? postObj.promotionId : undefined;
      postObj.clonedPromotionId =
        postObj.clonedPromotionId != ""
          ? postObj.clonedPromotionId
          : undefined;
      postObj.promoGroup =
        postObj.promoGroup != "" ? postObj.promoGroup : undefined;
      postObj.promoType =
        postObj.promoType != "" ? postObj.promoType : undefined;
      postObj.promoName =
        postObj.promoName != "" ? postObj.promoName : undefined;
      postObj.startDate =
        postObj.startDate != "" ? postObj.startDate : undefined;
      postObj.endDate = postObj.endDate != "" ? postObj.endDate : undefined;
      postObj.promoReason =
        postObj.promoReason != "" ? postObj.promoReason : undefined;
      postObj.promoDesc =
        postObj.promoDesc != "" ? postObj.promoDesc : undefined;
      postObj.promoDetailsText =
        postObj.promoDetailsText != "" ? postObj.promoDetailsText : undefined;
      postObj.promoDetails =
        postObj.promoDetails.length > 0 ? postObj.promoDetails : undefined;
      if (postObj.promoConfig != undefined) {
        postObj.promoConfig.dueDate =
          postObj.promoConfig.dueDate != ""
            ? postObj.promoConfig.dueDate
            : undefined;
        postObj.promoConfig.promoRate =
          postObj.promoConfig.promoRate != ""
            ? postObj.promoConfig.promoRate
            : undefined;
        postObj.promoConfig.maxDaysAllowed =
          postObj.promoConfig.maxDaysAllowed != ""
            ? postObj.promoConfig.maxDaysAllowed
            : undefined;
        postObj.promoConfig.freeTimeDaysAllowed =
          postObj.promoConfig.freeTimeDaysAllowed != ""
            ? postObj.promoConfig.freeTimeDaysAllowed
            : undefined;
        postObj.promoConfig.numberOfFreeDays =
          postObj.promoConfig.numberOfFreeDays != ""
            ? postObj.promoConfig.numberOfFreeDays
            : undefined;
        postObj.promoConfig.referralPromoFreeDays =
          postObj.promoConfig.referralPromoFreeDays != ""
            ? postObj.promoConfig.referralPromoFreeDays
            : undefined;
      }
      if (postObj.promoEnabled != undefined) {
        postObj.promoEnabled.customerOrderEnabled =
          postObj.promoEnabled.customerOrderEnabled != "N"
            ? postObj.promoEnabled.customerOrderEnabled
            : undefined;
        postObj.promoEnabled.ecommEnabled =
          postObj.promoEnabled.ecommEnabled != "N"
            ? postObj.promoEnabled.ecommEnabled
            : undefined;
        postObj.promoEnabled.onlineOnlyEnabled =
          postObj.promoEnabled.onlineOnlyEnabled != "N"
            ? postObj.promoEnabled.onlineOnlyEnabled
            : undefined;
        postObj.promoEnabled.promoTextBoxEnabled =
          postObj.promoEnabled.promoTextBoxEnabled != "N"
            ? postObj.promoEnabled.promoTextBoxEnabled
            : undefined;
      }
      postObj.assignStores =
        orgArray.length != 0 ? postObj.assignStores : undefined;

      postPromo();
    }
  };

  const cancelAction = () => {
    setFilterData({
      ...filterData,
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
      promoGroupName: "",
      promoType: "",
      startDate: "",
      endDate: "",
    });
    setNoAssStoresFound(false);
    setEmptyPostObj();
    setScheduleType("");
    setNewScheduleFooter(true);
    setUpdateFooter(false);
    setSubmissionFooter(false);
    setPsActionPage(false);
    setAssStoreGrid(false);
    setLandingPage(true);
  };
  const someThingWentWrongFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Something went Wrong
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setSomethingWentWrongGrid(false);
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const errorMessageFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              {errorStatement}
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setErrorMessage(false);
                setPostObj((pre: any) => ({
                  ...pre,
                  promoConfig: {
                    ...pre.promoConfig,
                    promoRate: '',
                  },
                }));
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  function submitValidation() {
    const date = moment(postObj.startDate, "YYYY-MM-DD");
    const currDate = moment().format("YYYY-MM-DD");
    if (postObj.promoType == "FIXED") {
      if (
        postObj.promoConfig.dueDate == "" ||
        postObj.promoConfig.promoRate == ""
      ) {
        return true;
      }
    }
    if (postObj.promoType == "POR") {
      if (postObj.promoConfig.promoRate == "") {
        return true;
      }
    }
    if (postObj.promoType == "AED") {
      if (
        postObj.promoConfig.promoRate == "" ||
        postObj.promoConfig.numberOfFreeDays == ""
      ) {
        return true;
      }
    }
    if (postObj.promoType == "PIFEPO") {
      if (TRTOD.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "PIFEPOIP") {
      if (INITD.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "PIFEPOMNM") {
      if (INITD.promoValue == "" || TRTOD.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "FMUFD") {
      if (postObj.promoConfig.promoRate == "") {
        return true;
      }
    }
    if (postObj.promoType == "GCRP") {
      if (INITD.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "PAY4GET1") {
      if (NUMPAYMENT.promoValue == "" || PERCENTOFF.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "FIRST_WK_5") {
      if (postObj.promoConfig.promoRate == "") {
        return true;
      }
    }
    if (postObj.promoType == "DDISC") {
      if (postObj.promoConfig.maxDaysAllowed == "") {
        return true;
      }
    }
    if (postObj.promoType == "HLFOFF_INT") {
      if (
        USEFREETIME.promoValue == "" ||
        MAXWEEKS.promoValue == "" ||
        PERCENTOFF.promoValue == ""
      ) {
        return true;
      }
    }
    if (postObj.promoType == "PAYXSKIPY") {
      if (NUMPAYMENT.promoValue == "" || PERCENTOFF.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "POR_FT") {
      if (postObj.promoConfig.promoRate == "") {
        return true;
      }
    }
    if (postObj.promoType == "PAYXGETY") {
      if (postObj.promoConfig.freeTimeDaysAllowed == "" || 
      postObj.promoConfig.numberOfFreeDays == "") {
        return true;
      }
    }
    if (postObj.promoType == "PAYNFSTY") {
      if (
        postObj.promoConfig.maxDaysAllowed == "" ||
        postObj.promoConfig.promoRate == ""
      ) {
        return true;
      }
    }
    if (postObj.promoType == "PAYNMATCHN") {
      if (postObj.promoConfig.maxDaysAllowed == "") {
        return true;
      }
    }
    if (postObj.promoType == "HLFOFF_NTR") {
      if (MAXDAYS.promoValue == "" || PERCENTOFF.promoValue == "") {
        return true;
      }
    }
    if (postObj.promoType == "PIFEPOIPF") {
      if (
        MAXDAYSOTHER.promoValue == "" ||
        MAXDAYSMONTHLY.promoValue == "" ||
        INITD.promoValue == ""
      ) {
        return true;
      }
    }
    if (postObj.promoType == "FAF") {
      if (postObj.promoConfig.promoRate == "") {
        return true;
      }
    }
    if (postObj.promoType == "PAYXTOY") {
      if (
        postObj.promoConfig.promoRate == "" ||
        postObj.promoConfig.dueDate == "" ||
        CLUBAMOUNT.promoValue == ""
      ) {
        return true;
      }
    }
    if (postObj.promoType == "PAYNFSTY+") {
      if (
        postObj.promoConfig.promoRate == "" ||
        postObj.promoConfig.maxDaysAllowed == "" ||
        CLUBAMOUNT.promoValue == ""
      ) {
        return true;
      }
    }
    if (headings == "NEW_SCHEDULENE") {
      if (date.isBefore(currDate)) {
        return true;
      }
      if (
        orgArray.length == 0 ||
        postObj.assignStores?.hierarchyType == "" ||
        hierarchyArrayForm.length == 0 ||
        postObj.promoGroup == "" ||
        postObj.promoCode == "" ||
        postObj.promoName == "" ||
        postObj.promoType == "" ||
        postObj.startDate == "" ||
        postObj.endDate == "" ||
        postObj.assignStores.hierarchyType == ""
      ) {
        return true;
      }
    } else if (headings == "CLONE") {
      if (noAssStoresFound == true) {
        if (date.isBefore(currDate)) {
          return true;
        }
        if (
          orgArray.length == 0 ||
          postObj.assignStores?.hierarchyType == "" ||
          hierarchyArrayForm.length == 0 ||
          postObj.promoGroup == "" ||
          postObj.promoCode == "" ||
          postObj.promoName == "" ||
          postObj.promoType == "" ||
          postObj.startDate == "" ||
          postObj.endDate == "" ||
          postObj.assignStores.hierarchyType == ""
        ) {
          return true;
        }
      } else {
        if (date.isBefore(currDate)) {
          return true;
        }
        if (
          postObj.promoGroup == "" ||
          postObj.promoCode == "" ||
          postObj.promoName == "" ||
          postObj.promoType == "" ||
          postObj.startDate == "" ||
          postObj.endDate == ""
          // postObj.assignStores.hierarchyType == ""
        ) {
          return true;
        } else if (
          postObj.assignStores?.hierarchyType != "" ||
          orgArray.length != 0
        ) {
          if (date.isBefore(currDate)) {
            return true;
          }
          if (
            orgArray.length == 0 ||
            postObj.assignStores?.hierarchyType == "" ||
            hierarchyArrayForm.length == 0 ||
            postObj.promoGroup == "" ||
            postObj.promoCode == "" ||
            postObj.promoName == "" ||
            postObj.promoType == "" ||
            postObj.startDate == "" ||
            postObj.endDate == "" ||
            postObj.assignStores?.hierarchyType == ""
          ) {
            return true;
          }
        } else if (
          postObj.assignStores?.hierarchyType == "" ||
          orgArray.length == 0
        ) {
          if (date.isBefore(currDate)) {
            return true;
          }
          if (
            postObj.promoGroup == "" ||
            postObj.promoCode == "" ||
            postObj.promoName == "" ||
            postObj.promoType == "" ||
            postObj.startDate == "" ||
            postObj.endDate == ""
          ) {
            return true;
          }
        }
      }
    }
  }
  function updateValidation() {
      if (postObj.assignStores?.hierarchyType != "" && orgArray.length != 0) {
      if (buttonDisabler != true) {
        console.log("true");
        
        if (
          (orgArray.length != 0 &&
          postObj.assignStores?.hierarchyType != "" &&
          hierarchyArrayForm.length != 0) ||
          (postObj.endDate != endDate ||
            postObj.promoEnabled?.customerOrderEnabled != customerOrderEnable ||
            postObj.promoEnabled?.ecommEnabled != ecommEnable ||
            postObj.promoEnabled?.onlineOnlyEnabled != onlineOnlyEnable ||
            postObj.promoEnabled?.promoTextBoxEnabled != promoTextBoxEnable)
        ) {
          return false;
        }
      } else {
        return true;
      }
    } else if (
      postObj.assignStores?.hierarchyType == "" ||
      orgArray.length == 0
    ) {
      if (
        postObj.endDate != endDate ||
        postObj.promoEnabled?.customerOrderEnabled != customerOrderEnable ||
        postObj.promoEnabled?.ecommEnabled != ecommEnable ||
        postObj.promoEnabled?.onlineOnlyEnabled != onlineOnlyEnable ||
        postObj.promoEnabled?.promoTextBoxEnabled != promoTextBoxEnable
      ) {
        return false;
      }
    } else {
      return true;
    }
    return true;
  }
  return (
    <>
      <Card
        style={{
          position: "fixed",
          bottom: "0px",
          width: "100%",
          zIndex: "1",
          left: "0",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid style={{ float: "right" }}>
                {updateFooter == true ? (
                  <>
                    {" "}
                    <RACButton
                      variant="outlined"
                      style={{
                        backgroundColor: "white",
                        color: "rgb(33 121 254)",
                        marginBottom: "10px",
                        marginRight: "15px",
                        blockSize: "44px",
                        fontFamily: "OpenSans-bold",
                      }}
                      onClick={() => {
                        cancelAction();
                      }}
                    >
                      Cancel
                    </RACButton>
                    <RACButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        cloneSchedule();
                      }}
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        marginRight: "10px",
                      }}
                    >
                      Clone Schedule
                    </RACButton>
                    <RACButton
                      disabled={updateValidation()}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateAction();
                        setFormLoader(true);
                      }}
                      style={{ color: "white", marginBottom: "10px" }}
                    >
                      Update
                    </RACButton>
                  </>
                ) : null}
                {submissionFooter == true ? (
                  <>
                    {" "}
                    <RACButton
                      variant="outlined"
                      size="small"
                      style={{
                        backgroundColor: "white",
                        color: "rgb(33 121 254)",
                        marginBottom: "10px",
                        marginRight: "15px",
                        blockSize: "44px",
                        fontFamily: "OpenSans-bold",
                      }}
                      onClick={() => {
                        cancelAction();
                      }}
                    >
                      Cancel
                    </RACButton>
                    <RACButton
                      disabled={
                        buttonDisabler == true ? true : submitValidation()
                      }
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        setFormLoader(true);
                        submitAction();
                      }}
                      style={{ color: "white", marginBottom: "10px" }}
                    >
                      Submit
                    </RACButton>
                  </>
                ) : null}
                {newScheduleFooter == true ? (
                  <RACButton
                    disabled={newScheduleDisabled}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      newSchedule();
                    }}
                    style={{ color: "white", marginBottom: "10px" }}
                  >
                    New Schedule
                  </RACButton>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <RACModalCard
        isOpen={createdSuccessfully}
        maxWidth="xs"
        borderRadius={10}
      >
        {newSchdeuleCreated()}
      </RACModalCard>
      <RACModalCard isOpen={updateSuccessfully} maxWidth="xs" borderRadius={10}>
        {updateSchdeuleCreated()}
      </RACModalCard>
      <RACModalCard
        isOpen={somethingWentWrongGrid}
        maxWidth="xs"
        borderRadius={10}
        onClose={() => setSomethingWentWrongGrid(false)}
      >
        {someThingWentWrongFn()}
      </RACModalCard>
      <RACModalCard
        isOpen={errorMessage}
        maxWidth="xs"
        borderRadius={10}
        onClose={() => setErrorMessage(false)}
      >
        {errorMessageFn()}
      </RACModalCard>
    </>
  );
}
