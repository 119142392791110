import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';
import { StyleClasses } from '../StyleClasses';

export interface ModalProps {
  isOpen: boolean;
  //'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  maxWidth: DialogProps['maxWidth'];
  title: string;
  content: React.ReactElement | number | string;
  buttons: React.ReactElement | number | string;
  titleVariant?: Variant;
  classes?: StyleClasses<
    'dialog' | 'dialogTitle' | 'dialogContent' | 'dialogActions'
  >;
  dialogOptionalProps?: DialogProps;
  onClose: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  iconButton: {
    padding: 0,
  },
  dialogActions: {
    padding: theme.typography.pxToRem(12),
    marginBottom: '.5rem',
  },
}));

export function Modal({
  isOpen,
  maxWidth,
  title,
  content,
  buttons,
  titleVariant,
  onClose,
  classes,
  dialogOptionalProps,
}: ModalProps) {
  const ownClasses = useStyles();
  return (
    <Dialog
      className={clsx(classes?.dialog)}
      maxWidth={maxWidth}
      fullWidth
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      {...dialogOptionalProps}
    >
      <DialogTitle
        disableTypography
        className={clsx(ownClasses.dialogTitle, classes?.dialogTitle)}
      >
        <Typography variant={titleVariant || 'h5'}>{title}</Typography>
        <IconButton onClick={onClose} className={clsx(ownClasses.iconButton)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={clsx(classes?.dialogContent)}>
        {content}
      </DialogContent>
      <DialogActions
        className={clsx(ownClasses.dialogActions, classes?.dialogActions)}
      >
        {buttons}
      </DialogActions>
    </Dialog>
  );
}
