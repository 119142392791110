import { Grid, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
const useStyles = makeStyles(() => ({
  listGroupItemAction: {
    width: '100%',
    color: '##495057',
    textAlign: 'inherit',

    '&:hover &:focus': {
      color: '#495057',
      zIndex: 1,
      textDecoration: 'none',
      backgroundColor: '#f8f9fa',
    },
    '&:active': {
      color: '#212529',
      backgroundColor: '#e9ecef',
    },
  },
  listGroupItem: {
    position: 'relative',
    display: 'block',
    padding: '0.5rem 1rem',
    color: '#212529',
    textDecoration: 'none',
  },

  border0: {
    border: '0 !important',
  },
}));
export interface EventCardprops {
  handleOnClick?: (event: any) => void;
  background?: string;
  borderLeft?: string;
  TextValue?: string;
  color?: string;
  id?: string;
  link?: boolean;
  href?: string;
  children?: any;
  className?: any;
  LinkClassName?: any;
}
export function RacEventCard({
  handleOnClick,
  background,
  borderLeft,
  color,
  children,
  className,
  LinkClassName,
}: EventCardprops) {
  const ownClasses = useStyles();
  return (
    <Grid style={{ borderLeft: borderLeft }} className={className}>
      <Link
        className={clsx(
          ownClasses.listGroupItemAction,
          ownClasses.listGroupItem,
          ownClasses.border0,
          LinkClassName
        )}
        style={{ textDecoration: 'none', background: background, color: color }}
        onClick={(event) => handleOnClick(event)}
      >
        {children}
      </Link>
    </Grid>
  );
}
