/* eslint-disable sonarjs/no-duplicate-string */
import { createTheme } from '@material-ui/core/styles';
import {
  DARK_GREY,
  DEEP_SKY_BLUE,
  SKY_BLUE,
  LIGHT_BLUE,
  MARIGOLD,
  ONYX,
  POMPEII_ASH,
  WASHED_BLACK,
} from '../colors/colors';
import {
  BIG_FONT_SIZE_PX,
  NORMAL_FONT_SIZE_PX,
  SMALL_FONT_SIZE_PX,
} from '../constants/contants';
import { pixelToRem } from '../utils/utils';

// Import this into your micro-frontend
export const theme = createTheme({
  palette: {
    primary: {
      main: DEEP_SKY_BLUE,
    },
    secondary: {
      main: WASHED_BLACK,
    },
    success: {
      main: POMPEII_ASH,
    },
    warning: {
      main: MARIGOLD,
    },
    text: {
      primary: ONYX,
      secondary: WASHED_BLACK,
      disabled: DARK_GREY,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          boxSizing: 'border-box',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
        fontFamily: 'OpenSans-semibold',
        fontSize: `${pixelToRem(14)}rem`,
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: SKY_BLUE,
        color: 'white',
        boxShadow: 'unset',
        '&:disabled': {
          background: LIGHT_BLUE,
          color: 'white',
        },
      },
    },
  },
  typography: {
    body1: {
      fontFamily: 'OpenSans-semibold',
      fontSize: `${pixelToRem(SMALL_FONT_SIZE_PX)}rem`,
      letterSpacing: 0,
    },
    body2: {
      fontFamily: 'OpenSans-regular',
      fontSize: `${pixelToRem(SMALL_FONT_SIZE_PX)}rem`,
      letterSpacing: 0,
      lineHeight: 'unset',
    },
    h1: {
      fontFamily: 'OpenSans-bold',
      marginTop: 0,
      marginBottom: '0.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: 'OpenSans-bold',
      marginTop: 0,
      marginBottom: '0.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: 'OpenSans-bold',
      marginTop: 0,
      marginBottom: '0.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: 'OpenSans-bold',
      fontSize: `${pixelToRem(BIG_FONT_SIZE_PX)}rem`,
      marginTop: 0,
      marginBottom: '0.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: 'OpenSans-bold',
      fontSize: `${pixelToRem(NORMAL_FONT_SIZE_PX)}rem`,
    },
    h6: {
      fontFamily: 'OpenSans-semibold',
      fontSize: `${pixelToRem(NORMAL_FONT_SIZE_PX)}rem`,
      fontWeight: 500,
      lineHeight: 1.2,
    },
  },
});
