import {
  Divider,
  makeStyles,
  Typography,
  TextField as MuiTextField,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { LOTION } from '../../colors/colors';
import { Button } from '../Button/Button';

const DEFAULT_MAX_LENGTH = 500;
const DEFAULT_PRE_INPUT_TEXT = 'Templates';
const DEFAULT_PLACEHOLDER = 'Type the message here...';
const DEFAULT_INPUT_VALUE = '';

export interface TextMessageInputProps {
  maxLength?: number;
  preInputText?: string;
  inputPlaceholder?: string;
  value?: string;
  isCommunicationAllowed: boolean;
  onPreInputTextClicked: (
    value: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onSend: (message: string) => void;
}

const StyledTextField = withStyles((theme) => ({
  root: {
    '& input': {
      ...theme.typography.body2,
      border: `none`,
    },
    '& div': {
      marginTop: '0.2rem !important',
    },
  },
}))((props) => <MuiTextField fullWidth {...props} />) as typeof MuiTextField;

const useStyles = makeStyles((theme: any) => ({
  textMessageInputRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.typography.pxToRem(16),
  },
  inputSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.typography.pxToRem(46),
    backgroundColor: LOTION,
    borderRadius: theme.typography.pxToRem(30),
  },
  preInput: {
    display: 'flex',
    paddingLeft: theme.typography.pxToRem(10),
    paddingRight: theme.typography.pxToRem(10),
  },
  input: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  charactersLeftSection: {
    marginTop: theme.typography.pxToRem(8),
  },
  button: {
    paddingRight: theme.typography.pxToRem(10),
  },
  sendButton: {
    height: '100%',
  },
  underline: {
    textDecoration: 'underline',
  },
}));
export const TextMessageInput = ({
  maxLength,
  preInputText,
  inputPlaceholder,
  value,
  onPreInputTextClicked,
  isCommunicationAllowed,
  onSend,
}: TextMessageInputProps) => {
  const [inputValue, setInputValue] = useState(value || DEFAULT_INPUT_VALUE);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    // reset field, when receiving empty value
    if (!value) {
      setInputValue('');
    } else {
      setInputValue(value);
    }
  }, [value]);

  const ownClasses = useStyles();
  return (
    <div className={ownClasses.textMessageInputRoot}>
      <div className={ownClasses.inputSection}>
        <div className={ownClasses.preInput}>
          <Button
            variant="text"
            size="small"
            color="primary"
            classes={{ root: ownClasses.button }}
            onClick={onPreInputTextClicked}
          >
            <Typography
              variant="h6"
              color="primary"
              className={ownClasses.underline}
            >
              {preInputText || DEFAULT_PRE_INPUT_TEXT}
            </Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
        </div>

        <div className={ownClasses.input}>
          <StyledTextField
            disabled={!isCommunicationAllowed}
            onChange={handleChange}
            value={inputValue}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: maxLength || DEFAULT_MAX_LENGTH }}
            placeholder={inputPlaceholder || DEFAULT_PLACEHOLDER}
          />
        </div>
        <Button
          classes={{ root: ownClasses.sendButton }}
          disabled={!inputValue || !isCommunicationAllowed}
          variant="contained"
          size="large"
          color="primary"
          isRounded
          onClick={() => {
            onSend(inputValue);
            setInputValue(DEFAULT_INPUT_VALUE);
          }}
        >
          <Typography variant="h6">Send</Typography>
        </Button>
      </div>
      <div className={ownClasses.charactersLeftSection}>
        <Typography variant="body2">
          {inputValue?.length}/{DEFAULT_MAX_LENGTH || 500} characters only
        </Typography>
      </div>
    </div>
  );
};
