/* eslint-disable */
import { Dispatch } from 'react';

import {
  getUserAction,
  getUserSuccessAction,
  getUserErrorAction,
  UserAction,
  getPermissionsErrorAction
} from './user-actions';
import { getCurrentUser,getUserPermissions } from '../../api/user';//
import { ProductPermissions, ProductType } from '../../constants/constants';
export interface UserThunks {
  getCurrentUser(): Promise<void>;
}

const fetchPermissions = async (
  dispatch: Dispatch<UserAction>
): Promise<ProductPermissions[]> => {
  try {
    return [];
  } catch (error: any) {
    dispatch(getPermissionsErrorAction(error));
    return [];
  }
};

export const getCurrentUserThunk =
  (dispatch: Dispatch<UserAction>) => async () => {
    try {
      dispatch(getUserAction());

      const userData = {
        ...(await getCurrentUser()),
        permissions: await fetchPermissions(dispatch),
      };
      //const userData = await getCurrentUser();

      dispatch(getUserSuccessAction(userData));
    } catch (error: any) {
      dispatch(getUserErrorAction(error));
    }
  };
