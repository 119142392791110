// --bs-primary: #0d6efd;
export const DEEP_SKY_BLUE = '#0d6efd';
export const SKY_BLUE = '#2179FE';

export const WRAPPER_BLUE = '#3C4353';
export const NORMAL_BLUE = '#2279fd';
// --bs-secondary: #6c757d;
export const POMPEII_ASH = '#6c757d';
// --bs-light: #f8f9fa;
export const DOCTOR = '#f8f9fa';
// --bs-success: #198754
export const VITAL_GREEN = '#198754';
// --bs-warning: #ffc107;
export const MARIGOLD = '#ffc107';
// --bs-danger: #dc3545;
export const CRUEL_RUBY = '#dc3545';
// --bs-info: #0dcaf0;
export const BLUE_RASPBERRY = '#0dcaf0';
// --bs-dark: #212529
export const WASHED_BLACK = '#212529';
// no corresponding; used for thin border
export const CRYSTAL_BELL = '#EFEFEF';
// no corresponding; used for primary text
export const ONYX = '#111111';
// no corresponding; used for borders
export const CLOUD = '#C4C4C4';
// used for background color for chip alert
export const PROVINCIAL_PINK = '#FCE5E5';
// used for background color for chip text color
export const DARK_GREY = '#4B4B4B';

// selected table row selection tip span color
export const PUNCH = '#DE412C';
export const TUFTS_BLUE = '#3581CE';

// for selected table row
export const LINEN = '#FAE2DF';
export const LAVENDER_MIST = '#E0EAFF';

// For table legends
export const INTERGALACTIC_COWBOY = '#281F65';
export const HONEYCOMB = '#dda611';
export const RICH_LILAC = '#B96AD6';
export const BLUE_MANA = '#61CEF4';

export const ALICE_BLUE = '#f0f7ff';
export const CADMIUM_YELLOW = '#fff6dd';
export const PINK_LACE = '#f4d7ff';
export const MAGNOLIA = '#f4f2fb';
export const WHITE = '#ffffff';

// Loader color
export const BLUE_MIRAGE = '#5B6C7A';

// Breadcrumbs text
export const GRAY = '#808080';
//Breadcrumbs separator
export const DARK_SILVER = '#707070';

// used for table content strings
export const INDEPENDENCE = '#4A5174';

// background color for disabled text field
export const ICEWIND_DALE = '#e9ecef';

// background color for disabled button
export const LIGHT_BLUE = '#62A1FE';

export const BLUE_CRAYOLA = '#2179FE';

// backgrounds of text message avatar
export const LOTION = '#FAFAFA';
export const PALE_SPRING_BUD = '#F0EEC6';

// text colors of text messages avatar
export const DARK_MAGENTA = '#900085';
//cannot find a proper name, but looks like PALE_SPRING_BUD(a little bit darker)
export const PALE_SPRING_BUD_DARKER = '#908300';

export const RICH_BLACK = '#020202';

// speech bubble bg
export const LIGHT_BLUE_BACKGROUND = '#E1EBFF';
export const CULTURED = '#F6F7F7';

// due stats
export const MAXIMUM_RED = '#DA2727';
export const CARNELIAN = '#B91919';

export const BRILLIANCE = '#fcfcfc';
export const HOARFROST = '#e1ecfc';

export const OLD_SILVER = '#838383';

// text message date
export const ARGENT = '#BFBFBF';
// Outlined background color
export const HEART_OF_ICE = '#FAFCFF';
// Outlined background color on  hover
export const CALLIGRAPHY = '#5c636a';

// Outlined border color
export const ARC_LIGHT = '#CEE0FF';
// Outlined border color on hover
export const BLUE_ANTHRACITE = '#565e64';
// For field error
export const MVS_RED = '#ef0000';

// color for disabled text like element's border
export const SATIN_WHITE = '#ced4da';
