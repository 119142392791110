import React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckBoxProps,
  FormControlLabel,
  FormControlLabelProps,
  withStyles,
  styled,
} from '@material-ui/core';

import { StyleClasses } from '../StyleClasses';
import { POMPEII_ASH } from '../../colors/colors';
import clsx from 'clsx';

type ClassesKeys = 'label' | 'checkbox' | 'indeterminate';

export interface CheckboxProps extends MuiCheckBoxProps {
  size?: 'small' | 'medium';
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classes?: StyleClasses<ClassesKeys>;
  label?: string;
  rest?: any;
}

const DISABLED_OPACITY = '0.5';
const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: POMPEII_ASH,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  disabled: {
    opacity: DISABLED_OPACITY,
  },
}))((props: MuiCheckBoxProps) => (
  <MuiCheckbox {...props} />
)) as typeof MuiCheckbox;

const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    margin: 0,
  },
  label: {
    color: theme.palette.text.primary,
    minWidth: 'fit-content',
    fontSize: 14,
  },
}))((props: FormControlLabelProps) => (
  <FormControlLabel {...props} />
)) as typeof FormControlLabel;

const ChkIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
}));

const CheckedIcon = styled(ChkIcon)({
  backgroundColor: '#0d6efd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0d6efd',
  },
});

export function Checkbox({
  label,
  size,
  classes,
  checked,
  onChange,
  ...rest
}: CheckboxProps) {
  return (
    <StyledFormControlLabel
      className={clsx(classes?.label)}
      control={
        <StyledCheckbox
          size={size || 'small'}
          className={clsx(classes?.checkbox)}
          checked={checked}
          onChange={onChange}
          {...rest}
          icon={<ChkIcon />}
          checkedIcon={<CheckedIcon />}
        />
      }
      label={label}
    />
  );
}
