/* eslint-disable */
import React, { createContext, useState } from "react";
import { getPromoSchedulerGrid, postPromoSchedule } from "../../api/user";
import { faUnderline } from "@fortawesome/free-solid-svg-icons";
import {
  PromoDetailRequest,
  PromoDetailResponse,
  SchedulePromoRequest,
} from "../shared/interface/PromoSchedulerInterface";
import { filter } from "lodash";

export const PromoSchedulerContext = createContext<any>("");

const PromoContext = (props: any) => {
  const [errorStatement, setErrorStatement] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [hasSelectAllgetHeirarchy, setHasSelectAllgetHeirarchy] =
    useState<any>(true);
  const [hasSelectAllgetHeirarchyForm, setHasSelectAllgetHeirarchyForm] =
    useState<any>(true);
  const [hasSelectAll, setHasSelectAll] = useState<any>(true);
  const [franchiseeuser, setFranchiseeuser] = useState<any>(false);
  const [customerOrderEnable, setCustomerOrderEnable] = useState<any>();
  const [ecommEnable, setEcommEnable] = useState<any>();
  const [onlineOnlyEnable, setOnlineOnlyEnable] = useState<any>();
  const [promoTextBoxEnable, setPromoTextBoxEnable] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [buttonDisabler, setButtonDisabler] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [hierarchyArrayFilter, setHiererchyArrayFilter] = useState([]);
  const [hierarchyArrayForm, setHiererchyArrayForm] = useState([]);
  const [newScheduleDisabled, setNewSheduleDisalbled] = useState(false);
  const [orgArray, setOrgArray] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(10);
  const [orgArrayFilter, setOrgArrayFilter] = useState<any>([]);
  const [psFormLoader, setPsFormLoader] = useState(false);
  const [formData, setFormData] = useState<any>([]);
  const [postObjCopy,setPostObjCopy] = useState<SchedulePromoRequest>({
    promotionId: "",
    clonedPromotionId: "",
    promoGroup: "",
    promoType: "",
    promoName: "",
    promoCode: "",
    startDate: "",
    endDate: "",
    promoConfig: {
      promoRate: "",
      dueDate: "",
      maxDaysAllowed: "",
      freeTimeDaysAllowed: "",
      numberOfFreeDays: "",
      referralPromoFreeDays: "",
    },
    promoDetails: [],
    promoReason: "",
    promoDesc: "",
    promoDetailsText: "",
    promoEnabled: {
      customerOrderEnabled: "N",
      ecommEnabled: "N",
      onlineOnlyEnabled: "N",
      promoTextBoxEnabled: "N",
    },
    assignStores: {
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
    },
  });
  const [assStoreObj, setAssStoreObj] = useState({
    companyCode: [],
    hierarchyType: "",
    hierarchyValue: [],
  });
  const [assignStoreObj, setAssignStoreObj] = useState({
    companyCode: [],
    hierarchyType: "",
    hierarchyValue: [],
  });
  const [TRTOD, setTRTOD] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [INITD, setINITD] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [NUMPAYMENT, setNUMPAYMENT] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [PERCENTOFF, setPERCENTOFF] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [USEFREETIME, setUSEFREETIME] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [MAXWEEKS, setMAXWEEKS] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [MAXDAYS, setMAXDAYS] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [MAXDAYSOTHER, setMAXDAYSOTHER] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [MAXDAYSMONTHLY, setMAXDAYSMONTHLY] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [CLUBAMOUNT, setCLUBAMOUNT] = useState({
    promoKey: "",
    promoValue: "",
  });
  const [ceDisable, setCeDisable] = useState(false);
  const [oeDisable, setOeDisable] = useState(false);
  const [eeDisable, setEeDisable] = useState(false);
  const [peDisable, setPeDisable] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [organization, setOrganization] = useState({
    value: "",
    label: "select",
  });
  const typeDropdown = [
    { value: "", label: "Select" },
    { value: "COUNTRY", label: "COUNTRY" },
    { value: "DISTRICT", label: "DISTRICT" },
    { value: "LOB", label: "LOB" },
    { value: "REGION", label: "REGION" },
    { value: "STATE", label: "STATE" },
    { value: "STORE", label: "STORE" },
  ];
  const [postObj, setPostObj] = useState<SchedulePromoRequest>({
    promotionId: "",
    clonedPromotionId: "",
    promoGroup: "",
    promoType: "",
    promoName: "",
    promoCode: "",
    startDate: "",
    endDate: "",
    promoConfig: {
      promoRate: "",
      dueDate: "",
      maxDaysAllowed: "",
      freeTimeDaysAllowed: "",
      numberOfFreeDays: "",
      referralPromoFreeDays: "",
    },
    promoDetails: [],
    promoReason: "",
    promoDesc: "",
    promoDetailsText: "",
    promoEnabled: {
      customerOrderEnabled: "N",
      ecommEnabled: "N",
      onlineOnlyEnabled: "N",
      promoTextBoxEnabled: "N",
    },
    assignStores: {
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
    },
  });
  const [promotype, setPromoType] = useState([
    {
      label: "Select",
      value: "",
    },
  ]);
  const [promoGroup, setPromoGroup] = useState([
    {
      label: "Select",
      value: "",
    },
  ]);
  const [applyDisable, setApplyDisable] = useState(false);
  var ddflag = false;
  const [headings, setHeadings] = useState("");
  const [assStoreGrid, setAssStoreGrid] = useState(false);
  const [clearAssStoresCollapse, setClearAssStoresCollapse] = useState(false);
  const [scheduleType, setScheduleType] = useState("");
  const [totRecord, setTotRecord] = useState<any>();
  const [organizatonSelected, setOrganizationSelected] = useState([]);
  const [hierarchyValueSelected, setHierarchyValueSelected] = useState([]);
  const [assStoresCont, setAssStoresCont] = useState(false);
  const [assignStore, setAssignStore] = useState(false);
  const [typeFlag, setTypeFlag] = useState("");
  const [psActionPage, setPsActionPage] = useState(false);
  const [updateFooter, setUpdateFooter] = useState(false);
  const [landingPage, setLandingPage] = useState(true);
  const [newScheduleFooter, setNewScheduleFooter] = useState(true);
  const [submissionFooter, setSubmissionFooter] = useState(false);
  const [disableFlagForUpdate, setDisableFlagForUpdate] = useState(false);
  const [updateSuccessfully, setUpdateSuccessfully] = useState(false);
  const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
  const [company, setCompany] = useState<any>([]);
  const [promotionGroup, setPromotionGroup] = useState<any>([]);
  const [associatedStores, setAssociatedStores] = useState<any>([]);
  const [promotionType, setPromotionType] = useState<any>([]);
  const [mainLoader, setMainLoader] = useState(true);
  const [filterLoaderFlag, setFilterLoaderFlag] = useState(true);
  const [gridLoaderFlag, setGridLoaderFlag] = useState(false);
  const [gridData, setGridData] = useState<any>([]);
  const [filterData, setFilterData] = useState<PromoDetailRequest>({
    companyCode: [],
    promotionId: "",
    hierarchyType: "",
    hierarchyValue: [],
    promoGroupName: "",
    promoType: "",
    startDate: "",
    endDate: "",
    limit: "",
    offset: "",
  });
  const [bedCrumbs, setBedcrumbs] = useState("");
  const [exposeFlag, setExposeFlag] = useState("");
  const [promoGroupFlag, setPromoGroupFlag] = useState(false);
  const [promoTypeFlag, setPromoTypeFlag] = useState(false);
  const [promoNameFlag, setPromoNameFlag] = useState(false);
  const [promoCodeFlag, setPromoCodeFlag] = useState(false);
  const [startDateFlag, setStartDateFlag] = useState(false);
  const [resnForPromoFlag, setResnForPromoFlag] = useState(false);
  const [promoDescFlag, setPromoDescFlag] = useState(false);
  const [promoDetailsTxtFlag, setPromoDetailsTxtFlag] = useState(false);
  const [promoRateFlag, setPromoRateFlag] = useState(false);
  const [dueDateFlag, setDueDateFlag] = useState(false);
  const [promoKeyFlag, setPromoKeyFlag] = useState(false);
  const [maxWeeksAllowedFlag, setMaxWeeksAllowedFlag] = useState(false);
  const [freeTimeWeeksAllowedFlag, setFreTimeWeeksAllowedFlag] =
    useState(false);
  const [freeDayFlag, setFreeDayFlag] = useState(false);
  const [referralPromoFlag, setReferralPromoFlag] = useState(false);
  const [noAssStoresFound, setNoAssStoresFound] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [somethingWentWrongGrid, setSomethingWentWrongGrid] = useState(false);
  const [somethingWentWrongFilter, setSomethingWentWrongFilter] =
    useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const sortDirection = { ASC: "asc", DESC: "desc" };
  const [tableHeader, setTableHeader] = useState([
    {
      columnTitle: "Promo Group",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Promo Type",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Promo Name",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Promo Code",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Customer Order",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Ecomm",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Start Date",
      sortOrder: sortDirection.ASC,
      columnId: "startDate",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "End Date",
      sortOrder: sortDirection.ASC,
      columnId: "endDate",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Action",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
  ]);
  const [assStoreHeader, setAssStoreHeader] = useState([
    {
      columnTitle: "Organization",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "State",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Store",
      sortOrder: sortDirection.ASC,
      columnId: "",
      IsSorted: true,
      IsSortable: false,
    },
  ]);

  const getPromoFormData = async (promotionId: any) => {
    filterData.companyCode = undefined;
    filterData.hierarchyType = undefined;
    filterData.hierarchyValue = undefined;
    filterData.promoGroupName = undefined;
    filterData.promoType = undefined;
    filterData.startDate = undefined;
    filterData.endDate = undefined;
    filterData.limit = undefined;
    filterData.offset = undefined;

    filterData.promotionId = promotionId;
    const response: PromoDetailResponse = await getPromoSchedulerGrid(
      filterData
    );
    console.log("promoFormdata reponse", response);
    if (response?.status === 200) {
      setPsFormLoader(false);
      // if (
      //   response?.data.result[0]?.promoType == "PIFEPOIP" ||
      //   response?.data.result[0]?.promoType == "PIFEPOIPF"
      // ) {
      //   if (response?.data.result[0]?.promoType == "PIFEPOIPF") {
      //     setExposeFlag("INITD_&_MAX_DAYS_MONTHLY_&_MAX_DAYS_OTHER");
      //   }
      //   if (response?.data.result[0]?.promoType == "PIFEPOIP") {
      //     setExposeFlag("INITD");
      //   }
      // }
      // if (
      //   response?.data.result[0]?.promoType == "PAY4GET1" ||
      //   response?.data.result[0]?.promoType == "PAYXSKIPY"
      // ) {
      //   setExposeFlag("NUM_PAYMENT_&_PERCENT_OFF");
      // }
      // if (
      //   response?.data.result[0]?.promoType == "HLFOFF_NTR" ||
      //   response?.data.result[0]?.promoType == "HLFOFF_INT"
      // ) {
      //   if (response?.data.result[0]?.promoType == "HLFOFF_NTR") {
      //     setExposeFlag("PERCENT_OFF_&_MAX_DAYS");
      //   }
      //   if (response?.data.result[0]?.promoType == "HLFOFF_INT") {
      //     setExposeFlag("PERCENT_OFF_&_USE_FREE_TIME_&_MAX_WEEKS");
      //   }
      //   // setExposeFlag("PERCENT_OFF");
      // }
      // if (response?.data.result[0]?.promoType == "PIFEPO") {
      //   setExposeFlag("TRTOD");
      // }
      // if (response?.data.result[0]?.promoType == "PIFEPOMNM") {
      //   setExposeFlag("TRTOD_&_INITD");
      // }
      // if (response?.data.result[0]?.promoType == 'PAYNFSTY+') {
      //   setExposeFlag("CLUB_AMOUNT");
      // }
      // if (response?.data.result[0]?.promoType == "GCRP") {
      //   setExposeFlag("INITD");
      // }
      // if (response?.data.result[0]?.promoType == "PAYXTOY") {
      //   setExposeFlag("CLUB_AMOUNT");
      // }
      if (response?.data.result[0]?.customerOrder == "1") {
        var CE = "Y";
      } else {
        var CE = "N";
        setCeDisable(true);
      }
      if (response?.data.result[0]?.ecomm == "1") {
        var EE = "Y";
      } else {
        var EE = "N";
        setEeDisable(true);
      }
      if (response?.data.result[0]?.onlineOnly == "1") {
        var OE = "Y";
      } else {
        var OE = "N";
        setOeDisable(true);
      }
      if (response?.data.result[0]?.promoTextBox == "1") {
        var PE = "Y";
      } else {
        var PE = "N";
        setPeDisable(true);
      }
      setBedcrumbs("UPDATE_PROMO");
      setHeadings("UPDATE_PROMO");
      setClearAssStoresCollapse(false);
      handleFormFields("updatePromo");
      manageFields("updatePromo");
      setUpdateFooter(true);
      setNewScheduleFooter(false);
      setIndexValue(0);
      setLandingPage(false);
      setDisableFlagForUpdate(true);
      setPsActionPage(true);
      setAssStoreGrid(true);
      setScheduleType("UPDATE");
      setCustomerOrderEnable(CE);
      setEcommEnable(EE);
      setOnlineOnlyEnable(OE);
      setPromoTextBoxEnable(PE);
      setEndDate(
        response?.data.result[0]?.endDate == null
          ? ""
          : response?.data.result[0]?.endDate
      );
      setPostObj({
        ...postObj,
        promotionId: response?.data.result[0]?.promotionId,
        // clonedPromotionId:response?.data.result[0]?.promotionId,
        promoGroup:
          response?.data.result[0]?.promoGroupName == null
            ? ""
            : response?.data.result[0]?.promoGroupName,
        promoType:
          response?.data.result[0]?.promoType == null
            ? ""
            : response?.data.result[0]?.promoType,
        promoName:
          response?.data.result[0]?.promoName == null
            ? ""
            : response?.data.result[0]?.promoName,
        promoCode:
          response?.data.result[0]?.promoCode == null
            ? ""
            : response?.data.result[0]?.promoCode,
        startDate:
          response?.data.result[0]?.startDate == null
            ? ""
            : response?.data.result[0]?.startDate,
        endDate:
          response?.data.result[0]?.endDate == null
            ? ""
            : response?.data.result[0]?.endDate,
        promoConfig: {
          promoRate:
            response?.data.result[0]?.promoRate == null
              ? ""
              : response?.data.result[0]?.promoRate,
          dueDate:
            response?.data.result[0]?.dueDate == null
              ? ""
              : response?.data.result[0]?.dueDate,
          maxDaysAllowed:
            response?.data.result[0]?.maxWeeksAllowed == null
              ? ""
              : response?.data.result[0]?.maxWeeksAllowed,
          freeTimeDaysAllowed:
            response?.data.result[0]?.freeTimeWeeksAllowed == null
              ? ""
              : response?.data.result[0]?.freeTimeWeeksAllowed,
          numberOfFreeDays:
            response?.data.result[0]?.numberOfFreeDays == null
              ? ""
              : response?.data.result[0]?.numberOfFreeDays,
          referralPromoFreeDays:
            response?.data.result[0]?.referralPromoFreeDays == null
              ? ""
              : response?.data.result[0]?.referralPromoFreeDays,
        },
        promoReason:
          response?.data.result[0]?.promotionReason == null
            ? ""
            : response?.data.result[0]?.promotionReason,
        promoDesc:
          response?.data.result[0]?.promoDesc == null
            ? ""
            : response?.data.result[0]?.promoDesc,
        promoDetailsText:
          response?.data.result[0]?.promoDetailsText == null
            ? ""
            : response?.data.result[0]?.promoDetailsText,
        promoEnabled: {
          customerOrderEnabled: CE,
          ecommEnabled: EE,
          onlineOnlyEnabled: OE,
          promoTextBoxEnabled: PE,
        },
      });
      setPostObjCopy(({
        ...postObjCopy,
        promotionId: response?.data.result[0]?.promotionId,
        // clonedPromotionId:response?.data.result[0]?.promotionId,
        promoGroup:
          response?.data.result[0]?.promoGroupName == null
            ? ""
            : response?.data.result[0]?.promoGroupName,
        promoType:
          response?.data.result[0]?.promoType == null
            ? ""
            : response?.data.result[0]?.promoType,
        promoName:
          response?.data.result[0]?.promoName == null
            ? ""
            : response?.data.result[0]?.promoName,
        promoCode:
          response?.data.result[0]?.promoCode == null
            ? ""
            : response?.data.result[0]?.promoCode,
        startDate:
          response?.data.result[0]?.startDate == null
            ? ""
            : response?.data.result[0]?.startDate,
        endDate:
          response?.data.result[0]?.endDate == null
            ? ""
            : response?.data.result[0]?.endDate,
        promoConfig: {
          promoRate:
            response?.data.result[0]?.promoRate == null
              ? ""
              : response?.data.result[0]?.promoRate,
          dueDate:
            response?.data.result[0]?.dueDate == null
              ? ""
              : response?.data.result[0]?.dueDate,
          maxDaysAllowed:
            response?.data.result[0]?.maxWeeksAllowed == null
              ? ""
              : response?.data.result[0]?.maxWeeksAllowed,
          freeTimeDaysAllowed:
            response?.data.result[0]?.freeTimeWeeksAllowed == null
              ? ""
              : response?.data.result[0]?.freeTimeWeeksAllowed,
          numberOfFreeDays:
            response?.data.result[0]?.numberOfFreeDays == null
              ? ""
              : response?.data.result[0]?.numberOfFreeDays,
          referralPromoFreeDays:
            response?.data.result[0]?.referralPromoFreeDays == null
              ? ""
              : response?.data.result[0]?.referralPromoFreeDays,
        },
        promoReason:
          response?.data.result[0]?.promotionReason == null
            ? ""
            : response?.data.result[0]?.promotionReason,
        promoDesc:
          response?.data.result[0]?.promoDesc == null
            ? ""
            : response?.data.result[0]?.promoDesc,
        promoDetailsText:
          response?.data.result[0]?.promoDetailsText == null
            ? ""
            : response?.data.result[0]?.promoDetailsText,
        promoEnabled: {
          customerOrderEnabled: CE,
          ecommEnabled: EE,
          onlineOnlyEnabled: OE,
          promoTextBoxEnabled: PE,
        },
      }))
      // const orgArr = response?.data.result[0]?.associatedStores.map(
      //   (key: any) => key.organisationName
      // );
      // const storeArr: any = response?.data.result[0]?.associatedStores.map(
      //   (key: any) => key.storeNumber
      // );
      // // console.log("orgArr", orgArr);
      // console.log("storeArr", storeArr);
      // setAssStoreObj({
      //   ...assStoreObj,
      //   companyCode: [],
      //   hierarchyType: "STORE",
      //   hierarchyValue: storeArr,
      // });
      let sortedAssGrid = response?.data.result[0]?.associatedStores?.sort(
        (a: any, b: any) => (a["storeNumber"] < b["storeNumber"] ? -1 : 1)
      );
      setAssociatedStores(sortedAssGrid);
      console.log(
        "promotionDetailsArray",
        response?.data.result[0]?.promotionDetails
      );
      if (response?.data.result[0]?.promotionDetails != null) {
        for (
          let i = 0;
          i < response?.data.result[0]?.promotionDetails.length;
          i++
        ) {
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "DEF_CLUB_PAYMENT"
          ) {
            setCLUBAMOUNT({
              ...CLUBAMOUNT,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "MAX_DAYS_OTHER"
          ) {
            setMAXDAYSOTHER({
              ...MAXDAYSOTHER,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "MAX_DAYS_MONTHLY"
          ) {
            setMAXDAYSMONTHLY({
              ...MAXDAYSMONTHLY,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "INITD"
          ) {
            setINITD({
              ...INITD,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "PERCENT_OFF"
          ) {
            setPERCENTOFF({
              ...PERCENTOFF,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "MAX_DAYS"
          ) {
            setMAXDAYS({
              ...MAXDAYS,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "NUM_PAYMENT"
          ) {
            setNUMPAYMENT({
              ...NUMPAYMENT,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "USE_FREE_TIME"
          ) {
            setUSEFREETIME({
              ...USEFREETIME,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "MAX_WEEKS"
          ) {
            setMAXWEEKS({
              ...MAXWEEKS,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
          if (
            response?.data.result[0]?.promotionDetails[i].promotionKey ==
            "TRTOD"
          ) {
            setTRTOD({
              ...TRTOD,
              promoKey:
                response?.data.result[0]?.promotionDetails[i].promotionKey,
              promoValue:
                response?.data.result[0]?.promotionDetails[i].promotionValue,
            });
          }
        }
      }
    } else if (response?.status === 401) {
      setPsFormLoader(false);
      setGridLoaderFlag(true);
      setNoDataFound(true);
    } else {
      setPsFormLoader(false);
      setSomethingWentWrongGrid(true);
    }
  };

  const applyFilter = () => {
    filterData.companyCode =
      orgArrayFilter.length != 0 ? filterData.companyCode : undefined;
    filterData.promotionId =
      filterData.promotionId != "" ? filterData.promotionId : undefined;
    filterData.hierarchyType =
      filterData.hierarchyType != "" ? filterData.hierarchyType : undefined;
    filterData.hierarchyValue =
      hierarchyArrayFilter.length != 0 ? filterData.hierarchyValue : undefined;
    filterData.promoGroupName =
      filterData.promoGroupName != "" ? filterData.promoGroupName : undefined;
    filterData.promoType =
      filterData.promoType != "" ? filterData.promoType : undefined;
    filterData.startDate =
      filterData.startDate != "" ? filterData.startDate : undefined;
    filterData.endDate =
      filterData.endDate != "" ? filterData.endDate : undefined;
    filterData.limit = filterData.limit != "" ? filterData.limit : undefined;
    filterData.offset = filterData.offset != "" ? filterData.offset : undefined;

    getPromoGridData();
  };

  const getPromoGridData = async () => {
    setFilterData({
      ...filterData,
      companyCode: filterData.companyCode ? filterData.companyCode : [],
      hierarchyType: filterData.hierarchyType ? filterData.hierarchyType : "",
      hierarchyValue: filterData.hierarchyValue
        ? filterData.hierarchyValue
        : [],
      promoGroupName: filterData.promoGroupName
        ? filterData.promoGroupName
        : "",
      promoType: filterData.promoType ? filterData.promoType : "",
      startDate: filterData.startDate ? filterData.startDate : "",
      endDate: filterData.endDate ? filterData.endDate : "",
    });
    // setGridLoaderFlag(false)
    const response = await getPromoSchedulerGrid(filterData);
    console.log("response in getPromoGridata", response?.data);
    if (response?.status === 200 && response?.data?.result?.length > 0) {
      setGridLoaderFlag(true);
      setGridData(response.data.result);
      setTotRecord(response?.data.totalRecordsCount); //change it
      setPageCount(Math.ceil(response.data.totalRecordsCount / 25));
    } else if (response?.status === 200 && response.data == "") {
      setGridLoaderFlag(true);
      setNoDataFound(true);
    } else {
      setGridLoaderFlag(true);
      setSomethingWentWrongGrid(true);
      // setNewSheduleDisalbled(true);
    }
  };

  const postPromo = async () => {

    // alert("postPromo");
    console.log("postObj in post promo", postObj);
    console.log("scheduleType", scheduleType);
    const response = await postPromoSchedule(postObj);
    if (response?.status === 200) {
      if (scheduleType === "UPDATE") {
        setFormLoader(false);
        setUpdateSuccessfully(true);
      } else if (scheduleType === "CREATE") {
        setFormLoader(false);
        setCreatedSuccessfully(true);
      }
    } else if (response?.status === 400) {
      if (
        response.data.code == "PS_ERR_SP_001" ||
        response.data.code == "PS_ERR_SP_002"
      ) {
        setFormLoader(false)
        setErrorMessage(true);
        setErrorStatement("Promo code already exsits");
      }
      else if(response.data.code == "PS_ERR_SP_010"){
        setFormLoader(false)
        setErrorMessage(true);
        setErrorStatement("Promo rate for the selected Promo type should be less than 1");
      }
      else{
        setFormLoader(false)
        setErrorMessage(true);
        setErrorStatement("Something went wrong");
      }
    } else {
      setFormLoader(false);
      setSomethingWentWrongGrid(true);
    }
  };
  function setEmptyPostObj() {
    setHasSelectAllgetHeirarchy(true)
    setHasSelectAllgetHeirarchyForm(true)
    setPromoGroupFlag(false);
    setPromoTypeFlag(false);
    setPromoNameFlag(false);
    setPromoCodeFlag(false);
    setStartDateFlag(false);
    setResnForPromoFlag(false);
    setPromoDescFlag(false);
    setPromoDetailsTxtFlag(false);
    setPromoRateFlag(false);
    setDueDateFlag(false);
    setPromoKeyFlag(false);
    setMaxWeeksAllowedFlag(false);
    setFreTimeWeeksAllowedFlag(false);
    setFreeDayFlag(false);
    setReferralPromoFlag(false);
    setPostObj({
      ...postObj,
      promoGroup: "",
      promoType: "",
      promoName: "",
      promoCode: "",
      startDate: "",
      endDate: "",
      promoConfig: {
        promoRate: "",
        dueDate: "",
        maxDaysAllowed: "",
        freeTimeDaysAllowed: "",
        numberOfFreeDays: "",
        referralPromoFreeDays: "",
      },
      promoDetails: [],
      promoReason: "",
      promoDesc: "",
      promoDetailsText: "",
      promoEnabled: {
        customerOrderEnabled: "N",
        ecommEnabled: "N",
        onlineOnlyEnabled: "N",
        promoTextBoxEnabled: "N",
      },
      assignStores: {
        companyCode: [],
        hierarchyType: "",
        hierarchyValue: [],
      },
    });
    setCLUBAMOUNT({
      ...CLUBAMOUNT,
      promoKey: "",
      promoValue: "",
    });
    setTRTOD({
      ...TRTOD,
      promoKey: "",
      promoValue: "",
    });
    setINITD({
      ...INITD,
      promoKey: "",
      promoValue: "",
    });
    setNUMPAYMENT({
      ...NUMPAYMENT,
      promoKey: "",
      promoValue: "",
    });
    setPERCENTOFF({
      ...PERCENTOFF,
      promoKey: "",
      promoValue: "",
    });
    setUSEFREETIME({
      ...USEFREETIME,
      promoKey: "",
      promoValue: "",
    });
    setMAXWEEKS({
      ...MAXWEEKS,
      promoKey: "",
      promoValue: "",
    });
    setMAXDAYS({
      ...MAXDAYS,
      promoKey: "",
      promoValue: "",
    });
    setMAXDAYSOTHER({
      ...MAXDAYSOTHER,
      promoKey: "",
      promoValue: "",
    });
    setMAXDAYSMONTHLY({
      ...MAXDAYSMONTHLY,
      promoKey: "",
      promoValue: "",
    });
    setAssStoreObj({
      ...assStoreObj,
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
    });
    setAssignStoreObj({
      ...assignStoreObj,
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
    });
    setHiererchyArrayForm([]);
    setOrgArray([]);
  }
  function emptyAssignStoreObj() {
    setAssStoreObj({
      ...assStoreObj,
      companyCode: [],
      hierarchyType: "",
      hierarchyValue: [],
    });
  }

  function handleFormFields(key: any) {
    switch (true) {
      case key == "updatePromo": {
        setPromoGroupFlag(true);
        setPromoTypeFlag(true);
        setPromoNameFlag(true);
        setPromoCodeFlag(true);
        setStartDateFlag(true);
        setResnForPromoFlag(true);
        setPromoDescFlag(true);
        setPromoDetailsTxtFlag(true);
        setPromoRateFlag(true);
        setDueDateFlag(true);
        setPromoKeyFlag(true);
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(true);
        break;
      }
      case key == "CLONE": {
        setPromoGroupFlag(false);
        setPromoTypeFlag(false);
        setPromoNameFlag(false);
        setPromoCodeFlag(false);
        setStartDateFlag(false);
        setResnForPromoFlag(false);
        setPromoDescFlag(false);
        setPromoDetailsTxtFlag(false);
        // setPromoRateFlag(false);
        // setDueDateFlag(false);
        setPromoKeyFlag(false);
        // setMaxWeeksAllowedFlag(false);
        // setFreTimeWeeksAllowedFlag(false);
        // setFreeDayFlag(false);
        // setReferralPromoFlag(false);
        break;
      }
      case key == "": {
        setPromoGroupFlag(false);
        setPromoTypeFlag(false);
        setPromoNameFlag(false);
        setPromoCodeFlag(false);
        setStartDateFlag(false);
        setResnForPromoFlag(false);
        setPromoDescFlag(false);
        setPromoDetailsTxtFlag(false);
        setPromoRateFlag(false);
        setDueDateFlag(false);
        setPromoKeyFlag(false);
        setMaxWeeksAllowedFlag(false);
        setFreTimeWeeksAllowedFlag(false);
        setFreeDayFlag(false);
        setReferralPromoFlag(false);
        break;
      }
    }
  }

  function manageFields(key: any) {
    switch (true) {
      case key == "": {
        setPromoGroupFlag(false);
        setPromoTypeFlag(false);
        setPromoNameFlag(false);
        setPromoCodeFlag(false);
        setStartDateFlag(false);
        setResnForPromoFlag(false);
        setPromoDescFlag(false);
        setPromoDetailsTxtFlag(false);
        setPromoRateFlag(false);
        setDueDateFlag(false);
        setPromoKeyFlag(false);
        setMaxWeeksAllowedFlag(false);
        setFreTimeWeeksAllowedFlag(false);
        setFreeDayFlag(false);
        setReferralPromoFlag(false);
        break;
      }
      // case key == "updatePromo": {
      //   setPromoGroupFlag(true);
      //   setPromoTypeFlag(true);
      //   setPromoNameFlag(true);
      //   setPromoCodeFlag(true);
      //   setStartDateFlag(true);
      //   setResnForPromoFlag(true);
      //   setPromoDescFlag(true);
      //   setPromoDetailsTxtFlag(true);
      //   setPromoRateFlag(true);
      //   setDueDateFlag(true);
      //   setPromoKeyFlag(true);
      //   setMaxWeeksAllowedFlag(true);
      //   setFreTimeWeeksAllowedFlag(true);
      //   setFreeDayFlag(true);
      //   setReferralPromoFlag(true);
      //   break;
      // }
      // case key == "CLONE": {
      //   setPromoGroupFlag(false);
      //   setPromoTypeFlag(false);
      //   setPromoNameFlag(false);
      //   setPromoCodeFlag(false);
      //   setStartDateFlag(false);
      //   setResnForPromoFlag(false);
      //   setPromoDescFlag(false);
      //   setPromoDetailsTxtFlag(false);
      //   // setPromoRateFlag(false);
      //   // setDueDateFlag(false);
      //   setPromoKeyFlag(false);
      //   // setMaxWeeksAllowedFlag(false);
      //   // setFreTimeWeeksAllowedFlag(false);
      //   // setFreeDayFlag(false);
      //   // setReferralPromoFlag(false);
      //   break;
      // }
      case key == "GCRP": {
        setPromoRateFlag(true);
        setDueDateFlag(true);
        setPromoKeyFlag(true);
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            promoRate: "",
            dueDate: "",
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case ["FMUFD", "FIRST_WK_5", "POR_FT", "FAF"].includes(key): {
        setPromoRateFlag(false);
        // ddflag=true
        setDueDateFlag(true);
        setPromoKeyFlag(true);
        setMaxWeeksAllowedFlag(true);
        setReferralPromoFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case [
        "PAYXSKIPY",
        "HLFOFF_NTR",
        "PIFEPOIPF",
        "PIFEPOIP",
        "PAY4GET1",
        "HLFOFF_INT",
      ].includes(key): {
        setDueDateFlag(true);
        setPromoRateFlag(true);
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(true);
        setPromoKeyFlag(false);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            promoRate: "",
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "PAYXTOY": {
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(true);
        setDueDateFlag(false);
        setPromoRateFlag(false);
        setPromoKeyFlag(false);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "POR": {
        setDueDateFlag(true);
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(false);
        setPromoRateFlag(false);
        setPromoKeyFlag(false);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            maxDaysAllowed: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "AED": {
        setDueDateFlag(true);
        setPromoKeyFlag(true);
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(false);
        setReferralPromoFlag(false);
        setPromoRateFlag(false);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            maxDaysAllowed: "",
            freeTimeDaysAllowed: "",
          },
        }));
        break;
      }
      case ["FIXED", "FREETIME"].includes(key): {
        setPromoKeyFlag(true);
        setPromoRateFlag(false);
        // ddflag=false
        setDueDateFlag(false);
        setMaxWeeksAllowedFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "PIFEPO": {
        setMaxWeeksAllowedFlag(true);
        setDueDateFlag(true);
        setPromoRateFlag(true);
        setPromoKeyFlag(false);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(false);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            promoRate: "",
            maxDaysAllowed: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "PIFEPOMNM": {
        setReferralPromoFlag(true);
        setMaxWeeksAllowedFlag(true);
        setDueDateFlag(true);
        setPromoRateFlag(true);
        setPromoKeyFlag(false);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            promoRate: "",
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "PAYNFSTY": {
        setPromoRateFlag(false);
        setExposeFlag("");
        setDueDateFlag(true);
        setMaxWeeksAllowedFlag(false);
        setPromoKeyFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setReferralPromoFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "PAYNFSTY+": {
        setDueDateFlag(true);
        setMaxWeeksAllowedFlag(false);
        setPromoRateFlag(false);
        setPromoKeyFlag(false);
        setReferralPromoFlag(true);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
      case key == "PAYXGETY": {
        setReferralPromoFlag(true);
        setMaxWeeksAllowedFlag(true);
        setDueDateFlag(true);
        setPromoRateFlag(true);
        setPromoKeyFlag(false);
        setFreTimeWeeksAllowedFlag(false);
        setFreeDayFlag(false);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            promoRate: "",
            maxDaysAllowed: "",
            referralPromoFreeDays: "",
          },
        }));
        break;
      }
      case ["DDISC", "PAYNMATCHN"].includes(key): {
        setReferralPromoFlag(true);
        setMaxWeeksAllowedFlag(false);
        setDueDateFlag(true);
        setPromoRateFlag(true);
        setPromoKeyFlag(false);
        setFreTimeWeeksAllowedFlag(true);
        setFreeDayFlag(true);
        setPostObj((pre: any) => ({
          ...pre,
          promoConfig: {
            ...pre.promoConfig,
            dueDate: "",
            promoRate: "",
            referralPromoFreeDays: "",
            freeTimeDaysAllowed: "",
            numberOfFreeDays: "",
          },
        }));
        break;
      }
    }
  }

  return (
    <PromoSchedulerContext.Provider
      value={{
        handleFormFields,
        hierarchyArrayForm,
        setHiererchyArrayForm,
        hierarchyArrayFilter,
        setHiererchyArrayFilter,
        applyDisable,
        setApplyDisable,
        orgArrayFilter,
        setOrgArrayFilter,
        applyFilter,
        orgArray,
        setOrgArray,
        getPromoFormData,
        TRTOD,
        setTRTOD,
        INITD,
        setINITD,
        NUMPAYMENT,
        setNUMPAYMENT,
        PERCENTOFF,
        setPERCENTOFF,
        USEFREETIME,
        setUSEFREETIME,
        MAXWEEKS,
        setMAXWEEKS,
        MAXDAYS,
        setMAXDAYS,
        MAXDAYSMONTHLY,
        setMAXDAYSMONTHLY,
        MAXDAYSOTHER,
        setMAXDAYSOTHER,
        CLUBAMOUNT,
        setCLUBAMOUNT,
        ceDisable,
        setCeDisable,
        oeDisable,
        setOeDisable,
        eeDisable,
        setEeDisable,
        peDisable,
        setPeDisable,
        bedCrumbs,
        setBedcrumbs,
        headings,
        setHeadings,
        assStoreGrid,
        setAssStoreGrid,
        clearAssStoresCollapse,
        setClearAssStoresCollapse,
        setEmptyPostObj,
        emptyAssignStoreObj,
        promoGroupFlag,
        setPromoGroupFlag,
        resnForPromoFlag,
        setResnForPromoFlag,
        promoTypeFlag,
        setPromoTypeFlag,
        promoNameFlag,
        setPromoNameFlag,
        promoCodeFlag,
        setPromoCodeFlag,
        startDateFlag,
        setStartDateFlag,
        promoDescFlag,
        setPromoDescFlag,
        promoDetailsTxtFlag,
        setPromoDetailsTxtFlag,
        ddflag,
        assStoreHeader,
        setAssStoreHeader,
        assStoresCont,
        setAssStoresCont,
        assignStore,
        setAssignStore,
        postObj,
        setPostObj,
        typeFlag,
        setTypeFlag,
        somethingWentWrong,
        setSomethingWentWrong,
        actionLoader,
        setActionLoader,
        updateSuccessfully,
        setUpdateSuccessfully,
        createdSuccessfully,
        setCreatedSuccessfully,
        company,
        setCompany,
        promotionGroup,
        setPromotionGroup,
        promotionType,
        setPromotionType,
        associatedStores,
        setAssociatedStores,
        mainLoader,
        setMainLoader,
        filterLoaderFlag,
        setFilterLoaderFlag,
        gridLoaderFlag,
        setGridLoaderFlag,
        gridData,
        setGridData,
        noDataFound,
        setNoDataFound,
        somethingWentWrongGrid,
        setSomethingWentWrongGrid,
        somethingWentWrongFilter,
        setSomethingWentWrongFilter,
        indexValue,
        setIndexValue,
        tableHeader,
        setTableHeader,
        sortDirection,
        getPromoGridData,
        postPromo,
        psActionPage,
        setPsActionPage,
        landingPage,
        setLandingPage,
        updateFooter,
        setUpdateFooter,
        newScheduleFooter,
        setNewScheduleFooter,
        submissionFooter,
        setSubmissionFooter,
        disableFlagForUpdate,
        setDisableFlagForUpdate,
        totRecord,
        setTotRecord,
        filterData,
        setFilterData,
        promotype,
        setPromoType,
        promoGroup,
        setPromoGroup,
        organization,
        setOrganization,
        typeDropdown,
        organizatonSelected,
        setOrganizationSelected,
        hierarchyValueSelected,
        setHierarchyValueSelected,
        noAssStoresFound,
        setNoAssStoresFound,
        scheduleType,
        setScheduleType,
        exposeFlag,
        setExposeFlag,
        promoRateFlag,
        setPromoRateFlag,
        dueDateFlag,
        setDueDateFlag,
        promoKeyFlag,
        setPromoKeyFlag,
        maxWeeksAllowedFlag,
        setMaxWeeksAllowedFlag,
        freeTimeWeeksAllowedFlag,
        setFreTimeWeeksAllowedFlag,
        freeDayFlag,
        setFreeDayFlag,
        referralPromoFlag,
        setReferralPromoFlag,
        manageFields,
        assStoreObj,
        setAssStoreObj,
        assignStoreObj,
        setAssignStoreObj,
        formData,
        setFormData,
        psFormLoader,
        setPsFormLoader,
        newScheduleDisabled,
        setNewSheduleDisalbled,
        pageCount,
        setPageCount,
        formLoader,
        setFormLoader,
        buttonDisabler,
        setButtonDisabler,
        endDate,
        setEndDate,
        customerOrderEnable,
        setCustomerOrderEnable,
        ecommEnable,
        setEcommEnable,
        onlineOnlyEnable,
        setOnlineOnlyEnable,
        promoTextBoxEnable,
        setPromoTextBoxEnable,
        franchiseeuser,
        setFranchiseeuser,
        hasSelectAll,
        setHasSelectAll,
        hasSelectAllgetHeirarchy,
        setHasSelectAllgetHeirarchy,
        hasSelectAllgetHeirarchyForm,
        setHasSelectAllgetHeirarchyForm,
        errorMessage,
        setErrorMessage,
        errorStatement,
        setErrorStatement,
        postObjCopy,setPostObjCopy
      }}
    >
      {props.children}
    </PromoSchedulerContext.Provider>
  );
};

export default PromoContext;
