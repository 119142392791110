/* eslint-disable */
import { GetMenuAndStoredbasedOnProfileAPI, fetchOrganizationDetails,getUserID } from '../../api/user';


const employeeAndReferenceDetailsForPromoScheduler = async(containerProps:any) => {
try{
  let isError= false
  let organization:any;
  let promotionGrp:any;
  let promotionTyp:any;
  let userName;
  let employeeId;
  let placeHolderDDdata: any = {};
  if ((containerProps != undefined)) {
    sessionStorage.setItem('currentRole',containerProps.GetRole());
    employeeId = await containerProps.GetEmployeeId();
    userName=containerProps.GetName();

    console.log("employeeId from containerProps::", employeeId);
  } else {
    const userDetails = await getUserID();
    console.log("userDetails console", userDetails);
    employeeId = userDetails?.data.employeeId;
    userName = `${userDetails?.data.firstName} ${userDetails?.data.lastName}`;
    console.log("employeeId from GetCurrentUser call::", employeeId);
    console.log("username for the user", userName)
  }
  let req = {
    coworkerId: employeeId,
    menuRequired: true,
    companyCodeRequired: true,
  };
    const menuAndStoreResponse = await GetMenuAndStoredbasedOnProfileAPI(req);
    console.log("menuAndStoreResponse",menuAndStoreResponse)
    let franchiseeuser, companyCode:any=[]
    if (menuAndStoreResponse?.status === 200) {
      franchiseeuser = menuAndStoreResponse?.data.franchiseeUser;
      companyCode = menuAndStoreResponse?.data.companyCode;
    } else {
      isError=true;
    }
    console.log("franchiseeuser",franchiseeuser)
    console.log("companyCode in menu and store",companyCode)
//promotionType "BOGO" removed ..To have that promotionType "BOGO", Remove the filter
    if (franchiseeuser==true) {
      const organisationPayload:any = { companyCode:companyCode, type: "COMPANY", isPromoGroupAndTypeRequired: "Y" };
      const franchiseeResponse:any = await fetchOrganizationDetails(organisationPayload);
      console.log("franchiseeResponse",franchiseeResponse)
      if (franchiseeResponse?.status === 200) {
        organization = franchiseeResponse?.data?.organizationHierarchyDetails?.result.map((item:any) => ({ label: item.referenceName, value: item.referenceValue }))
        organization=organization?.sort((a: any, b: any) =>
         a["label"] < b["label"] ? -1 : 1)
        promotionGrp = franchiseeResponse?.data?.promoGroup?.referenceDetails?.map((item:any)=> ({ label: item.promotionGroupName, value: item.promotionGroupName }));
        promotionGrp=promotionGrp?.sort((a: any, b: any) =>
         a["label"] < b["label"] ? -1 : 1)
         promotionTyp = await franchiseeResponse?.data?.promotype?.referenceDetails
         ?.filter((item: any) => item.referenceCode !== "BOGO").map((item: any) => ({ label: item.referenceCode, value: item.referenceCode }));
        promotionTyp=promotionTyp?.sort((a: any, b: any) =>
         a["label"] < b["label"] ? -1 : 1)
      } else {
        isError=true;
      }
    } else {
      const organisationPayload = { type: "COMPANY", isPromoGroupAndTypeRequired: "Y" };
      const response:any = await fetchOrganizationDetails(organisationPayload);
      console.log('fetchOrganizationDetails-response',response)
      if (response?.status === 200) {
         organization = await response?.data?.organizationHierarchyDetails?.result.map((item:any) => ({ label: item.referenceName, value: item.referenceValue }));
         organization=organization?.sort((a: any, b: any) =>
         a["label"] < b["label"] ? -1 : 1)
         promotionGrp = await response?.data?.promoGroup?.referenceDetails?.map((item:any) => ({ label: item.promotionGroupName, value: item.promotionGroupName }));
         promotionGrp=promotionGrp?.sort((a: any, b: any) =>
         a["label"] < b["label"] ? -1 : 1)
         promotionTyp = await response?.data?.promotype?.referenceDetails
         ?.filter((item: any) => item.referenceCode !== "BOGO").map((item: any) => ({ label: item.referenceCode, value: item.referenceCode }));
         promotionTyp=promotionTyp?.sort((a: any, b: any) =>
         a["label"] < b["label"] ? -1 : 1)
      } else {
        isError=true;
      }
    }
console.log("organization",organization)
console.log("promotionGrp",promotionGrp)
console.log("promotionTyp",promotionTyp)
console.log("companyCode",companyCode)
    if (isError) {
      return { status: 400 };
    } else {
      return { status: 200, organization:organization, promoGroup:promotionGrp, promoType:promotionTyp,companyCode:companyCode,franchiseeuser:franchiseeuser };
    }
}
catch(error:any){
  return{
    status:500,
    message:error.message
  }
}

};

export const getHierarchyType = async (companyCode:any,type:any,) => {
  console.log("comopanyCode in getHierarchy",companyCode)
  var hierarchyType:any
    const organisationPayload = { companyCode:companyCode,type:type, isPromoGroupAndTypeRequired: "N" };
    const hierarchyResponse:any = await fetchOrganizationDetails(organisationPayload);
    console.log("hierarchyResponse",hierarchyResponse)
    if (hierarchyResponse?.status === 200 && hierarchyResponse.data && Object.keys(hierarchyResponse.data).length !==0) {
      if(type=="COUNTRY"||type=="STATE"||type=="LOB"){
        hierarchyType = hierarchyResponse?.data.result.map((item:any) => ({ label: item.referenceName, value: item.referenceValue }));
      }
      else{
        hierarchyType = hierarchyResponse?.data.result.map((item:any) => ({ label: item.referenceName, value: item.referenceName }));
      }
       
      hierarchyType=hierarchyType.sort((a: any, b: any) =>
      a["label"] < b["label"] ? -1 : 1)
      return { status: 200, hierarchyType };
    } else {
      return { status: 400 };
    }
  };

export default employeeAndReferenceDetailsForPromoScheduler;