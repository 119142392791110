/* eslint-disable */
import { Typography, Grid, Card, CardContent, RACTable, RACTableRow, RACTableCell, RACButton, RACModalCard, RACSelect, RACTextbox, RACDatePicker, CircularProgress } from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { globalStyles } from './Styles/globalstyles';
import { ReactComponent as EditIcon } from "../../assets/images/Edit Icon.svg";
import { ReactComponent as FilterWhite } from "../../assets/images/filter-white.svg"
import { ReactComponent as UpArrowFilter } from "../../assets/images/up-arrow-filter.svg"
import { ReactComponent as DownArrowFilter } from "../../assets/images/down-arrow-filter.svg"
import { ReactComponent as SortUpIcon } from "../../assets/images/sort-up-icon.svg"
import { ReactComponent as SortDownIcon } from "../../assets/images/sort-down-icon.svg"
import { ReactComponent as SuccessIcon } from "../../assets/images/success-icon.svg"
import { ProgressBar } from './Styles/ProgressStyles';
import { ReactComponent as SomethingWrong } from "../../assets/images/no-records-found.svg";
import { ReactComponent as NorecordsIcon } from '../../assets/images/No-records.svg';
// import { IconButton } from '@mui/material';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import {getZoneDD, manageZoneDetail, zoneData } from '../../api/user';
import { ContainerContext } from '../../app/App';
import { getEmployeeInfoAndReferenceData, validateApprover } from '../shared/getEmployeeInfoAndReferenceData';
import { CompanyDetails, responseGetProfileBasedOnMenu } from '../shared/interface/getProfileBasedOnMenuInterface';
import { ZoneDetailResponse, zoneDetails } from '../shared/interface/Response';
import { CustomPropInterface } from '../..';
import { ManageZoneDetailRequest, ZoneDetailRequestDTO } from '../shared/interface/Request';
import { PricingReportStyle } from './Styles/PricingReport';


let userResponseFranchisee: boolean = false;
let userResponseCompanyCode: Array<string> = []

export default function Zone() {

  const Classes = globalStyles();
  const class1 = ProgressBar();
  const pricing = PricingReportStyle();
  const [filterPopup, setFilterPopup] = useState(false);// if true it will open filter popup
  const [newZonePopup, setNewZonePopup] = useState(false)//if true it will open newzone popup card
  const [updateZonePopup, setUpdateZonePopup] = useState(false);//if true it will open update popup card
  //for ok popup

    /**if true the ok popup card will open for both the newzone and */
  const insertZoneSuccessText = 'Zone has been added successfully';
  const updateZoneSuccessText = 'Zone has been updated successfully';
  
  /**For opening the success pop for both Insert & Data
   * 
   * Default value => 0
   * Insert successfull => 1
   * Update successfull => 2
   * 
  */
  const [isApprovalOpen, setIsApprovalOpen] = useState(0)
  //for error messages
  const [zoneErrMsg, setZoneErrMsg] = useState(false)
  const [errorPopup, setErrorPopup] = useState("")
  //for page number count
  const [pageCount, setPageCount] = useState<number>(1);
  const [totRecord, setTotRecord] = useState(0);
  const [outOfRecord, setOutOfRecord] = useState(25);
  const [pginitial, setpginitial] = useState(0);
  const [showRecordData, setShowRecordData] = useState(true)//not to show the record when the user enters edit. 
  const [loader, setLoader] = useState<any>(false);// for the whole application
  const [gridLoader, setGridLoader] = useState(false)// for grid 
  const [showZonePopup, setShowZonePopup] = useState(0)
  const [gridFullLoader, setGridFullLoader] = useState(true)// for grid 
  const [dateError, setDateError] = useState(false);
  const [companyCodeZone, setCompanyCodeZone] = useState<any>()
  const [zoneBinded, setZoneBinded] = useState<any>(false)
  const [duplicateZoneInfo, setDuplicateZoneInfo] = useState<any>([])
  console.log("setpginitial:: ",pginitial);
const progressclasses = ProgressBar();

  const [zoneGridData, setZoneGridData] = useState<any>([]); 

  const sortDirection = {
    ASC: "A",
    DESC: "B",
    };// this is for sorting the columns
    const [canAccess, setCanAccess] = useState(false);
  const [zoneDetail, setZoneDetail] = useState([{
    heading: 'Zone',
    isSortable: true,
    isSorted: true,
    sortOrder: sortDirection.ASC,
    columnId: "zoneNumber"
  },
  {
    heading: 'Zone Name',
    isSortable: false,
    isSorted: false,
    sortOrder: sortDirection.ASC,
    columnId: "zoneName",
  },
  {
    heading: 'Company Name',
    isSortable: false,
    isSorted: false,
    sortOrder: sortDirection.ASC,
    columnId: 'company Name'
  },
  {
    heading: 'Company Code',
    isSortable: true,
    isSorted: true,
    sortOrder: sortDirection.ASC,
    columnId: 'companyCode'
  },
  {
    heading: 'Start Date',
    isSortable: true,
    isSorted: true,
    sortOrder: sortDirection.ASC,
    columnId: 'startDate'
  },
  {
    heading: 'Zone Status',
    isSortable: false,
    isSorted: false,
    sortOrder: sortDirection.ASC,
    columnId: 'zoneStatus'
  },
  {
    heading: 'Action',
    isSortable: false,
    isSorted: false,
    sortOrder: sortDirection.ASC,
    columnId: 'Action'
  }]) // for head of the grid

  const [companyCode, setCompanyCode] = useState(['']);
  const [zoneDropdown, setZoneDropdown] = useState<any>({
    label : 'Select',
    value: undefined
  });
  const [companyDropdown, setCompanyDropdown] = useState<any> ({
    label: 'Select',
    value: undefined
  });
  const [costZoneDropdown, setCostZoneDropdown] = useState<any>({
    label: 'Select',
    value: undefined
  });

 const zoneStatus = [
    { value: "Y", label: "Active" },
    { value: "N", label: "Inactive" }
] // for dropdown of the zoneStatus

/**ManageZoneDetail service request object */
  const zoneObject: ManageZoneDetailRequest = {
    zoneNumber: "",
    zoneName: "",
    companyCode: "",
    startDate: "",
    newZone: "",
    costZoneNumber: ""
    // Y or N to create or update.
  }
  /**ZoneDetail service request object */
  const zoneDetailsReq : ZoneDetailRequestDTO = {

    zoneNumber: undefined,
    companyCode: undefined,
    zoneStatus: "Y",
    offset: "1",
    limit: "25"

  } // zoneDetails

  const [zoneInfo, setZoneInfo] = useState(zoneObject);
  const [zoneDetailsState, setZoneDetailsState] = useState(zoneDetailsReq);
  const [franchiseeUser, setfranchiseeUser] = useState(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface; 
//sort Data
const sortDropDown = (value: any) => {
  return value.slice().sort((a: any, b: any) => a.label.localeCompare(b.label));
}

  useEffect(() => {

    getEmployeeInfoAndReferenceDataFun();    
  }, [])
{
  console.log(pageCount, 'Page number', pginitial, 'The match ', Math.ceil(46 / 25))
}
  
  const companyDropdownfun = async (data: responseGetProfileBasedOnMenu) => {

    console.log("data.dropdownData.company", data.dropdownData.company)
    let zoneCompanyArray = []
    for(let i = 0; i < data.dropdownData.company.length; i++){
      if (data.isFranchiseeUser && data.companyCode.includes(data.dropdownData.company[i].companyCode)) {
        zoneCompanyArray.push({
          label: data.dropdownData.company[i].companyName,
          value: data.dropdownData.company[i].companyCode
        })
      }
      else if(!data.isFranchiseeUser){ //for pricing team
        zoneCompanyArray.push({
          label: data.dropdownData.company[i].companyName,
          value: data.dropdownData.company[i].companyCode
        })
      }
    }
    
    const response = zoneCompanyArray
    return response
  }

  console.log(zoneInfo)
  const [menuConfigArray, setMenuConfigArray] = useState<any>([]);
//To find whether the user is from pricing or from franchisee.
  const getEmployeeInfoAndReferenceDataFun = async () => {

    //Request for the dropdown API
    const pricingDDRefReq = {
      type : ["COMPANY", "COST_ZONE"]
    }
console.log(containerData, "the container Datain the zone component ln204");

    const userResponse = await getEmployeeInfoAndReferenceData(containerData, pricingDDRefReq);
    console.log(userResponse, "thefoadniobfdosbkjv fkbv kjfe");
    setCanAccess(true)
    if (userResponse.isFranchiseeUser) {
      setfranchiseeUser(userResponse?.isFranchiseeUser);

        if(userResponse.companyCode?.length>0)
        setCompanyCodeZone(userResponse.companyCode);
      else{
        setErrorPopup('Something Went Wrong');
        setZoneErrMsg(true);
        setCompanyCodeZone(userResponse.companyCode);
    }
    }

    
    if(userResponse.status == 200){
      console.log(typeof(userResponse.zone));
      let arr : any = [];
      arr = userResponse.zone.sort((a: any, b: any) =>
      a["label"].localeCompare(b["label"], undefined, { numeric: 'true' }));
      arr.unshift({
        label:'Select',
        value:'0',
      })
           
        setZoneDropdown(userResponse.zone); // for zone dropdown after checking if the user is from franchisee or pricing
        setZoneBinded(true)
        setCompanyCode(userResponse.companyCode);
        setMenuConfigArray(userResponse.menuConfigArray);
        console.log(await companyDropdownfun(userResponse), "the company code function");
        setfranchiseeUser(userResponse.isFranchiseeUser);
        let response2: any = await companyDropdownfun(userResponse)
        let arr1 : any = [];
      arr1 = response2.sort((a: any, b: any) =>
      a["label"].localeCompare(b["label"], undefined, { numeric: 'true' }))
      arr1.unshift({
        label:'Select',
        value:'0',
      })
      setCompanyDropdown(response2);// for company dropdown after checking if the user is from franchisee or pricing
        //const pgLoadCompanyCode : any = userResponse.isFranchiseeUser ? userResponse.companyCode : undefined;
        //setZoneDetailsState({...zoneDetailsState,companyCode: pgLoadCompanyCode});
        //zoneDetailsReq.companyCode = userResponse.isFranchiseeUser ? userResponse.companyCode : undefined;
        let costZoneArr = userResponse.dropdownData.costZone.map((item : any)=> {
          return ({
            label: item.costZoneDesc,
            value: item.costZoneNumber,
          })
        })
        console.log("costZoneArr", costZoneArr);
        
        const sortedCostZoneArr = sortDropDown(costZoneArr)
        let tempCostZoneArr : any = [];
        tempCostZoneArr = sortedCostZoneArr;
        tempCostZoneArr.unshift({
            label:'Select',
            value:'0',
          })
        setCostZoneDropdown(tempCostZoneArr);
  }else{
      setErrorPopup("Something Went Wrong");
      setZoneErrMsg(true);
    }
    //Api to get the Zone data grid
    userResponseFranchisee = userResponse.isFranchiseeUser;
    userResponseCompanyCode = userResponse.companyCode
    zoneDataFun('', userResponse.isFranchiseeUser, userResponse.companyCode);
  }

//To fetch the grid data.
const zoneDataFun = async (params: any ='', franchisee:boolean = false, company: any= undefined) => {
  debugger
    if(filterPopup) setFilterPopup(false);
    setGridLoader(false)
    setGridFullLoader(true);
        let req = JSON.parse(JSON.stringify(zoneDetailsState))
        console.log('The request inside the zoneDataFunc',zoneDetailsState);
        let responseForGrid : any
        if(params == 'C'){
          req.companyCode = franchisee ? company : undefined; 
          req.zoneNumber = undefined;
          req.zoneStatus = "Y";
          req.offset = "1";
          setpginitial(0);
          responseForGrid= await zoneData(req);
          setZoneDetailsState(zoneDetailsReq)
        }
        
        else if(params == "A"){
        req.offset = "1";
        req.companyCode = req.companyCode?.includes("0") || req.companyCode == undefined ? franchiseeUser ? companyCode :undefined  : req.companyCode;
        req.zoneNumber = req.zoneNumber  == "0" ? undefined  : req.zoneNumber;
        setpginitial(0);
        responseForGrid = await zoneData(req);
       }
       else {
        console.log("zoneDetailsReq console", zoneDetailsReq);
        req.companyCode =  franchisee ? company : undefined;
        req.zoneNumber = undefined;
        responseForGrid = await zoneData(req);
      }
    console.log("ZoneDetails response :: ",responseForGrid);
    setGridFullLoader(false);
    if(responseForGrid.status == 200 || responseForGrid.status == 204){
      
      if(responseForGrid.status == 204 || responseForGrid.data ==''){ // Working code
          // setNoContent(true);
          setZoneGridData([]);
          setGridLoader(true);
        }
        else{
          setFilterPopup(false);
          setZoneGridData(responseForGrid.data.result);
          setPageCount(Math.ceil(responseForGrid.data.totalRecordsCount / 25));
          setTotRecord(responseForGrid.data.totalRecordsCount); 
          setGridLoader(false)
        } 
       
    }else{
      setZoneDetailsState(zoneDetailsReq)
        setErrorPopup("Something Went Wrong");
        setZoneErrMsg(true);
        // setGridLoader(false);
    }

  }
//if no records found 
  const rendernorecordsheader = () => {
    return (
      <React.Fragment>
        <RACTableCell className={Classes.p2}>Zone</RACTableCell>
        <RACTableCell className={Classes.p2}>Zone Name</RACTableCell>
        <RACTableCell className={Classes.p2}>Company Name</RACTableCell>
        <RACTableCell className={Classes.p2}>Company Code</RACTableCell>
        <RACTableCell className={Classes.p2}>Start Date</RACTableCell>
        <RACTableCell className={Classes.p2}>Zone Status</RACTableCell>
        <RACTableCell className={`${Classes.p2} ${Classes.textCenter}`}> Action</RACTableCell>
      </React.Fragment>
    )
  }
  

  function formatDate(date: any) { 
    var d = new Date(date), 
  month = '' + (d.getMonth() + 1), 
  day = '' + d.getDate(),
  year = d.getFullYear(); 
  if (month.length < 2) {
  month = '0' + month;
  } 
  if (day.length < 2) {
  day = '0' + day; 
  }
  return [month, day, year].join('/');
  }
  
//Sorting functionality
  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any
  ) => {
    try {
      const sortedData: any =
        datacolumn[index].sortOrder == sortDirection.DESC
          ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
          : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );

      const reorderColumns = datacolumn;

      reorderColumns[index].sortOrder =

        reorderColumns[index].sortOrder == sortDirection.ASC

          ? sortDirection.DESC

          : sortDirection.ASC;

      reorderColumns.map((value: any, index1: any) => {

        reorderColumns[index1].isSorted = index == index1 ? true : true;

      });

      setZoneGridData([...sortedData]);

      setZoneDetail([...reorderColumns]);

    } catch (error) {

      console.log(error);

    }

  };

//PageClick functionality
  const handlePageClick = (event: any) => {
setpginitial(event.selected );

    scrollTop;
    console.log(event.selected + 1,"Tje value dfawfnol");
    zoneDetailsState.offset = (event.selected + 1).toString();
    console.log("event", event)
    console.log("page initial", pginitial);
    zoneDataFun('',franchiseeUser,zoneDetailsState.companyCode ? zoneDetailsState.companyCode :companyCode);
  };

  const scrollTop = (ref: any) => {

    if (ref) {
      const element = ref;
      console.log("elementsss", element);
      const clientWidth = element.getBoundingClientRect().height;
      console.log("clientWidth refAssignCallback", clientWidth);
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };

//Error popup
  function ZoneError() {
    return (
      <>
        <Grid>
          {zoneErrMsg ? (//if NewZonepopup is true then the error message should be shown to the user.
            <Grid style={{ position: "relative", top: "-24px" }}>
              <Grid className={progressclasses.successPopup}>
                <SomethingWrong style={{ position: "relative", top: "13px", bottom: "10px" }} />
              </Grid>
              <Grid className={`${progressclasses.textcenterImage} ${Classes.fontBold}`} style={{ marginBottom: "0px" }}>
                {errorPopup}
              </Grid>
              <Grid className={`${progressclasses.loader} ${Classes.mt2}`}>
                <RACButton
                  style={{ position: "relative", top: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {setZoneErrMsg(false), showZonePopup == 1 ? setNewZonePopup(true) : showZonePopup == 2 ? setUpdateZonePopup(true) : "", !dateError ? (franchiseeUser && companyCodeZone.length > 0) ? zoneDataFun('', userResponseFranchisee, userResponseCompanyCode) : "" : ""}} >
                        OK
                </RACButton>
              </Grid>
            </Grid>
            ) : ("")
          }
        </Grid>
      </>
      );
    }

  //rendering zone header
  const renderZoneTableHeader = () => {
    return (
      <>
        {zoneDetail.map((value: any, index: any) => {
          return value.heading == "Action" ? (
            <RACTableCell className={`${Classes.p2} ${Classes.textCenter}`}>
              Action
            </RACTableCell>
          ) : (
            <RACTableCell key={index} className={Classes.p2}>
              {value.isSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() =>
                    sortArray(value.columnId, index, zoneDetail, zoneGridData)
                  }
                >
                  {value.heading + "  "}
                  {value.isSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortUpIcon className={Classes.ml0} />
                    ) : (
                      <SortDownIcon className={Classes.ml0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.heading + "  "}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  }

//filter popup 
const openFilterZone = () => {
    return (
      <Card style={{width: "100%", borderRadius: "12px", marginTop: "77px", marginBottom: "25px", height: "171px"}}>
        <CardContent>
          <Grid
            style={{ position: "relative", top: "11px" }}
            container
            md={12}
            spacing={6}
          >
            <Grid item md={4} lg={2} sm={4}>
              <RACSelect
                inputLabel="Zone"
                options={zoneDropdown}
                defaultValue={zoneDetailsState.zoneNumber ? zoneDetailsState.zoneNumber : ['0']}
                onChange={(e) => {
                  handleOnchangeFilter(e.target.value, "zoneNumber");
                }}
              ></RACSelect>
            </Grid>
            <Grid item md={4} lg={2} sm={4}>
              <RACSelect
                inputLabel="Company Name"
                options={companyDropdown}
                defaultValue={zoneDetailsState?.companyCode?.length ? zoneDetailsState.companyCode : ['0']}
                onChange={(e) => {
                  handleOnchangeFilter([e.target.value], "companyCode");
                }}
              ></RACSelect>
            </Grid>
            <Grid item md={4} lg={2} sm={4}>
              <RACSelect
                inputLabel="Zone Status"
                options={zoneStatus}
                defaultValue={zoneDetailsState?.zoneStatus}
                onChange={(e) => {
                  handleOnchangeFilter(e.target.value, "zoneStatus");
                }}
              ></RACSelect>
            </Grid>

          </Grid>
          <Grid>
            <Grid
              style={{ float: "right", position: "relative", top: "44px" }}
              item
              md={4}
            >
              <RACButton

                variant="text"
                onClick={() => (setZoneDetailsState({...zoneDetailsState, companyCode : 
                  franchiseeUser ? companyCode: undefined}), zoneDataFun("C", userResponseFranchisee, userResponseCompanyCode))}
                style={{
                  backgroundColor: "white",
                  color: "blue",
                  marginBottom: "10px",
                  marginRight: "5px",
                }}
              >
                Clear
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => zoneDataFun("A")}
                style={{ color: "white", marginBottom: "10px" }}
              >
                Apply
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };   
//edit zone functionality
function editZone(index: any) {
    //to populate the data in the update popup.
    debugger
    console.log(zoneGridData, "for checking the data in the zoneGridData");
    console.log(zoneGridData[index].costZoneNumber,"The start date");
    console.log(zoneGridData[index].startDate,"The start date");
    let date2 = formatDate(zoneGridData[index].startDate)
    setZoneInfo({
      ...zoneInfo,
      zoneNumber: zoneGridData[index].zoneNumber,
      zoneName: zoneGridData[index].zoneName,
      companyCode: zoneGridData[index].companyCode,
      startDate:   zoneGridData[index].startDate,
      costZoneNumber: zoneGridData[index]?.costZoneNumber,
      newZone: "N"
    });

    let dupZoneInfo = JSON.parse(JSON.stringify(zoneGridData))
    setDuplicateZoneInfo({
      ...duplicateZoneInfo,
      zoneNumber: dupZoneInfo[index].zoneNumber,
      zoneName: dupZoneInfo[index].zoneName,
      companyCode: dupZoneInfo[index].companyCode,
      startDate:   dupZoneInfo[index].startDate,
      costZoneNumber: dupZoneInfo[index]?.costZoneNumber,
      newZone: "N"
    })

    //assiging the popup true 
    setUpdateZonePopup(true)
  }
//rendering zone body
  const renderZoneTableBody = () => {
    return (
      <> {gridFullLoader ?(<>
        <Grid style={{position: 'absolute',
top: '50%',
transform: 'translate(-50%, -50%)',
left: '50%'}}>
       <Grid className={pricing.textSpaceNorecords}>
         <Grid className={pricing.textcenterImage}>
         <CircularProgress />
         </Grid>
       </Grid>
     </Grid>
        </>) :
        zoneGridData.map((obj: any, index: any) => {
          let date = formatDate(obj.startDate)
          return (
           (<RACTableRow key={index} className={Classes.bgTransparent} >
              <RACTableCell className={`${Classes.p1}`} style={{ fontSize: "0.85rem" }}>{obj.zoneNumber}
              </RACTableCell>
              <RACTableCell className={`${Classes.p1}`} style={{ fontSize: "0.85rem" }} title={obj.zoneName.length > 20 ? obj.zoneName : ''}>{obj.zoneName ?.length > 20 ? obj.zoneName.slice(0,20) + '...' : obj.zoneName}
              </RACTableCell>
              <RACTableCell className={`${Classes.p1}`} style={{ fontSize: "0.85rem" }}  title={obj.companyName.length > 35 ? obj.companyName : ''}> {obj.companyName ?.length > 35 ? obj.companyName.slice(0,35) + '...' : obj.companyName}
              </RACTableCell>
              <RACTableCell className={`${Classes.p1}`} style={{ fontSize: "0.85rem" }}>{obj.companyCode}
              </RACTableCell>
              <RACTableCell className={`${Classes.p1}`} style={{ fontSize: "0.85rem" }}>{date}
              </RACTableCell>
              <RACTableCell className={`${Classes.p1}`} style={{ color: obj.zoneStatus == "Y" ? "#38ebc7" : "grey", fontSize: "0.75rem" }} >
                <span><span className={`${Classes.statusLegend}`} style={{ backgroundColor: obj.zoneStatus == "Y" ? "#38ebc7" : "grey" }}></span><span> {obj.zoneStatus == "Y" ? 'Active' : 'Inactive'}</span></span>
              </RACTableCell>
              <RACTableCell className={`${Classes.p1} ${Classes.textCenter}`}>
              <EditIcon style={{ width: "20px", height: "20px", cursor: 'pointer' }} onClick={() =>{ if(validateApprover(menuConfigArray,'Zone')){editZone(index)}else {
                // alert('Not alloweed')
               { setZoneErrMsg(true);
                setErrorPopup("Access to this feature is currently restricted to this user");}

              }}}></EditIcon>
              </RACTableCell>
            </RACTableRow>)
          )
        })
      }
      </>
    )
  } 

  //calling the zoneDD dropdown again if the zone is inserted or updated.
const zoneDDFun = async () => {
  let zoneResponse = await getZoneDD();
  console.log("zoneResponse", zoneResponse)

  let zoneArray = [{
    label:'Select',
    value:'0',
  }];
    for(let i = 0; i < zoneResponse.data.result.length; i++){
      if (franchiseeUser && companyCode.includes(zoneResponse.data.result[i].companyCode)) {
        console.log("zoneResponse.data.result[i].companyCode", zoneResponse.data.result[i].companyCode)
        console.log("franchiseeUser", franchiseeUser)
        zoneArray.push({
          label: zoneResponse.data.result[i].zone,
          value: zoneResponse.data.result[i].zone,
        })
      }
      else if(!franchiseeUser){ //for pricing team
        console.log("NOzoneResponse.data.result[i].companyCode", zoneResponse.data.result[i].companyCode)
        console.log("NOfranchiseeUser", franchiseeUser)
        zoneArray.push({
          label: zoneResponse.data.result[i].zone,
          value: zoneResponse.data.result[i].zone,
        })
      }
    };
      
      setZoneDropdown(zoneArray)
    
} 

//clearing the zoneInfo object
  const zoneInfoObjectClear = () => {
    setZoneInfo({
      ...zoneInfo,
      zoneNumber: "",
      zoneName: "",
      startDate: "",
      companyCode: "",
      costZoneNumber: "",
      newZone: "",
    });
  }
//updating the zone
const updateZoneData = async () => {
  setShowZonePopup(2);
  setUpdateZonePopup(false);
  setLoader('Y');    
  let copyZoneInfo = zoneInfo
  console.log("copyZoneInfo", copyZoneInfo);
  copyZoneInfo.zoneName = zoneInfo.zoneName.trim()
  copyZoneInfo.costZoneNumber = zoneInfo.costZoneNumber != "0" && zoneInfo.costZoneNumber != null  ? zoneInfo.costZoneNumber : undefined 
  console.log("copyZoneInfo after", copyZoneInfo);
    const response = await manageZoneDetail(copyZoneInfo)
    if(response.status == 200){
      zoneDDFun()
      setUpdateZonePopup(false);
      setIsApprovalOpen(2);//value 2 for update scenario
      zoneInfoObjectClear();
      setShowZonePopup(0);
    console.log("to clear the error message")
    }else if(response.status == 400){
      setUpdateZonePopup(false);
      setZoneErrMsg(true);
      console.log(response.data.errors[0].error);
      setErrorPopup(response.data.errors[0].error) //remove
      if((response.data.errors[0].error).startsWith("Z_ERR")){
        setErrorPopup(response.data.errors[0].message);
        setZoneErrMsg(true);
      }
    }
    else{
      setErrorPopup("Something Went Wrong")
      setZoneErrMsg(true)
    }
    setLoader(false);
}

//inserting the zone 
const insertZone = async () => {
  setShowZonePopup(1)
  setNewZonePopup(false);
  setLoader('Y');
  let copyNewZoneInfo = zoneInfo
  console.log("copyNewZoneInfo", copyNewZoneInfo);
  copyNewZoneInfo.zoneName = zoneInfo.zoneName.trim()
  console.log("copyNewZoneInfo after", copyNewZoneInfo);
  const response = await manageZoneDetail(copyNewZoneInfo) 
  if(response.status == 200){
  zoneDDFun()
  setNewZonePopup(false);
  setIsApprovalOpen(1) //Insert success has value 1
  zoneInfoObjectClear();
  setShowZonePopup(0)
   }else if(response.status == 400){
    setNewZonePopup(false);
    setZoneErrMsg(true)
    console.log("This is the error for status 400", response.data.errors[0].error)
      setErrorPopup(response.data.errors[0].error) //remove
    if((response.data.errors[0].error).startsWith("Z_ERR")){
      setErrorPopup(response.data.errors[0].message);
      setZoneErrMsg(true);
    }
  }
  else{
    setErrorPopup("Something Went Wrong");
    setZoneErrMsg(true);
  } 
  setLoader(false);
  
}

console.log(moment(new Date(zoneInfo.startDate)).format('MM-DD-YYYY'),"The date iu fbvho", zoneInfo.startDate);
console.log(zoneInfo.startDate, "The start date i the form");

//newZone popup
  const renderNewZone = () => {
    return (
      <>
      {console.log("start date for new zone", zoneInfo.startDate)}
        <Grid container spacing={2} style={{ padding: '0px 10px' }}>
          <Grid item md={6}  lg={4}  sm={6} >
            <RACTextbox
              required
              value={zoneInfo.zoneNumber}
              inputlabel="Zone"
              minlength = {4}
              maxlength = {5}
              OnChange={(e) => (handleOnchangeFields(e, "zoneNumber"))}
            ></RACTextbox>
          </Grid>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACTextbox
              required
              value={zoneInfo.zoneName}
              inputlabel="Zone Name"
              OnChange={(e) => (handleOnchangeFields(e, "zoneName"))}
            ></RACTextbox>
          </Grid>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACSelect
              required
              inputLabel="Company Name"
              options={companyDropdown}
              defaultValue={zoneInfo.companyCode ? zoneInfo.companyCode : '0'}
              onChange={(e) =>
                (handleOnchangeFields(e, "companyCode"))}
            ></RACSelect>

          </Grid>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACSelect
              required
              inputLabel="Cost Zone"
              options={costZoneDropdown}
              defaultValue={zoneInfo.costZoneNumber ? zoneInfo.costZoneNumber : '0'}
              onChange={(e) =>
                (handleOnchangeFields(e, "costZoneNumber"))}
            ></RACSelect>

          </Grid>

          <Grid item md={6}  lg={4}  sm={6}>
            <RACDatePicker 
              inputProps={{
                min: moment().format("YYYY-MM-DD"),
              }}   required label="Start Date" onChange={(e) => (handleOnchangeFields(e, "startDate"))} value={zoneInfo.startDate}/>
          </Grid>

          <Grid style={{ height: "69px" , marginTop: "20px"}} item  md={12}  lg={12}  sm={12}>
          <Grid  style={{ float: "right", display: 'flex', gap:'16px' }}>
            <RACButton
              variant="outlined"
              color="primary"
              style={{ float: 'left', paddingTop:'0',paddingBottom:'0'  }}
              onClick={() => (setNewZonePopup(false), zoneInfoObjectClear())}
            >
              Cancel
            </RACButton>
              <RACButton
              disabled={(zoneInfo.startDate!='' && zoneInfo.zoneNumber !='' &&  zoneInfo.companyCode !="0" && zoneInfo.companyCode !=""  && zoneInfo.zoneName !='' && zoneInfo.costZoneNumber !="0" && zoneInfo.costZoneNumber !="") ? false : true}
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                onClick={() => insertZone()}
              >
                Save
              </RACButton>
            </Grid>

          </Grid>
        </Grid>
      </>
    );
  }
//updateZone popup
  const renderUpdateZone = () => {
    return (
      <>
      {console.log("start date for update zone", zoneInfo.startDate)}
        <Grid container spacing={2} style={{ padding: '0px 10px' }}>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACTextbox
              value={zoneInfo.zoneNumber}
              disabled
              inputlabel="Zone"
              OnChange={(e) => handleOnchangeFields(e, "zone")}
            ></RACTextbox>
          </Grid>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACTextbox
              value={zoneInfo.zoneName}
              inputlabel="Zone Name"
              OnChange={(e) => handleOnchangeFields(e, "zoneName")}
            ></RACTextbox>
          </Grid>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACSelect

              inputLabel="Company Name"
              options={companyDropdown}
              defaultValue={zoneInfo.companyCode ? zoneInfo.companyCode : '0'}
              onChange={(e) => handleOnchangeFields(e, "companyCode")}
            ></RACSelect>

          </Grid>

          <Grid item md={6}  lg={4}  sm={6}>
            <RACTextbox
              value={zoneInfo.companyCode}
              disabled
              inputlabel="Company Code"
              OnChange={(e) => handleOnchangeFields(e, "companyCode")}
            ></RACTextbox>

          </Grid>
          <Grid item md={6}  lg={4}  sm={6}>
            <RACSelect

              inputLabel="Cost Zone"
              options={costZoneDropdown}
              defaultValue={zoneInfo.costZoneNumber ? zoneInfo.costZoneNumber : '0'}
              onChange={(e) =>
                (handleOnchangeFields(e, "costZoneNumber"))}
            ></RACSelect>
          </Grid> 
          <Grid item md={6}  lg={4}  sm={6}>
                <RACDatePicker inputProps={{
                min: moment().format("YYYY-MM-DD")
              }} required label="Start Date" onChange={(e: any) => handleOnchangeFields(e, "startDate")}  value={zoneInfo.startDate} />
          </Grid>
          <Grid style={{ height: "69px" , marginTop: "20px"}} item  md={12}  lg={12}  sm={12}>
          <Grid  style={{ float: "right", display: 'flex', gap:'16px' }}>
              <RACButton
                variant="outlined"
		        style={{ float: 'left', paddingTop:'0',paddingBottom:'0'  }}
		        color="primary"
                onClick={() =>
                  (setUpdateZonePopup(false), zoneInfoObjectClear())
                }
              >
                Cancel
              </RACButton>
              <RACButton
                disabled={(zoneInfo.zoneNumber == "" ||
                zoneInfo.zoneName == "" || zoneInfo.startDate == "" ||  zoneInfo.companyCode == "0" || (zoneInfo.zoneNumber == duplicateZoneInfo.zoneNumber && zoneInfo.zoneName == duplicateZoneInfo.zoneName && zoneInfo.startDate == duplicateZoneInfo.startDate && zoneInfo.companyCode == duplicateZoneInfo.companyCode && zoneInfo.costZoneNumber ==  duplicateZoneInfo.costZoneNumber) || (duplicateZoneInfo.costZoneNumber == null && zoneInfo.costZoneNumber == "0" && zoneInfo.zoneNumber == duplicateZoneInfo.zoneNumber && zoneInfo.zoneName == duplicateZoneInfo.zoneName && zoneInfo.startDate == duplicateZoneInfo.startDate && zoneInfo.companyCode == duplicateZoneInfo.companyCode)  /* JSON.stringify(zoneInfo) == JSON.stringify(duplicateZoneInfo) */) ? true : false}
                variant="contained"
                color="primary"
		            style={{ color: "white" }}
                onClick={() => updateZoneData()}
                >
                Save
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
//rendering the recordData and pagination
  const renderRecordData = () => {
    return (
      <>
        <Grid className={`${Classes.mb0} ${Classes.textRight}`} style={{marginTop: "32px"}}>

          <Grid className={`${progressclasses.totrec}`}>

          { !totRecord || gridFullLoader? null : <Typography >

              {/* # of Records: {pageCount - pginitial == 0 ? (totRecord) : pginitial *25  
              
              /*  totRecord - (pginitial* 25) <  25 ? (pginitial *25) : totRecord - (pginitial* 25)  /{totRecord}{" "}*/}
# of Records:&nbsp;
                    {pginitial == 0
                      ? totRecord < 25 ? totRecord :25 * 1
                      : (pginitial+1) == pageCount
                      ? totRecord
                      : (pginitial + 1) * 25}
                    /{totRecord}
            </Typography>}

          </Grid>

         {!totRecord || gridFullLoader? null : <ReactPaginate

nextLabel=">"

onPageChange={(num: any) => {
  // setZoneDetailsState({...zoneDetailsState, ['offset'] : `${(num.selected + 1 )}`})
  handlePageClick(num)}}
  initialPage= {pginitial}
pageCount={pageCount} // pageCount needs to be set here.

pageRangeDisplayed={2}

marginPagesDisplayed={0}

previousLabel="<"

pageClassName={progressclasses.pagination}

pageLinkClassName={progressclasses.pagelink}

previousLinkClassName={

  pginitial === 0

    ? progressclasses.paginationprevnextdisable

    : progressclasses.paginationprevnext

}
disableInitialCallback={true}
nextLinkClassName={

   pginitial === pageCount- 1

    ? progressclasses.paginationprevnextdisable

    : progressclasses.paginationprevnext

}

breakLabel=""

breakClassName={progressclasses.pagination}

breakLinkClassName={progressclasses.pagination}

containerClassName={progressclasses.pagination}

activeClassName={progressclasses.pageactive}

forcePage={pginitial === 0 ? 0 : undefined}

/>}

        </Grid>
      </>
    )
  }
//Rendering the okPopup
  const renderOkPopup = () => {
    return (
      <>
        <Grid >
          <Grid style={{ textAlign: 'center', marginBottom: '5%', padding: "0px" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography variant='h5' style={{ textAlign: 'center', marginBottom: '5%' }}> { isApprovalOpen == 1 ?  insertZoneSuccessText : isApprovalOpen == 2 ? updateZoneSuccessText : '' }</Typography>

          </Grid>
          <Grid style={{
            display: 'flex',
            justifyContent: 'center', margin: '5%'
          }}>

            {/* setIsApprovalOpen value 0 to close the pop up */}
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => (setIsApprovalOpen(0), zoneDataFun('', userResponseFranchisee, userResponseCompanyCode))
              }
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
//For handling the input field changes in the filter popup
  const handleOnchangeFilter = (e: any, field: string) => {
    setZoneDetailsState({ ...zoneDetailsState, [field]: e });
  }
//handling the input field changes in the newzone and updatezone 
  const handleOnchangeFields = (e: any, field: string) => {

    console.log(e,"The current value", field);
    
    if(field == 'zoneNumber'){
         setZoneInfo({ ...zoneInfo, [field]: e.target.value.replace(/\D/g, '')})
    }else if (field == 'startDate'){
      const date1 = moment(e, "YYYY-MM-DD");
        const date3 = moment().format("YYYY MM DD");
        if(date1.isBefore(date3)){
          setDateError(true);
          setNewZonePopup(false);
          setUpdateZonePopup(false);
          setZoneErrMsg(true);
          setErrorPopup("Cannot start the zone in the previous date");
        }else{
          setDateError(false);
          setZoneInfo({ ...zoneInfo, [field]: e })
        }
        }else if(field == 'zoneName'){
          setZoneInfo({ ...zoneInfo, [field]: e.target.value.replace(/^[\s\t]*/, "")})
        }
        else{
            setZoneInfo({ ...zoneInfo, [field]: e.target.value.trim() })
        }
    }

  return (
   <>
    {/* {loader == true ? (
        <Grid style={{position: "fixed", background: "#f7f5f5", width: "100%", height: "100%", top: 0, left: 0, opacity: 0.6, zIndex: 1000, textAlign: "center", margin: "0px 0px"}}>
            <Grid style={{display: "block", position: "fixed", zIndex: 9999999, top: "40%", right: "50%"}}>
                <CircularProgress />
            </Grid>
        </Grid>
      ) : ( */}
       
        { loader  ? <Grid  className={ class1.masterLoader}>
          <Grid className={class1.Loader}>
          <CircularProgress />
          </Grid>
        </Grid>: null}
        <Grid style={{ /* backgroundColor: "red",  */width: "100%", height: "auto", paddingTop: "0.75rem", marginBottom: "60px" }}>
          <Grid style={{position: "relative", top: "10px", width: "100%", left: "0", zIndex: "1"}}>
            <Typography style={{ float: "left", position: "relative", top: "12px" }}
              variant="h4">
                Zone Details
            </Typography>
            <RACButton
              variant="contained"
              color="primary"
              disabled = {zoneBinded == true ? false : true}
              onClick={() =>
                filterPopup == true ? setFilterPopup(false) : setFilterPopup(true)
              }
              style={{ float: "right", color: "white", marginBottom: "10px" }}
            >
              <FilterWhite></FilterWhite>
              <Typography
                style={{ position: "relative", left: "6px" }}
                variant="h6"
              >
                Filter
              </Typography>
              {filterPopup == true ? (
                <UpArrowFilter style={{ marginLeft: "10px" }} />
              ) : (
                <DownArrowFilter style={{ marginLeft: "10px" }} />
              )}
            </RACButton>
          </Grid>
        {filterPopup == true ? openFilterZone() : ""}
          <Card style={{ width: "100%", borderRadius: "12px", marginTop: filterPopup ? "5px" : "77px",  marginBottom: "5%"}}>
            <CardContent style={{paddingBottom: "0px"}} >

              { gridLoader == false /* &&  zoneGridData.length != 0 */ ?               
              (
                <Grid >
                  <Grid className={Classes.zoneDetailGrid} style={{position:'relative'}} >
                    <RACTable className={Classes.tableCell}
                      renderTableHead={renderZoneTableHeader}
                      renderTableContent={renderZoneTableBody}
                    ></RACTable>
                  </Grid>
                  {renderRecordData()}
                </Grid>
              ) : (
                <Grid>
                <RACTable renderTableHead={rendernorecordsheader} />
                <Grid item className={progressclasses.textSpaceNorecords} style={{height: "25vh"}}>
                    <Grid className={progressclasses.textcenterNorecords}>
                        <NorecordsIcon className={progressclasses.racErrorIcon} />
                    </Grid>
                    <Grid className={progressclasses.textcenterImage}>
                                    No Records Found !
                    </Grid>
                  </Grid>
                </Grid>)                
              }
            </CardContent>
          </Card>
          <RACModalCard
            isOpen={updateZonePopup}
            maxWidth="sm"
            closeIcon={true}
            borderRadius="10px"
            onClose={() => (setUpdateZonePopup(false), zoneInfoObjectClear())}
            children={renderUpdateZone()}
            title="Update Zone"
          />
          <Card
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              zIndex: "1",
              left: "0",
            }}
          >
            <CardContent>
              <Grid style={{ float: "right" }}>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ bottom: "7px" }}
                  disabled={canAccess ? false  : true }
                  onClick={() => { 
                    console.log(validateApprover(menuConfigArray,'Zone'), 'The user dfj')
                    if(validateApprover(menuConfigArray,'Zone')){setNewZonePopup(true), setZoneInfo({...zoneInfo, ["newZone"]: "Y"})}
                  else{
                    // alert('Not allowed')
                    setZoneErrMsg(true);
                    setErrorPopup("Access to this feature is currently restricted to this user");
    
                  }}}
                >
                  New Zone
                </RACButton>
              </Grid>
            </CardContent>
          </Card>
          <RACModalCard
            isOpen={newZonePopup}
            maxWidth="sm"
            closeIcon={true}
            borderRadius="10px"
            onClose={() => (setNewZonePopup(false), zoneInfoObjectClear())}
            children={renderNewZone()}
            title="New Zone"
            />
          <RACModalCard
            isOpen={isApprovalOpen!=0?true : false}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="10px"
            onClose={() => (setIsApprovalOpen(0))}
            children={renderOkPopup()}
          />
          <RACModalCard
            isOpen={zoneErrMsg}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => (setZoneErrMsg(false), zoneDataFun('', userResponseFranchisee, userResponseCompanyCode))}
            children={ZoneError()}
          />
        </Grid>

        </>

      
    
)
}


