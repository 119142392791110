/* eslint-disable */
import {
    RACSelect,
    Typography,
    RACButton,
    Grid,
    CardContent,
    Card,
    RACCheckBox,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACTextbox,
    RACModalCard,
    RACDatePicker,
    CircularProgress,
  } from "@rentacenter/racstrap";
  import React, { useContext, useEffect, useState } from "react";
import PackageContext, { packageContext } from "./PackageContext";
export default function PackageFooter(){
    let {loader, setLoader, enableGrid, setEnableGrid, pginitial,criteriaFirstRecord, setCriteriaFirstRecord,
        setpginitial, tableRecord, setTableRecord, pageCount, setPageCount, 
        totRecord, setTotRecord, noRecordsFound, setNoRecordsFound, filterPopup, setFilterPopup,
        errorMsgPackage, setErrorMsgPackage, gridErrorPopup, setGridErrorPopup, applyFilter, filterPackagePayload, setFilterPackagePayload, 
       zoneDd, setZoneDd, bracketDd, setBracketDd, departmentDd, setDepartmentDd, subDepartmentDd, setSubDepartmentDd,
        packageNameDd, setPackageNameDd, dropdownLoader, updatePackagePopup, setUpdatePackagePopup, newPackagePopup, setNewPackagePopup, isPackageFormOpen, setIsPackageFormOpen, 
        formRecords, setFormRecords, 
        backupRecords, setBackupRecords, formatData, count, setCount, duplicateDepartmentDropdown, setDuplicateDepartmentDropdown, 
        duplicateSubdepartmentDropdown, setDuplicateSubdepartmentDropdown, masterLoader, setMasterLoader, 
        isSubmitButtonClicked, setIsSubmitButtonClicked, submitClick, submitButtonStatus, setSubmitButtonStatus, handleSubmitButtonValidation, handleCancelClick, 
        ApprovedBy, setApprovedBy, selectQueueType, setSelectQueueType, editRecordsArray, setEditRecordsArray, companyCodeZone, setCompanyCodeZone, franchisee, setFranchisee, startDateCheck, setStartDateCheck} = useContext(packageContext)

        const handleNewClick = () => {
          debugger;
          const criteriaFirstRecordObj : any = {
            rmsDepartmentNumber: "0",
            rmsSubdepartmentNumber: "0",
            departmentDesc: "", 
            subdepartmentDesc: "", 
            bracketName: "",
            minQuantity: "",
            bracketNumber: "0", 
            maxQuantity: "",
            duplicateSubdepartmentDd: [],
            duplicateDepartmentDd:[],
            duplicateBracketDd:[],
            rmsItemNumber: null}
          criteriaFirstRecordObj['duplicateSubdepartmentDd'] = subDepartmentDd
          criteriaFirstRecordObj['duplicateDepartmentDd'] =  departmentDd
          criteriaFirstRecordObj['duplicateBracketDd'] =  bracketDd
          console.log("ghcgvjbkl;", criteriaFirstRecordObj);
          setCriteriaFirstRecord(criteriaFirstRecordObj);

            formatData.duplicateDepartmentDd= JSON.parse(JSON.stringify(departmentDd)),
          formatData.duplicateSubdepartmentDd= JSON.parse(JSON.stringify(subDepartmentDd)),
          formatData.duplicateBracketDd= JSON.parse(JSON.stringify(bracketDd))
          setFormRecords(formatData);
          setBackupRecords(formatData);
          setNewPackagePopup(true);
          setIsPackageFormOpen(true); 
          console.log("count before", count);
        }
        
{
  console.log("formRecords for submit", formRecords)
}

const handleSubmitClick = () => {
  debugger;
  console.log("to check the startDateCheck", startDateCheck)
        if(formRecords.startDate == startDateCheck){
          setErrorMsgPackage("Startdate should not be same as the existing date.");
          setGridErrorPopup(true)
        }else{
          let errorStatement: Array<string> = [];//array
  let criteriaArr = JSON.parse(JSON.stringify(formRecords?.criteria))
  console.log("criteriaArr for the", criteriaArr)
  let checkMin = 0
  for(let i= 0; i < criteriaArr.length ; i++){
    if(criteriaArr[i].rmsDepartmentNumber == "0" || criteriaArr[i].minQuantity == "" || criteriaArr[i].maxQuantity == ""){
      //push S1
      errorStatement.push("All the mandatory fields should be filled")
    }
    if(criteriaArr[i].maxQuantity == 0){
      //push S1
      errorStatement.push("Max.Quantity should not be 0")
    }
    if(Number(criteriaArr[i].maxQuantity) < Number(criteriaArr[i].minQuantity)){
      errorStatement.push("Max.Quantity should not be lesser than Min.Quantity") 
    }
    checkMin = checkMin + criteriaArr[i].minQuantity
    /* if(!(criteriaArr[i].minQuantity > 0)){
      errorStatement = errorStatement == "" ? "At least any one of the minimum quantity should be greater than 0": errorStatement + ", At least any one of the minimum quantity should be greater than 0"
    } */
    for(let j = i + 1; j < criteriaArr.length; j++){
      console.log("i, j", i, j)
      if((criteriaArr[i].rmsDepartmentNumber == criteriaArr[j].rmsDepartmentNumber) && (criteriaArr[i].rmsSubdepartmentNumber == criteriaArr[j].rmsSubdepartmentNumber) &&  (criteriaArr[i]?.rmsItemNumber == criteriaArr[j]?.rmsItemNumber) &&  (criteriaArr[i].bracketNumber == criteriaArr[j].bracketNumber)){
        errorStatement.push("Duplicate Item Criteria Present") 
      }
    }
    if(errorStatement.length > 0){ //lengeth > 0 S2

      // Remove duplicates using Set
      const uniqueArray = [...new Set(errorStatement)];// take unnique S3
      console.log("uniqueArray array for checking if the error message is unique", uniqueArray)
      // Join the array back into a string
      let resultString = uniqueArray.join(', ');// join S4
      console.log("result of the resultString", resultString)
      setErrorMsgPackage(resultString);
    }/* else if(errorStatement.length == 1){
      console.log("for checking the errorStatement")
      setErrorMsgPackage(errorStatement[0]);
    } */
  }
  console.log(errorStatement, "the log errorStatement");
  
  console.log("checkMin", checkMin)
  if(checkMin == 0){
    errorStatement.push("Atleast one of the minQuantity should be greater than 0")
    if(errorStatement.length > 0){ //lengeth > 0 S2

      // Remove duplicates using Set
      const uniqueArray = [...new Set(errorStatement)];// take unnique S3
      console.log("uniqueArray array for checking if the error message is unique", uniqueArray)
      // Join the array back into a string
      let resultString = uniqueArray.join(', ');// join S4
      console.log("result of the resultString", resultString)
      setErrorMsgPackage(resultString);
    }
  }
  if(errorStatement.length > 0){
    setGridErrorPopup(true)
  }else if(errorStatement.length == 0){
    submitClick()
  }
        }
  
}


        

    const NewPackageCard = () => {
        return(<>
            <Card
                        style={{
                          position: "fixed",
                          bottom: "0px",
                          width: "100%",
                          zIndex: "1",
                          left: "0",
                        }}
                      >
                        <CardContent>
                          <Grid style={{ float: "right" }}>
                            <RACButton
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ bottom: "7px"}}
                              onClick={() => {
                                handleNewClick()
                              }}
                              disabled= {dropdownLoader == true? true: false}
                            >
                              New Package
                            </RACButton>
                          </Grid>
                        </CardContent>
                      </Card></>)
    }

    const submitPackageCard = () => {
        return(<><Card
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              zIndex: "1",
              left: "0",
            }}
          >
            <CardContent>
              <Grid style={{ float: "right" }}>
              <RACButton
                variant="outlined"
                color="primary"
                /* onClick={() => setFormRecords({...formatData})} */
                style={{ float: 'left', marginBottom: "8px", marginRight: "20px", paddingTop: "8px"}}
                onClick={() => handleCancelClick()}
              >
                Cancel
              </RACButton>
              <RACButton
                
                variant="contained"
                color="primary"
                disabled={handleSubmitButtonValidation()}
                style={{ color: "white", marginBottom: "10px" }}
                onClick={() => {
                  handleSubmitClick()
                }}
              >
                Submit
              </RACButton> 
              </Grid>
            </CardContent>
          </Card></>)
    }
    return(<>{isPackageFormOpen ? submitPackageCard() : NewPackageCard()}
   </>)
}