import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { LARGE_FONT_SIZE_PX } from '../../constants/contants';
import clsx from 'clsx';

export interface LetterAvatarProps {
  text: string;
  backgroundColor: string;
  textColor: string;
  isMirror?: boolean;
}
const useStyles = makeStyles((theme: any) => ({
  root: {
    width: `${theme.typography.pxToRem(42)} !important`,
    height: `${theme.typography.pxToRem(42)} !important`,
    color: ({ textColor }: Partial<LetterAvatarProps>) =>
      `${textColor} !important`,
    backgroundColor: ({ backgroundColor }: Partial<LetterAvatarProps>) =>
      `${backgroundColor} !important`,
  },
  text: {
    fontSize: `${theme.typography.pxToRem(LARGE_FONT_SIZE_PX)} !important`,
  },
  mirror: {
    transform: 'scale(-1, 1)',
  },
}));

export const LetterAvatar = ({
  text,
  backgroundColor,
  textColor,
  isMirror,
}: LetterAvatarProps) => {
  const ownClasses = useStyles({ backgroundColor, textColor });

  return (
    <Avatar className={ownClasses.root}>
      <Typography
        className={clsx(ownClasses.text, isMirror && ownClasses.mirror)}
        variant="body1"
      >
        {text}
      </Typography>
    </Avatar>
  );
};
