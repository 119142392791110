import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}
interface Props extends SwitchProps {
  classes: Styles;
}

export type Position = 'Top' | 'Bottom' | 'left' | 'Right';

export interface ToggleProps {
  className?: any;
  toggleColor?: string;
  name?: string;
  id?: string;
  OnChange?: (e: any) => void;
  OnClick?: (e: any) => void;
  disabled?: boolean;
  labelVariant?: Variant;
  labelPosition?: Position;
  ToggleSizeClassName?: any;
  leftText?: boolean;
  rightText?: boolean;
  leftLabel?: any;
  rightLabel?: any;
  checked?: boolean;
  labelClassName?: any;
  labelGridClassName?: any;
  value?: any;
  GridClassName?: any;
  style?: any;
}
export function RacToggle(props: ToggleProps) {
  const ToggleSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 39,
        height: 22,
        padding: 0,
        margin: theme.spacing(0),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(1rem)',
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: props.toggleColor,
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: props.toggleColor,
          border: '0.375rem solid #fff',
        },
      },
      thumb: {
        width: 21,
        height: 20,
      },
      track: {
        borderRadius: 26 / 2,
        border: `0.063rem solid ${theme.palette.grey[400]}`,
        backgroundColor: '#dce8de',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      checked: {},
      focusVisible: {},
    })
  )(({ classes, ...props }: Props) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  return (
    <Grid className={clsx(props.GridClassName)}>
      {props.leftText === true ? (
        <Grid className={clsx(props.labelGridClassName)}>
          <Typography
            variant={props.labelVariant || 'h5'}
            component="label"
            className={clsx(props.labelClassName)}
          >
            {props.leftLabel}
          </Typography>
        </Grid>
      ) : null}
      <Grid className={clsx(props.ToggleSizeClassName)}>
        <ToggleSwitch
          className={clsx(props.className)}
          style={props.style}
          checked={props.checked}
          onChange={props.OnChange}
          name={props.name}
          id={props.id}
          value={props.value}
          onClick={props.OnClick}
          disabled={props.disabled}
        />
      </Grid>
      {props.rightText === true ? (
        <Grid className={clsx(props.labelGridClassName)}>
          <Typography
            variant={props.labelVariant || 'h5'}
            component="label"
            className={clsx(props.labelClassName)}
          >
            {props.rightLabel}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}
