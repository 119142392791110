/* eslint-disable */
import React, { useContext } from "react";
import AutoTermGrid from "../autoTerm/AutoTermGrid";
import AutoTermContextProvider from '../autoTerm/AutoTermContext';


export default function  AutoTerm(){
    return (
        <AutoTermContextProvider>
           <AutoTermGrid/>
        </AutoTermContextProvider>
    );
}