/* eslint-disable */
import {
  Typography,
  Grid,
  CardContent,
  Card,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress,
} from "@rentacenter/racstrap";
import { ReactComponent as SortdownIcon } from "../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../assets/images/sort-icon-up.svg";
import { PromoSchedulerContext } from "./PromoSchedulerContext";
import { useContext, useState } from "react";
import { globalStyles } from "../Pricing/Styles/globalstyles";
import { ProgressBar } from "../Pricing/Styles/ProgressStyles";
import { ReactComponent as NorecordsIcon } from "../../assets/images/No-records.svg";
import { getPromoSchedulerGrid } from "../../api/user";
export default function PromoSchedulerAssociatedStoresGrid() {
  const classes = globalStyles();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const DEFAULT_LIMIT = 18;
  const DEFAULT_OFFSET = 1;
  const [loader, setLoader] = useState<boolean>(false);
  const progressclasses = ProgressBar();
  const {
    postObj,
    assStoreHeader,
    associatedStores,
    setAssociatedStores,
    sortDirection,
    noAssStoresFound,
    setNoAssStoresFound,
    emptyAssignStoreObj,
    clearAssStoresCollapse,
    filterData,
  } = useContext(PromoSchedulerContext);

  const bindGridHead = () => {
    return (
      <>
        {assStoreHeader.map((value: any, index: any) => {
          return value.columnTitle == "Action" ? (
            <RACTableCell className={`${classes.textCenter}`}>
              Action
            </RACTableCell>
          ) : (
            <RACTableCell key={index}>
              {value.IsSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + "  "}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ml0} />
                    ) : (
                      <SortdownIcon className={classes.ml0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + "  "}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  };

  const buildTableContent = () => {
    return (
      <>
        {associatedStores?.map((value: any, index: any) => {
          return (
            <>
              <RACTableRow key={index} className={classes.bgTransparent}>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  <Typography
                    title={
                      value.organizationName 
                    }
                  >
                    {value.organizationName?.length > 38
                      ? value.organizationName.slice(0,38) + "..."
                      : value.organizationName
                      ? value.organizationName
                      : "-"}
                  </Typography>
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.stateCode ? value.stateCode : "-"}
                </RACTableCell>
                <RACTableCell
                  style={{ fontSize: "0.85rem" }}
                  title={value.storeNumber > 30 ? value.storeNumber : ""}
                >
                  {value.storeNumber?.length > 30
                    ? value.storeNumber.slice(0, 30) + "..."
                    : value.storeNumber
                    ? value.storeNumber
                    : "-"}
                </RACTableCell>
              </RACTableRow>
            </>
          );
        })}
      </>
    );
  };
  async function fetchNextData() {
    try {
      filterData.companyCode = undefined;
      filterData.hierarchyType = undefined;
      filterData.hierarchyValue = undefined;
      filterData.promoGroupName = undefined;
      filterData.promoType = undefined;
      filterData.startDate = undefined;
      filterData.endDate = undefined;
      filterData.limit = undefined;
      filterData.offset = undefined;
      filterData.storeLimit="250"
      if (associatedStores.length > 10) {
        setLoader(true);
        const assGridResponse: any = await getPromoSchedulerGrid(filterData);
        setLoader(false);
        if (assGridResponse && assGridResponse?.status == 200) {
          if (assGridResponse?.response?.length < DEFAULT_LIMIT) {
            setHasMore(false);
          }
          filterData.storeOffset = (DEFAULT_OFFSET +  Number(filterData.storeOffset)).toString()
          setAssociatedStores([
            ...associatedStores,
            ...assGridResponse?.data.result[0]?.associatedStores?.sort(
              (a: any, b: any) => (a["storeNumber"] < b["storeNumber"] ? -1 : 1)
            ),
          ]);
        } else {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (err: any) {
      setLoader(false);
      setHasMore(false);
    }
  }

  return (
    <>
      <Grid style={{ marginBottom: "51px", marginTop: "16px" }}>
        <Typography style={{ float: "left" }} variant="h5">
          Associated Stores
        </Typography>
        {clearAssStoresCollapse == true ? (
          <>
            <Typography style={{ float: "right" }}>
              <a
                style={{
                  color: "#2179fe",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setNoAssStoresFound(true);
                  emptyAssignStoreObj();
                  postObj.clonedPromotionId = undefined;
                }}
              >
                Clear Associated Stores
              </a>
            </Typography>
          </>
        ) : null}
      </Grid>
      {noAssStoresFound == false ? (
        <Card
          style={{
            width: "100%",
            borderRadius: "12px",
            marginBottom: "5%",
            height: "862px",
          }}
        >
          <CardContent style={{ paddingBottom: "0px" }}>
            <>
              {/* <InfiniteScroll
                next={fetchNextData}
                dataLength={associatedStores?.length}
                hasMore={hasMore}
                height={"840px"}
                loader={
                  loader ? (
                    <Grid className={`${classes.infiniteLoader}`}>
                      <CircularProgress />
                    </Grid>
                  ) : null
                }
              >
                <Grid
                 className={classes.associatedStoresGrid}
                 >
                  <Grid className={classes.tableCell}>
                    <RACTable
                      renderTableHead={bindGridHead}
                      renderTableContent={buildTableContent}
                    ></RACTable>
                  </Grid>
                </Grid>
              </InfiniteScroll> */}
              <Grid
                 className={classes.associatedStoresGrid}
                 >
                  <Grid className={classes.tableCell}>
                    <RACTable
                      renderTableHead={bindGridHead}
                      renderTableContent={buildTableContent}
                    ></RACTable>
                  </Grid>
                </Grid>
            </>
          </CardContent>
        </Card>
      ) : (
        <Card
          style={{
            width: "100%",
            borderRadius: "18px",
            marginBottom: "5%",
            height: "273px",
          }}
        >
          <CardContent>
            <Grid>
              <Grid
                item
                className={progressclasses.textSpaceNorecords}
                style={{ height: "25vh" }}
              >
                <Grid className={progressclasses.textcenterNorecords}>
                  <NorecordsIcon className={progressclasses.racErrorIcon} />
                </Grid>
                <Grid className={progressclasses.textcenterImage}>
                  No associated stores found 
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
