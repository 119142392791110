/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";
import { ContainerContext } from "../../../app/App";
import updatePricingQueueStatusAPI, { addPricingQueueAPI, autoTermDetailAPI } from "../../../api/user";
import moment from "moment";
import { getEmployeeInfoAndReferenceData, validateApprover } from "../../shared/getEmployeeInfoAndReferenceData";

// Create context with an initial value of an empty string
export const autoTermContext = createContext<any>({});

// Interface for the filter options
export interface FilterInterface {
  zoneNumber: string[];
  companyCode?: string[];
  departmentNumber?: string;
  subdepartmentNumber?: string;
  bracketNumber?: string;
  brandName?: string;
  rmsItemNumber?: string;
  modelNumber?: string;
  limit?: string;
  offset?: string;
}
//interface for the auto Term discount Range
export interface RangeObject {
  startDay: "string";
  endDay: "string";
  percentDiscount: "string";
}
// Interface for the error object
export interface ErrorObjectInterface {
  isOpen: boolean;
  statement: string;
}

// Create the context provider function
export default function AutoTermContextProvider(props: any) {
  // State variables
  const containerData = useContext(ContainerContext);
const filterObj ={
  zoneNumber: [],
  companyCode: [''],
  departmentNumber: '',
  subdepartmentNumber:'',
  bracketNumber: '',
  brandName:'',
  rmsItemNumber: '',
  modelNumber: '',
  limit: '25',
  offset: '0',
}
  const [departmentOptions, setDepartmentOptions] = useState<any[]>([]);
  const [filterReqAtApply,setFilterReqAtApply] = useState();
  const [subDepartmentOptions, setSubDepartmentOptions] = useState<any[]>([]);
  const [zoneOptions, setZoneOptions] = useState<any[]>([]);
  const [zoneSelected, setZoneSelected] = useState<any[]>([]);
  const [loader, setLoader] = useState<any>(false);
  const [displayGrid, setDisplayGrid] = useState<boolean>(false);
  const [noRecords, setNoRecords] = useState<boolean>(false);
  const [pgCount, setPgCount] = useState<number>(0);
  const [companyCode, setCompanycode] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [formRecords, setFormRecords] = useState<any>({});
  const [emailId,setEmailId] = useState('');
  const [employeeId,setEmployeeId] = useState('')
  const [count,setCount] = useState(false);
  const [isSLACompleted, setIsSLACompleted]= useState(false);
  const [isSLACancel,setIsSLACancel] = useState(false); 
  const [isApproved, setIsApproved] = useState(false);
  const [backupRecords, setBackupRecords] = useState<any>({});
  const [isNewAutoTerm, setIsNewAutoTerm] = useState<any>({});
  const [approvalPopUp, setApprovalPoup] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [gridData, setGridData] = useState<any>([]);
  const [isAutoTermFormOpen, setIsAutoTermFormOpen] = useState<boolean>(false);
  const [editRecordsArray, setEditRecordsArray] = useState<any[]>([]);
  const [gridLoader,setGridLoader]  = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [approvedBy,setApprovedBy] = useState();
  const[bracketOptionsCopy,setBracketOptionsCopy]= useState<any>([])
  const [errorPopup,setErrorPopup] = useState<ErrorObjectInterface>({
    isOpen:false,
    statement:''
  })
  const createNewRange ={ startDay: "", endDay: "", percentDiscount: "" }
  const [addnewRange, setAddNewRange]= useState( { startDay: "", endDay: "", percentDiscount: "" });
  const [ bracketOptions, setBracketOptions]= useState<any>([{
    label: "Select",
    value: "",
    deptId: "",
  }])
  const [isFranchiseeUser, setIsFranchiseeUser] = useState(false);
  const [tableHeader, setTableHeader] = useState<any>([
    {
      heading: "Zone",
      isSortable: true,
      sortDirection: "DESC",
      key: "zoneNumber",
      isSorted: true,
    },
    {
      heading: "Department",
      isSortable: true,
      sortDirection: "DESC",
      key: "departmentDesc",
      isSorted: true,
    },
    {
      heading: "Sub-Department",
      isSortable: true,
      sortDirection: "DESC",
      key: "subdepartmentDesc",
      isSorted: true,
    },
    {
      heading: "Bracket",
      isSortable: false,
      sortDirection: "DESC",
      key: "bracketNumber",
      isSorted: false,
    },
    {
      heading: "Brand",
      isSortable: false,
      sortDirection: "DESC",
      key: "brandName",
      isSorted: false,
    },
    {
      heading: "Model #",
      isSortable: false,
      sortDirection: "DESC",
      key: "modelNumber",
      isSorted: false,
    },
    {
      heading: "RMS #",
      isSortable: false,
      sortDirection: "DESC",
      key: "rmsItemNumber",
      isSorted: false,
    },
    {
      heading: "Tier",
      isSortable: false,
      sortDirection: "DESC",
      key: "termConfigName",
      isSorted: false,
    },
    {
      heading: "Start Date",
      isSortable: true,
      sortDirection: "DESC",
      key: "startDate",
      isSorted: true,
    },
    {
      heading: "End Date",
      isSortable: true,
      sortDirection: "DESC",
      key: "endDate",
      isSorted: true,
    },
    {
      heading: "Action",
      isSortable: false,
      sortDirection: "DESC",
      key: "action",
      isSorted: false,
    },
  ]);
  const [pricingQueueId,setPricingQueueId] = useState('');
  const [isApprovalOpen,setIsApprovalOpen] = useState(false);
  const formatData = {
    zone: [],
    companyCode: [],
    departmentNumber: "",
    subdepartmentNumber: "",
    bracketName: "",
    brandName: "",
    rmsItemNumber: "",
    modelNumber: "",
    tier: "",
    startDate: "",
    endDate: "",
    limit: "",
    offset: "",
    range: [
      {
        startDay: "0",
        endDay: "30",
        percentDiscount: "",
      },
      {
        startDay: "31",
        endDay: "60",
        percentDiscount: "",
      },
      {
        startDay: "61",
        endDay: "90",
        percentDiscount: "",
      },
      {
        startDay: "91",
        endDay: "120",
        percentDiscount: "",
      },
      {
        startDay: "121",
        endDay: "150",
        percentDiscount: "",
      },
      {
        startDay: "151",
        endDay: "180",
        percentDiscount: "",
      },
      {
        startDay: "181",
        endDay: "210",
        percentDiscount: "",
      },
      {
        startDay: "211",
        endDay: "240",
        percentDiscount: "",
      },
      {
        startDay: "241",
        endDay: "270",
        percentDiscount: "",
      },
      {
        startDay: "271",
        endDay: "300",
        percentDiscount: "",
      },
      {
        startDay: "301",
        endDay: "330",
        percentDiscount: "",
      },
      {
        startDay: "331",
        endDay: "360",
        percentDiscount: "",
      },
      {
        startDay: "361",
        endDay: "390",
        percentDiscount: "",
      },
      {
        startDay: "391",
        endDay: "420",
        percentDiscount: "",
      },
      {
        startDay: "421",
        endDay: "450",
        percentDiscount: "",
      },
      {
        startDay: "451",
        endDay: "480",
        percentDiscount: "",
      },
      {
        startDay: "481",
        endDay: "510",
        percentDiscount: "",
      },
      {
        startDay: "511",
        endDay: "540",
        percentDiscount: "",
      },
      {
        startDay: "541",
        endDay: "570",
        percentDiscount: "",
      },
      {
        startDay: "571",
        endDay: "600",
        percentDiscount: "",
      },
      {
        startDay: "601",
        endDay: "630",
        percentDiscount: "",
      },
      {
        startDay: "631",
        endDay: "660",
        percentDiscount: "",
      },
      {
        startDay: "661",
        endDay: "690",
        percentDiscount: "",
      },
      {
        startDay: "691",
        endDay: "720",
        percentDiscount: "",
      },
      {
        startDay: "721",
        endDay: "750",
        percentDiscount: "",
      },
      {
        startDay: "751",
        endDay: "780",
        percentDiscount: "",
      },
      {
        startDay: "781",
        endDay: "810",
        percentDiscount: "",
      },
      {
        startDay: "811",
        endDay: "840",
        percentDiscount: "",
      },
      {
        startDay: "841",
        endDay: "870",
        percentDiscount: "",
      },
      {
        startDay: "871",
        endDay: "9999",
        percentDiscount: "",
      },
    ],
  };
  let rangeHeaders = [
    {
      heading: "Days on Rent Start",
      isSortable: true,
      sortDirection: "DESC",
      key: "startDay",
      isSorted: true,
    },
    {
      heading: "Days on Rent End",
      isSortable: true,
      sortDirection: "DESC",
      key: "endDay",
      isSorted: true,
    },
    {
      heading: "Discount %",
      isSortable: true,
      sortDirection: "DESC",
      key: "percentDiscount",
      isSorted: true,
    },
    {
      heading: "Action",
      isSortable: false,
      sortDirection: "DESC",
      key: "action",
      isSorted: true,
    },
  ];
  const[authOpen, setAuthOpen] = useState(false);
  const [zoneSelectedForm,setZoneSelectedForm] = useState([])
  const [selectQueueType,setSelectQueueType] = useState('');
  const [originalSubDeptArr,setOriginalSubDeptArr] = useState<any[]>([])
  const [rangeHeader, setRangeHeader] = useState<any>(rangeHeaders);
  const [filterOptions, setFilterOptions] =
    useState<FilterInterface>(filterObj);

  useEffect(() => {
    getCompanyCodeAndDropdown();
  }, []);
  const createOptionsArray = (
    data: any,
    labelKey: string,
    valueKey: string
  ) => {
    return data.map((item: any) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  };
  // Async function to fetch auto term details
  const fetchAutoTermDetails = async (index: number = 0, request: any) => {
    try {
      debugger;
      setGridLoader(true);
      setDisplayGrid(true);

      let req: any = { ...request };

      // Remove keys with empty or null values
      Object.keys(req).forEach((key: any) => {
        if (req[key] == "" || req[key] === null) {
          delete req[key];
        }
      });

      req["offset"] = JSON.stringify(index + 1);
      setTotalRowCount(0);
      req.zoneNumber.length == zoneOptions.length ? req.zoneNumber = undefined: null;
      req.companyCode = companyCode.length? companyCode : undefined;
      const response: any = await autoTermDetailAPI(req);
      console.log("Thedvjedvthngt3  ",response);
      

      if (response.status === 200) {
        if (response.data?.autoTermDetails) {
          setGridData(response.data.autoTermDetails);
          setDisplayGrid(true);
          setIsFilterOpen(false);
          setNoRecords(false);
          setTotalRowCount(response.data.totalRecordsCount);
          // Calculate pgCount
          setPgCount(Math.ceil(response.data.totalRecordsCount / 25));
        } else {
          console.log(response.data.autoTermDetails, "the autoTermDetails");
          setNoRecords(true);
        }
      } else {
        setDisplayGrid(false);

        setErrorPopup( {
          isOpen: true,
  statement: "Something went wrong"
        })
      }

      setGridLoader(false);
    } catch (error) {
      setDisplayGrid(false);
      setErrorPopup( {
        isOpen: true,
statement: "Something went wrong"
      })
      setGridLoader(false);
      console.log(error, "the log in the autoTerm Context ln 185");
    }
  };


  useEffect(()=>{
  if(count)
  {
    submitClick();
  }
  },[count])
  {
    console.log(errorPopup,"the err hhhhhhhhhhhh");
    
  }
    // Function to validate auto term
    const validateAutoTerm = () => {
      debugger;
      try {
        let arr= JSON.parse(JSON.stringify(formRecords.range))
        const sortedData = arr.sort(
          (a: any, b: any) => a.startDay - b.startDay
        );
  
        console.log(sortedData," The rdtryftguhinj");
        
        if (Number(sortedData[0].startDay) !== 0) {
          // errorPopup.isOpen =true;
          // errorPopup.statement ="Initial Interval missing";
          setErrorPopup ({
            isOpen: true,
    statement: `Interval missing between 0 and ${Number(sortedData[0].startDay - 1)}`
          })
          return 
        }
        if (Number(sortedData[sortedData.length -1].endDay) < 9999) {
          // errorPopup.isOpen =true;
          // errorPopup.statement ="Initial Interval missing";
          setErrorPopup ({
            isOpen: true,
    statement: 'The maximum days on rent end should be equal or greater than 9999 or above'
          })
          return 
        }
  
        for (let i = 0; i < sortedData.length; i++) {
          if (i === sortedData.length - 1) {
            // setCount(true)
            submitClick();

            return
          }
  
          if (
            
            Number(sortedData[i].endDay) < Number(sortedData[i + 1].startDay)
          ) {
            if((Number(sortedData[i].endDay) + 1 )!== Number(sortedData[i + 1].startDay)){
              setErrorPopup ({
                isOpen: true,
        statement: `Interval missing between ${
          Number(sortedData[i].endDay) + 1
        } and ${sortedData[i + 1].startDay - 1}`
              })
            //   errorPopup.isOpen =true;
            //   errorPopup.statement =`Interval missing between ${
            //   Number(sortedData[i].endDay) + 1
            // } to ${sortedData[i + 1].startDay - 1}`
            return
          }}
          else{
            setErrorPopup ({
              isOpen: true,
      statement: "Please correct the range conflicts before submitting"
            })
            // errorPopup.isOpen =true;
            // errorPopup.statement ="You are trying to override an Interval";
            return
        }
        }
      } catch (err: any) {
        console.log(err);
      }
    };
  const getCompanyCodeAndDropdown = async () => {
    try {
      debugger;
      const response : any = await getEmployeeInfoAndReferenceData(containerData, {
        type: ["DEPARTMENT", "SUBDEPARTMENT", 'BRACKET'],
      });
      console.log(
        response,
        "Auto Term Context ln 205 The response from the getEmployeeInfo "
      );
      sessionStorage.setItem("userName", response.userName? response.userName:"");

      if (response.isFranchiseeUser) {
        setIsFranchiseeUser(response.isFranchiseeUser)
          if(response.companyCode?.length>0)
          setCompanycode(response.companyCode);
        else{
        setErrorPopup( {
          isOpen: true,
  statement: "Something went wrong"
        }) 
               setCompanycode(response.companyCode);
      }
      }
      setIsApprover(validateApprover(response.menuConfigArray));
      setEmployeeId(response.employeeId);
        setEmailId(response.emailId);
      console.log(
        createOptionsArray(
          response.dropdownData.department,
          "descEn",
          "departmentNumber"
        ),
        "the log in the dropdown"
      );
      let deptArray = createOptionsArray(
        response.dropdownData.department.sort((a: any, b: any) => a["descEn"].localeCompare(b["descEn"], undefined, { sensitivity: 'base' })
        ),
        "descEn",
        "departmentNumber"
      );
      deptArray.unshift({
        label: "Select",
        value: "",
      });
      setDepartmentOptions(deptArray);
      setZoneOptions(createOptionsArray(response.zone.sort((a: any, b: any) =>
      a["label"].localeCompare(b["label"], undefined, { numeric: 'true' })), "label", "value"));

      let subDeptArray : any = response.dropdownData.subdepartment.map(
        (item: any) => ({
          label: item.descEn,
          deptId: item.departmentNumber,
          value: item.subdepartmentNumber,
        })
      );
      subDeptArray.sort((a: any, b: any) => a["label"].localeCompare(b["label"], undefined, { sensitivity: 'base' })
      ).unshift({
        label: "Select",
        value: "",
      });
      let bracketArr : any = response.dropdownData.bracket.map(
        (item: any) => ({
          label: item.descEn,
          subdeptId: item.subdepartmentNumber,
          value: item.bracketNumber,
        })
      )
      bracketArr.sort((a: any, b: any) => a["label"].localeCompare(b["label"], undefined, { sensitivity: 'base' })
      ).unshift({
        label: "Select",
        value: "",
      });
      setBracketOptions(bracketArr);
      setBracketOptionsCopy(bracketArr);

      setSubDepartmentOptions(subDeptArray);
      setOriginalSubDeptArr(JSON.parse(JSON.stringify(subDeptArray)))
    } catch (error) {
      console.log(error);
      setErrorPopup( {
        isOpen: true,
statement: "Something went wrong"
      })
     
    }
  };
  // Function to validate input
  const validateInput = (key: string, item: any) => {
    debugger;
    if (key.toLowerCase() === "rmsitemnumber") {
      // Replace characters other than numbers with an empty string
      return item.replace(/[^0-9]/g, "");
    } 
    // else if (key.toLowerCase() === "bracketnumber") {
    //   // Replace characters other than alphabets with an empty string
    //   return item.replace(/[^a-zA-Z]/g, "");
    // }

    return item;
  };
    // Async function to submit data
    const submitClick = async () => {
      try {
          setLoader("Y");
          console.log(formRecords,"The keys");
          
          const allowedKeys = ['zone','startDate','termConfigName','departmentNumber','subdepartmentNumber','bracketNumber','rmsItemNumber','brandName', 'modelNumber'];
          let autotermReqPayload = JSON.parse(JSON.stringify(formRecords));
          Object.keys(autotermReqPayload).forEach((item: any)=>{
            if(!(allowedKeys.includes(item)) || ((allowedKeys.includes(item)) && !(autotermReqPayload[item]))){
              delete autotermReqPayload[item]
            }
          })
          autotermReqPayload['autotermRange'] = formRecords.range.map((item : any ) => {
            // Create a new object with the modified key
            return {
                startDay: item.startDay,
                endDay: item.endDay,
                discountPct:  item.percentDiscount.replace('%','')
            };
        });
          let req = {
            schedule: selectQueueType,
            status: "WAITING",
            submittedBy: sessionStorage.getItem('userName'),
            type: "AUTOTERM",
            companyCode:companyCode ? companyCode[0]: undefined,
            queueData: {
              autotermDetails: autotermReqPayload
            }
          }
          const response = await addPricingQueueAPI(req);
  
          if (response.status === 200) {
            setSelectQueueType('')
            setZoneSelected([]);
            setZoneSelectedForm([]);
            setLoader(false);
            setIsNewAutoTerm(false);
            setIsAutoTermFormOpen(false);
            setPricingQueueId(response.data.pricingQueueId);
            setIsApprovalOpen(true);
          } else {
            setErrorPopup( {
              isOpen: true,
      statement: "Something went wrong"
            })
            setLoader(false);
          }
      } catch (error) {
        setErrorPopup( {
          isOpen: true,
  statement: "Something went wrong"
        })
        setLoader(false);
      }
    };

  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any,
    compareBy: string = ""
  ) => {
    try {
      debugger;
      let sortedData;
      if (compareBy == "date") {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) => {
                let date1 = moment(b[columnId]);
                let date2 = moment(a[columnId]);
                return date2.isBefore(date1) ? -1 : 1;
              })
            : dataValues.sort((a: any, b: any) => {
                let date1 = moment(b[columnId]);
                let date2 = moment(a[columnId]);
                return date1.isBefore(date2) ? -1 : 1;
              });
      } else if (compareBy == "number") {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
            : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
      } else {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) =>
                b[columnId] < a[columnId] ? -1 : 1
              )
            : dataValues.sort((a: any, b: any) =>
                a[columnId] < b[columnId] ? -1 : 1
              );
      }
      const reorderColumns = datacolumn;
      reorderColumns[index].sortDirection =
        reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : true;
      });
      console.log("sortedData", sortedData);
      return [[...sortedData], [...reorderColumns]];
    } catch (error) {
      console.log(error);
    }
  };
  const updatePricingQueueStatus = async (params: any) => {
    try {
      setLoader(true);
      let req = {
        pricingQueueId: pricingQueueId?.toString(),
        status: params,
        actionBy: approvedBy,
      };
      const response = await updatePricingQueueStatusAPI(req);
      if (response.status == 200) {
        setAuthOpen(false);
      setIsApproved(true);
        setLoader(false);
      } else {
        setErrorPopup( {
          isOpen: true,
  statement: "Something went wrong"
        })
        setLoader(false);
      }
    } catch (e) {
      setErrorPopup( {
        isOpen: true,
statement: "Something went wrong"
      })
      setLoader(false);
    }
  };
  return (
    <autoTermContext.Provider
      value={{
        departmentOptions,createNewRange,
        setDepartmentOptions,isSLACompleted, setIsSLACompleted,
        isSLACancel,setIsSLACancel,
        isFilterOpen,originalSubDeptArr,setOriginalSubDeptArr,
        setIsFilterOpen,
        subDepartmentOptions,
        setSubDepartmentOptions,
        zoneOptions,
        setZoneOptions,
        loader,
        setLoader,
        displayGrid, bracketOptions, setBracketOptions,
        setDisplayGrid,
        sortArray,
        rangeHeader,
        setRangeHeader,
        zoneSelected,
        setZoneSelected,
        noRecords,
        setNoRecords,
        tableHeader,
        setTableHeader,
        pgCount,
        setPgCount,
        companyCode,
        setCompanycode,zoneSelectedForm,setZoneSelectedForm,
        pageNumber,validateAutoTerm,isApproved, setIsApproved,
        setPageNumber,
        totalRowCount,
        setTotalRowCount,
        formRecords,selectQueueType,setSelectQueueType,
        setFormRecords,
        backupRecords,
        formatData,isApprovalOpen,setIsApprovalOpen, pricingQueueId,setPricingQueueId,
        setBackupRecords,approvedBy,setApprovedBy,
        isNewAutoTerm,
        setIsNewAutoTerm,
        approvalPopUp,
        setApprovalPoup,
        gridData,
        setGridData,errorPopup,setErrorPopup,
        setEditRecordsArray,editRecordsArray,
        filterOptions,
        setFilterOptions,isApprover, setIsApprover,
        isAutoTermFormOpen,isFranchiseeUser, setIsFranchiseeUser,
        setIsAutoTermFormOpen,authOpen, setAuthOpen,addnewRange, setAddNewRange,
        fetchAutoTermDetails,filterReqAtApply,setFilterReqAtApply,
        validateInput,gridLoader,setGridLoader,employeeId,setEmployeeId,
        emailId,setEmailId,updatePricingQueueStatus,bracketOptionsCopy,setBracketOptionsCopy,
        // errorPopupStatement,
        // setErrorPopupStatement,
        // errorPopupOpen,
        // setErrorPopupOpen,
      }}
    >
      {props.children}
    </autoTermContext.Provider>
  );
}

// export default AutoTermContextProvider;
