/* eslint-disable */
import { client, clientCall } from "./client";
import { appConfig } from "../config/app-config";
import { CancelToken } from "axios";
import { ProductPermissions } from "../constants/constants";
import { User } from "../types/types";

export const getCurrentUser = (): Promise<User> =>
  client("users/current", { method: "GET" }, appConfig.apiUrls.container);

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container,
    true
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: "PATCH",
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    "users/current/stores?q=permissions[:includes]=RACPAD_DAP",
    { method: "GET" },
    appConfig.apiUrls.container
  );

const getCurrentUserBaseURL = appConfig.apiUrls.menu;
const QAToken =
  "eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIydm42dGV0MmhxYmpmbW00ZzdrdDFlNWw4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicmFjXC9yZWFkIiwiYXV0aF90aW1lIjoxNjM5MTM3ODEwLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84TWJPeUVZRzYiLCJleHAiOjE2MzkxNDE0MTAsImlhdCI6MTYzOTEzNzgxMCwidmVyc2lvbiI6MiwianRpIjoiOGIyNTlmMWQtMDRjNC00OTQwLTk4MWQtMGE1MGE2MGYyY2NjIiwiY2xpZW50X2lkIjoiMnZuNnRldDJocWJqZm1tNGc3a3QxZTVsODcifQ.V_B9rNYJrgFoOJn1BVKBapnzQ__y-xfa7crNMABhguZT4VKUSZecIXcNTV19dhseMMNhVwRsutEhWJSnTi6s-IxcnN3u3USbNXrVzcbbqX5hAgTuyQUHhXTiK22suMgJMyhqKmAjxYV3YNvAua-fy3bmlEAJbwQKZYzjCmU4c8vxLjgDh_BEwXQ8_Rjciy5_dEdvf9llBaAC1yC1TAbh1grUy6LnSx-UP_lslA5ff_x5C-CQ1Repq6VZNXzgrwPtm90ce3cP9zit71vOZEfwGmfvJpm0GeHnvKz3w5SySsI_Mci7ikbTQEU6LvC-Te0wyYjOlFitogzUhIQ_7zzTCg";

// const baseurl = 'https://z6530a2fmh.execute-api.us-east-1.amazonaws.com/api';
export const getUserID = async () => {
  return await clientCall(
    `user/current`,
    { method: "GET" },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};

export const getZoneDD = async () => {
  return await clientCall(
    `zone/store`,
    { method: "GET" },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getReportGridData = async () => {
  return await clientCall(
    `report/summary`,
    { method: "GET" },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

//company code logic
// export const getReportGridData = async (empid: any) => {
//   return await clientCall(
//     `report/summary/${empid}`,
//     { method: 'GET' },
//     appConfig.apiUrls.micro,
//     QAToken,
//     true
//   );
// };

//Company code logic for getReportGrid
// export const getReportGrid = async (empid: any, companycd: any) => {
//   return await clientCall(
//     `report/summary/${empid}?companyCode=${companycd}`,
//     { method: 'GET' },
//     appConfig.apiUrls.micro,
//     QAToken,
//     true
//   );
// };

export const generateReport = async (reportReq: any) => {
  return await clientCall(
    `report/generate`,
    { method: "POST", body: reportReq },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getXlFile = async (FileReq: any) => {
  return await clientCall(
    `document`,
    { method: "POST", body: FileReq },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getUploadGridData = async () => {
  return await clientCall(
    `upload/summary`,
    { method: "GET" },
    //base,
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const pricingDocumnetUpload = async (documentrequest: any) => {
  return await clientCall(
    `upload/document`,
    { method: "POST", body: documentrequest },
    // PricingURL,
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

// export const getOnloadUploadGridData = async () => {
//   return await clientCall(
//     `upload/summary`,
//     { method: 'GET' },
//     appConfig.apiUrls.micro,
//     QAToken,
//     true
//   );
// };

export const manageZoneDetail = async (object: any) => {
  return await clientCall(
    `managezone`,
    { method: "POST", body: object },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const zoneData = async (object: any) => {
  return await clientCall(
    `zone`,
    { method: "POST", body: object },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const GetMenuAndStoredbasedOnProfileAPI = async (profileReq: any) => {
  return await clientCall(
    `menu-and-stores`,
    { method: "POST", body: profileReq },
    appConfig.apiUrls.menu,
    QAToken,
    true
  );
};

export const fetchDropDownAPI = async (params: string[]) => {
  return await clientCall(
    `reference`,
    { method: "POST", body: params },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const fetchPricingDetailsAPI = async (params: any) => {
  return await clientCall(
    `pricing`,
    { method: "POST", body: params },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const addPricingQueueAPI = async (params: any) => {
  return await clientCall(
    `addqueue`,
    { method: "POST", body: params },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const fetchPricingQueueAPI = async (params: any) => {
  return await clientCall(
    `queuedetails`,
    { method: "POST", body: params },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export default async function updatePricingQueueStatusAPI (params : any ){
  //set the payload and call the updatePricning queue lambda
  return await clientCall(
    `/updatequeue`,
    {method: 'POST', body:params },
    appConfig.apiUrls.micro,
    QAToken,
    true
  )
}
export const getPinDetails = async (Payload: any) => {
  return await clientCall(
    `coworker`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.storemgmt,
    QAToken,
    true
  );
};

export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};
export const getsecondfactor = async (Payload: any) => {
  return await clientCall(
    `coworker-authentication`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.storemgmt,
    QAToken,
    true
  );
};
export const getprofileaccess = async (Payload: any) => {
  return await clientCall(
    `store-access`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.storemgmt,
    QAToken,
    true
  );
};
export const getCurrentInfo = async () => {
  return await clientCall(
    `user/current`,
    { method: "GET" },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};


export const postFilterValue = async (Payload: any) => { // Store Zone detail API 
  // return ([]);
  return await clientCall(
    `storezone`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
     true
  );
};

export const insertZoneValue = async (Payload: any) => {
  return await clientCall(
    `addqueue`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};


// export const updatePricingQueueStatus = async (Payload: any) => {
//   return await clientCall(
//     `updatequeue`,
//     { method: 'POST', body: Payload },
//     appConfig.apiUrls.micro,
//     QAToken,
//     true
//   );
// };
export const autoTermDetailAPI= async(params: any)=>{
  return await clientCall(
    `autoterm`,
    {method: 'POST', body:params },
    appConfig.apiUrls.micro,
    QAToken,
    true
  )
}
export const getPromoSchedulerGrid = async (Payload: any) => {
  console.log("getPromoSchedulerGrid payload in user.ts",Payload)
  return await clientCall(
    `promodetail`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const postPromoSchedule = async (Payload: any) => {
  console.log("post obj in user", Payload);
  return await clientCall(
    `schedule`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const fetchOrganizationDetails = async (Payload: any) => {
  return await clientCall(
    `hierarchy`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const packageDetails = async (Payload: any) => {
  return await clientCall(
    `package`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};