import {
  FormControl,
  InputBase,
  InputBaseProps,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  withStyles,
  Theme,
  Grid,
  createStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { CLOUD, MVS_RED } from '../../colors/colors';
import { FieldError } from '../FieldError/FieldError';
import { StyleClasses } from '../StyleClasses';
import { Tooltip } from '../Tooltip/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
export type ClassKey =
  | 'formControl'
  | 'inputLabel'
  | 'nativeSelect'
  | 'option'
  | 'paper';

export interface Option {
  label: string;
  value: any;
}

export interface SelectProps {
  name?: string;
  inputLabel?: string;
  inputLabelClassName?: any;
  infoIconClassName?: any;
  infoTitle?: any;
  tooltipClassName?: any;
  infoIcon?: boolean;
  defaultValue?: string | number | string[] | number[];
  options?: Option[];
  required?: boolean;
  multiple?: boolean;
  isDisabled?: boolean;
  borderCondition?: boolean;
  borderColor?: any;
  backgroundColorCondition?: boolean;
  backgroundColor?: any;
  fontColor?: any;
  AccordionColor?: any;
  classes?: StyleClasses<ClassKey>;
  loading?: boolean;
  // check event.target.value
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  errorMessage?: string;
  labelFocused?: boolean;
  optionsToolTips?: Option[];
}

const StyledFormControl = withStyles(() => ({
  root: {
    width: '100%',
  },
}))(FormControl) as typeof FormControl;

const StyledExpandMore = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
  },
}))(ExpandMore) as typeof ExpandMore;

const StyledCircularProgress = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    width: `${theme.typography.pxToRem(15)} !important`,
    height: `${theme.typography.pxToRem(15)} !important`,
    margin: '4px 8px 0 0',
  },
}))(CircularProgress) as typeof CircularProgress;

const StyledInputLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    position: 'relative',
    '& span': {
      color: 'red !important',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))((props) => <InputLabel {...props} />) as typeof InputLabel;

const StyledMuiSelect = withStyles(() => ({
  root: {
    maxWidth: '100%',
  },
}))((props) => <MuiSelect {...props} />) as typeof MuiSelect;

export function Select({
  name = 'select',
  inputLabel,
  options,
  defaultValue,
  required,
  classes,
  errorMessage,
  onChange,
  multiple,
  inputLabelClassName,
  infoIconClassName,
  tooltipClassName,
  backgroundColorCondition,
  backgroundColor,
  borderCondition,
  borderColor,
  fontColor,
  AccordionColor,
  isDisabled,
  infoTitle,
  infoIcon,
  loading,
  labelFocused = false,
  optionsToolTips,
  ...rest
}: SelectProps) {
  const makeSelectClass = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        borderColor: `${MVS_RED} !important`,
      },
      disabledButton: {
        backgroundColor: 'rgb(235, 235, 228) !important',
        borderRadius: theme.typography.pxToRem(5),
      },
      iconColor: {
        color: AccordionColor,
      },
      selectColor: {
        color: fontColor,
      },
      InfoIconGrid: {
        display: 'flex',
      },
      InfoLabel: {
        display: 'inline',
      },
      InfoIcon: {
        width: '15px !important',
        color: '#007EFF',
        height: 'auto !important',
      },
    })
  );
  const ownClass = makeSelectClass();
  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: theme.typography.pxToRem(5),
      position: 'relative',
      backgroundColor: backgroundColorCondition
        ? backgroundColor
        : theme.palette.background.paper,
      border: borderCondition ? borderColor : `thin solid ${CLOUD}`,
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      maxWidth: '100%',
      // Use the system font instead of the default Roboto font.
      ...theme.typography.body2,
      '&:focus': {
        borderRadius: theme.typography.pxToRem(5),
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        backgroundColor: 'inherit',
      },
      '& span': {
        color: fontColor,
      },
    },
  }))((props: InputBaseProps) => <InputBase {...props} />) as typeof InputBase;
  return (
    <StyledFormControl className={clsx(classes?.formControl)} id={name}>
      <Grid className={ownClass.InfoIconGrid}>
        <StyledInputLabel
          className={clsx(
            classes?.inputLabel,

            inputLabelClassName
          )}
          disableAnimation
          focused={labelFocused}
          required={required}
        >
          {inputLabel}
        </StyledInputLabel>
        {infoIcon ? (
          <Tooltip
            placement={'right'}
            title={infoTitle}
            className={tooltipClassName}
          >
            <InfoIcon
              className={clsx(ownClass.InfoIcon)}
              classes={infoIconClassName}
            ></InfoIcon>
          </Tooltip>
        ) : null}
      </Grid>
      <StyledMuiSelect
        className={clsx(classes?.nativeSelect)}
        classes={{
          root: clsx(errorMessage && ownClass.root),
          icon: clsx(ownClass.iconColor),
          selectMenu: clsx(ownClass.selectColor),
        }}
        value={defaultValue}
        onChange={onChange}
        multiple={multiple}
        displayEmpty
        input={
          <BootstrapInput classes={{ disabled: ownClass.disabledButton }} />
        }
        disabled={isDisabled || loading}
        IconComponent={loading ? StyledCircularProgress : StyledExpandMore}
        MenuProps={{
          classes: {
            paper: clsx(classes?.paper),
          },
          style: { maxHeight: 270 },
        }}
        renderValue={(selected: string[] | string) => {
          if (typeof selected === 'string') {
            selected = [selected];
          }
          if (selected?.length === 0) {
            return <span>Select</span>;
          }

          const labelsOfSelected = selected?.map(
            (val) => options.find((opt) => opt.value === val)?.label
          );

          if (typeof labelsOfSelected?.join === 'function')
            return labelsOfSelected?.join(', ');
          return labelsOfSelected;
        }}
        {...rest}
      >
        {options?.map(({ value, label }) => (
          <MenuItem
            className={clsx(classes?.option)}
            key={value}
            value={value}
            title={optionsToolTips?.find((opt) => opt.value === value)?.label}
          >
            {label}
          </MenuItem>
        ))}
      </StyledMuiSelect>
      {errorMessage && <FieldError errorMessage={errorMessage} />}
    </StyledFormControl>
  );
}
