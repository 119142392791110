import React from 'react';
import { Chip as MuiChip, ChipProps, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { PROVINCIAL_PINK } from '../../colors/colors';
import { pixelToRem } from '../../utils/utils';

const StyledChip = withStyles((theme) => ({
  root: {
    backgroundColor: PROVINCIAL_PINK,
    color: theme.palette.text.disabled,
    padding: `${pixelToRem(4)}rem ${pixelToRem(15)}rem ${pixelToRem(
      4
    )}rem ${pixelToRem(15)}rem `,
  },
  label: {
    paddingLeft: '0',
    paddingRight: '0',
    marginTop: `${pixelToRem(1)}rem`,
  },
  icon: {
    marginRight: `${pixelToRem(4)}rem`,
    marginLeft: '0',
  },
  deleteIcon: {
    marginRight: '0',
    marginLeft: `${pixelToRem(4)}rem`,
  },
}))((props: ChipProps) => <MuiChip {...props} />) as typeof MuiChip;

export function Chip(props: ChipProps | any) {
  const { onClickImg, deleteIcon, icon, ...rest } = props;

  return (
    <StyledChip
      deleteIcon={deleteIcon ? deleteIcon : <FontAwesomeIcon icon={faTimes} />}
      icon={
        icon ? (
          icon
        ) : icon === false ? (
          ''
        ) : (
          <FontAwesomeIcon icon={faExternalLinkAlt} onClick={onClickImg} />
        )
      }
      {...rest}
    />
  );
}
