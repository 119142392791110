/* eslint-disable */
import PackageContext from "./PackageContext";
import React from 'react';
import PackageFilter from "./PackageFilter";
import { PackageGrid } from "./PackageGrid";
import PackageFooter from "./PackageFooter";
export default function PackageParentContainer(){
    console.log("hi this is working")

    return(<>
    <PackageContext>
        <PackageFilter/>
        <PackageGrid/>
        <PackageFooter/>
    </PackageContext>
    </>
    )
}