import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';
import { StyleClasses } from '../StyleClasses';
import Grid from '@material-ui/core/Grid';
export interface ModalPropsUpdate {
  isOpen: boolean;

  maxWidth: DialogProps['maxWidth'];
  title?: string;
  className?: string;
  titleVariant?: Variant;
  classes?: StyleClasses<
    'dialog' | 'dialogTitle' | 'dialogContent' | 'dialogActions'
  >;
  dialogOptionalProps?: DialogProps;
  GridClassname?: any;
  TitleClassName?: any;
  TitleVariantClassName?: any;
  contentClassName?: any;
  backgroundColor?: any;
  onClose?: () => void;
  children?: any;
  closeIcon?: boolean;
  borderRadius?: any;
}

export function RacModalUpdate({
  isOpen,
  maxWidth,
  title,
  titleVariant,
  onClose,
  classes,
  dialogOptionalProps,
  closeIcon,
  className,
  GridClassname,
  TitleClassName,
  contentClassName,
  borderRadius,
  backgroundColor,
  TitleVariantClassName,
  children,
}: ModalPropsUpdate) {
  const useStyles = makeStyles((theme: any) => ({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },

    iconButton: {
      padding: 0,
    },
    dialogActions: {
      padding: theme.typography.pxToRem(12),
      marginBottom: '.5rem',
    },
    paperBorder: {
      borderRadius: borderRadius,
    },
    paperLarge: {
      borderRadius: borderRadius,
      backgroundColor: backgroundColor !== '' ? backgroundColor : '',
    },
  }));
  const ownClasses = useStyles();
  return (
    <Grid className={GridClassname}>
      <Dialog
        className={clsx(classes?.dialog, className)}
        classes={{
          paperWidthFalse: ownClasses.paperBorder,
          paperWidthLg: ownClasses.paperLarge,
          paperWidthMd: ownClasses.paperBorder,
          paperWidthSm: ownClasses.paperBorder,
          paperWidthXl: ownClasses.paperBorder,
          paperWidthXs: ownClasses.paperBorder,
        }}
        disableBackdropClick
        maxWidth={maxWidth}
        fullWidth
        open={isOpen}
        onClose={onClose}
        {...dialogOptionalProps}
      >
        <DialogTitle
          disableTypography
          className={clsx(
            ownClasses.dialogTitle,
            classes?.dialogTitle,
            TitleClassName
          )}
        >
          <Typography
            className={TitleVariantClassName}
            variant={titleVariant || 'h5'}
          >
            {title}
          </Typography>
          {closeIcon === true ? (
            <IconButton
              onClick={onClose}
              className={clsx(ownClasses.iconButton)}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent
          className={clsx(
            classes?.dialogContent,
            ownClasses.dialogActions,
            contentClassName
          )}
        >
          {children}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
export default RacModalUpdate;
