/* eslint-disable */
import React from "react";
import PricingContext, { PricingDetailsProvider } from "./PricingContext";
import PricingFilter from "./PricingFilter";
import PricingGrid from "./PricingGrid";
import { Grid } from '@rentacenter/racstrap';
import PricingFooter from "./PricingFooter";
import { globalStyles } from "../Styles/globalstyles";
export default function PricingContainer() {
    const classes = globalStyles();
    
    return (
        <PricingContext>
            <div >
                <Grid style={{ width: "100%" }}>
                    <PricingFilter />                    
                    <PricingGrid />
                </Grid>
                <PricingFooter />
            </div>
        </PricingContext>
    )
}