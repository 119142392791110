import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MUITextField from '@material-ui/core/TextField';
import { CLOUD, ONYX, WHITE } from '../../colors/colors';
import clsx from 'clsx';
import { pixelToRem } from '../../utils/utils';
import { StyleClasses } from '../../components/StyleClasses';
import { useEffect } from 'react';
import { FieldError } from '../../components/FieldError/FieldError';

export type TextFieldClassKeys = 'input' | 'label' | 'errorMessage';
export interface Props {
  name?: string;
  type: string;
  label?: React.ReactElement | string;
  value?: string;
  classes?: StyleClasses<TextFieldClassKeys>;
  inputProps?: any;
  rest?: any;
  required?: boolean;
  onBlur?: (value: any) => void;
  onChange?: (value: string) => void;
  onKeyDown?: (value: any) => void;
  disabled?: boolean;
  errorMessage?: string;
  min?: number;
  max?: number;
}

const StyledTextField = withStyles((theme) => ({
  root: {
    '& input': {
      ...theme.typography.body2,
      background: WHITE,
      border: `thin solid ${CLOUD}`,
      paddingLeft: `${pixelToRem(8)}rem`,
      paddingRight: `${pixelToRem(8)}rem`,
      borderRadius: `${pixelToRem(5)}rem`,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
    '& div': {
      marginTop: '0rem !important',
    },
  },
}))((props) => <MUITextField fullWidth {...props} />) as typeof MUITextField;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  labelRoot: {
    ...theme.typography.body1,
    position: 'relative',
    transform: 'scale(1) !important',
    color: ONYX,
  },
  mandatory: {
    '& .MuiFormLabel-asterisk': {
      color: 'red !important',
    },
  },
  inputRoot: {},
  disabled: { backgroundColor: `hsl(0, 0%, 95%) !important` },
}));
export function TextField({
  type,
  label,
  onChange,
  onKeyDown,
  onBlur,
  classes,
  inputProps,
  value,
  disabled,
  required,
  errorMessage,
  min,
  max,
  ...rest
}: Props) {
  const ownClasses = useStyles();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    // reset field, when receiving empty value
    if (!value) {
      setInputValue('');
    } else {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;
    if (type === 'number' && value && (value > max || value < min)) return;

    setInputValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className={ownClasses.root}>
      <StyledTextField
        required={required}
        label={label}
        value={inputValue || ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: clsx(
              ownClasses.labelRoot,
              classes?.label,
              ownClasses.mandatory
            ),
          },
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: clsx(ownClasses.inputRoot, classes?.input),
            disabled: clsx(disabled && ownClasses.disabled),
          },
          inputProps: {
            ...inputProps,
          },
        }}
        type={type}
        disabled={disabled}
        {...rest}
      />
      {errorMessage && (
        <FieldError classes={classes} errorMessage={errorMessage} />
      )}
    </div>
  );
}
