/* eslint-disable */
import {
  Typography,
  Grid,
  Card,
  RACTable,
  RACTableRow,
  RACTableCell,
  List,
  ListItem,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { globalStyles } from "../Styles/globalstyles";
import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up-icon.svg";
import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down-icon.svg";
import { PricingQueueContext } from "./PricingQueueContext";

export default function AutotermApproval() {
  const {
    indexValue,
    setIndexValue,
    autotermRange,
    setAutotermRange,
    rangeHeader,
    setRangeHeader,
    setHideMainPage,
    comments,
    setComments,
  } = useContext(PricingQueueContext);
  //  useEffect(()=>{
  //   setAutotermRange(indexValue?.autoTerm?.range);
  //   console.log(indexValue?.autoTerm?.range,"The indexValue?.autoTerm?.rang",autotermRange);
  //  },[indexValue.autoTerm])
  const Classes = globalStyles();
  {
    console.log(autotermRange, "set range");
  }
  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any,
    compareBy: string = ""
  ) => {
    try {
      debugger;
      let sortedData;
      if (compareBy == "date") {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) => {
                let date1 = moment(b[columnId]);
                let date2 = moment(a[columnId]);
                return date2.isBefore(date1) ? -1 : 1;
              })
            : dataValues.sort((a: any, b: any) => {
                let date1 = moment(b[columnId]);
                let date2 = moment(a[columnId]);
                return date1.isBefore(date2) ? -1 : 1;
              });
      } else if (compareBy == "number") {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
            : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
      } else {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) =>
                b[columnId] < a[columnId] ? -1 : 1
              )
            : dataValues.sort((a: any, b: any) =>
                a[columnId] < b[columnId] ? -1 : 1
              );
      }
      const reorderColumns = datacolumn;
      reorderColumns[index].sortDirection =
        reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : true;
      });
      console.log("sortedData", sortedData);
      return [[...sortedData], [...reorderColumns]];
    } catch (error) {
      console.log(error);
    }
  };
  const RangeHead = () => (
    <>
      {rangeHeader.map((value: any, index: any) => {
        return (
          <>
            <RACTableCell>
              {value.isSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() => {
                    let rep: any = sortArray(
                      value.key,
                      index,
                      rangeHeader,
                      autotermRange,
                      "number"
                    );
                    console.log(rep, "the sort array in the form");

                    // setGridData(rep[0]);  need to set the data into formData.range()
                    setRangeHeader(rep[1]);
                    // let req = indexValue.autoTerm;
                    // req.range = rep[0];
                    setAutotermRange(rep[0] );
                  }}
                >
                  {value.heading + "  "}
                  {value.isSorted === true ? (
                    value.sortDirection == "DESC" ? (
                      <SortUpIcon
                        style={{ cursor: "pointer" }}
                        className={Classes.ml0}
                      />
                    ) : (
                      <SortDownIcon
                        style={{ cursor: "pointer" }}
                        className={Classes.ml0}
                      />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.heading + "  "}
                </a>
              )}
            </RACTableCell>
          </>
        );
      })}
    </>
  );
  const RangeContent = () =>
    autotermRange.map((item: any) => (
      <>
        <RACTableRow style={{ backgroundColor: "transparent" }}>
          <RACTableCell>
            <Typography
              variant="caption"
              className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
            >
              {item?.startDay}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="caption"
              className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
            >
              {item?.endDay}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              variant="caption"
              className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
            >
              {              Number(item?.percentDiscount)?.toFixed(2)}%
            </Typography>
          </RACTableCell>
        </RACTableRow>
      </>
    ));
  return (
    <>
      <Grid style={{ width: "100%", paddingTop: "0.75rem" }}>
        {/* braedcrumbs starts  */}
        <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
          <ListItem
            style={{ paddingLeft: "0" }}
            className={`${Classes.breadcrumbItemActive} `}
          >
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIndexValue("");
                setHideMainPage(false);
              }}
            >
              Autoterm Details
            </a>
          </ListItem>
          <ListItem
            className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
          >
            <a>Autoterm Approval</a>
          </ListItem>
        </List>
        {/* braedcrumbs ends  */}

        <Grid container spacing={2}>
          {/* Update Autoterm starts  */}
          <Grid item xs={12} md={12} lg={5}>
            <Typography variant="h4">Autoterm Approval</Typography>
            <Card className={`${Classes.p3} `} style={{ height: "74vh" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} ${Classes.mt3} `}>
                    Zone
                  </Typography>
                  <Typography title={indexValue?.autoTerm.zoneNumber?.join(', ').length > 17?  (indexValue?.autoTerm.zoneNumber.join(', ')):''}>{indexValue?.autoTerm.zoneNumber ?(indexValue?.autoTerm.zoneNumber?.join(', ')).length > 17 ? (indexValue?.autoTerm.zoneNumber.join(', ')).slice(0,17) + '...' : (indexValue?.autoTerm.zoneNumber.join(', ')) : '-'}</Typography>
                  {/* <Typography>{indexValue?.zoneNumber}</Typography> */}
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} ${Classes.mt3} `}>
                    Department
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.autoTerm.departmentDesc
                        ? indexValue.autoTerm.departmentDesc.length > 25
                          ? indexValue.autoTerm.departmentDesc
                          : ""
                        : ""
                    }
                  >
                    {indexValue.autoTerm.departmentDesc
                      ? indexValue.autoTerm.departmentDesc.length > 25
                        ? indexValue.autoTerm.departmentDesc.slice(0, 25) +
                          "..."
                        : indexValue.autoTerm.departmentDesc
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} ${Classes.mt3} `}>
                    SubDepartment
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.autoTerm.subdepartmentDesc
                        ? indexValue.autoTerm.subdepartmentDesc.length > 25
                          ? indexValue.autoTerm.subdepartmentDesc
                          : ""
                        : ""
                    }
                  >
                    {indexValue.autoTerm.subdepartmentDesc
                      ? indexValue.autoTerm.subdepartmentDesc.length > 25
                        ? indexValue.autoTerm.subdepartmentDesc.slice(0, 25) +
                          "..."
                        : indexValue.autoTerm.subdepartmentDesc
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold}  `}>
                    Bracket
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.autoTerm.bracketName
                        ? indexValue.autoTerm.bracketName.length > 25
                          ? indexValue.autoTerm.bracketName
                          : ""
                        : ""
                    }
                  >
                    {indexValue.autoTerm.bracketName
                      ? indexValue.autoTerm.bracketName.length > 25
                        ? indexValue.autoTerm.bracketName.slice(0, 25) + "..."
                        : indexValue.autoTerm.bracketName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Brand
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.autoTerm.brandName
                        ? indexValue.autoTerm.brandName.length > 25
                          ? indexValue.autoTerm.brandName
                          : ""
                        : ""
                    }
                  >
                    {indexValue.autoTerm.brandName
                      ? indexValue.autoTerm.brandName.length > 25
                        ? indexValue.autoTerm.brandName.slice(0, 25) + "..."
                        : indexValue.autoTerm.brandName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Model #
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.autoTerm.modelNumber
                        ? indexValue.autoTerm.modelNumber.length > 25
                          ? indexValue.autoTerm.modelNumber
                          : ""
                        : ""
                    }
                  >
                    {indexValue.autoTerm.modelNumber
                      ? indexValue.autoTerm.modelNumber.length > 25
                        ? indexValue.autoTerm.modelNumber.slice(0, 25) + "..."
                        : indexValue.autoTerm.modelNumber
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    RMS #
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {indexValue?.autoTerm.rmsItemNumber}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Tier
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.autoTerm.termConfigName
                        ? indexValue.autoTerm.termConfigName.length > 25
                          ? indexValue.autoTerm.termConfigName
                          : ""
                        : ""
                    }
                  >
                    {indexValue.autoTerm.termConfigName
                      ? indexValue.autoTerm.termConfigName.length > 25
                        ? indexValue.autoTerm.termConfigName.slice(0, 25) +
                          "..."
                        : indexValue.autoTerm.termConfigName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Start Date
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {moment(indexValue?.autoTerm.startDate).format(
                      "MM/DD/YYYY"
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={`${Classes.mt1}  `}>
                  <Typography className={`${Classes.fontBold} `}>
                    End Date
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {moment(indexValue?.autoTerm.endDate).format("MM/DD/YYYY")}
                  </Typography>
                </Grid>
                <Grid md={12}>
                  <Typography variant="h4" style={{ marginTop: "2%" }}>
                    Comments
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <textarea
                      rows={6}
                      style={{
                        resize: "none",
                        width: "100%",
                        border: "1px solid lightgray !important",
                      }}
                      disabled={
                        indexValue?.status?.toUpperCase() == "QUEUED" ||
                        indexValue?.status?.toUpperCase() == "WAITING"
                          ? false
                          : true
                      }
                      value={comments}
                      onChange={(e: any) => {
                        setComments(e.target.value);
                      }}
                    ></textarea>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Update Autoterm ends  */}

          {/* Range starts  */}
          <Grid item xs={12} md={12} lg={7}>
            <Typography variant="h4">Range</Typography>
            <Card
              className={`${Classes.p3}`}
              style={{ overflowY: "auto", height: "74vh" }}
            >
              <Grid className={Classes.fixTableHeadAgrTrsRec} >
              <RACTable
                renderTableHead={RangeHead}
                renderTableContent={RangeContent}
              />
              </Grid>
            </Card>
          </Grid>
          {/* Range starts  */}
        </Grid>
      </Grid>
    </>
  );
}
