import React, { PropsWithChildren } from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  withStyles,
} from '@material-ui/core';
import {
  ARC_LIGHT,
  HEART_OF_ICE,
  CALLIGRAPHY,
  BLUE_ANTHRACITE,
} from '../../colors/colors';
import { pixelToRem } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export interface ButtonProps extends MuiButtonProps {
  'data-testid'?: string;
  className?: string;
  isRounded?: boolean;
  loading?: boolean;
}

const StyledButton = withStyles({
  root: {
    borderRadius: (props: ButtonProps) => (props?.isRounded ? '30px' : '4px'),
  },
  contained: {
    padding: `${pixelToRem(10)}rem ${pixelToRem(22)}rem`,
    lineHeight: `${pixelToRem(21)}rem`,
    border: `${pixelToRem(1)}rem solid transparent`,
  },
  outlined: {
    backgroundColor: HEART_OF_ICE,
    borderColor: ARC_LIGHT,
    padding: `${pixelToRem(12)}rem ${pixelToRem(25)}rem`,
    lineHeight: `${pixelToRem(21)}rem`,
    '&:hover': {
      color: 'white',
      backgroundColor: CALLIGRAPHY,
      borderColor: BLUE_ANTHRACITE,
    },
  },
  iconSizeLarge: {
    '& svg': {
      fontSize: `${pixelToRem(18)}rem !important`,
    },
  },
})((props: MuiButtonProps) => {
  return <MuiButton disableRipple {...props} />;
}) as typeof MuiButton;

export function Button(props: PropsWithChildren<ButtonProps>) {
  const { children, classes, loading, ...rest } = props;

  return (
    <StyledButton
      endIcon={
        loading && (
          <FontAwesomeIcon
            size="lg"
            icon={faCircleNotch}
            className={'fa-w-14 fa-spin'}
          />
        )
      }
      {...rest}
    >
      {children}
    </StyledButton>
  );
}
