import React from 'react';
import { TextField } from '../../common/TextField/TextField';
import { StyleClasses } from '../StyleClasses';

export type DatePickerClassKeys = 'input' | 'label';
export interface DatePickerProps {
  name?: string;
  id?: string;
  label: React.ReactElement | string;
  value?: string;
  classes?: StyleClasses<DatePickerClassKeys>;
  rest?: any;
  required?: boolean;
  inputProps?: any;
  onBlur?: (value: any) => void;
  onChange?: (value: string) => void;
  onKeyDown?: (value: any) => void;
  disabled?: boolean;
  errorMessage?: string;
}
export function DatePicker({
  id,
  name,
  label,
  value,
  required,
  onChange,
  classes,
  inputProps,
  errorMessage,
  onKeyDown,
  onBlur,
  ...rest
}: DatePickerProps) {
  return (
    <TextField
      required={required}
      name={id || name}
      type="date"
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      classes={classes}
      inputProps={inputProps}
      errorMessage={errorMessage}
      onKeyDown={onKeyDown}
      {...rest}
    />
  );
}
