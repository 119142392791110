/* eslint-disable */
import {
  RACSelect,
  Typography,
  RACButton,
  Grid,
  CardContent,
  Card,
  RACDatePicker,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { ContainerContext } from "../../app/App";
import employeeAndReferenceDetailsForPromoScheduler, {
  getHierarchyType,
} from "../shared/PromoDropDownDetails";
import { PricingReportStyle } from "../Pricing/Styles/PricingReport";
import { MultiSelect } from "react-multi-select-component";
import { PromoSchedulerContext } from "./PromoSchedulerContext";
import { ReactComponent as RacFilter } from "../../assets/images/filter-white.svg";
import { ReactComponent as UpArrow } from "../../assets/images/up-arrow-filter.svg";
import { ReactComponent as DownArrow } from "../../assets/images/down-arrow-filter.svg";
import moment from "moment";
import { check } from "prettier";
import { ProgressBar } from "../Pricing/Styles/ProgressStyles";
export default function PromoSchedulerFilter() {
  const pricing = PricingReportStyle();
  const classes = ProgressBar();
  const [typeObj, setTypeObj] = useState<any>([]);
  const [hierarchyValueField, setHierarchyValueField] = useState(false);
  const [hierarchyLoader, setHierarchyLoader] = useState(true);
  const [filterPopup, setFilterPopup] = useState(false);
  const containerData = useContext(ContainerContext);
  const {
    hasSelectAll,setHasSelectAll,
    franchiseeuser,
    setFranchiseeuser,
    setNoDataFound,
    setGridLoaderFlag,
    orgArrayFilter,
    setOrgArrayFilter,
    applyFilter,
    company,
    setCompany,
    setTypeFlag,
    filterLoaderFlag,
    setFilterLoaderFlag,
    filterData,
    setFilterData,
    setMainLoader,
    setSomethingWentWrongFilter,
    promotype,
    setPromoType,
    promoGroup,
    setPromoGroup,
    organization,
    setOrganization,
    typeDropdown,
    organizatonSelected,
    setOrganizationSelected,
    hierarchyValueSelected,
    setHierarchyValueSelected,
    hierarchyArrayFilter,
    setHiererchyArrayFilter,
    buttonDisabler,
    setButtonDisabler,
    hasSelectAllgetHeirarchy,setHasSelectAllgetHeirarchy
  } = useContext(PromoSchedulerContext);

  useEffect(() => {
    getFilterData();
  }, []);
  const getFilterData = async () => {
    const response = await employeeAndReferenceDetailsForPromoScheduler(
      containerData
    );
    if (response.status === 200) {
      setFilterLoaderFlag(false);
      setOrganization(response.organization);
      setFranchiseeuser(response.franchiseeuser);
      setCompany(response.companyCode);
      let promoTypeArr = response.promoType;
      let tempPT: any = [];
      tempPT = promoTypeArr;
      tempPT?.unshift({
        label: "Select",
        value: "",
      });
      setPromoType(promoTypeArr);
      let promoGroupArr = response.promoGroup;
      let tempPG: any = [];
      tempPG = promoGroupArr;
      tempPG?.unshift({
        label: "Select",
        value: "",
      });
      setPromoGroup(promoGroupArr);
    } else {
      setHasSelectAll(false)
      setButtonDisabler(true);
      setFilterLoaderFlag(false);
      setOrganization([{ value: "", label: "Select" }]);
      setHierarchyValueSelected([]);
      setOrganizationSelected([]);
      setFilterData({
        ...filterData,
        companyCode: [],
        hierarchyType: "",
        hierarchyValue: [],
        promoGroupName: "",
        promoType: "",
        startDate: "",
        endDate: "",
      });
    }
  };
  const handleFilterValues = (e: any, key: any) => {
    if (key == "startDate" || key == "endDate") {
      setFilterData({ ...filterData, [key]: e });
    }
    // setFilterData({ ...filterData, [key]: e?.target.value });
  };

  const handleType = async (e: any, key: any) => {
    setHierarchyValueField(true);
    const response = await getHierarchyType(
      filterData.companyCode,
      e.target.value
    );
    console.log("HeirarcyType respone", response?.hierarchyType);
    if (response?.status === 200) {
      setHierarchyLoader(false);
      switch (true) {
        case e.target.value == "STATE": {
          setTypeFlag("State");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "STORE": {
          setTypeFlag("STORE");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "COUNTRY": {
          setTypeFlag("COUNTRY");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "LOB": {
          setTypeFlag("LOB");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "DISTRICT": {
          setTypeFlag("DISTRICT");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "REGION": {
          setTypeFlag("REGION");
          setTypeObj(response?.hierarchyType);
          break;
        }
      }
      setMainLoader(false);
    } else {
      setHasSelectAllgetHeirarchy(false)
      // setHasSelectAll(false)
      setMainLoader(false);
      setHierarchyLoader(false);
      // setHierarchyValueField(false)
      setTypeObj([{ value: "", label: "Select" }]);
      setSomethingWentWrongFilter(true);
      setHierarchyValueSelected([]);
      // setOrganizationSelected([]);
      // setFilterData({
      //   ...filterData,
      //   hierarchyType: "",
      // });
    }
  };

  function validationCheckfilter() {
    if (hierarchyValueField == true) {
      if (
        (orgArrayFilter.length != 0 ||
          filterData.hierarchyType != "" ||
          filterData.promoGroupName != "" ||
          filterData.promoType != "" ||
          filterData.startDate != "" ||
          filterData.endDate != "") &&
        hierarchyArrayFilter.length == 0
      ) {
        return true;
      } else if (
        filterData.hierarchyType != "" ||
        hierarchyArrayFilter.length != 0
      ) {
        return false;
      }
    } else {
      if (
        orgArrayFilter.length != 0 ||
        filterData.promoGroupName != "" ||
        filterData.promoType != "" ||
        filterData.startDate != "" ||
        filterData.endDate != ""
      ) {
        return false;
      }
    }
    return true;
  }
  const openPromoFilter = () => {
    return (
      <Grid
        style={{
          margin: "0.5% 0% 1.5% 0%",
          overflow: "visible",
        }}
      >
        <Card
          style={{
            height: "172px",
            overflow: "visible",
            marginTop: "51px",
            marginBottom: "10px",
            borderRadius: "12px",
          }}
        >
          <CardContent>
            <Grid container spacing={4}>
              <Grid className={classes.multiSelectStyles} item md={2}>
                <Typography style={{ fontFamily: "OpenSans-bold" }}>
                  Organization
                </Typography>
                <MultiSelect
                hasSelectAll={hasSelectAll}
                  isLoading={filterLoaderFlag}
                  options={organization}
                  value={organizatonSelected}
                  className={`${pricing.demo}  ${pricing.promozoneMultiSelect} `}
                  onChange={(e: any) => {
                    setHasSelectAllgetHeirarchy(true)
                    setHierarchyValueField(false);
                    setHiererchyArrayFilter([]);
                    filterData.hierarchyType = "";
                    filterData.hierarchyValue = "";
                    setOrganizationSelected(e);
                    let arr = e.map((item: any) => {
                      if (item.value == item.value) return item.value;
                    });
                    console.log("arr im org filter", arr.length);
                    setOrgArrayFilter(arr);
                    if (arr.length > 0) {
                      if (franchiseeuser == true) {
                        setFilterData({
                          ...filterData,
                          ["companyCode"]: arr,
                        });
                      } else {
                        if (organization.length == arr.length) {
                          setFilterData({
                            ...filterData,
                            ["companyCode"]: [],
                          });
                        } else {
                          setFilterData({
                            ...filterData,
                            ["companyCode"]: arr,
                          });
                        }
                      }
                    } else {
                      setHierarchyValueField(false);
                      setHiererchyArrayFilter([]);
                      filterData.hierarchyType = "";
                      filterData.hierarchyValue = "";
                    }
                  }}
                  labelledBy={"Select"}
                />
              </Grid>
              <Grid className={classes.dropDownStyles} item md={2}>
                <Typography style={{ fontFamily: "OpenSans-bold" }}>
                  Type
                </Typography>
                <RACSelect
                  isDisabled={orgArrayFilter.length == 0 ? true : false}
                  options={typeDropdown}
                  defaultValue={
                    filterData.hierarchyType ? filterData.hierarchyType : ""
                  }
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setHierarchyValueField(false);
                      setHiererchyArrayFilter([]);
                    } else {
                      handleType(e, "hierarchyType");
                    }
                    setFilterData({
                      ...filterData,
                      hierarchyType: e.target.value,
                    });
                    setHasSelectAllgetHeirarchy(true)
                    setHierarchyLoader(true);
                    setHierarchyValueSelected([]);
                  }}
                />
              </Grid>
              {hierarchyValueField == true ? (
                <Grid className={classes.multiSelectStyles} item md={2}>
                  <Typography style={{ fontFamily: "OpenSans-bold" }}>
                    {filterData.hierarchyType}
                    <span style={{ color: "red", paddingLeft: "3px" }}>
                      {filterData.hierarchyType != 0 ? "*" : ""}
                    </span>
                  </Typography>
                  <MultiSelect
                  hasSelectAll={hasSelectAllgetHeirarchy}
                    isLoading={hierarchyLoader}
                    options={typeObj}
                    value={hierarchyValueSelected}
                    className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                    onChange={(e: any) => {
                      setHierarchyValueSelected(e);
                      let arr = e.map((item: any) => {
                        if (item.value == item.value) return item.value;
                      });
                      arr =arr.includes("")?[]:arr
                      setHiererchyArrayFilter(arr);
                      console.log(arr);
                      if(arr.length>0){
                        if (typeObj.length == arr.length) {
                          setFilterData({
                            ...filterData,
                            ["hierarchyValue"]: [],
                          });
                        } else {
                          setFilterData({
                            ...filterData,
                            ["hierarchyValue"]: arr,
                          });
                        }
                      }

                    }}
                    labelledBy={"Select"}
                  />
                </Grid>
              ) : null}

              <Grid className={classes.dropDownStyles} item md={2}>
                <Typography style={{ fontFamily: "OpenSans-bold" }}>
                  Promotion Group
                </Typography>
                <RACSelect
                  loading={filterLoaderFlag}
                  options={promoGroup}
                  defaultValue={
                    filterData.promoGroupName ? filterData.promoGroupName : ""
                  }
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      ["promoGroupName"]: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid className={classes.dropDownStyles} item md={2}>
                <Typography style={{ fontFamily: "OpenSans-bold" }}>
                  Promotion Type
                </Typography>
                <RACSelect
                  loading={filterLoaderFlag}
                  options={promotype}
                  defaultValue={
                    filterData.promoType ? filterData.promoType : ""
                  }
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      ["promoType"]: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid className={classes.datePickerStyles} item md={2}>
                <RACDatePicker
                  // inputProps={{ min: moment().format("YYYY-MM-DD") }}
                  value={
                    //  moment(
                    //   filterData.startDate
                    // ).format("YYYY-MM-DD")
                    filterData.startDate
                  }
                  onChange={(e) => {
                    handleFilterValues(e, "startDate");
                  }}
                  label="Start Date"
                />
              </Grid>
              <Grid className={classes.datePickerStyles} item md={2}>
                <RACDatePicker
                  value={
                    //  moment(
                    //     filterData.endDate
                    //   ).format("YYYY-MM-DD")
                    filterData.endDate
                  }
                  onChange={(e) => {
                    handleFilterValues(e, "endDate");
                  }}
                  label="End Date"
                />
              </Grid>
              <Grid item md={hierarchyValueField == true ? 10 : 12}>
                <Grid style={{ float: "right" }}>
                  <RACButton
                    variant="text"
                    onClick={() => {
                      setHierarchyValueSelected([]);
                      setOrganizationSelected([]);
                      setFilterData({
                        ...filterData,
                        companyCode: [],
                        hierarchyType: "",
                        hierarchyValue: [],
                        promoGroupName: "",
                        promoType: "",
                        startDate: "",
                        endDate: "",
                      });
                      setHierarchyValueField(false);
                      setTypeObj([...typeObj]);
                      setHiererchyArrayFilter([]);
                      setOrgArrayFilter([]);
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "blue",
                      marginBottom: "10px",
                      marginRight: "5px",
                    }}
                  >
                    Clear
                  </RACButton>
                  <RACButton
                    disabled={
                      buttonDisabler == true ? true : validationCheckfilter()
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      applyFilter();
                      setGridLoaderFlag(false);
                      setFilterPopup(false);
                      setNoDataFound(false);
                    }}
                    style={{ color: "white", marginBottom: "10px" }}
                  >
                    Apply
                  </RACButton>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  console.log("filterData", filterData);
  return (
    <>
      <RACButton
        variant="contained"
        color="primary"
        onClick={() => {
          filterPopup == true ? setFilterPopup(false) : setFilterPopup(true);
        }}
        style={{ float: "right", color: "white", marginBottom: "10px" }}
      >
        <RacFilter></RacFilter>
        <Typography style={{ position: "relative", left: "6px" }} variant="h6">
          Filter
        </Typography>
        {filterPopup == true ? (
          <UpArrow style={{ marginLeft: "10px" }} />
        ) : (
          <DownArrow style={{ marginLeft: "10px" }} />
        )}
      </RACButton>
      {filterPopup == true ? openPromoFilter() : null}
    </>
  );
}
