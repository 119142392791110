import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro,
  needAccessToken = false
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
  };
  if (needAccessToken) {
    const accessToken =
      window.localStorage.getItem('ac') || (await getAccessToken());
    headers.accesstoken = accessToken;
    headers.storenumber = window.sessionStorage.getItem('storeNumber');
  }
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response.data
  );
}

export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();

//const AC = localStorage.getItem('ac');
// const JT =
//   'eyJraWQiOiJBK2d0Y1Nhc05nSGdMUFczQ0haMVwvUk9FeUV0OVQxOVROamZGVXpxZWlhcz0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiX21yMVRYVGR5Z1NDbFdGZFNVNjEtZyIsImN1c3RvbTplbXBsb3llZUlkIjoiMDAxMTIyIiwic3ViIjoiMWQ1NmRlMzQtZmI3MS00YmU0LWFmZGItMzFhOGYyMjU1Zjg0IiwiY29nbml0bzpncm91cHMiOlsidXMtZWFzdC0xX0ZQV3V0N2FCal9Pa3RhIl0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY3VzdG9tOmxhc3ROYW1lIjoiTG5hbWUxIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfRlBXdXQ3YUJqIiwiY29nbml0bzp1c2VybmFtZSI6Ik9rdGFfRlNDVVNFUjFAcmVudGFjZW50ZXIuY29tIiwibm9uY2UiOiJzR3FvWnkxYXZEWVFkX3ZLZzhfY2tOTzkxYnpjTXhvUm5aUEFnajU3RXpwNlVDT2ltQzBqempWYVRlZVdtZVZyUjFJR0VtclJfcExSdUNuUzRlQTdsM2dLakh0OTlReUFtX2ZDT3M3ZEtlV0NpQVJVdXB5M2NqZjRxQktkUGZpbXJkTlNpUDV0VXpMU3gyakZZeVZlVGhHNWYzT2hXMmZncE5YcjhSbU5ReXMiLCJhdWQiOiI2MDhzOGRzbzRhZWNrYThvbWpwdWtzc3EzbiIsImlkZW50aXRpZXMiOlt7InVzZXJJZCI6IkZTQ1VTRVIxQHJlbnRhY2VudGVyLmNvbSIsInByb3ZpZGVyTmFtZSI6Ik9rdGEiLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cDpcL1wvd3d3Lm9rdGEuY29tXC9leGt1MXozNG8xb1VybjFEdzBoNyIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNjAxMjc5MDc3NTA1In1dLCJjdXN0b206Zmlyc3ROYW1lIjoiRlNDVVNFUjEiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYzODc4MjAwOSwiZXhwIjoxNjM4ODEwODA5LCJpYXQiOjE2Mzg3ODIwMDksImVtYWlsIjoiRlNDVVNFUjFAcmVudGFjZW50ZXIuY29tIn0.ku9OZlaGnEi0tDKeMXOmtyGD20mUIqcFfLjF4JVDYq-krdvOvVYkHrw5ZcAKaiQiG9nIr0GRU4Y4mz7BwbAi1mWLYGOrwID1vdceQrcaXyZ-78A0j3Eq7wvfPWL9eaD2zPEU39cyrpZbUv3a2fAPRicsx1_hAHOzABVCui40QtZzegyxKTMgA7kA8KvumegfnbRddRRF1eYTGXv1Sp_xjm1V6GOQ0p7ZsLPFb9OmxmNUSJK-qCryBUzSFzPNkA0IlJlJn1z_pAfeQRX26vp2V5Pn4IErW7B-2vRDQxTHQ4Vh40DffRZFWhzK3E2HAzHbwpvTr3G_KDPwlsBGc2zdNQ';

export async function clientCall(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: any,
  // access token is required for accessing AM APIs, but not for general ones (e.g. user APIs)djvbh
  AccessToken?: string,
  needAccessToken = true
) {
  try {
    const jwtToken = await getJWToken();
    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storenumber: window.sessionStorage.getItem('storeNumber'),
    };
    if (needAccessToken) {
      const accessToken =
        window.localStorage.getItem('ac') || (await getAccessToken());
      // eslint-disable-next-line no-console
      console.log('client access token', accessToken);
      // eslint-disable-next-line no-console
      console.log('GetPriceQuote Payload --->', body);
      headers.accesstoken = accessToken;
    }
    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }
    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };
    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    // eslint-disable-next-line no-console
    console.log('api url from app config', apiUrl);
    // eslint-disable-next-line no-console
    console.log('api request config', requestConfig);
    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => response
    );
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('catch', e);
    // eslint-disable-next-line no-console
    console.log('catch response', e.response);
    return e.response;
  }
}
