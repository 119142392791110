/* eslint-disable */
import React from "react";
import { Grid, CardContent, Card } from "@rentacenter/racstrap";
import PQContext from "./PricingQueueContext";
import PQGrid from "./PricingQueueGrid";
export default function PricingQueue() {
  return (
    <PQContext>
        <div>
      <Grid container md={12}>
        <PQGrid />
        {/* <Card
          style={{
            width: "100%",
            position: "fixed",
            zIndex: "1",
            left: "0",
            bottom: "0",
          }}
        >
          <CardContent></CardContent>
        </Card> */}
      </Grid>
      </div>
    </PQContext>
  );
}
