import * as React from 'react';
import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import { OLD_SILVER } from '../../colors/colors';

export interface LightTooltipProps extends Partial<TooltipProps> {
  children: JSX.Element;
  text: JSX.Element | string;
}

const StyledTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: `thin solid ${OLD_SILVER}`,
    borderRadius: theme.typography.pxToRem(15),
  },
}))((props: TooltipProps) => <Tooltip {...props} />);

export const LightTooltip = ({
  children,
  text,
  ...rest
}: LightTooltipProps) => {
  return (
    <StyledTooltip placement="top" title={text} {...rest}>
      {children}
    </StyledTooltip>
  );
};
