import {
  AppBar,
  Tab,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { CRYSTAL_BELL, SATIN_WHITE } from '../../colors/colors';
import { StyleClasses } from '../StyleClasses';
import { WRAPPER_BLUE, NORMAL_BLUE } from '../../colors/colors';

export type TabsClassKey = 'appbar' | 'tabs' | 'tab' | 'tabPanel';
export interface TabsProps
  extends Omit<MuiTabsProps, 'onChange' | 'value' | 'classes'> {
  tabs: (React.ReactElement | number | string)[];
  contentForTabs: (React.ReactElement | number | string)[];
  defaultTab?: number;
  classes?: StyleClasses<TabsClassKey>;
  icon?: React.ReactElement | number | string;
  disabledTabs?: number[];
  TabClassName?: any;
  loadAllTabContentOnMount?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLButtonElement>,
    newValue: number
  ) => void;
}

const StyledAppBar = withStyles({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    borderBottom: `thin solid ${CRYSTAL_BELL}`,
    zIndex: 1,
  },
})((props) => <AppBar {...props} />) as typeof AppBar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      width: '100%',
    },
    textInput: {
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: NORMAL_BLUE,
      },
      '& .MuiButtonBase-root': {
        fontSize: theme.typography.pxToRem(16),
      },
      '& .MuiTab-wrapper': {
        color: WRAPPER_BLUE,
      },
    },
  })
);
const StyledTabs = withStyles({
  root: {
    width: 'auto',
    minheight: 'auto',
    position: 'relative',
  },
  indicator: {
    height: 3,
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
  },
})((props) => (
  // TODO: check why ts is complaining here (and why it isn't for AppBar and Tab)
  // eslint-disable-next-line
  //@ts-ignore
  <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />
)) as typeof MuiTabs;

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: 'auto',
    padding: 16,
    textTransform: 'none',
    color: theme.palette.primary.main,
    ...theme.typography.h5,
    '&:focus': {
      opacity: 1,
    },
  },
  disabled: {
    backgroundColor: SATIN_WHITE,
    opacity: 0.3,
  },
}))((props) => (
  <Tab disableRipple disableFocusRipple {...props} />
)) as typeof Tab;

function TabPanel({
  children,
  value,
  index,
  classes,
  loadAllTabContentOnMount,
}) {
  const loadChild = loadAllTabContentOnMount || value === index;
  return (
    <div
      className={clsx(classes)}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {loadChild && <>{children}</>}
    </div>
  );
}

export function Tabs({
  tabs,
  contentForTabs,
  defaultTab,
  classes,
  icon,
  TabClassName,
  disabledTabs,
  loadAllTabContentOnMount = false,
  onChange,
}: TabsProps) {
  const [currentTabIndex, setCurrentTabIndex] = useState(defaultTab || 0);

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
    onChange && onChange(event, newValue);
  };
  const ownclasses = useStyles();
  return (
    <>
      <StyledAppBar className={clsx(classes?.appbar)} position="relative">
        <StyledTabs
          className={clsx(classes?.tabs, ownclasses.textInput, TabClassName)}
          value={currentTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs?.map((tab, index) => (
            <StyledTab
              disabled={disabledTabs?.includes(index)}
              className={clsx(classes?.tab)}
              label={tab}
              key={index}
            />
          ))}
          {icon && <>{icon}</>}
        </StyledTabs>
      </StyledAppBar>
      {contentForTabs?.map((content, index) => (
        <TabPanel
          classes={clsx(classes?.tabPanel)}
          key={index}
          value={currentTabIndex}
          index={index}
          loadAllTabContentOnMount={loadAllTabContentOnMount}
        >
          {content}
        </TabPanel>
      ))}
    </>
  );
}
