import React from 'react';
import { Grid } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleClasses } from '../StyleClasses';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import { FieldError } from '../FieldError/FieldError';
import InputBase from '@material-ui/core/InputBase';
import { Tooltip } from '../Tooltip/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export type ClassKey =
  | 'formControl'
  | 'inputLabel'
  | 'nativeSelect'
  | 'option'
  | 'paper';

export interface Textboxprops {
  disabled?: boolean;
  OnChange?: (e: any) => void;
  Onblur?: (event: any) => void;
  OnClick?: (e: any) => void;
  OnKeydown?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onFocus?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  placeHolder?: any;
  required?: boolean;
  className?: string;
  inputlabel?: string;
  infoTitle?: any;
  infoIcon?: boolean;
  inputLabelClassname?: any;
  infoIconClassname?: any;
  tooltipClassName?: any;
  formControlClassName?: any;
  inputTextBoxClassname?: any;
  dollarTextClassName?: any;
  inputlabelClasses?: StyleClasses<ClassKey>;
  isCurrency?: boolean | undefined;

  maxlength?: number;
  minlength?: number;
  type?: 'password' | 'text' | 'number' | 'email' | 'phoneno';
  id?: string;
  name?: string;
  hidden?: boolean;
  value: string;
  pattern?: string;
  errorMessage?: string;
  digitFormat?: 'normal' | 'currency';
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(0),
      },
    },

    input: {
      borderRadius: theme.typography.pxToRem(5),
      position: 'relative',

      backgroundColor: theme.palette.background.paper,
      border: `${theme.typography.pxToRem(1)} solid #c4c4c4`,
      fontSize: theme.typography.pxToRem(14),
      padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(
        11.2
      )} ${theme.typography.pxToRem(7)} ${theme.typography.pxToRem(11.2)}`,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      maxWidth: '100%',

      '&:focus': {
        borderRadius: theme.typography.pxToRem(5),
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.25rem rgba(0,123,255,.25)',
      },
      '&:disabled': {
        backgroundColor: 'rgb(235, 235, 228)',
      },
    },
  })
)(InputBase);
const StyledInputLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(14),

    position: 'relative',
    '& span': {
      color: 'red !important',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))((props) => <InputLabel {...props} />) as typeof InputLabel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      width: '100% !important',
    },
    textInput: {
      '& .MuiInputBase-input': {
        borderTopLeftRadius: '0 !important' as any,
        borderBottomLeftRadius: '0 !important' as any,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
        margin: 0,
      },

      '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
        margin: 0,
      },
      '& input::-ms-reveal': {
        display: 'none !important' as any,
      },
    },

    dollarInput: {
      width: '13%',
      padding: '9px 14px',
    },
    dollarInputText: {
      width: '87%',
    },
    InfoIconGrid: {
      display: 'flex',
    },
    InfoLabel: {
      display: 'inline',
    },
    InfoIcon: {
      width: '15px !important',
      color: '#007EFF',
      height: 'auto !important',
    },
  })
);

export function RacTextbox(props: Textboxprops) {
  const handleOnblurFromPackage = (e: any) => {
    if (e.target.value !== '' && props.isCurrency === true) {
      e.target.value = e.target.value.replaceAll(',', '');
      let decimalSeparation = e.target.value.split('.');
      if (
        !!decimalSeparation &&
        !!decimalSeparation.length &&
        decimalSeparation.length == 1
      ) {
        e.target.value = parseInt(e.target.value).toFixed(2);
      }
      decimalSeparation = e.target.value.split('.');
      const nf = new Intl.NumberFormat();
      let value = nf.format(decimalSeparation[0]);
      if (
        !!decimalSeparation &&
        !!decimalSeparation.length &&
        decimalSeparation.length > 1
      ) {
        if (decimalSeparation[1].length > 1) {
          value = value + '.' + decimalSeparation[1].slice(0, 2).toString();
        } else if (decimalSeparation[1].length < 1) {
          value = value + '.00';
        } else {
          value =
            value + '.' + decimalSeparation[1].slice(0, 1).toString() + '0';
        }
      } else {
        value = value + '.00';
      }

      e.target.value = value;
      props.OnChange(e);
    } else {
      props.OnChange(e);
    }
  };

  const currencyOnChange = (e: any, number) => {
    if (number.test(e.target.value)) {
      const decimalSeparation = e.target.value.split('.');

      const nf = new Intl.NumberFormat();
      let value = nf.format(decimalSeparation[0]);
      const decimalSeparationIndex1 = decimalSeparation[1];
      if (
        decimalSeparationIndex1 !== undefined &&
        decimalSeparationIndex1 !== null
      ) {
        value = value + '.' + decimalSeparationIndex1.slice(0, 2).toString();
      }
      e.target.value = value;
      props.OnChange(e);
    }
  };
  const numberOnChange = (e: any) => {
    if (props.isCurrency === true) {
      const numberWithDigit = /^[0-9]*\.?[0-9]*$/;
      e.target.value = e.target.value.replaceAll(',', '');
      if (e.target.value) {
        currencyOnChange(e, numberWithDigit);
      } else {
        props.OnChange(e);
      }
    } else if (props.isCurrency === false || props.isCurrency === undefined) {
      const number = /^[0-9\b]+$/;

      if (e.target.value) {
        if (number.test(e.target.value)) {
          const value2 = e.target.value;
          e.target.value = value2;
          props.OnChange(e);
        }
      } else {
        props.OnChange(e);
      }
    }
  };

  const phonenoOnChange = (e: any) => {
    const value = e.target.value;
    if (value.length > 1 && value.length < 3) {
      e.target.value = '(' + value;
    } else if (value.length > 1 && value.length <= 3) {
      e.target.value = '(' + value;
    } else if (value.length > 1 && value.length < 4) {
      e.target.value = '(' + value + ') ';
    } else if (value.length > 1 && value.length <= 6) {
      e.target.value = '(' + value.slice(0, 3) + ') ' + value.slice(3);
    } else if (value.length > 1 && value.length < 12) {
      e.target.value =
        '(' +
        value.slice(0, 3) +
        ') ' +
        value.slice(3, 6) +
        '-' +
        value.slice(6, 11);
    }

    props.OnChange(e);
  };
  const handleOnChangeFromPackage = (e: any) => {
    if (props.type == 'number') {
      numberOnChange(e);
    } else if (props.type == 'email') {
      const regex =
        /^(([^<>()[\],;:\s@"]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
      if (e.target.value === '' || regex.test(e.target.value)) {
        const val = e.target.value;
        e.target.value = val;
      }
      props.OnChange(e);
    } else if (props.type == 'phoneno') {
      const number = /^[0-9\b]+$/;

      e.target.value = e.target.value.replaceAll('(', '');
      e.target.value = e.target.value.replaceAll(') ', '');
      e.target.value = e.target.value.replaceAll('-', '');

      if (e.target.value === '' || number.test(e.target.value)) {
        phonenoOnChange(e);
      }
    } else {
      props.OnChange(e);
    }
  };
  const classes = useStyles();

  return (
    <div>
      {props.isCurrency === false || props.isCurrency === undefined ? (
        <FormControl
          className={clsx(classes.margin, props.formControlClassName)}
        >
          <Grid className={classes.InfoIconGrid}>
            <StyledInputLabel
              className={clsx(props.inputLabelClassname)}
              disableAnimation
              required={props.required}
            >
              {props.inputlabel}
            </StyledInputLabel>
            {props.infoIcon ? (
              <Tooltip
                placement={'right'}
                title={props.infoTitle}
                className={props.tooltipClassName}
              >
                <InfoIcon
                  className={classes.InfoIcon}
                  classes={props.infoIconClassname}
                ></InfoIcon>
              </Tooltip>
            ) : null}
          </Grid>

          <BootstrapInput
            className={clsx(props.className)}
            placeholder={props.placeHolder}
            aria-describedby="my-helper-text"
            type={props.type == 'number' ? 'text' : props.type}
            id={props.id}
            value={props.value}
            error={true}
            name={props.name}
            hidden={props.hidden}
            disabled={props.disabled}
            onChange={handleOnChangeFromPackage}
            onFocus={props.onFocus}
            onKeyDown={props.OnKeydown}
            onKeyPress={props.onKeyPress}
            onClick={props.OnClick}
            onBlur={(event) => {
              props.Onblur == undefined
                ? handleOnblurFromPackage(event)
                : props.Onblur(event);
            }}
            inputProps={{
              minLength: props.minlength,
              maxLength: props.maxlength,
            }}
          />
          {props.errorMessage && (
            <FieldError errorMessage={props.errorMessage} />
          )}
        </FormControl>
      ) : (
        <FormControl
          className={clsx(classes.margin, props.formControlClassName)}
        >
          <StyledInputLabel
            disableAnimation
            required={props.required}
            className={clsx(props.inputLabelClassname)}
          >
            {props.inputlabel}
          </StyledInputLabel>

          <Grid container alignItems={'flex-start'}>
            <Grid
              item
              className={clsx(props.dollarTextClassName, classes.dollarInput)}
              style={{
                borderTopLeftRadius: 9,
                borderBottomLeftRadius: 9,

                border: '1px solid #ced4da',
                textAlign: 'center',
                backgroundColor: '#e9ecef',
              }}
            >
              $
            </Grid>

            <BootstrapInput
              className={clsx(
                classes.textInput,

                classes.dollarInputText,

                props.className
              )}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              type={props.type == 'number' ? 'text' : props.type}
              id={props.id}
              value={props.value}
              name={props.name}
              disabled={props.disabled}
              onChange={handleOnChangeFromPackage}
              onKeyDown={props.OnKeydown}
              onKeyUp={props.onKeyUp}
              onKeyPress={props.onKeyPress}
              // eslint-disable-next-line sonarjs/no-identical-functions
              onBlur={(event) => {
                props.Onblur == undefined
                  ? handleOnblurFromPackage(event)
                  : props.Onblur(event);
              }}
              onFocus={props.onFocus}
              onClick={props.OnClick}
              inputProps={{
                minLength: props.minlength,
                maxLength: props.maxlength,
              }}
            />
            {props.errorMessage && (
              <FieldError errorMessage={props.errorMessage} />
            )}
          </Grid>
        </FormControl>
      )}
    </div>
  );
}
