import { CssBaseline, MuiThemeProvider, Theme } from '@material-ui/core';
import React from 'react';
import { theme } from '../../theme/theme';

export interface ThemeProviderProps {
  children: JSX.Element;
  customTheme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
}
export function ThemeProvider({ children, customTheme }: ThemeProviderProps) {
  return (
    <MuiThemeProvider theme={customTheme || theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
