/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles } from "@rentacenter/racstrap";
export const PricingReportStyle = () => {
  const useClasses = makeStyles((theme) => ({
    textSpaceNorecords: {
      textAlign: "center",
      width: "100%",
    },
    gridtextcenterNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(5),
      width: "100%",
      fontFamily: "OpenSans-regular",
      fontSize: theme.typography.pxToRem(14),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    gridgenpopupbtn: {
      marginBottom: theme.typography.pxToRem(10),
      marginLeft: theme.typography.pxToRem(10),
      marginTop: theme.typography.pxToRem(10),
    },
    textcenterNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(60),
      width: "100%",
      fontFamily: "OpenSans-regular",
      fontSize: theme.typography.pxToRem(14),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    footerFixed: {
      backgroundColor: "#fff !important",
      padding: "1rem !important",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    tooltip: {
      backgroundColor: "white !important",
      fontSize: "14px !important",
      fontFamily: "OpenSans-regular !important",
      color: "black !important",
      border: "1px solid !important",
      textAlign: "justify",
    },
    Tooltiparrow: {
      display: "none",
    },
    floatLeft: {
      float: "left",
    },
    floatRight: {
      float: "right",
    },
    racErrorIcon: {
      width: "50px",
    },
    textcenterImage: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(20),
      width: "100%",
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    //multi select CSS
    demo: {
      "& .dropdown-content": {
        "& .search": {
          border: "1px solid #e3e3e3",
        },
      },
      "& .options": {
        overflowX: "hidden !important",
      },
    },

    multioverflow: {
      overflow: "visible !important",
    },

    demopopup: {
      "& div": {
        "& div": {
          overflowY: "visible !important",
          width: "312px !important",
        },
      },
    },
    disableMultiselect: {
      "& .dropdown-container": {
        backgroundColor: "#d5d4d4",
      },
    },
    customMultiselect: {
      "& .dropdown-container": {
        width: "270px !important",
        "& div": {
          width: "270px !important",
          overflow: "hidden !important",
        },
      },
      "& .dropdown-heading": {
        height: "30px !important",
        width: "270px !important",
        paddingRight: "0px",
        "& .gray": {
          color: "#808080",
        },
        "& .dropdown-heading-dropdown-arrow": {
          color: "#212529",
        },
      },
    },
    pbcustom: {
      "& div": {
        "& div": {
          width: "270px !important",
        },
      },
    },
    pbcustom1: {
      paddingBottom: "20px !important",
      width: "270px !important",
    },
    genbtn: {
      marginRight: "25px",
    },
    circularprogress: {
      "& div": {
        width: "30px !important",
        height: "30px !important",
      },
    },
    //loader css new
    masterLoader: {
      position: "fixed",
      // eslint-disable-next-line no-undef
      backgroundColor: "#212529",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1000,
      textAlign: "center",
      margin: 0,
    },

    Loader: {
      display: "block",
      position: "fixed",
      zIndex: 1051,
      top: "50%",
      right: "50%",
    },
    //end of style
    successicon: {
      marginBottom: theme.typography.pxToRem(15),
      marginLeft: theme.typography.pxToRem(189),
    },
    genpop: {
      fontFamily: "OpenSans-bold",
      marginBottom: theme.typography.pxToRem(15),
      textAlign: "center",
    },
    genpopupbtn: {
      marginBottom: theme.typography.pxToRem(15),
      marginLeft: theme.typography.pxToRem(177),
    },
    headingCss: {
      padding: theme.typography.pxToRem(8),
      fontSize: theme.typography.pxToRem(14),
    },
    pagination: {
      display: "flex",
      listStyle: "none",
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
      justifyContent: "flex-end",
      cursor: "pointer",
    },
    paginationprevnext: {
      color: "#000",
      padding: "5px 17px",
      display: "inline-block",
    },
    paginationprevnextdisable: {
      color: "lightgray !important",
      padding: "5px 17px",
      display: "inline-block",
    },
    pageactive: {
      backgroundColor: "#2468FF",
      color: "#fff",
      borderRadius: "50%",
    },
    pagelink: {
      position: "relative",
      display: "block",
      textDecoration: "none",
      padding: "5px 0px",
      width: "29px",
      textAlign: "center",
    },
    totrec: {
      float: "left",
    },
    //grid table column width
    p36: {
      marginRight: theme.typography.pxToRem(8),
    },
    zonewidth: {
      width: "15%",
    },
    typewidth: {
      width: "23%",
    },
    datewidth: {
      width: "23%",
    },
    statuswidth: {
      width: "15%",
    },
    actionwidth: {
      width: "15%",
    },
    textCenter: {
      textAlign: "center",
    },
    validerroralign: {
      marginTop: "7px",
      paddingLeft: "2px",
    },
    Top: {
      marginTop: "5px",
    },
    Bottom: {
      marginBottom: "5px",
    },
    infocss: {
      marginLeft: theme.typography.pxToRem(4),
      width: "12px",
    },
    prcingMultiSelect: {
      "& .dropdown-container": {
        "& div": {
          overflow: "hidden !important",
        },
        height: "31px",
      },
      "& dropdown-heading": {
        height: "15px !important",
      },
    },
    prcingzoneMultiSelect: {
      "& .dropdown-container": {
        "& div": {
          overflow: "hidden !important",
        },
        height: "31px",
      },
      "& .dropdown-heading": {
        height: "31px !important",
      },
      "& .dropdown-content": {
        borderRadius: "8px",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
        zIndex: "99",
      },
    },
    promozoneMultiSelect: {
      "& .dropdown-container": {
        "& div": {
          overflow: "hidden !important",
        },
        height: "31px",
      },
      "& .dropdown-heading": {
        height: "31px !important",
      },
      "& .dropdown-content": {
        borderRadius: "8px",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
        zIndex: "99",
        width:'550px',
        position:'relative',
        top:0
      },
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = useClasses();
  return classes;
};
