import React, { PropsWithChildren } from 'react';
import {
  makeStyles,
  Tooltip as MuiTooltip,
  TooltipProps,
} from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontWeight: 400,
    left: 0,
    fontFamily: 'sans-serif',
    fontSize: '0.875rem',
    backgroundColor: theme.palette.common.black,
  },
}));

export function Tooltip({
  title,
  arrow = true,
  placement = 'right',
  children,
  ...rest
}: PropsWithChildren<TooltipProps>) {
  const classes = useStylesBootstrap();

  return (
    <MuiTooltip
      classes={classes}
      title={title}
      arrow={arrow}
      placement={placement}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}
