import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@material-ui/core/Accordion';
import AccordionDetails, {
  AccordionDetailsProps,
} from '@material-ui/core/AccordionDetails';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { LAVENDER_MIST } from '../../colors/colors';

export interface AccordionProps {
  summary?: React.ReactElement | number | string;
  details?: React.ReactElement | number | string;
  defaultExpanded?: boolean;
  accordionProps?: Partial<MuiAccordionProps>;
  summaryProps?: Partial<AccordionSummaryProps>;
  detailsProps?: Partial<AccordionDetailsProps>;
}

const StyledAccordion = withStyles({
  root: {
    width: '100%',
    boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%)',
  },
})((props: MuiAccordionProps) => (
  <MuiAccordion {...props} />
)) as typeof MuiAccordion;

const StyledAccordionSummary = withStyles((theme) => ({
  expanded: {
    minHeight: 'unset !important',
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    '& .MuiIconButton-label': {
      backgroundColor: LAVENDER_MIST,
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
  },
}))((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
)) as typeof AccordionSummary;

const StyledAccordionDetails = withStyles({
  root: {
    padding: '1rem',
  },
})((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
)) as typeof AccordionDetails;

export function Accordion({
  summary,
  details,
  defaultExpanded,
  accordionProps,
  summaryProps,
  detailsProps,
}: AccordionProps) {
  return (
    <StyledAccordion defaultExpanded={defaultExpanded} {...accordionProps}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        {...summaryProps}
      >
        {summary}
      </StyledAccordionSummary>
      <Divider />
      <StyledAccordionDetails {...detailsProps}>
        {details}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
