import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface Badgeprops {
  handleOnClick: (event: any) => void;
  backgroundColor: string;
  color: string;
  TextValue: string;
  OnclickNeed: boolean;
  children?: any;
  className?: any;
}

const useStyles = makeStyles((theme: any) => ({
  dialogTitle: {
    background: '#DD4F89',
    color: '#fff !important',
    borderRadius: theme.typography.pxToRem(50),
  },
  '&:hover': {
    color: '#fff',
  },
  buttonStyle: {
    display: 'inline-block',
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: 'center',
    textDecoration: 'none',
    verticalAlign: 'middle',
    border: `${theme.typography.pxToRem(1)} solid transparent`,
    transition: 'color 0.15s ease-in-out',

    borderColor: ' 0.15s ease-in-out',
    boxShadow: '0.15s ease-in-out',
  },
  btnsm: {
    padding: '0.25rem 0.5rem !important',
    fontSize: '0.875rem',
    borderRadius: '1.2rem !important',
  },
  badge: {
    display: 'inline-block !important',
    padding: '0.35em 0.65em !important',
    fontSize: '0.75em !important',
    fontWeight: 700,
    lineHeight: '1!important',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline !important',
    borderRadius: '0.25rem!important',
  },
  racpadBadge: {
    borderRadius: `${theme.typography.pxToRem(20)}!important`,
  },
}));

export function RacBadge({
  handleOnClick,
  backgroundColor,
  TextValue,
  OnclickNeed,
  className,
  color,
  children,
}: Badgeprops) {
  const ownClasses = useStyles();

  return (
    <Grid>
      {OnclickNeed === true ? (
        <Button
          type="button"
          className={clsx(
            ownClasses.btnsm,
            ownClasses.dialogTitle,
            ownClasses.buttonStyle,
            ownClasses['&:hover'],
            className
          )}
          style={{ background: backgroundColor, color: color }}
          onClick={(event) => handleOnClick(event)}
        >
          {TextValue}
          {children}
        </Button>
      ) : (
        <Grid
          className={clsx(ownClasses.badge, ownClasses.racpadBadge, className)}
          style={{ background: backgroundColor, paddingTop: 2, color: color }}
        >
          {TextValue}
          {children}
        </Grid>
      )}
    </Grid>
  );
}
