/* eslint-disable no-console */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import { RACThemeProvider, makeStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const History = require('history');
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../index';
// // eslint-disable-next-line @typescript-eslint/no-var-requires
// const History = require('history');

interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
}));

export const App = ({ history, customProps }: Props) => {
  const { isRenderedByContainer } = window;
  const classes = useStyles();

  const enableLogs = window.sessionStorage.getItem('enableLogs');
  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
  return (
    <div data-testid={appTestId}>
      <RACThemeProvider>
        <ContainerContext.Provider value={customProps}>
          <div className={clsx(classes.container)}>
            <ErrorBoundary>
              <Router history={history}>
                {isRenderedByContainer ? (
                  <InContainerAuthProvider>
                    <AuthGuard />
                  </InContainerAuthProvider>
                ) : (
                  <StandaloneAuthProvider>
                    <AuthGuard />
                  </StandaloneAuthProvider>
                )}
              </Router>
            </ErrorBoundary>
          </div>
        </ContainerContext.Provider>
      </RACThemeProvider>
    </div>
  );
};
