/* eslint-disable */
import {
  Typography,
  Grid,
  RACButton,
  List,
  ListItem,
  Card,
  CardContent,
  RACTextbox,
  RACSelect,
  RACModalCard,
} from "@rentacenter/racstrap";
import React, { useContext, useState, useEffect } from "react";
import { autoTermContext } from "../autoTerm/AutoTermContext";
import { MultiSelect } from "react-multi-select-component";
import { globalStyles } from "../Styles/globalstyles";
import { PricingReportStyle } from "../Styles/PricingReport";
import WarningIcon from "../../../assets/images/no-records-found.svg";
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as AccordianOpen } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as AccordianClose } from "../../../assets/images/down-arrow-filter.svg";
export default function AutoTermFilter() {
  // const classes = globalStyles();
  const pricing = PricingReportStyle();
  const {
    companyCode,
    setCompanyCode,
    departmentOptions,isFranchiseeUser, setIsFranchiseeUser,
    errorPopup,bracketOptionsCopy,setBracketOptionsCopy,
    bracketOptions, setBracketOptions,
    zoneSelected,
    setZoneSelected,
    isFilterOpen,gridLoader,
    zoneOptions,
    setZoneOptions,setFilterReqAtApply,
    setIsFilterOpen,setPageNumber,
    setDisplayGrid,
    displayGrid,
    validateInput,
    fetchAutoTermDetails,
    setNoRecords,setTotalRowCount,
    setDepartmentOptions,
    subDepartmentOptions,
    setSubDepartmentOptions,
    originalSubDeptArr,
    setErrorPopup,
    loader,
    setGridData,
    setLoader,
    filterOptions,
    isTrue,
    setIsTrue,
    setFilterOptions,
  } = useContext(autoTermContext);

  //   const [companyCode, setCompanyCode] = useState([]);
  //   const [departmentOptions, setDepartmentOptions] = useState([]);
  //   const [subDepartmentOptions, setSubDepartmentOptions] = useState([]);
  //   const [loader, setLoader] = useState(false);
  //   const [errorPopup, setErrorPopup] = useState({ isOpen: false, statement: '' });
  //   const [filterOptions, setFilterOptions] = useState({
  //     zoneNumber: [],
  //     // ... (Other keys from filterOptions)
  //   });
  {
    console.log(displayGrid, "the disafgbhnj");
  }

  // const handleApplyClick = () => {
  //   fetchAutoTermDetails();
  // };

  const handleClearClick = () => {
    setZoneSelected([])
    setFilterOptions({
      zoneNumber: [],
      // ... (Other keys in filterOptions set to default values)
    });
    setDisplayGrid(false);
    setSubDepartmentOptions(JSON.parse(JSON.stringify(originalSubDeptArr)));
    setBracketOptions(JSON.parse(JSON.stringify(bracketOptionsCopy)));
  };

  useEffect(() => {}, [errorPopup.isOpen]);
  const handleZoneSelect = (selectedZone: any) => {
    setFilterOptions({
      ...filterOptions,
      zoneNumber: selectedZone,
    });
  };

  const handleInputChange = (value: any, key: any) => {
    debugger;
    const validatedValue = validateInput(key, value.target.value).trim();
    setFilterOptions({
      ...filterOptions,
      [key]: validatedValue,
    });
  };

  return (
    <>
      <Grid
        container
        md={12}
        lg={12}
        sm={12}
        xs={12}
        style={{ paddingTop: "1%", paddingBottom:"0%" }}
      >
        <Grid
          item
          md={9}
          lg={9}
          sm={4}
          xs={4}
          style={{ padding: "0.5% 0%", display: "flex" }}
        >
          <Typography variant="h4" style={{ alignSelf: "center" }}>
            Autoterm Details
          </Typography>
        </Grid>
        <Grid item md={3} lg={3} sm={3} xs={3} style={{ float: "right" }}>
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            style={{ cursor: "pointer", float: "right" }}
            
            onClick={() => {
              if(displayGrid)
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            <RacFilter></RacFilter>
            <span style={{ margin: "0px 8px" }}>Filter</span>
            {/* Need to add filter icon !important */}
            {isFilterOpen ? <AccordianOpen /> : <AccordianClose />}
          </RACButton>
        </Grid>
      </Grid>
      {isFilterOpen ? (
        <Grid style={{ margin: "0.5% 0% 1.5% 0%", overflow: "visible" }}>
          <Card style={{ overflow: "visible" }}>
            <CardContent style={{ overflow: "visible" }}>
              <Grid container spacing={4}>
                <Grid item md={2}>
                  <Typography>
                    Zone<span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <RACSelect
                    multiple
                    options={zoneOptions}
                    defaultValue={filterOptions.zoneNumber}
                    onChange={(e) => {
                      //Which spreads the arrray and checks for the '' string & if yes, removes it from the array and updates it to the set variable.
                      let temp = [...e.target.value];
                      if (temp.includes(""))
                        temp.splice(e.target.value.indexOf(""), 1);
                      setFilterOptions({
                        ...filterOptions,
                        ["zoneNumber"]: [...temp],
                      });
                    }}
                  /> */}
                  <MultiSelect
                    isLoading={zoneOptions.length > 1 || (isFranchiseeUser && companyCode.length ==0)? false : true}
                    options={zoneOptions}
                    value={zoneSelected}
                    className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                    onChange={(e: any) => {
                      setZoneSelected(e);
                      let arr = e.map((item: any) => {
                        if (item.value == item.value) return item.value;
                      });
                      console.log(arr);

                      setFilterOptions({
                        ...filterOptions,
                        ["zoneNumber"]: arr,
                      });
                    }}
                    labelledBy={"Select"}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Department</Typography>
                  <RACSelect
                    options={departmentOptions}
                    loading={departmentOptions.length > 1 ? false : true}
                    defaultValue={
                      filterOptions.departmentNumber
                        ? filterOptions.departmentNumber
                        : ""
                    }
                    onChange={(e) => {
                      setFilterOptions({
                        ...filterOptions,
                        ["departmentNumber"]: e.target.value,
                        ["subdepartmentNumber"]: "",
                      });
                      let restrictSubDept = originalSubDeptArr.filter(
                        (item: any) => {
                          if (item.deptId == e.target.value) {
                            return item;
                          }
                        }
                      );
                      restrictSubDept.sort((a: any, b: any) =>
                      a["label"] < b["label"] ? -1 : 1).unshift({
                        label: "Select",
                        value: "",
                        deptId: "",
                      });
                      if(e.target.value !='')
                      setSubDepartmentOptions(restrictSubDept);
                    else {
                      setBracketOptions(bracketOptionsCopy);
                    setSubDepartmentOptions(originalSubDeptArr)};
                      
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Sub Department</Typography>
                  <RACSelect
                    loading={subDepartmentOptions.length > 1 ? false : true}
                    options={subDepartmentOptions}
                    defaultValue={
                      filterOptions.subdepartmentNumber
                        ? filterOptions.subdepartmentNumber
                        : ""
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      setFilterOptions({
                        ...filterOptions,
                        ["subdepartmentNumber"]: e.target.value,
                        ['bracketNumber'] : ''
                      });
                      let bracketOpts = bracketOptionsCopy.filter((obj: any) => {
                        console.log(obj, e.target.value);

                        if (obj.subdeptId == e.target.value) {
                          return obj;
                        }
                        // return obj.departmentNumber == e.target.value;
                      });
                      console.log("subDeptOptions1", subDepartmentOptions);
                      console.log(bracketOpts, "The sub mohan");
                      bracketOpts.sort((a: any, b: any) =>
                      a["label"] < b["label"] ? -1 : 1).unshift({
                        label: "Select",
                        value: "",
                        deptId: "",
                      });
                      if(e.target.value !='')
                      setBracketOptions(bracketOpts);
                    else    
                      setBracketOptions(bracketOptionsCopy)

                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  {/* <Typography>Bracket</Typography> */}
                  {/* <RACTextbox
                    maxlength={10}
                    value={
                      filterOptions.bracketNumber ? filterOptions.bracketNumber : ""
                    }
                    OnChange={(e) => handleInputChange(e, "bracketNumber")}
                  /> */}
                   <RACSelect
                inputLabel="Bracket"
                options={bracketOptions}//bracketDd
                defaultValue={filterOptions?.bracketNumber ? filterOptions?.bracketNumber : ""}//filterPackagePayload.bracketName ? filterPackagePayload.bracketName : '0'
                onChange={(e) => handleInputChange(e, "bracketNumber")}
                loading={bracketOptions.length > 1 ? false : true}
                ></RACSelect>
                </Grid>
                <Grid item md={2}>
                  <Typography>Brand</Typography>
                  <RACTextbox
                    maxlength={50}
                    value={
                      filterOptions.brandName ? filterOptions.brandName : ""
                    }
                    OnChange={(e) => handleInputChange(e, "brandName")}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Model #</Typography>
                  <RACTextbox
                    maxlength={30}
                    value={
                      filterOptions.modelNumber ? filterOptions.modelNumber : ""
                    }
                    OnChange={(e) => handleInputChange(e, "modelNumber")}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>RMS #</Typography>
                  <RACTextbox
                    maxlength={13}
                    value={
                      filterOptions.rmsItemNumber
                        ? filterOptions.rmsItemNumber
                        : ""
                    }
                    OnChange={(e) => handleInputChange(e, "rmsItemNumber")}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid md={12} style={{ float: "right" }} spacing={3}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    // The apply button enabled only if the zone is selected.
                    disabled={
                      filterOptions["zoneNumber"]?.length > 0 &&
                      !filterOptions["zoneNumber"]?.includes("")   && !gridLoader
                    // &&
                    //   // (filterOptions["modelNumber"] == null ||
                    //   //   filterOptions["modelNumber"].length == 10) &&
                    //   // (filterOptions["bracketName"] == null ||
                    //   //   filterOptions["bracketName"].length == 10) &&
                    //   // (filterOptions["brandName"] == null ||
                    //   //   filterOptions["brandName"].length == 10) &&
                    //   // (filterOptions["rmsItemNumber"] == null ||
                    //   //   filterOptions["rmsItemNumber"].length == 13)
                        ? false
                        : true
                    }
                    size="small"
                    style={{
                      float: "right",
                      cursor: "pointer",
                      marginLeft: "2%",
                    }}
                    
                    onClick={() => {
                      setDisplayGrid(true);
                      setTotalRowCount(0);
                      setPageNumber(0);
                      fetchAutoTermDetails(0, filterOptions);
                      setFilterReqAtApply(JSON.parse(JSON.stringify(filterOptions)));
                      // console.log(filterOptions);
                    }}
                  >
                    Apply
                  </RACButton>
                  <RACButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled= { !gridLoader ? false
                      : true }
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={() => handleClearClick()}
                  >
                    Clear
                  </RACButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : null}
    </>
  );
}
