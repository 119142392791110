import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { TimePicker } from '../TimePicker/TimePicker';

export interface TimeRangePickerProps {
  name?: string;
  id?: string;
  fromLabel: React.ReactElement | string;
  toLabel: React.ReactElement | string;
  fromValue?: string;
  toValue?: string;
  onFromChanged?: (value: string) => void;
  onToChanged?: (value: string) => void;
  disabled?: boolean;
  fromErrorMessage?: string;
  toErrorMessage?: string;
}

const useStyles = makeStyles(() => ({
  timeRangePickerRoot: {
    display: 'flex',
    gap: '0.5rem',
  },
}));

export function TimeRangePicker({
  name,
  id,
  fromLabel,
  toLabel,
  fromValue,
  toValue,
  onFromChanged,
  onToChanged,
  disabled,
  fromErrorMessage,
  toErrorMessage,
}: TimeRangePickerProps) {
  const ownClasses = useStyles();
  const [maxFrom, setMaxFrom] = useState();
  const [minTo, setMinTo] = useState();

  const handleOnFromChanged = (e) => {
    setMinTo(e);
    onFromChanged(e);
  };

  const handleOnToChanged = (e) => {
    setMaxFrom(e);
    onToChanged(e);
  };

  return (
    <div className={ownClasses.timeRangePickerRoot}>
      <TimePicker
        onChange={handleOnFromChanged}
        value={fromValue}
        label={fromLabel}
        inputProps={{ max: maxFrom }}
        name={`${id || name}From`}
        disabled={disabled}
        errorMessage={fromErrorMessage}
      />
      <TimePicker
        onChange={handleOnToChanged}
        value={toValue}
        label={toLabel}
        inputProps={{ min: minTo }}
        name={`${id || name}To`}
        disabled={disabled}
        errorMessage={toErrorMessage}
      />
    </div>
  );
}
