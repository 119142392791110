/* eslint-disable */
import { Grid } from "@rentacenter/racstrap";
import PromoContext from "./PromoSchedulerContext";
import PromoSchedulerGrid from "./PromoSchedulerGrid";
export default function PromoScheduler() {
  return (
    <PromoContext>
      <Grid container>
        <PromoSchedulerGrid />
      </Grid>
    </PromoContext>
  );
}
